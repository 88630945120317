import React, { Component } from "react";
import { connect } from "react-redux";
import WebNotif from "../WebNotif";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { urlImageUrl } from "../../config/urlapi";
import Loader from "react-loader-spinner";
import Moment from "react-moment";
import MUIDataTable from "mui-datatables";
import CustomToolbarSelect from "./CustomToolbarSelect";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { TextField, MenuItem } from "@material-ui/core";
import ClipLoader from "react-spinners/ClipLoader";
// import Cleave from 'cleave.js/react';
import Dropzone from "react-dropzone";
import * as yup from "yup";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import fs from "fs";
import Modal from "react-modal";
import Select from "react-select";
import {
  getadmins,
  getFilterAdmins,
  searchAdminUsers,
  getadmincount,
  getRoles,
  deleteAdminUser,
  deleteMultiple,
  editBulk,
  addAdmin,
  editAdmin,
} from "../../redux/admin/users/adminuseractions";
var obj = new WebNotif();

const imagePath = "uploads/";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "600px",

    overflowY: "hidden",
    backgroundColor: "#fff",
    border: "none",
    color: "black",
  },
  overlay: {
    zIndex: 1000,
    backgroundColor: "rgba(0, 0, 0, 0.35)",
  },
};

const customStyles1 = {
  content: {
    marginTop: "20px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "600px",
    backgroundColor: "#fff",
    border: "none",
    color: "black",
  },
  overlay: {
    zIndex: 1000,
    backgroundColor: "rgba(0, 0, 0, 0.35)",
  },
};

const acceptedImageFileTypes =
  "image/x-png, image/png, image/jpg, image/jpeg, image/gif";
const acceptedImageFileTypesArray = acceptedImageFileTypes
  .split(",")
  .map((item) => {
    return item.trim();
  });

Modal.setAppElement("#root");

// function CleaveInput(props) {
//   const { inputRef, ...rest } = props;
//   return (
//     <Cleave
//       ref={inputRef}
//       // placeholder="Enter your credit card number"
//       options={{
//         delimiter: '-',
//         blocks: [5, 7, 1],
//       }}
//       {...rest}
//     />
//   );
// }

const addAdminSchema = yup.object().shape({
  email: yup.string().email().required(),
  fullname: yup.string().required(),

  role: yup.string().required(),

  description: yup.string().required(),
});

const editAdminSchema = yup.object().shape({
  fullname: yup.string().required(),
  role: yup.string().required(),
  description: yup.string().required(),
});

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "green",
    },
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "#20a84c",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#20a84c",
      },
    },
  },
})(TextField);

class UserManagement extends Component {
  state = {
    load: false,
    isRemoved: false,
    imageUrl: null,
    image64: null,
    rolesSet: [],
    rolesSet1: [],
    perPage: 25,
    page: 0,
    limit: 25,
    modalIsOpen: false,
    originalData: [],
    data: [],
    realData: [],
    modalIsOpen: false,
    modalIsOpen1: false,
    isEditable: false,
    allowPagination: true,
    filterOptionsState: false,
    simpleOptionsState: true,
    searchOptionsState: false,
    searchType: [
      { label: "Email", value: "userinfo.PrimaryEmail" },
      { label: "Full Name", value: "userinfo.Fullname" },
    ],
    selectedType: "",
    search: "",
    offset: 0,
    count: 0,
    selected_role: { value: "", Label: "Select Role" },
    selected_role_value: "",
    getUserId: "",
    detailsIsOpen: false,
    userID: [],
    userDescription: [],
    aboutDetails: "",
    nameDetails: "",
    emailDetails: "",
    roleDetails: "",
    formdata: {
      profilePicture: [],
      fullname: "",
      email: "",
      role: "",
      description: "",
    },
  };
  //lifecycle
  constructor(props) {
    super(props);

    this.searchEmailInput = React.createRef();
    this.searchNameInput = React.createRef();
    this.searchTypeInput = React.createRef();
    this.searchTextInput = React.createRef();
    this.searchRoleInput = React.createRef();
    this.searchStatusInput = React.createRef();

    // this.searchFilter = this.searchFilter.bind(this);
  }

  componentDidMount() {
    // this.setState({ load: true });
    // console.log('Inside usermanagement');
    // console.log(this.props.permissions)

    this.getadminfunction();

    this.props
      .getRoles()
      .then((res) => {
        // console.log(res)
        var temp = [];

        for (var i = 0; i < res.length; i++) {
          var obj = { value: res[i].Role_ID, label: res[i].RoleName };
          temp.push(obj);
        }
        this.setState({ rolesSet: temp, rolesSet1: res, load: false }, () => {
          // console.log(this.state.rolesSet)
        });
      })
      .catch((err) => console.log(err));
  }

  //bulk edit modal handlers
  openModal = (item) => {
    this.setState({ modalIsOpen: true, userstoEdit: item }, () => {
      // console.log(this.state.userstoEdit)
    });
  };
  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };
  detailsClose = () => {
    this.setState({
      detailsIsOpen: false,
      image64: null,
      filterOptionsState: false,
    });
  };
  handleRoleChange = (selectedOption) => {
    this.setState(
      {
        selected_role: selectedOption.value,
        selected_role_value: selectedOption,
      },
      () => {
        // console.log(this.state.selected_role_value, this.state.selected_role)
      }
    );
  };
  //handler for form changes
  onChange = (e) => {
    this.setState(
      { formdata: { ...this.state.formdata, [e.target.name]: e.target.value } },
      () => {
        // console.log(this.state.formdata)
      }
    );
  };

  //get all admins
  getadminfunction = () => {
    this.setState({ load: true }, () => {
      this.props
        .getadmins({ limit: this.state.limit, offset: this.state.offset })
        .then((res) => {
          console.log("Admin Data: ", res);
          let temp = [];
          let tempUsers = [];
          for (let i = 0; i < res.length; i++) {
            temp.push(Object.values(res[i]));
            tempUsers.push({ userId: res[i].UserID });
          }
          let adminCount = temp.length;
          this.setState({
            load: false,
            selectedType: "",
            search: "",
            data: temp,
            originalData: res,
            UserID: tempUsers,
            realData: temp,
            allowPagination: true,
            simpleOptionsState: true,
            searchOptionsState: false,
            filterOptionsState: false,
          });
        })
        .catch((err) => {
          this.setState({
            load: false,
          });
          console.log(err);
        });
    });

    this.getCount();

    // this.searchTextInput.current.value = "";
  };

  getCount = () => {
    this.props.getadmincount().then((res) => {
      this.setState({
        count: res,
      });
    });
  };

  getadminRoles = () => {
    this.props
      .getRoles()
      .then((res) => {
        // console.log(res)
        var temp = [];

        for (var i = 0; i < res.length; i++) {
          var obj = { value: res[i].Role_ID, label: res[i].RoleName };
          temp.push(obj);
        }
        this.setState({ rolesSet: temp, rolesSet1: res }, () => {
          // console.log(this.state.rolesSet)
        });
      })
      .catch((err) => console.log(err));
  };

  //add useradmin open modal1
  openModal1 = () => {
    this.setState({ modalIsOpen1: true }, () => {});
  };
  openDetailsModal = (fullName, email, roleName, about, rowIndex) => {
    const value = this.state.originalData[rowIndex];

    this.setState({
      detailsIsOpen: true,
      image64: value.ProfileImageURL,
      nameDetails: fullName,
      emailDetails: email,
      roleDetails: roleName,
      aboutDetails: about,
    });
  };
  closeModal1 = () => {
    this.setState({
      modalIsOpen1: false,
      isEditable: false,
      image64: null,
      formdata: {
        ...this.state.formdata,
        profilePicture: [],
        fullname: "",
        email: "",
        role: "",
        id: "",
        description: "",
      },
    });
  };

  removeImage = () => {
    this.setState({
      image64: null,
      isRemoved: true,
      formdata: {
        ...this.state.formdata,
        profilePicture: [],
      },
    });
  };

  changeFilter = (data) => {
    console.log("Inside changeFilter: ", data);
    // this.setState({load: true});

    let newdata = [];
    data.map((item, index) => {
      if (!(item.length === 0)) {
        switch (index) {
          case 2: {
            newdata.push({ "roles.RoleName": item[0] });
            break;
          }
          case 4: {
            newdata.push({ "userinfo.Status": item[0] });
            break;
          }
          case 5: {
            newdata.push({ "roles.Role_ID": item[0] });
          }
          // case 6: {
          //   newdata.push({ "userinfo.PEC_REG_NO": item[0] });
          //   break;
          // }
          // case 9: {
          //   newdata.push({ "userinfo.discipline": item[0] });
          //   break;
          // }
        }
      }
    });
    if (newdata.length === 0) {
      // console.log("INISDEEE IFFFFF: ", newdata);
      this.setState({
        data: this.state.realData,
        allowPagination: true,
        load: false,
      });
    } else {
      // console.log("INSIDEEE ELSEEE");

      this.props
        .getFilterAdmins(newdata)
        .then((res) => {
          // console.log("Get Filter Admins: ", res);

          let filterCount = res.length;
          newdata.push({ limit: this.state.limit, offset: this.state.offset });

          let temp = [];
          let tempUsers = [];
          for (let i = 0; i < res.length; i++) {
            temp.push(Object.values(res[i]));
            tempUsers.push({ userId: res[i].UserID });
          }
          this.setState(
            {
              filterOptionsState: true,
              simpleOptionsState: false,
              searchOptionsState: false,
              data: temp,
              originalData: res,
              UserID: tempUsers,
              count: filterCount,
              perPage: 25,
              load: false,
            },
            () => {}
          );
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  changeFiltersPage = (page, sortOrder, data) => {
    this.setState(
      {
        offset: page * this.state.perPage,
        page: page,
      },
      () => {
        this.changeFilter(data);
      }
    );
  };

  verifyImageFile = (files) => {
    if (files && files.length > 0) {
      const currentFile = files[0];
      const currentFileType = currentFile.type;
      const currentFileSize = currentFile.size;
      if (currentFileSize > 2e6) {
        obj.createNotification(
          "error",
          "Image should be less than 2mbs, Try Again"
        );
        return false;
      }
      if (!acceptedImageFileTypesArray.includes(currentFileType)) {
        obj.createNotification(
          "error",
          "This file type is not allowed, Only Images are allowed"
        );

        return false;
      }
      return true;
    }
  };

  handleOnDropImage = (files, rejectedFiles) => {
    if (rejectedFiles && rejectedFiles.length > 0) {
      this.verifyImageFile(rejectedFiles);
    }
    if (files && files.length > 0) {
      if (this.verifyImageFile(files)) {
        const currentFile = files[0];
        // console.log("Current File: ", files[0].path);
        const reader = new FileReader();
        reader.addEventListener(
          "load",
          () => {
            this.setState({ image64: reader.result });
          },
          false
        );
        reader.readAsDataURL(currentFile);

        obj.createNotification("success", "Image Dropped");
        this.setState({
          formdata: {
            ...this.state.formdata,
            profilePicture: files[0],
          },
        });
      }
    }
  };

  changePage = (page, sortOrder) => {
    this.setState(
      {
        offset: page * this.state.perPage,
        page: page,
      },
      () => {
        this.getadminfunction();
        this.getadminRoles();
      }
    );
  };

  //submit handler
  submitadmindetails = (e) => {
    e.preventDefault();

    this.setState({ load: true });

    // console.log("Form Dataaa: ", this.state.formdata);

    if (this.state.isEditable) {
      editAdminSchema
        .validate(this.state.formdata)
        .then((validation) => {
          const { profilePicture, fullname, email, role, description } =
            this.state.formdata;
          const id = this.state.getUserId;
          var data = new FormData();
          data.append("profilePicture", profilePicture);
          data.append("fullname", fullname);
          data.append("email", email);
          data.append("role", role);
          data.append("description", description);
          data.append("id", id);
          this.props
            .editAdmin(data)
            .then((res) => {
              // console.log(res)
              this.setState({ load: false });
              obj.createNotification("success", "Edited successfully");
              setTimeout(() => {
                window.location.reload();
              }, 750);
            })
            .catch((err) => {
              this.setState({ load: false });
              console.log(err.response.data.message);
              obj.createNotification("error", err.response.data.message);
            });
        })
        .catch((err) => {
          this.setState({ load: false });
          console.log(err);
          obj.createNotification("error", err.message);
        });
    } else {
      if (!this.state.image64) {
        this.setState({ load: false });
        obj.createNotification("error", "Image is required");
      } else {
        addAdminSchema
          .validate(this.state.formdata)
          .then((validation) => {
            const { profilePicture, fullname, email, role, description } =
              this.state.formdata;
            console.log("Profile Picture path: ", profilePicture);
            var data = new FormData();
            data.append("profilePicture", profilePicture);
            data.append("fullname", fullname);
            data.append("email", email);
            data.append("role", role);
            data.append("description", description);
            // console.log('Form Data: ',data);
            this.props
              .addAdmin(data)
              .then((res) => {
                // console.log("add admin response");
                // console.log(res)
                this.setState({ load: false });
                obj.createNotification("success", "Admin added successfully");

                setTimeout(() => {
                  window.location.reload();
                }, 750);
              })
              .catch((err) => {
                this.setState({ load: false });
                console.log(err.response.data.message);

                obj.createNotification("error", err.response.data.message);

                // setTimeout(() => {
                //   window.location.reload();
                // }, 1000);
              });
          })
          .catch((err) => {
            this.setState({ load: false });
            console.log(err);
            obj.createNotification("error", err.message);
          });
      }
    }
  };

  //edit single prop function
  editSingle = (data) => {
    console.log("Dataaaaa: ", data);
    console.log("Original Data: ", data);
    console.log(this.state.originalData[data]);
    const value = this.state.originalData[data];
    // console.log("DESCRIPTION: ", value.about);

    this.setState(
      {
        ...this.state,
        isEditable: true,
        image64: value.ProfileImageURL,
        formdata: {
          ...this.state.formdata,
          image64: value.ProfileImageURL,
          cnic: value.CNIC,
          fullname: value.Fullname,
          email: value.PrimaryEmail,
          role: value.Role_ID,
          id: value.UserID,
          description: value.about,
        },
        getUserId: value.UserID,
      },
      () => {
        this.openModal1();

        console.log("Image Path: ", imagePath + this.state.image64);
      }
    );
  };
  //delete single function prop function
  delete = (value) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to delete this admin?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            this.setState({ load: true });
            // console.log(this.state.originalData[value].UserID)
            this.props
              .deleteAdminUser({ id: this.state.originalData[value].UserID })
              .then((res) => {
                this.setState({ load: false });
                obj.createNotification("success", "User Deleted Successfully");
                setInterval(() => {
                  window.location.reload();
                }, 750);
              })
              .catch((err) => {
                this.setState({ load: false });
                console.log(err);
              });
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };
  //edit multiple prop function
  editMultiple = (indexes) => {
    // console.log(indexes)
    let adminsToEdit = [];
    for (let i = 0; i < indexes.length; i++) {
      // console.log(this.state.originalData[indexes[i].dataIndex])
      adminsToEdit.push(this.state.originalData[indexes[i].dataIndex]);
    }
    this.openModal(adminsToEdit);
  };
  //edit bulk function redux called from modal
  editMultipleUsers = () => {
    const data = {
      id: this.state.userstoEdit,
      role: this.state.selected_role_value.value,
    };
    // console.log(data)
    this.props
      .editBulk(data)
      .then((res) => {
        // console.log(res)
        obj.createNotification("success", "Edited Roles");
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //delete multiple prop function
  deleteMultiple = (indexes) => {
    // console.log(indexes)
    let idstodelt = [];
    for (let i = 0; i < indexes.length; i++) {
      // console.log(this.state.originalData[indexes[i].dataIndex])
      idstodelt.push({
        id: this.state.originalData[indexes[i].dataIndex].UserID,
      });
    }
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to delete these admins?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            this.props
              .deleteMultiple(idstodelt)
              .then((res) => {
                window.location.reload();
              })
              .catch((err) => {
                console.log(err);
              });
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  changeAdminRows = (num) => {
    this.setState(
      {
        limit: num,
      },
      () => {
        this.getadminfunction();
      }
    );
  };

  resetAdminFilters = () => {
    this.searchEmailInput.current.value = "";
    this.searchNameInput.current.value = "";
    this.searchRoleInput.current.value = "";
    this.searchStatusInput.current.value = "";

    this.setState({ allowPagination: true }, () => {      
      this.getadminfunction();
    });
  };

  changePageSearch = (tableState) => {
    this.setState(
      {
        filterOptionsState:
          tableState.searchText === null ||
          tableState.searchText === "" ||
          !tableState.searchText
            ? false
            : true,
      },
      () => {
        // console.log('Server Side State: ', this.state.renderServerSide);
        // console.log('Search Text: ', tableState.searchText);
      }
    );
  };

  handleTextInput = (event) => {
    this.setState({ ...this.state, search: event.target.value });
  };

  searchFilter = (selectedOption) => {
    this.setState({
      selectedType: selectedOption,
      // searchOptionsState: true,
      // filterOptionsState: false,
      // simpleOptionsState: false,
    });
  };

  searchData = () => {
    let searchEmail = this.searchEmailInput?.current?.value;

    let searchName = this.searchNameInput?.current?.value;

    let searchTextValue = this.searchTextInput?.current?.value;

    let searchRoleValue = this.searchRoleInput?.current?.value;

    let searchStatusValue = this.searchStatusInput?.current?.value;

    if (
      searchEmail ||
      searchName ||
      (searchTextValue && this.state.selectedType !== "") ||
      searchRoleValue ||
      searchStatusValue
    ) {
      this.setState(
        {
          load: true,
        },
        () => {
          let data = [];

          let searchTypeValue = this.state.selectedType;

          // Push these values inside data array

          data.push({ type: searchTypeValue });
          data.push({ search: searchTextValue });
          data.push({ email: searchEmail });
          data.push({ name: searchName });
          data.push({ role: searchRoleValue });
          data.push({ status: searchStatusValue });

          // Create a new array to push these values
          // Eash index should have a "key: value"
          // These values will be used inside query

          let newdata = [];
          let itemType = "";
          data.map((item, index) => {
            itemType = item["type"]?.label;

            if (!(item.length === 0)) {
              switch (index) {
                case 0: {
                  if (itemType && itemType === "Email") {
                    newdata.push({ "userinfo.PrimaryEmail": searchTextValue });
                  } else if (itemType && itemType === "Full Name") {
                    newdata.push({ "userinfo.FullName": searchTextValue });
                  }
                  break;
                }
                // case 1: {
                //   if (item['discipline'] !== "") {
                //     newdata.push({ "userinfo.discipline": item['discipline'] });
                //   }
                //   break;
                // }
                case 2: {
                  if (item["email"]) {
                    newdata.push({ "userinfo.PrimaryEmail": item["email"] });
                  }
                  break;
                }
                case 3: {
                  if (item["name"]) {
                    newdata.push({ "userinfo.FullName": item["name"] });
                  }
                  break;
                }
                case 4: {
                  if (item["role"]) {
                    newdata.push({ "roles.RoleName": item["role"] });
                  }
                  break;
                }
                case 5: {
                  if (item["status"]) {
                    newdata.push({ "userinfo.Status": item["status"] });
                  }
                  break;
                }
              }
            }
          });

          // console.log("NEW DATA: ", newdata);

          this.props
            .searchAdminUsers(newdata)
            .then((res) => {
              // console.log("Search res: ", res);
              let searchLength = res.length;
              let temp = [];
              let tempUsers = [];
              for (let i = 0; i < res.length; i++) {
                temp.push(Object.values(res[i]));
                tempUsers.push({ userId: res[i].UserID });
              }
              this.setState({
                load: false,
                data: temp,
                originalData: res,
                count: searchLength,
                UserID: tempUsers,
                allowPagination: true,
                searchOptionsState: true,
                filterOptionsState: false,
                simpleOptionsState: false,
              });
            })
            .catch((err) => {
              this.setState({
                load: false,
              });
              console.log("Search Error: ", err);
            });
        }
      );
    }
  };

  render() {
    const columns = [
      {
        name: "User ID",
        options: {
          filter: false,
          display: true,
        },
      },
      {
        name: "Full Name",
        options: {
          filter: false,
          display: true,
        },
      },
      {
        name: "Role Name",
        options: {
          filter: true,
          display: true,
        },
      },
      {
        name: "Email",
        options: {
          filter: false,
          display: true,
        },
      },
      {
        name: "Status",
        options: {
          filter: true,
          display: true,
        },
      },
      {
        name: "Role ID",
        label: "Roll ID",
        options: {
          filter: true,
          display: false,
        },
      },
      {
        name: "PEC_REG_NO",
        label: "PEC Number",
        options: {
          filter: false,
          display: false,
          customBodyRender: (data, tableMeta, updateValue) => {
            // console.log('Data: ', data);
            return (
              <>
                <p>{!data ? "Not Found" : data}</p>
              </>
            );
          },
        },
      },

      {
        name: "about",
        label: "About",
        options: {
          filter: false,
          display: true,
          customBodyRender: (data, tableMeta, updateValue) => {
            const rowIndex = tableMeta.rowIndex;
            const id = tableMeta.currentTableData[tableMeta.rowIndex].data[0];
            const columnValue = this.state.data.filter(
              (item) => item[0] === id
            );

            return (
              <>
                <button
                  className="btn btn-sm btn-outline-success"
                  onClick={() => {
                    this.openDetailsModal(
                      columnValue[0][1],
                      columnValue[0][3],
                      columnValue[0][2],
                      columnValue[0][7],
                      rowIndex
                    );
                  }}
                >
                  View <i class="fa fa-eye" aria-hidden="true"></i>
                </button>
              </>
            );
          },
        },
      },
      {
        name: "ProfileImageURL",
        label: "Image URL",
        options: {
          filter: false,
          display: false,
        },
      },
      {
        name: "discipline",
        label: "Discipline",
        options: {
          filter: false,
          display: false,
          customBodyRender: (data, tableMeta, updateValue) => {
            // console.log('Data: ', data);
            return (
              <>
                <p>{!data ? "Not Found" : data}</p>
              </>
            );
          },
        },
      },
    ];
    const options = {
      search: true,
      responsive: "vertical",
      tableBodyHeight: "600px",
      tableBodyMaxHeight: "",
      filter: true,
      filterType: "dropdown",
      rowsPerPage: this.state.perPage,
      rowsPerPageOptions: [25, 50, 75, 100],
      pagination: this.state.allowPagination,
      serverSide:
        this.state.filterOptionsState || this.state.searchOptionsState
          ? false
          : true,
      count: this.state.count,
      page: this.state.page,
      // onRowClick: (rowData, rowMeta) => console.log(rowData),
      customToolbarSelect: (selectedRows) => (
        <CustomToolbarSelect
          selectedRows={selectedRows}
          Edit={this.editSingle}
          BulkEdit={this.editMultiple}
          Delete={this.delete}
          BulkDelete={this.deleteMultiple}
          permissions={this.props.permissions}
        />
      ),
      onTableChange: (action, tableState) => {
        this.state.filterOptionsState || this.state.searchOptionsState
          ? ""
          : action === "changePage"
          ? this.changePage(tableState.page, tableState.sortOrder)
          : "";

        if (action === "filterChange") {
          // this.setState({filterOptionsState: true});
          this.changeFilter(tableState.filterList);
        }
        if (action === "search") {
          this.changePageSearch(tableState);
        }
      },
      onChangeRowsPerPage: (num) => {
        if (
          this.state.filterOptionsState === true ||
          this.state.searchOptionsState === true
        ) {
        } else {
          this.setState({ perPage: num, page: 0 });
          this.changeAdminRows(num);
        }
      },
      onFilterChange: (changedColumn, filterList, type) => {
        if (type === "reset") {
          this.resetAdminFilters();
        }
      },
      onFilterChipClose: (index, removedFilter, filterList) => {
        let temp = [];
        filterList.map((value, index) => {
          for (let i = 0; i <= value.length; i++) {
            temp.push(i);
          }
        });
        console.log("TEMP LENGTH: ", temp.length);
        if (temp.length === 10) {
          this.resetAdminFilters();
        }
      },
    };

    const theme = createMuiTheme({
      overrides: {
        MUIDataTableToolbar: {
          filterPaper: {
            width: "450px !important",
            height: "auto !important",
          },
        },
      },
    });

    return (
      <React.Fragment>
        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Edit Admin Roles"
        >
          <div className="row ptb-15">
            <div className="col-md-11"></div>
            <div className="col-md-1 ">
              <a
                className="float-right modalCloseIcon"
                onClick={this.closeModal}
              >
                <i className="fas fa-window-close"></i>
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6  offset-md-3">
              <h4 className="co_g20A84C text-center">Edit Admin Roles</h4>
            </div>
          </div>
          <div className="row ">
            <div className="col-md-9  offset-md-2 ptb_20 ">
              <div
                style={{
                  maxHeight: "200px",
                  minHeight: "150px",
                  overflowY: "scroll",
                  overflowX: "hidden",
                }}
              >
                <div className="row">
                  <div className="col-md-6 ">
                    <span className="bold"> Name</span>{" "}
                  </div>
                  <div className="col-md-6 ">
                    <span className="bold"> Role</span>{" "}
                  </div>
                </div>
                {this.state.userstoEdit &&
                  this.state.userstoEdit.map((item, index) => (
                    <div className="row" key={index}>
                      <div className="col-md-6">
                        <span>{"  " + item.Fullname} </span>
                      </div>
                      <div className="col-md-6 ">
                        <span> {"   " + item.RoleName} </span>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 offset-md-3">
              <Select
                className="mtb-10"
                menuPlacement="top"
                name="selected_role_value"
                options={this.state.rolesSet}
                value={this.state.selected_role_value}
                onChange={this.handleRoleChange}
              />
              <button
                onClick={this.editMultipleUsers}
                className="btn text-center w-100 btn-submit black bold"
              >
                Submit
              </button>
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={this.state.modalIsOpen1}
          onRequestClose={this.closeModal1}
          style={customStyles1}
          contentLabel="Add User"
        >
          <h2 className="ptb-30">
            {this.state.isEditable ? "Edit Admin User" : "Add Admin User"}
          </h2>

          <form>
            <div className="form-group">
              {this.state.image64 !== null ? (
                <div
                  onClick={this.removeImage}
                  className="pointer text-center"
                  style={{
                    color: "black",
                    border: "1px solid #20A84C",
                    padding: 10,
                    borderRadius: 4,
                    cursor: "pointer",
                    height: "150px",
                    width: "auto",
                    padding: "15px",
                  }}
                >
                  {this.state.isRemoved || this.state.isEditable === false ? (
                    <>
                      {console.log(
                        "Inside If Is Editable: ",
                        this.state.isEditable
                      )}
                      <img className="preview-image" src={this.state.image64} />
                    </>
                  ) : (
                    <>
                      {console.log("Inside Else")}
                      <img
                        className="preview-image"
                        src={urlImageUrl + imagePath + this.state.image64}
                      />
                    </>
                  )}
                  <i
                    className="fa fa-times-circle preview-close-icon fa-lg"
                    style={{ color: "#20A84C", marginTop: "100px" }}
                  />
                </div>
              ) : (
                ""
              )}

              {this.state.image64 === null && (
                <div
                  className="text-center"
                  style={{
                    color: "black",
                    border: "1px solid #20A84C",
                    padding: 10,
                    borderRadius: 4,
                    cursor: "pointer",
                    height: "150px",
                    width: "auto",
                    padding: "15px",
                  }}
                >
                  {" "}
                  <Dropzone
                    name="dropzone1"
                    accept="image/*"
                    required
                    onDrop={this.handleOnDropImage}
                    maxSize={2e6}
                    multiple={false}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <i
                            className="fa fa-image fa-3x pb-10"
                            style={{ color: "#20A84C" }}
                          />
                          <br />
                          <span className="fs-10">
                            Click or Drag Image to Upload Here.
                            <br />
                            (x-png, png, jpg, jpeg, gif)
                          </span>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </div>
              )}
            </div>

            <div className="form-group">
              <CssTextField
                type="text"
                fullWidth={true}
                label="Full Name"
                variant="outlined"
                name="fullname"
                value={this.state.formdata.fullname}
                onChange={this.onChange}
              />
            </div>
            <div className="form-group">
              <CssTextField
                type="text"
                fullWidth={true}
                label="Email"
                variant="outlined"
                name="email"
                value={this.state.formdata.email}
                onChange={this.onChange}
                disabled={this.state.isEditable}
              />
            </div>

            <div className="form-group">
              <CssTextField
                id="outlined-select-role"
                select
                fullWidth={true}
                label="Select Role"
                value={this.state.formdata.role}
                onChange={this.onChange}
                name="role"
                variant="outlined"
              >
                {this.state.rolesSet1.map((option) => (
                  <MenuItem key={option.Role_ID} value={option.Role_ID}>
                    {option.RoleName}
                  </MenuItem>
                ))}
              </CssTextField>
            </div>

            <div className="form-group">
              <CssTextField
                type="text"
                fullWidth={true}
                label="Description"
                variant="outlined"
                name="description"
                value={this.state.formdata.description}
                onChange={this.onChange}
                multiline
                rows={4}
              />
            </div>

            <div className="row d-flex justify-content-center ">
              <div className="col-md-6 d-flex justify-content-center">
                {this.state.load ? (
                  <ClipLoader
                    color={"#20a84c"}
                    loading={this.state.load}
                    size={60}
                  />
                ) : (
                  <button
                    onClick={this.submitadmindetails}
                    type="submit"
                    className="btn text-center btn-submit black bold"
                  >
                    {this.state.isEditable ? "Save Changes" : "Add Admin"}
                  </button>
                )}
              </div>
            </div>
          </form>
        </Modal>

        <Modal
          isOpen={this.state.detailsIsOpen}
          onRequestClose={this.detailsClose}
          style={customStyles}
          contentLabel="Admin Details"
        >
          <div className="row">
            <div className="col-md-12">
              <div className="">
                <strong>Profile Picture:</strong>
              </div>
              {this.state.image64 !== null ? (
                <div
                  className="pointer text-center"
                  style={{
                    color: "black",
                    padding: 10,
                    borderRadius: 4,
                    height: "150px",
                    width: "auto",
                    padding: "15px",
                  }}
                >
                  <>
                    <img
                      className="preview-image"
                      src={urlImageUrl + imagePath + this.state.image64}
                    />
                  </>
                </div>
              ) : (
                "Image Not Found"
              )}
            </div>
            <br />
            <div className="col-md-12">
              <div className="">
                <strong>Full Name:</strong>
              </div>
              <div className="">
                <p>
                  {!this.state.nameDetails
                    ? "Data not found"
                    : this.state.nameDetails}
                </p>
              </div>
            </div>
            <div className="col-md-12">
              <div className="">
                <strong>Email:</strong>
              </div>
              <div className="">
                <p>
                  {!this.state.emailDetails
                    ? "Data not found"
                    : this.state.emailDetails}
                </p>
              </div>
            </div>
            <div className="col-md-12">
              <div className="">
                <strong>Role:</strong>
              </div>
              <div className="">
                <p>
                  {!this.state.roleDetails
                    ? "Data not found"
                    : this.state.roleDetails}
                </p>
              </div>
            </div>
            <div className="col-md-12">
              <div className="">
                <strong>About User:</strong>
              </div>
              <div className="">
                <p>
                  {!this.state.aboutDetails
                    ? "Data not found"
                    : this.state.aboutDetails}
                </p>
              </div>
            </div>
          </div>
        </Modal>

        <div className="row mtb-20">
          <div className="col-md-9"></div>
          <div className="col-md-3 ">
            <button
              onClick={this.openModal1}
              className="btn admin_btn white w-150p float-right bold"
            >
              {" "}
              + Add New
            </button>
          </div>
        </div>

        <>
          <hr></hr>
          <div className="col-md-12">
            <div className="row">
              {/* <div className="col-md-3 ">
                  <label className="bm-0">Type</label>
                  <Select
                    className=" mtb-10"
                    options={this.state.searchType}
                    value={this.state.selectedType}
                    onChange={this.searchFilter}
                    ref={this.searchTypeInput}
                  />
                </div> */}

              <div className="col-md-2">
                <label className="bm-0">Email</label>
                <div className="input-group md-form form-sm form-2 mtb-10">
                  <input
                    type="text"
                    className="form-control"
                    name="search"
                    // value={this.state.search}
                    // onChange={this.handleTextInput}
                    ref={this.searchEmailInput}
                    placeholder={"Search By Email"}
                    aria-label="Text input with dropdown button"
                  />
                </div>
              </div>

              <div className="col-md-2">
                <label className="bm-0">Full Name</label>
                <div className="input-group md-form form-sm form-2 mtb-10">
                  <input
                    type="text"
                    className="form-control"
                    name="search"
                    // value={this.state.search}
                    // onChange={this.handleTextInput}
                    ref={this.searchNameInput}
                    placeholder={"Search By Name"}
                    aria-label="Text input with dropdown button"
                  />
                </div>
              </div>

              {/* <div className="col-md-2">
                  <label className="bm-0">Search</label>
                  <div className="input-group md-form form-sm form-2 mtb-10">
                    <input
                      type="text"
                      className="form-control"
                      name="search"
                      // value={this.state.search}
                      // onChange={this.handleTextInput}
                      ref={this.searchTextInput}
                      placeholder={"Search By Value"}
                      aria-label="Text input with dropdown button"
                    />
                  </div>
                </div> */}

              <div className="col-md-2 ">
                <label className="bm-0">Role Name</label>
                <div className="input-group md-form form-sm form-2 mtb-10">
                  <input
                    type="text"
                    className="form-control"
                    name="search"
                    // value={this.state.search}
                    // onChange={this.handleTextInput}
                    ref={this.searchRoleInput}
                    placeholder={"Search By Role"}
                    aria-label="Text input with dropdown button"
                  />
                </div>
              </div>

              <div className="col-md-2 ">
                <label className="bm-0">Status</label>
                <div className="input-group md-form form-sm form-2 mtb-10">
                  <input
                    type="text"
                    className="form-control"
                    name="search"
                    // value={this.state.search}
                    // onChange={this.handleTextInput}
                    ref={this.searchStatusInput}
                    placeholder={"Search By Status"}
                    aria-label="Text input with dropdown button"
                  />
                </div>
              </div>

              <div className="col-md-2">
                <label className="bm-0"></label>
                <button
                  className="btn admin_btn white w-150p float-right bold mtb-10"
                  onClick={() => {
                    this.searchData();
                  }}
                >
                  Search
                </button>
              </div>

              <div className="col-md-2">
                <label className="bm-0"></label>
                <button
                  className="btn admin_btn_new text-center white w-150p float-right bold mtb-10"
                  onClick={() => {
                    this.resetAdminFilters();
                  }}
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
          <hr></hr>
        </>

        {this.state.load === true ? (
          <div className="loaderDiv text-center">
            <Loader type="Oval" color="#06580e" height={80} width={80} />
          </div>
        ) : (
          <div className="mt-50">
            <MuiThemeProvider theme={theme}>
              <MUIDataTable
                title={"All Admin Users"}
                data={this.state.data}
                columns={columns}
                options={options}
              />
            </MuiThemeProvider>
          </div>
        )}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    admins: state.UserManageData.alladmins,
  };
}
export default connect(mapStateToProps, {
  addAdmin,
  getFilterAdmins,
  searchAdminUsers,
  getadmincount,
  editAdmin,
  getadmins,
  deleteAdminUser,
  editBulk,
  getRoles,
  deleteMultiple,
})(UserManagement);
