import api from "../../../api";

// Get all Admins   Super Admin Action
export const gotAllAdmins = (data) => ({
  type: "GOT_ADMIN_USERS",
  data,
});
export const getadmins = (data) => (dispatch) =>
  api.admin.users.get_admins(data).then((res) => {
    dispatch(gotAllAdmins(res.data));
    return res.data;
  });

export const gotFilteredAdmins = (data) => ({
  type: "GOT_FILTERED_ADMINS",
  data,
});

export const getFilterAdmins = (data) => (dispatch) =>
  api.admin.users.get_filter_admins(data).then((res) => {
    dispatch(gotFilteredAdmins(res.data));
    return res.data;
  });

export const gotAdminCount = (data) => ({
  type: "GOT_ADMIN_COUNT",
  data,
});

export const getadmincount = (data) => (dispatch) =>
  api.admin.users.get_admin_count(data).then((res) => {
    dispatch(gotAdminCount(res.count));
    return res.count;
  });

export const searchedAdminUsers = (data) => ({
  type: "SEARCH_ADMIN_USERS",
  data,
});

export const searchAdminUsers = (data) => (dispatch) => 
  api.admin.users.search_admin_users(data).then((res) => {
    dispatch(searchedAdminUsers(res.data));
    return res;
  });

//Get all roles
export const gotRoles = (data) => ({
  type: "GOT_ADMIN_ROLES",
  data,
});

export const getRoles = (data) => (dispatch) =>
  api.admin.users.get_roles(data).then((res) => {
    dispatch(gotRoles(res.data));
    return res.data;
  });

//addAdmin
export const AddedAdmin = (data) => ({
  type: "ADDED_ADMIN",
  data,
});
export const addAdmin = (data) => (dispatch) =>
  api.admin.users.add_admin(data).then((res) => {
    dispatch(AddedAdmin(res.data));
    return res;
  });



  //edit admin user
export const EditedAdmin = (data) => ({
  type: "EDITED_ADMIN",
  data,
});
export const editAdmin = (data) => (dispatch) =>
  api.admin.users.edit_admin(data).then((res) => {
    dispatch(EditedAdmin(res.data));
    return res;
  });

  
//verify email

export const GotNewResetPassword = (data) => ({
  type: "NEW_RESET_PASSWORD",
  data,
});

export const newResetPassword = (data) => (dispatch) => 
    api.admin.users.new_reset_password(data).then((res) => {
      dispatch(GotNewResetPassword(res.data));
      return res;
    });

export const GotNewResetEmail = (data) => ({
  type: "NEW_RESET_EMAIL",
  data,
});

export const newResetEmail = (data) => (dispatch) => 
    api.admin.users.new_reset_email(data).then((res) => {
      dispatch(GotNewResetEmail(res.data));
      return res;
    });

export const EmailVerified = () => (dispatch) => ({
  type: "EMAIL_VERIFIED",
});
export const verifyEmail = (data) => (dispatch) =>
  api.admin.users.verify_email(data).then((res) => {
    dispatch(EmailVerified(data));
    return res;
  });
//reset password
export const PaswwordResetted =  (data) => ({
  type: "PASSWORD_RESETTED",
  data,
})
export const passwordReset = (data) => (dispatch) =>
  api.admin.users.resetPassword(data).then((res) => {
    dispatch(PaswwordResetted(res.data));
    return res;
  })
export const SetPaswwordResetted = (data) => ({
  type: "SET_PASSWORD_RESETTED",
  data,
})
export const passwordResetSet = (data) => (dispatch) =>
  api.admin.users.setResetPassword(data).then((res) => {
    dispatch(SetPaswwordResetted(res.data));
    return res;
  })

//get webinar attendees and registered
export const gotWebinarAttendees = (data) => ({
  type: "GOT_WEBINAR_ATTENDEES",
  data,
})
export const getWebinarAttendees = (data) => (dispatch) =>
  api.admin.users.getWebinarAttendees(data).then((res) => {
    dispatch(gotWebinarAttendees(res.data));
    return res;
  })

export const gotWebinarRegistered = (data) => ({
  type: "GOT_WEBINAR_REGISTERED",
  data,
})
export const getWebinarRegistered = (data) => (dispatch) =>
  api.admin.users.getWebinarRegistered(data).then((res) => {
    dispatch(gotWebinarRegistered(res.data));
    return res;
  })

//set password from limk
export const PasswordSet = () => (dispatch) => ({
  type: "PASSWORD_SET",
});
export const setPassWord = (data) => (dispatch) =>
  api.admin.users.set_password(data).then((res) => {
    dispatch(PasswordSet());
    return res;
  });


//get attendence
export const AttendenceData = (data) => ({
  type: "GOT_ATTENDENCE_DATA", data
})

export const GotAttendenceData = () => (dispatch) =>
  api.admin.users.get_attendence_data().then(res => {
    dispatch(AttendenceData(res.data));
    return res
  })

//Admin Permission 

//get admin permission
export const AdminPermission = (data) => ({
  type: "GOT_ADMIN_PERMISSION", data
})


export const getAdminPermission = () => (dispatch) =>
  api.admin.users.get_admin_permission().then(res => {
    dispatch(AdminPermission(res.data));
    return res
  })
export const GotAllComponents = (data) =>({
  type:"GOT_ALL_COMPONENTS",
  data
})
export const GetAllComponents = () => (dispatch) =>
api.admin.users.get_all_components().then(res => {
  dispatch(GotAllComponents(res.data));
  return res
})
//Edit Admin Permission
export const EditedAdminPermission = (data)=> ({
  type: "EDITED_ADMIN_PERMISSION",
  data
})

export const RoleDeleted = (data)=>({
  type: "ROLE_DELETED",
  data
})

export const AddedRole = (data)=>({
  type: "ROLE_CREATED",
  data
})

export const EditAdminPermission = (data) => (dispatch)=> 
  api.admin.users.edit_admin_permission(data).then((res)=>{
    dispatch(EditedAdminPermission(res.data));
    return res
  })


export const DeleteRole = (data) => (dispatch) =>
api.admin.users.delete_Role(data).then((res)=>{
  dispatch(RoleDeleted(res.data));
  return res
})

export const AddRoleAdmin = (data) => (dispatch)=>
api.admin.users.add_role_admin(data).then((res)=>{
  dispatch(AddedRole(res.data));
  return res
})
//delete admin user
export const AdminDeleted = (data) => ({
  type: "ADMIN_DELETED",
});

export const deleteAdminUser = (data) => (dispatch) =>
  api.admin.users.delete_admin(data).then((res) => {
    dispatch(AdminDeleted());
    return res;
  });
//delete multiple
export const deleteMultiple = (data) => (dispatch) =>
  api.admin.users.delete_admins(data).then((res) => {
    dispatch(AdminDeleted());
    return res;
  });

//edit Bulk
export const BulkEdited = (data) => ({
  type: "BULK_EDIT",
  data,
});
export const editBulk = (data) => (dispatch) =>
  api.admin.users.edit_bulk(data).then((res) => {
    dispatch(BulkEdited(res.data));
    return res;
  });


//Get engineerlist
export const GotEngineerList = (data) => ({
  type: "ENGINEER_LIST",
  data,
});
export const engineerlist = (data) => (dispatch) =>
  api.admin.users.engineerlist(data).then((res) => {
    dispatch(GotEngineerList(res.data));
    return res;
  });

export const GotEngineerFilter = (data) => ({
  type: "ENGINEER_FILTER",
  data,
});

export const getEngineerFilter = (data) => (dispatch) =>
  api.admin.users.getEngineerFilter(data).then((res) => {
    dispatch(GotEngineerFilter(res.data));
    return res;
  });

export const GotEngineerSearch = (data) => ({
  type: "ENGINEER_SEARCH",
  data,
});

export const getEngineerSearch = (data) => (dispatch) =>
  api.admin.users.getEngineerSearch(data).then((res) => {
    console.log("INSIDE getEngineerSearch: ", data);
    dispatch(GotEngineerSearch(res.data));
    return res;
  });

  export const GotEngineerCount = (data) => ({
    type: "ENGINEER_COUNT",
    data,
  });
  export const countEngineers = (data) => (dispatch) =>
    api.admin.users.countEngineers(data).then((res) => {
      dispatch(GotEngineerCount(res.data));
      return res;
    });
  
    export const GotTenurePoints = (data) => ({
      type: "GOT_TENURE_POINTS",
      data,
    });
    export const getTenurePoints = (data) => (dispatch) =>
      api.admin.users.getTenurePoints(data).then((res) => {
        dispatch(GotTenurePoints(res.data));
        return res;
      });
    
