import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import MyToggleButton from "./MyToggleButton";
import WebNotif from "../WebNotif";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import { urlImageUrl } from "../../config/urlapi";
import { connect } from "react-redux";
import {
  getAllWebinars,
  getAllWebinarAdmin,
  archive_webinar,
  getInstructors,
  getCategories,
  getAdminWebinarCount,
} from "../../redux/admin/webinar/webinarActions";
import Moment from "react-moment";
import FuzzySearch from "fuzzy-search";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
var searcher = null;
let newarray = [];
class Webinar extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    pq: [1],
    originalData: [],
    showLoader: true,
    perPage: 12,
    elements: [],
    currentPage: 0,
    courseType: null,
    ModalOpen: false,
    webinarType: [
      { label: "Live Webinar", value: "Live Webinar" },
      { label: "Recorded Webinar", value: "Recorded Webinar" },
      { label: "Webinar by PEBs", value: "Webinar by PEBs" },
    ],
    selectedType: "",
    selectedCategory: "",
    SelectedInstructor: { value: "", label: "All" },
    selectedDate: "",
    InstructorSet: [],
    CategorySet: [],
    instructor: null,
    search: "",
    result: [],
    optionsDate: [
      { label: "Recent First", value: "Recent First" },
      { label: "Recent Last", value: "Recent Last" },
    ],
    value: false,
  };
  onOpenModal = () => {
    this.setState({ ModalOpen: true });
  };
  onCloseModal = () => {
    this.setState({ ModalOpen: false });
  };
  //DELETING A WEBINAR
  archiveWebinar = (id) => {
    let newarray = [];
    newarray = this.state.result.filter((item) => item.CourseID !== id);
    this.state.result.splice(this.state.result.indexOf(id));
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to delete this webinar.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            this.props.archive_webinar(id).then((res) =>
              this.props.getAllWebinars().then((res) => {
                this.setState({ result: newarray }, () => {
                  this.setElementsForCurrentPage();
                });
              })
            );
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };
  preview = (cid) => {
    this.props.history.push(`/LiveWebinar/${cid}`);
  };
  componentDidUpdate(prevProps, prevState) {
    if (this.state.currentPage != prevState.currentPage) {
      this.getAllPublishedWebinarLimit();
    }
  }
  getWebinarCount = () => {
    this.props
      .getAdminWebinarCount()
      .then((res) => {
        this.setState(
          {
            pageCount: Math.ceil(res[0].count / this.state.perPage),
          },
          () => {
            this.getAllPublishedWebinarLimit();
          }
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };
  getAllPublishedWebinarLimit = () => {
    let data = { OffSet: this.state.perPage * this.state.currentPage };
    this.props
      .getAllWebinarAdmin(data)
      .then((res) => {
        // console.log('----------------------');
        // console.log('GET ALL WEBINARS:', res);
        // console.log('----------------------');
        this.setState({ result: res }, () => {
          this.setElementsForCurrentPage();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  componentDidMount() {
    // console.log(this.props);
    this.setState({ courseType: parseInt(this.props.courseType) });
    var URL = "";
    var editurl = "";
    if (parseInt(this.props.courseType) == 0) {
      URL = "/admin/practicequestion/new";
      editurl = "/admin/practicequestion/edit";
    } else if (parseInt(this.props.courseType) == 1) {
      editurl = "/admin/mockexam/edit";
      URL = "/admin/mockexam/new";
    } else if (parseInt(this.props.courseType) == 2) {
      editurl = "/admin/course/edit";
      URL = "/admin/dashboard/webinar/form";
    }
    this.setState({ editURL: URL, editCourse: editurl });
    this.getWebinarCount();
    this.props.getAllWebinars().then((res) => {});
    this.props.getInstructors().then((res) => {
      var temp = [];
      temp.push({ value: "", label: "All" });
      for (var i = 0; i < res.length; i++) {
        var obj = { value: res[i].FullName, label: res[i].FullName };
        temp.push(obj);
      }
      this.setState({ InstructorSet: temp });
    });
    this.props.getCategories().then((res) => {
      var temp = [];
      temp.push({ value: "", label: "All" });
      for (var i = 0; i < res.length; i++) {
        var obj = { value: res[i].Name, label: res[i].Name };
        temp.push(obj);
      }
      this.setState({ CategorySet: temp });
    });
  }
  handleChange1 = (event) => {
    this.setState(
      { ...this.state, [event.target.name]: event.target.value },
      () => {
        var searcher;
        if (this.state.SelectedInstructor.value == "") {
          searcher = new FuzzySearch(this.props.allWebinars, ["FullName"], {});
        } else {
          if (this.state.result.length == 0) {
            searcher = new FuzzySearch(
              this.props.allWebinars,
              ["FullName"],
              {}
            );
          } else {
            searcher = new FuzzySearch(this.state.result, ["FullName"], {});
          }
        }
        const result = searcher.search(this.state.SelectedInstructor.value);
        this.setState({ result: result }, () => {
          const searcher = new FuzzySearch(
            this.props.allWebinars,
            ["Course_Name"],
            {}
          );
          const result1 = searcher.search(this.state.search);
          this.setState({ result: result1 }, () => {
            this.setElementsForCurrentPage();
          });
        });
      }
    );
  };
  filterType = (selectedOption) => {
    this.setState({ selectedType: selectedOption });
    const searcher = new FuzzySearch(this.props.allWebinars, ["Type_Name"], {});
    const result = searcher.search(selectedOption.value);
    this.setState({ result: result }, () => {
      this.setElementsForCurrentPage();
    });
  };

  filterCategory = (selectedOption) => {
    // console.log('Selected Option: ', selectedOption);
    this.setState({ selectedCategory: selectedOption });
    const searcher = new FuzzySearch(
      this.props.allWebinars,
      ["category.Name"],
      {}
    );
    const result = searcher.search(selectedOption.value);
    this.setState({ result: result }, () => {
      this.setElementsForCurrentPage();
    });
  };
  hclicked = (value) => {
    this.props.history.push({
      pathname: "/admin/dashboard/webinarregistered",
      state: { id: value },
    });
  };
  h1clicked = (value) => {
    this.props.history.push({
      pathname: "/admin/dashboard/webinarattendees",
      state: { id: value },
    });
  };
  handleInstructor = (selectedOption) => {
    this.setState({ SelectedInstructor: selectedOption });
    var searcher;
    if (this.state.result.length == 0) {
      searcher = new FuzzySearch(this.props.allWebinars, ["Course_Name"], {});
    } else {
      if (this.state.search == "") {
        searcher = new FuzzySearch(this.props.allWebinars, ["Course_Name"], {});
      } else {
        searcher = new FuzzySearch(this.state.result, ["Course_Name"], {});
      }
    }
    const result = searcher.search(this.state.search);
    this.setState({ result: result }, () => {
      const searcher = new FuzzySearch(
        this.props.allWebinars,
        ["FullName"],
        {}
      );
      const result = searcher.search(selectedOption.value);
      this.setState({ result: result }, () => {
        this.setElementsForCurrentPage();
      });
    });
  };
  handleDate = (selectedOption) => {
    this.setState({ selectedDate: selectedOption }, () => {
      if (this.state.selectedDate.value === "Recent Last") {
        this.setState({ result: this.state.result.reverse() });
      } else {
        this.setState({ result: this.state.result.reverse() });
      }
    });
  };
  handlePageClick = (data) => {
    const selectedPage = data.selected;
    const offset = selectedPage * this.state.perPage;
    this.setState({ currentPage: selectedPage, offset: offset }, () => {
      // this.getWebinarsbyType();
    });
  };

  setElementsForCurrentPage() {
    if (this.state.result) {
      let elements =
        this.state.result.length > 0 ? (
          this.state.result.map((item, key) => (
            <div className="d-flex justify-content-center lrp-15" key={key}>
              <div className="card bm-20 w-100 box_shadow-listing">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-3 lrp-0">
                      <img
                        src={
                          urlImageUrl +
                          item.Image_URL.slice(0, 7) +
                          "\\" +
                          item.Image_URL.slice(7)
                        }
                        alt="Practise Q"
                        className="lrtbb-5 course-listing-image"
                      />
                    </div>
                    <div className="col-md-7 center-card-listing mtb-15 ">
                      <div className="row lrm-0">
                        <div className="col lrp-0">
                          <p
                            className="text-darkgrey fs-13 bm-0"
                            style={{ marginTop: "10px", marginBottom: "5px" }}
                          >
                            Title
                          </p>
                          <div className="row p-inherit lrm-0">
                            <h6 className="co_black  fs-20">
                              {item.Course_Name}
                            </h6>
                          </div>
                        </div>
                        <div className="col-md-4 text-right">
                          <p className="text-darkgrey">
                            <span>Webinar Date</span>
                            <br />
                            <Moment format="hh:mm a DD-MMM-YYYY">
                              {item.Session_StartDate}
                            </Moment>
                          </p>
                        </div>
                      </div>
                      <div className="row lrm-0">
                        <div className="col-md-8 lrp-0">
                          <p className="text-darkgrey fs-11">
                            Category : &nbsp;
                            <ul className="category-list-courselisting">
                              {item.category.map((cat, key) => (
                                <li key={key}>
                                  <b>{cat.Name + " |\t "} &nbsp;</b>
                                </li>
                              ))}
                            </ul>
                          </p>
                        </div>
                        <div className="col-md-4 text-right">
                          <p className="text-darkgrey">
                            CPD Points
                            <br />
                            <b>{item.CPDPoints}</b>
                          </p>
                        </div>
                      </div>
                      <div className="row admin-course-priceDiv lrm-0 ">
                        <div className="col-md-6 lrp-0">
                          <div className="col-md-12 lrp-0">
                            <span
                              onClick={() => this.hclicked(item.CourseID)}
                              style={{ cursor: "pointer" }}
                              className="clr-black-new"
                            >
                              Registered
                            </span>
                            &nbsp;|&nbsp;
                            <span
                              onClick={() => this.h1clicked(item.CourseID)}
                              style={{ cursor: "pointer" }}
                              className="clr-black-new"
                            >
                              Attendees
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6 text-right">
                          <div
                            className="col-md-12"
                            style={{ paddingRight: "0px" }}
                          >
                            <p className="text-darkgrey mb-0_2">Price(PKR)</p>
                            <h4 className="bm-0 bold">
                              {item.pricing_type == "Free"
                                ? "Free"
                                : item.price}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2 listing-right-menu bl-grey lrp-0">
                      <div className="bb-grey p-10 row lrm-0 lm-1 ">
                        {this.props.permissions.Publish ? (
                          <div className="row">
                            {/* {console.log(this.props.permissions)} */}
                            <div className="col-md-6 lrp-0 text-center">
                              Visibility{" "}
                            </div>
                            <div className="col-md-6">
                              <MyToggleButton item={item} />
                            </div>
                          </div>
                        ) : (
                          "Actions"
                        )}
                      </div>
                      {this.props.permissions.Edit ? (
                        <div className="bb-grey ptb-10 pl-15 pointer lm-1 ">
                          <Link
                            className="noTextDecor"
                            to={{
                              pathname: this.state.editURL,
                              state: {
                                ID: parseInt(item.CourseID),
                                courseType: parseInt(this.props.courseType),
                              },
                            }}
                          >
                            <i className="fas fa-edit co_admin"></i>
                            &nbsp; &nbsp;
                            <span className="clr-black">Edit</span>
                          </Link>
                        </div>
                      ) : (
                        ""
                      )}
                      {this.props.permissions.Delete ? (
                        <div
                          className="bb-grey ptb-10 pl-15  pointer lm-1 "
                          onClick={() => this.archiveWebinar(item.CourseID)}
                        >
                          <i
                            className="fa fa-trash co_admin"
                            aria-hidden="true"
                          />
                          &nbsp; &nbsp;<span>Delete</span>
                        </div>
                      ) : (
                        ""
                      )}
                      {this.props.permissions.View ? (
                        <div className="bb-grey ptb-10 pl-15  pointer lm-1 ">
                          {console.log('This props permissions: ', this.props.permissions)}
                          <a
                            className="noTextDecor"
                            onClick={() => this.preview(item.CourseID)}
                          >
                            <i className="fas fa-external-link-alt co_admin  " />
                            &nbsp; &nbsp;{" "}
                            <span className="clr-black">Preview</span>
                          </a>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>{" "}
            </div>
          ))
        ) : (
          <div className="fs-24 text-center w-100 h-225p ">
            No Results Found.
          </div>
        );
      this.setState({ elements: elements });
    } else {
    }
  }
  render() {
    let paginationElement;
    if (this.state.result.length > 0 && this.state.pageCount > 1) {
      paginationElement = (
        <ReactPaginate
          previousLabel={"←"}
          nextLabel={"→"}
          breakLabel={<span className="gap">...</span>}
          pageCount={this.state.pageCount}
          onPageChange={this.handlePageClick}
          initialPage={0}
          className={this.state.pageCount === 1 ? "active" : "not active"}
          containerClassName={"pagination"}
          previousLinkClassName={"previous_page"}
          nextLinkClassName={"next_page"}
          disabledClassName={"disabled"}
          activeClassName={"active"}
          activeLinkClassName={"pagination__link--active"}
        />
      );
    }
    return (
      <React.Fragment>
        <WebNotif />
        <div className="col-md-12 mtb-25">
          <div className="row lrm-0">
            <div className="col-md-9 lrp-0">
              <h4 className="regular_font fs-26 bm-0">
                {this.props.courseType == 0
                  ? "PEC Courses"
                  : this.props.courseType == 1
                  ? "PEC Training"
                  : "Webinars"}{" "}
              </h4>
            </div>
            <div className="col-md-3 lrp-0">
              {this.props.permissions.Add == 1 && (
                <Link
                  to={{
                    pathname: this.state.editURL,
                    state: {
                      type: 0,
                      courseType: parseInt(this.props.courseType),
                    },
                  }}
                >
                  <button className="btn admin_btn white w-150p float-right bold">
                    {" "}
                    + Add New
                  </button>
                </Link>
              )}
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-4 ">
              <label className="bm-0">Search</label>
              <div className="input-group md-form form-sm form-2 mtb-10">
                <input
                  type="text"
                  className="form-control"
                  name="search"
                  value={this.state.search}
                  placeholder="Enter Webinar Name"
                  aria-label="Text input with dropdown button"
                  onChange={this.handleChange1}
                />
              </div>
            </div>
            <div className="col-md-2 ">
              <label className="bm-0">Type</label>
              <Select
                className=" mtb-10"
                options={this.state.webinarType}
                value={this.state.selectedType}
                onChange={this.filterType}
              />
            </div>
            <div className="col-md-2 ">
              <label className="bm-0">Category</label>
              <Select
                className=" mtb-10"
                options={this.state.CategorySet}
                value={this.state.selectedCategory}
                onChange={this.filterCategory}
              />
            </div>
            <div className="col-md-2">
              <label className="bm-0">Instructor</label>
              <Select
                className="mtb-10"
                options={this.state.InstructorSet}
                value={this.state.SelectedInstructor}
                onChange={this.handleInstructor}
                placeholder="Instructor"
              />
            </div>
            <div className="col-md-2">
              <label className="bm-0">Date Sorting</label>
              <Select
                className="mtb-10"
                options={this.state.optionsDate}
                value={this.state.selectedDate}
                onChange={this.handleDate}
              />
            </div>
          </div>
        </div>
        <hr></hr>
        {this.state.elements}
        {paginationElement}
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    allWebinars: state.webinarData.allWebinars,
  };
}
export default withRouter(
  connect(mapStateToProps, {
    getCategories,
    getAllWebinars,
    getAllWebinarAdmin,
    getInstructors,
    getAdminWebinarCount,
    archive_webinar,
  })(Webinar)
);
