import React from "react";
import { TextField, MenuItem } from "@material-ui/core";
import WebNotif from "../WebNotif";
import * as yup from "yup";
import Loader from "react-loader-spinner";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import {
  newResetPassword,
  newResetEmail,
  passwordReset,
  passwordResetSet,
} from "../../redux/admin/users/adminuseractions";
import { toggleFullscreen } from "video-react/lib/actions/player";
import { Tune } from "@material-ui/icons";

var obj = new WebNotif();

const emailSchema = yup.object().shape({
  email: yup.string().email().required(),
});
const passwordSchema = yup.object().shape({
  pass: yup
    .string()
    .required("Please enter your password")
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Password must contain at least 8 characters, one uppercase, one number and one special case character"
    ),
  confirmPass: yup
    .string()
    .required("Please confirm your password")
    .when("password", {
      is: (password) => (password && password.length > 0 ? true : false),
      then: yup.string().oneOf([yup.ref("password")], "Password doesn't match"),
    }),
});

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "green",
    },
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "#20a84c",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#20a84c",
      },
    },
  },
})(TextField);

class NewResetPassword extends React.Component {
  state = {
    email: "",
    pass: "",
    confirmPass: "",
    loading: false,
    next: false,
    emailFlag: false,
    token: null,
    tokenFlag: false,
    tokenParams: ""
  };
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  componentDidMount() {
    console.log('Token Params: ', this.props.match.params.token);
    this.setState({ tokenParams: this.props.match.params.token }, () => {
      console.log("Token Params: ", this.state.tokenParams);
    });
    if (this.props.match.params.token && this.props.match.params.token) {
      this.setState({
        tokenFlag: true,
        token: this.props.match.params.token,
      });
    } else {
      this.setState({
        emailFlag: true,
      });
    }
  }
  proceed = () => {
    this.setState({ loading: true });
    this.props
      .passwordReset({ email: this.state.email })
      .then((res) => {
        console.log("Res Data passwordReset: ", res);
        this.setState({
          token: res.data,
          next: true,
          emailFlag: false,
          tokenFlag: true,
          loading: false
        }, () => {
            this.props.newResetEmail({ email: this.state.email, token: res.data })
          .then((res) => {
            obj.createNotification(
                "success",
                "Email sent successfully"
              );
            this.setState({
                loading: false
            });
          })
          .catch((err) => {
              obj.createNotification(
              "error",
              "Error while sending email"
            );
            this.setState({
              loading: false
            });
          });
        });
       
      })
      .catch((err) => {
        obj.createNotification(
          "error",
          "Email does not exist, or admin is inactive"
        );
        this.setState({
          loading: false
        });
      });
  
  };

  setPass = () => {
    passwordSchema
      .validate(this.state)
      .then((validation) => {
        this.setState(
          {
            loading: true,
          },
          () => {
            if (this.state.pass !== this.state.confirmPass) {
              obj.createNotification("error", "Password doesn't match");
            } else {
              this.props
                .passwordResetSet({
                  validate: validation,
                  token: this.state.token,
                })
                .then((res) => {
                  this.setState({ loading: false }, () => {
                    obj.createNotification(
                      "success",
                      "Password updated successfully"
                    );
                    this.props.history.push("/admin");
                  });
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          }
        );
      })
      .catch((err) => {
        console.log(err);
        obj.createNotification("error", err.message);
      });
  };

  render() {
    console.log("Inside NewResetPassword.jsx");
    return (
      <React.Fragment>
        <div className="row ptb-20 d-flex justify-content-center align-items-center Total-Body">
          <div className="col-md-6  ">
            <h4 className="ptb-20">

              {this.state.next && !this.state.emailFlag
                ? "Please Check Your Email To Reset Your Password."
                : "Dear Admin, Please Enter Your Email To Proceed."}
            </h4>

            {this.state.emailFlag ? (
              <div className="form-group">
                <CssTextField
                  type="email"
                  fullWidth={true}
                  label="Email"
                  variant="outlined"
                  name="email"
                  value={this.state.email}
                  onChange={this.onChange}
                />
              </div>
            ) : (
              this.state.tokenFlag && (
                <div>
                 
                </div>
              )
            )}

            {this.state.loading === true ? (
              <>
                <div className="loaderDiv text-center">
                  <Loader type="Oval" color="#06580e" height={80} width={80} />
                </div>
              </>
            ) : (
              <div className="row">
                <div className="col-md-4 offset-md-4">
                  {this.state.emailFlag ? (
                    <button
                      onClick={
                        this.state.emailFlag
                          ? this.proceed
                          : this.state.tokenFlag && this.setPass
                      }
                      type="submit"
                      className="btn text-center btn-submit black bold fs-20"
                    >
                      {this.state.emailFlag ? "Proceed" : ""}{" "}
                    </button>
                  ) : (
                    ""
                    // <button
                    //   onClick={
                    //     this.props.history.push("/admin")
                    //   }
                    //   type="submit"
                    //   className="btn text-center btn-submit black bold fs-20"
                    // >
                    //   Back To Login
                    // </button>
                  )}
                  {/* {this.state.loading ? 
                                  <div className="mb-40">
                                    <Loader type="Oval" color="#06580e" height={80} width={80}/>
                                  </div>
                                  :
                                  <button 
                                  onClick={this.state.next && !(this.state.emailFlag) ? this.setPass : this.proceed}
                                  type="submit" 
                                  className="btn text-center btn-submit black bold fs-20"                    
                                  >
                                 {this.state.next && !(this.state.emailFlag) ? "Set Password ": "Proceed"} </button>
                                  } */}
                </div>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {};
}
export default connect(mapStateToProps, { newResetPassword, newResetEmail, passwordReset, passwordResetSet })(
  NewResetPassword
);
