import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from '@material-ui/icons/Edit';
import { withStyles } from "@material-ui/core/styles";

const defaultToolbarSelectStyles = {
  iconButton: {
    marginRight: "24px",
    top: "50%",
    display: "inline-block",
    position: "relative",
    transform: "translateY(-50%)",
  },
  deleteIcon: {
    color: "#000",
  },
};
class CustomToolbarSelect extends React.Component {
componentDidMount(){
  
}
    
handleEdit=()=>{
    if(this.props.selectedRows.data.length>1){
      //bulk edit
      this.props.BulkEdit(this.props.selectedRows.data)

    }else{
     //single edit
      this.props.Edit(this.props.selectedRows.data[0].dataIndex)
    }
}

handleDelete=()=>{
    if(this.props.selectedRows.data.length>1){
      //bulk delete
      this.props.BulkDelete(this.props.selectedRows.data)

     
    }else{
      //single delete
      this.props.Delete(this.props.selectedRows.data[0].dataIndex)

    }
}

  render() {
    const { classes } = this.props;

    return (
      <div className="custom-toolbar-select ">
        {this.props.permissions.Edit ? 
        <Tooltip title={"edit"}>
          <IconButton className={classes.iconButton+"mt-15"}  onClick={this.handleEdit}>
            <EditIcon className={classes.deleteIcon} style={{color:'#20a84c'}}/>
          </IconButton>
        </Tooltip>
        : 
        ""
        }
        {this.props.permissions.Delete ? 
        
        <Tooltip title={"delete"}>
        <IconButton className={classes.iconButton+"mt-15"}  onClick={this.handleDelete}>
          <DeleteIcon className={classes.deleteIcon} style={{color:'#20a84c'}}/>
        </IconButton>
        </Tooltip>
            : 
            ""
        }
        
      </div>
    );
  }

}

export default withStyles(defaultToolbarSelectStyles, { name: "CustomToolbarSelect" })(CustomToolbarSelect);