import React, { Component } from "react";
import "../assets/css/pdf.css";
import Cookies from "universal-cookie";
import moment from "moment";
import jsPDF from "jspdf";
import Button from "@material-ui/core/Button";
import html2canvas from "html2canvas";
import numToWords from "num-to-words";
import img1 from "../assets/image/pec_logo.jpg";
// import img2 from "../assets/image/pec_logo.jpg";

const cookies = new Cookies();
export default class Pdf extends Component {
  state = {
    data: false,
  };
  componentDidMount() {
    let data = cookies.get("my");
    // let newData = cookies.get("pma_lang");
    console.log('pdfffffff data: ', data);
    // console.log('new pdfff data: ', newData);
    console.log('data[0].CouseName: ', data[0].CouseName);
    this.setState(
      {
        courseName: data[0].CouseName,
        price: data[1].price,
        date: data[2].Date,
        name: data[3].Name,
        reg: data[4].RegNo,
        account: data[5].account,
        bank: data[7].bank,
        discipline: data[6].discipline,
        refNo: Math.floor(100000 + Math.random() * 900000),
      },
      () => {
        const input = document.getElementById("Artboard__1");
        html2canvas(input).then((canvas) => {
          var imgWidth = 200;
          var pageHeight = 290;
          var imgHeight = (canvas.height * imgWidth) / canvas.width;
          var heightLeft = imgHeight;
          const imgData = canvas.toDataURL("image/png");
          const pdf = new jsPDF("p", "mm", "a4");
          var position = 0;
          var heightLeft = imgHeight;
          pdf.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
          pdf.save("download.pdf");
        });
        cookies.remove("my");
      }
    );
  }
  printDocument = () => {
    const input = document.getElementById("Artboard__1");
    // console.log(input);
    html2canvas(input).then((canvas) => {
      //   console.log(canvas);
      var imgWidth = 200;
      var pageHeight = 290;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      var position = 0;
      var heightLeft = imgHeight;
      //   console.log(imgData);
      pdf.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
      pdf.save("download.pdf");
    });
  };
  render() {
    return (
      <div id="Artboard__1">
        <svg class="Path_5211" viewBox="2250.428 8588.342 1 595.275">
          <path
            id="Path_5211"
            d="M 2250.428466796875 8588.341796875 L 2250.428466796875 9183.6171875"
          ></path>
        </svg>
        <svg class="Path_5212" viewBox="2250.428 8588.342 1 595.275">
          <path
            id="Path_5212"
            d="M 2250.428466796875 8588.341796875 L 2250.428466796875 9183.6171875"
          ></path>
        </svg>
        <img id="Image_125" src={img1} />
        <div id="Account_No_01540035529603">
          <span>Account No: {this.state.account}</span>
        </div>
        <div id="Date_5102020">
          <span>Date: </span>
          <span style={{ "font-style": "normal", "font-weight": "normal" }}>
            {moment().format("DD-MM-YYYY")}
          </span>
        </div>
        <div id="Engineers_Name_PEC_Number_Cour">
          <span>
            Engineer's Name
            <br />
            PEC Number
            <br />
            Discipline
            <br />
            Course / Webinar
            <br />
            Course Date
          </span>
        </div>
        <div id="John_Doe_12345_Mechanical_Engi">
          <span>
            {this.state.name}
            <br />
            {this.state.reg}
            <br />
            {this.state.discipline}
            <br />
            {this.state.courseName}
            <br />
            {moment(this.state.date).format("DD-MM-YYYY")}
          </span>
        </div>
        <div id="Group_6739">
          <svg class="Path_5217" viewBox="2355.488 8546.621 1 43.155">
            <path
              id="Path_5217"
              d="M 2355.48779296875 8546.62109375 L 2355.48779296875 8589.7763671875"
            ></path>
          </svg>
          <svg class="Path_5215" viewBox="2355.488 8546.62 1 42.527">
            <path
              id="Path_5215"
              d="M 2355.48779296875 8546.6201171875 L 2355.48779296875 8589.1474609375"
            ></path>
          </svg>
          <div id="Repeat_Grid_134">
            <div id="Repeat_Grid_134_0" class="">
              <svg class="Path_5216" viewBox="2268 8551.244 239.158 1">
                <path
                  id="Path_5216"
                  d="M 2268 8551.244140625 L 2507.157958984375 8551.244140625"
                ></path>
              </svg>
            </div>
            <div id="Repeat_Grid_134_1" class="">
              <svg class="Path_5216_be" viewBox="2268 8551.244 239.158 1">
                <path
                  id="Path_5216_be"
                  d="M 2268 8551.244140625 L 2507.157958984375 8551.244140625"
                ></path>
              </svg>
            </div>
            <div id="Repeat_Grid_134_2" class="">
              <svg class="Path_5216_bg" viewBox="2268 8551.244 239.158 1">
                <path
                  id="Path_5216_bg"
                  d="M 2268 8551.244140625 L 2507.157958984375 8551.244140625"
                ></path>
              </svg>
            </div>
          </div>
          <svg class="Path_5218" viewBox="2355.488 8546.619 1 43.023">
            <path
              id="Path_5218"
              d="M 2355.48779296875 8546.619140625 L 2355.48779296875 8589.642578125"
            ></path>
          </svg>
          <div id="Payment_Date">
            <span>Payment Date</span>
          </div>
          <div id="Course_Price">
            <span>Course Fee</span>
          </div>
          <div id="PKR_6000-">
            <span>PKR {this.state.price}/-</span>
          </div>
        </div>
        <div id="Rupees_in_words">
          <span>Rupees in words</span>
        </div>
        <svg class="Path_5219" viewBox="2467.389 8526 163.584 1">
          <path
            id="Path_5219"
            d="M 2467.38916015625 8526 L 2630.9736328125 8526"
          ></path>
        </svg>
        <svg class="Path_5220" viewBox="2467.389 8526 240.017 1">
          <path
            id="Path_5220"
            d="M 2467.38916015625 8526 L 2707.406494140625 8526"
          ></path>
        </svg>
        <div id="Six_Thousand_Rupees_only">
          <span>{numToWords(this.state.price)} conly</span>
        </div>
        <img id="Image_125_bp" src={img1} />
        <img id="Image_125_bq" src={img1} />
        <div id="Errors_and_omissions_expected_">
          <span>
            Errors and omissions expected. In case of any problem <br />
            please contact us at cpd@pec.org.pk
          </span>
        </div>
        <div id="Errors_and_omissions_expected__bs">
          <span>
            Errors and omissions expected. In case of any problem <br />
            please contact us at cpd@pec.org.pk
          </span>
        </div>
        <div id="Errors_and_omissions_expected__bt">
          <span>
            Errors and omissions expected. In case of any problem <br />
            please contact us at cpd@pec.org.pk
          </span>
        </div>
        <div id="Habib_Bank_Limited">
          <span>
            {this.state.bank && this.state.bank === "MCB"
              ? "MCB Bank Limited"
              : this.state.bank === "HBL" && "Habib Bank Limited"}
          </span>
        </div>
        <div id="CPD">
          <span>CPD</span>
        </div>
        <div id="Group_67422">
          <div id="PECs_Copy">
            <span>PEC's Copy</span>
          </div>
          <div id="Engineers_Copy">
            <span>Engineer's Copy</span>
          </div>
          <div id="Banks_Copy">
            <span>Bank's Copy</span>
          </div>
        </div>
        <div id="Group_6742">
          <div id="Reference_No_01540035529603">
            <span>
              Reference No: 00{this.state.reg}0000{this.state.refNo}
            </span>
          </div>
          <div id="Reference_No_01540035529603_b">
            <span>
              Reference No: 00{this.state.reg}0000{this.state.refNo}
            </span>
          </div>
          <div id="Reference_No_01540035529603_ca">
            <span>
              Reference No: 00{this.state.reg}0000{this.state.refNo}
            </span>
          </div>
        </div>
        <div id="CPD_b">
          <span>CPD</span>
        </div>
        <div id="CPD_ca">
          <span>CPD</span>
        </div>
        <div id="Account_No_01540035529603_b">
          <span>Account No: {this.state.account}</span>
        </div>
        <div id="Engineers_Name_PEC_Number_Cour_b">
          <span>
            Engineer's Name
            <br />
            PEC Number
            <br />
            Discipline
            <br />
            Course / Webinar
            <br />
            Course Date
          </span>
        </div>
        <div id="John_Doe_12345_Mechanical_Engi_b">
          <span>
            {this.state.name}
            <br />
            {this.state.reg}
            <br />
            {this.state.discipline}
            <br />
            {this.state.courseName}
            <br />
            {moment(this.state.date).format("DD-MM-YYYY")}
          </span>
        </div>
        <div id="Group_6740">
          <svg class="Path_5217_ca" viewBox="2355.488 8546.621 1 43.155">
            <path
              id="Path_5217_ca"
              d="M 2355.48779296875 8546.62109375 L 2355.48779296875 8589.7763671875"
            ></path>
          </svg>
          <svg class="Path_5215_cb" viewBox="2355.488 8546.62 1 42.527">
            <path
              id="Path_5215_cb"
              d="M 2355.48779296875 8546.6201171875 L 2355.48779296875 8589.1474609375"
            ></path>
          </svg>
          <div id="Repeat_Grid_134_cc">
            <div id="Repeat_Grid_134_cc_0" class="">
              <svg class="Path_5216_ce" viewBox="2268 8551.244 239.158 1">
                <path
                  id="Path_5216_ce"
                  d="M 2268 8551.244140625 L 2507.157958984375 8551.244140625"
                ></path>
              </svg>
            </div>
            <div id="Repeat_Grid_134_cc_1" class="">
              <svg class="Path_5216_cg" viewBox="2268 8551.244 239.158 1">
                <path
                  id="Path_5216_cg"
                  d="M 2268 8551.244140625 L 2507.157958984375 8551.244140625"
                ></path>
              </svg>
            </div>
            <div id="Repeat_Grid_134_cc_2" class="">
              <svg class="Path_5216_ci" viewBox="2268 8551.244 239.158 1">
                <path
                  id="Path_5216_ci"
                  d="M 2268 8551.244140625 L 2507.157958984375 8551.244140625"
                ></path>
              </svg>
            </div>
          </div>
          <svg class="Path_5218_cj" viewBox="2355.488 8546.619 1 43.023">
            <path
              id="Path_5218_cj"
              d="M 2355.48779296875 8546.619140625 L 2355.48779296875 8589.642578125"
            ></path>
          </svg>
          <div id="Payment_Date_ck">
            <span>Payment Date</span>
          </div>
          <div id="Course_Price_cl">
            <span>Course Fee</span>
          </div>
          <div id="PKR_6000-_cm">
            <span>PKR {this.state.price}/-</span>
          </div>
        </div>
        <div id="Rupees_in_words_cn">
          <span>Rupees in words</span>
        </div>
        <svg class="Path_5227" viewBox="2467.389 8526 163.584 1">
          <path
            id="Path_5227"
            d="M 2467.38916015625 8526 L 2630.9736328125 8526"
          ></path>
        </svg>
        <svg class="Path_5228" viewBox="2467.389 8526 240.017 1">
          <path
            id="Path_5228"
            d="M 2467.38916015625 8526 L 2707.406494140625 8526"
          ></path>
        </svg>
        <div id="Six_Thousand_Rupees_only_cq">
          <span>{numToWords(this.state.price)} only</span>
        </div>
        <div id="Habib_Bank_Limited_cr">
          <span>
            {this.state.bank && this.state.bank === "MCB"
              ? "MCB Bank Limited"
              : this.state.bank === "HBL" && "Habib Bank Limited"}
          </span>
        </div>
        <div id="Account_No_01540035529603_cs">
          <span>Account No: {this.state.account}</span>
        </div>
        <div id="Engineers_Name_PEC_Number_Cour_ct">
          <span>
            Engineer's Name
            <br />
            PEC Number
            <br />
            Discipline
            <br />
            Course / Webinar
            <br />
            Course Date
          </span>
        </div>
        <div id="John_Doe_12345_Mechanical_Engi_cu">
          <span>
            {this.state.name}
            <br />
            {this.state.reg}
            <br />
            {this.state.discipline}
            <br />
            {this.state.courseName}
            <br />
            {moment(this.state.date).format("DD-MM-YYYY")}
          </span>
        </div>
        <div id="Group_6741">
          <svg class="Path_5217_cw" viewBox="2355.488 8546.621 1 43.155">
            <path
              id="Path_5217_cw"
              d="M 2355.48779296875 8546.62109375 L 2355.48779296875 8589.7763671875"
            ></path>
          </svg>
          <svg class="Path_5215_cx" viewBox="2355.488 8546.62 1 42.527">
            <path
              id="Path_5215_cx"
              d="M 2355.48779296875 8546.6201171875 L 2355.48779296875 8589.1474609375"
            ></path>
          </svg>
          <div id="Repeat_Grid_134_cy">
            <div id="Repeat_Grid_134_cy_0" class="">
              <svg class="Path_5216_c" viewBox="2268 8551.244 239.158 1">
                <path
                  id="Path_5216_c"
                  d="M 2268 8551.244140625 L 2507.157958984375 8551.244140625"
                ></path>
              </svg>
            </div>
            <div id="Repeat_Grid_134_cy_1" class="">
              <svg class="Path_5216_da" viewBox="2268 8551.244 239.158 1">
                <path
                  id="Path_5216_da"
                  d="M 2268 8551.244140625 L 2507.157958984375 8551.244140625"
                ></path>
              </svg>
            </div>
            <div id="Repeat_Grid_134_cy_2" class="">
              <svg class="Path_5216_db" viewBox="2268 8551.244 239.158 1">
                <path
                  id="Path_5216_db"
                  d="M 2268 8551.244140625 L 2507.157958984375 8551.244140625"
                ></path>
              </svg>
            </div>
          </div>
          <svg class="Path_5218_c" viewBox="2355.488 8546.619 1 43.023">
            <path
              id="Path_5218_c"
              d="M 2355.48779296875 8546.619140625 L 2355.48779296875 8589.642578125"
            ></path>
          </svg>
          <div id="Payment_Date_c">
            <span>Payment Date</span>
          </div>
          <div id="Course_Price_c">
            <span>Course Fee</span>
          </div>
          <div id="PKR_6000-_c">
            <span>PKR {this.state.price}/-</span>
          </div>
        </div>
        <div id="Rupees_in_words_c">
          <span>Rupees in words</span>
        </div>
        <svg class="Path_5230" viewBox="2467.389 8526 163.584 1">
          <path
            id="Path_5230"
            d="M 2467.38916015625 8526 L 2630.9736328125 8526"
          ></path>
        </svg>
        <svg class="Path_5231" viewBox="2467.389 8526 240.017 1">
          <path
            id="Path_5231"
            d="M 2467.38916015625 8526 L 2707.406494140625 8526"
          ></path>
        </svg>
        <div id="Six_Thousand_Rupees_only_dc">
          <span>{numToWords(this.state.price)} only</span>
        </div>
        <div id="Habib_Bank_Limited_dd">
          <span>
            {this.state.bank && this.state.bank === "MCB"
              ? "MCB Bank Limited"
              : this.state.bank === "HBL" && "Habib Bank Limited"}
          </span>
        </div>
        <div id="Date_5102020_de">
          <span>Date: </span>
          <span style={{ "font-style": "normal", "font-weight": "normal" }}>
            {moment().format("DD-MM-YYYY")}
          </span>
        </div>
        <div id="Date_5102020_df">
          <span>Date: </span>
          <span style={{ "font-style": "normal", "font-weight": "normal" }}>
            {moment().format("DD-MM-YYYY")}
          </span>
        </div>
      </div>
    );
  }
}
