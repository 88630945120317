export const CREATE_WEBINAR_DETAILS= "CREATE_WEBINAR_DETAILS";
export const  CREATE_WEBINAR_SESSIONS= " CREATE_WEBINAR_SESSIONS";
export const  CREATE_WEBINAR_PRICING= " CREATE_WEBINAR_PRICING";
export const  CREATE_WEBINAR_FAQS= " CREATE_WEBINAR_FAQS";
export const GOT_ALL_WEBINARS= "GOT_ALL_WEBINARS";
export const SAVE_WEBINAR_DRAFT = "SAVE_WEBINAR_DRAFT";
export const GOT_WEBINAR_DATA = "GOT_WEBINAR_DATA";


export const EDITED_WEBINAR_DETAILS= "EDITED_WEBINAR_DETAILS";

export const EDITED_WEBINAR_PRICING = "EDITED_WEBINAR_PRICING";

export const POPULATE_SESSIONS= "POPULATE_SESSIONS";
export const POPULATE_PRICE_PLAN  = "POPULATE_PRICE_PLAN";