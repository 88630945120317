import React from 'react';
import {Route,Redirect} from 'react-router-dom';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
const UserRoute = ({isAuthenticated, component:Component, ...rest  }) => (
<Route  {...rest}  render={props => isAuthenticated ? 
 <Component {...props}/>: <Redirect to="/Login"></Redirect>}    />
);
UserRoute.propTypes = {

    component : PropTypes.func.isRequired,
    
}

function mapStateToProps(state){
   return{
    isAuthenticated : state.userData.user
   };
}

export default connect (mapStateToProps)(UserRoute);