import React, { Component } from "react";

export default class QualityCell extends Component {
  //   static propTypes = {
  //     value: PropTypes.string.isRequired,
  //     index: PropTypes.number.isRequired,
  //     change: PropTypes.func.isRequired,
  //   };

  render() {
    const { value, index, change } = this.props;
    const colors = {
      green: "#20a84c",
      yellow: "#ffc733",
      red: "#ff0000",
      grey: "#00000",
    };

    let backgroundColor = colors.grey;
    if (value === "Pending") {
      backgroundColor = colors.yellow;
    } else if (value === "Rejected" || value === "Not Approved") {
      backgroundColor = colors.red;
    } else if (value === "Approved") {
      backgroundColor = colors.green;
    }

    return (
      <p
        value={value}
        onChange={(event) => change(event.target.value, index)}
        style={{ color: backgroundColor }}
      >
        {value}
      </p>
    );
  }
}
