import React, { Component } from "react";
import AdminSidebar from "./AdminSidebar";
import { connect } from "react-redux";
import CustomToolbarSelect from "./CustomToolbarSelect";
import QualityCell from "./QualityCell";
import WebNotif from "../WebNotif";
// import TestComp from "./TestComp";
import MUIDataTable from "mui-datatables";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import FuzzySearch from "fuzzy-search";
import Loader from "react-loader-spinner";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import Select from "react-select";
import * as adminroutes from "../../constants/adminRoutes";
import {
  getcoursepayments,
  getcoursepaymentsrest,
  getcoursepaymentsRejected,
  getFilterPaymentsData,
  getPaymentSearch,
  getCoursePaymentCount,
  getCoursePaymentCountRest,
  getCoursePaymentCountRejected,
  approvePayment,
  rejectPayment,
} from "../../redux/admin/financial/financialactions";
import Moment from "react-moment";
import { urlImageUrl } from "../../config/urlapi";
import ModalImage from "react-modal-image";
import Modal from "react-modal";
import { object } from "yup";
var obj = new WebNotif();

let newdata = [];
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "500px",
    backgroundColor: "#e0eadf",
    border: "none",
    color: "black",
    height: "300px",
    overflowY: "hidden",
    "z-index": 9999,
  },
  overlay: {
    zIndex: 1000,
    backgroundColor: "rgba(0, 0, 0, 0.35)",
  },
};

const customStyles1 = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "1000px",
    backgroundColor: "#FFFFFF",
    border: "none",
    color: "black",
    //height: "1000px",
    overflowY: "hidden",
    zIndex: 9990,
  },
  overlay: {
    zIndex: 999,
    backgroundColor: "rgba(0, 0, 0, 0.35)",
  },
};

//let this.coursePaymentIterator;

Modal.setAppElement("#root");
class CoursePayments extends Component {
  state = {
    filterOptionsState: false,
    simpleOptionsState: true,
    searchOptionsState: false,
    filter: "",
    modalIsOpen: false,
    applicationId: [],
    loader: false,
    loader1: false,
    currentpayment: {},
    freeWebinars: [],
    courseSet: [],
    offset: 0,
    perPage: 25,
    limit: 25,
    setActiveTab: "Pending",
    allowPagination: true,
    topNavPending: "",
    topNavApproved: "",
    topNavRejected: "",
    showActivePending: "",
    showActiveApproved: "",
    showActiveRejected: "",
    count: 1,
    page: 0,
    pendingPage: 0,
    approvedPage: 0,
    rejectedPage: 0,
    selectedTabs: 1,
    statusSet: [
      { label: "Pending", value: "Pending" },
      { label: "Approved", value: "Approved" },
      { label: "Rejected", value: "Rejected" },
      { label: "All", value: "All" },
    ],
    SelectedstatusSet: { value: "", label: "All" },
    SelectedCourseSet: { value: "", label: "All" },
    SelectApplicationId: { value: "", label: "All" },
    active: "",
    newdata: [],
    unactive: "show active",
    alldata: [],
    singledata: [],
    opentable: false,
    nextbtn: false,
    previousbtn: false,
    responsedata: [],
    selectedDate: "",
    searchType: [
      { label: "Email", value: "userinfo.PrimaryEmail" },
      { label: "Full Name", value: "userinfo.FullName" },
    ],
    selectedType: "",
    search: "",
    optionsDate: [
      { label: "Recent First", value: "Recent First" },
      { label: "Recent Last", value: "Recent Last" },
    ],
    adminId: "",
  };

  constructor(props) {
    super(props);

    this.searchEmailInput = React.createRef();
    this.searchNameInput = React.createRef();
    this.searchTypeInput = React.createRef();
    this.searchTextInput = React.createRef();
    this.searchPecInput = React.createRef();
    this.searchDisciplineInput = React.createRef();
    this.searchIdInput = React.createRef();

    // this.searchFilter = this.searchFilter.bind(this);
  }

  selectedTab = 1;

  handleChangee = (event) => {
    this.setState({ filter: event.target.value });
  };
  handleNameChange = (event) => {
    this.setState(
      { ...this.state, [event.target.name]: event.target.value },
      () => {
        var searcher;
        searcher = new FuzzySearch(
          this.state.alldata,
          [
            "Amount submitted",
            "Name of Engineer",
            "PEC Number",
            "Date of Challan Submission",
            "Challan Number",
            "CPD Course Name",
            "Amount submitted",
            "Status",
          ],
          {}
        );
        const result = searcher.search(this.state.search);
        this.setState({ newdata: result });
      }
    );
  };

  handleApplication = (event) => {
    let val = event.target.value;
    val = val + "";
    this.setState(
      { ...this.state, [event.target.name]: event.target.value },
      () => {
        var searcher;
        searcher = new FuzzySearch(this.state.alldata, ["Application ID"], {});
        const result = searcher.search(val);
        this.setState({ newdata: result });
      }
    );
  };
  handleCourses = (selectedOption) => {
    this.setState({ SelectedCourseSet: selectedOption });
    var searcher;
    searcher = new FuzzySearch(this.state.alldata, ["CPD Course Name"], {});
    const result = searcher.search(selectedOption.value);
    this.setState({ newdata: result });
  };
  handleStatus = (selectedOption) => {
    this.setState({ SelectedstatusSet: selectedOption });
    if (selectedOption.label === "All") {
      this.setState({ newdata: this.state.alldata });
    } else {
      var searcher;
      searcher = new FuzzySearch(this.state.alldata, ["Status"], {});
      const result = searcher.search(selectedOption.value);
      this.setState({ newdata: result });
    }
  };

  componentDidUpdate(prevProp) {
    if (prevProp.location.state !== this.props.location.state) {
      if (this.props.location.state === undefined) {
        this.getcoursePaymentCount();
        this.getCoursePayments();
        this.setState({
          topNavPending: "active",
          topNavApproved: "",
          topNavRejected: "",
          showActivePending: "show active",
          showActiveApproved: "",
          showActiveRejected: "",
        });
      } else {
        if (this.props.location.state.pending) {
          this.loadPendingData();
          this.setState({
            topNavPending: "active",
            topNavApproved: "",
            topNavRejected: "",
            showActivePending: "show active",
            showActiveApproved: "",
            showActiveRejected: "",
          });
        }
        if (this.props.location.state.approved) {
          this.loadData();
          this.setState({
            topNavApproved: "active",
            topNavRejected: "",
            topNavPending: "",
            showActivePending: "",
            showActiveApproved: "show active",
            showActiveRejected: "",
          });
        }
        if (this.props.location.state.rejected) {
          this.loadRejectedData();
          this.setState({
            topNavRejected: "active",
            topNavPending: "",
            topNavApproved: "",
            showActivePending: "",
            showActiveApproved: "",
            showActiveRejected: "show active",
          });
        }
      }
    }
  }
  componentDidMount() {
    const adminInfo = localStorage.getItem("admin_info");

    const { UserID } = JSON.parse(adminInfo);

    console.log("COMPONENT DID MOUNT User ID ... ", UserID);

    this.setState({
      ...this.state,
      adminId: UserID,
    });

    if (this.props.location.state === undefined) {
      this.getcoursePaymentCount();
      this.getCoursePayments();
      this.setState({
        topNavPending: "active",
        topNavApproved: "",
        topNavRejected: "",
        showActivePending: "show active",
        showActiveApproved: "",
        showActiveRejected: "",
        setActiveTab: "Pending",
      });
    } else {
      if (this.props.location.state.pending) {
        this.loadPendingData();
        this.setState({
          topNavPending: "active",
          topNavApproved: "",
          topNavRejected: "",
          showActivePending: "show active",
          showActiveApproved: "",
          showActiveRejected: "",
          setActiveTab: "Pending",
        });
      }
      if (this.props.location.state.approved) {
        this.loadData();
        this.setState({
          topNavApproved: "active",
          topNavRejected: "",
          topNavPending: "",
          showActivePending: "",
          showActiveApproved: "show active",
          showActiveRejected: "",
          setActiveTab: "Approved",
        });
      }
      if (this.props.location.state.rejected) {
        this.loadRejectedData();
        this.setState({
          topNavRejected: "active",
          topNavPending: "",
          topNavApproved: "",
          showActivePending: "",
          showActiveApproved: "",
          showActiveRejected: "show active",
          setActiveTab: "Rejected",
        });
      }
    }
  }

  changeFilter = (data) => {
    // console.log("Filter Data: ", data);

    let newdata = [];
    data.map((item, index) => {
      if (!(item.length === 0)) {
        switch (index) {
          case 0: {
            newdata.push({ "course_payment.paymentID": item[0] });
            break;
          }
          case 1: {
            newdata.push({ "userinfo.FullName": item[0] });
            break;
          }
          case 2: {
            newdata.push({ "userinfo.PEC_REG_NO": item[0] });
            break;
          }
          case 3: {
            newdata.push({ "userinfo.discipline": item[0] });
            break;
          }
          case 4: {
            newdata.push({ "userinfo.Primary_Contact_No": item[0] });
            break;
          }
          case 5: {
            newdata.push({ "course_payment.referenceNo": item[0] });
            break;
          }
          case 6: {
            newdata.push({ "course_payment.DateCreated": item[0] });
            break;
          }
          case 7: {
            newdata.push({ "courses.Course_Name": item[0] });
            break;
          }
          case 8: {
            newdata.push({ "course_session.price": item[0] });
            break;
          }
        }
      }
    });
    if (newdata.length === 0) {
      this.setState({ newdata: this.state.alldata, allowPagination: true });
    } else {
      let filterActiveTab = this.state.setActiveTab;
      newdata.push({ "course_payment.Status": filterActiveTab });
      newdata.push({ limit: this.state.limit, offset: this.state.offset });
      this.props
        .getFilterPaymentsData(newdata)
        .then((res) => {
          // console.log("Ress: ", res);
          let filterCount = res.length;
          // console.log("Filter Count: ", filterCount);
          let PaymentsLists = this.coursePaymentIterator(res);

          this.setState({
            filterOptionsState: true,
            simpleOptionsState: false,
            searchOptionsState: false,
            allowPagination: true,
            newdata: PaymentsLists,
            count: filterCount,
            perPage: 25,
            loader: false,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  changePagePending = (page, sortOrder) => {
    this.setState(
      {
        offset: page * this.state.perPage,
        pendingPage: page,
      },
      () => {
        this.loadPendingData();
      }
    );
  };

  resetFiltersPending = () => {
    this.searchEmailInput.current.value = "";
    this.searchNameInput.current.value = "";
    this.searchPecInput.current.value = "";
    this.searchDisciplineInput.current.value = "";
    this.searchIdInput.current.value = "";

    this.setState(
      {
        allowPagination: true,
        filterOptionsState: false,
        searchOptionsState: false,
        simpleOptionsState: true,
      },
      () => {
        this.loadPendingData();
      }
    );
  };

  resetFiltersApproved = () => {
    this.searchEmailInput.current.value = "";
    this.searchNameInput.current.value = "";
    this.searchPecInput.current.value = "";
    this.searchDisciplineInput.current.value = "";
    this.searchIdInput.current.value = "";

    this.setState(
      {
        allowPagination: true,
        filterOptionsState: false,
        searchOptionsState: false,
        simpleOptionsState: true,
      },
      () => {
        this.loadData();
      }
    );
  };

  resetFiltersRejected = () => {
    this.searchEmailInput.current.value = "";
    this.searchNameInput.current.value = "";
    this.searchPecInput.current.value = "";
    this.searchDisciplineInput.current.value = "";
    this.searchIdInput.current.value = "";

    this.setState(
      {
        allowPagination: true,
        filterOptionsState: false,
        searchOptionsState: false,
        simpleOptionsState: true,
      },
      () => {
        this.loadRejectedData();
      }
    );
  };

  changePageApproved = (page, sortOrder) => {
    this.setState(
      {
        offset: page * this.state.perPage,
        approvedPage: page,
      },
      () => {
        this.loadData();
      }
    );
  };

  changePageRejected = (page, sortOrder) => {
    this.setState(
      {
        offset: page * this.state.perPage,
        rejectedPage: page,
      },
      () => {
        this.loadRejectedData();
      }
    );
  };

  changePage = (page, sortOrder) => {
    this.setState(
      {
        offset: page * this.state.perPage,
        pendingPage: page,
      },
      () => {
        this.getCoursePayments();
      }
    );
  };
  getCoursePayments = () => {
    this.setState(
      {
        loader: true,
      },
      () => {
        this.props
          .getcoursepayments({
            limit: this.state.limit,
            offset: this.state.offset,
          })
          .then((res1) => {
            // console.log('Course Payments Resss: ', res1);
            let PaymentsLists = this.coursePaymentIterator(res1);
            this.setState({
              loader: false,
              newdata: PaymentsLists,
              alldata: PaymentsLists,
              responsedata: res1,
            });
          })
          .catch((err) => {
            this.setState({ loader: false });
          });
      }
    );
  };
  getCoursePaymentRejected = () => {
    this.setState({ loader1: true }, () => {
      this.props
        .getcoursepaymentsRejected({
          limit: this.state.limit,
          offset: this.state.offset,
        })
        .then((res1) => {
          // console.log(res1);
          let PaymentsLists = this.coursePaymentIterator(res1);
          this.setState({
            loader1: false,
            newdata: PaymentsLists,
            alldata: PaymentsLists,
            responsedata: res1,
          });
        })
        .catch((err) => {
          this.setState({ loader1: false });
          console.log(err);
        });
    });
  };

  getCoursePaymentsRest = () => {
    this.setState({ loader1: true }, () => {
      this.props
        .getcoursepaymentsrest({
          limit: this.state.limit,
          offset: this.state.offset,
        })
        .then((res1) => {
          // console.log(res1);
          let PaymentsLists = this.coursePaymentIterator(res1);
          this.setState({
            loader1: false,
            newdata: PaymentsLists,
            alldata: PaymentsLists,
            responsedata: res1,
          });
        })
        .catch((err) => {
          this.setState({ loader1: false });
          console.log(err);
        });
    });
  };

  changeRowsApproved = (num) => {
    this.setState(
      {
        limit: num,
      },

      () => {
        this.loadData();
      }
    );
  };

  changeRowsPending = (num) => {
    this.setState(
      {
        limit: num,
      },

      () => {
        this.loadPendingData();
      }
    );
  };

  changeRowsRejected = (num) => {
    this.setState(
      {
        limit: num,
      },

      () => {
        this.loadRejectedData();
      }
    );
  };

  coursePaymentIterator = (coursePaymentsLists) => {
    // console.log('Course Payment List: ', coursePaymentsLists);
    let courseSet = [];
    let applicationId = [];
    let coursePaymentsList = coursePaymentsLists.reverse();
    let rows = [];

    for (let i = 0; i < coursePaymentsList.length; i++) {
      courseSet.push({
        value: coursePaymentsList[i].Course_Name,
        label: coursePaymentsList[i].Course_Name,
      });
      applicationId.push({
        value: coursePaymentsList[i].paymentID,
        label: coursePaymentsList[i].paymentID,
      });

      rows.push({
        Status: coursePaymentsList[i].Status,
        Action: this.props.permissions.Edit ? (
          <div>
            <div className="pc_item p-0">
              <a
                onClick={() => this.openModal(coursePaymentsList[i])}
                className="changebtn"
              >
                Status <i className="fas fa-edit"></i>
              </a>
            </div>
            <div
              className={
                coursePaymentsList[i].Status == "Rejected"
                  ? "cored"
                  : coursePaymentsList[i].Status == "Approved"
                  ? "co_g20A84C"
                  : ""
              }
            ></div>
          </div>
        ) : (
          ""
        ),
        "Application ID": coursePaymentsList[i].paymentID + "",
        "Name of Engineer": coursePaymentsList[i].FullName,
        "PEC Number": coursePaymentsList[i].PEC_REG_NO + "",
        Discipline: coursePaymentsList[i].discipline + "",
        "Mobile Number": coursePaymentsList[i].Primary_Contact_No + "",
        "Challan Number": coursePaymentsList[i].referenceNo + "",
        "Date of Challan Submission":
          moment(coursePaymentsList[i].DateCreated).format("ddd Do MMMM YY") +
          "",
        "CPD Course Name": coursePaymentsList[i].Course_Name,
        "Amount submitted": coursePaymentsList[i].price + "",
        "View Challan Tab": (
          <div onClick={() => this.opentable(i, coursePaymentsList[i])}>
            <img
              className="payment-image"
              src={urlImageUrl + coursePaymentsList[i].payment_image_path}
              alt="Payment Image"
            />
          </div>
        ),
      });
    }
    let newset = [];
    const uniqueAges = [...new Set(courseSet.map((obj) => obj.label))];
    uniqueAges.map((item, index) => {
      newset.push({ value: item, label: item });
    });
    this.setState(
      { courseSet: newset, applicationId: applicationId },
      () => {}
    );
    return rows;
  };

  getcoursePaymentCount = () => {
    this.props
      .getCoursePaymentCount()
      .then((res) => {
        let count = Math.floor(res[0].count);
        this.setState({ count: count });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getCoursePaymentCountrest = () => {
    this.props
      .getCoursePaymentCountRest()
      .then((res) => {
        let count = Math.floor(res[0].count);
        this.setState({ count: count });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getCoursePaymentCountRejected = () => {
    this.props
      .getCoursePaymentCountRejected()
      .then((res) => {
        let count = Math.floor(res[0].count);
        this.setState({ count: count });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  loadRejectedPayments = () => {
    var test = [];
    Object.keys(this.state.alldata).map((key) => {
      if (this.state.alldata[key].Status === "Rejected") {
        test.push(this.state.alldata[key]);
      }
      this.setState({ newdata: test });
    });
  };
  loadAllPayments = () => {
    var test = [];
    this.setState({ newdata: this.state.alldata });
  };
  loadPendingPayments = () => {
    var test = [];
    Object.keys(this.state.alldata).map((key) => {
      if (this.state.alldata[key].Status === "Pending") {
        test.push(this.state.alldata[key]);
      }
      this.setState({ newdata: test });
    });
  };
  loadApprovedPayments = () => {
    var test = [];
    Object.keys(this.state.alldata).map((key) => {
      if (this.state.alldata[key].Status === "Approved") {
        test.push(this.state.alldata[key]);
      }
      this.setState({ newdata: test });
    });
  };
  handleChange1 = (event) => {
    this.setState({
      webinardetails: {
        ...this.state,
        [event.target.name]: event.target.value,
      },
    });
  };
  openModal = (item) => {
    this.setState({ modalIsOpen: true, currentpayment: item });
  };

  openModal1 = () => {
    this.setState({ modalIsOpen: true });
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  opentable = (key, item) => {
    this.setState({ opentable: true, singledata: key, currentpayment: item });
  };
  next = () => {
    this.setState({ previousbtn: false }, () => {
      if (this.state.singledata === this.state.alldata.length - 1) {
        this.setState({ nextbtn: true });
      } else {
        this.setState({ nextbtn: false }, () => {
          this.setState({ singledata: this.state.singledata + 1 }, () => {});
        });
      }
    });
  };
  previous = () => {
    this.setState({ nextbtn: false }, () => {
      if (this.state.singledata === 0) {
        this.setState({ previousbtn: true });
      } else {
        this.setState({ previousbtn: false }, () => {
          this.setState({ singledata: this.state.singledata - 1 }, () => {});
        });
      }
    });
  };
  closetable = () => {
    this.setState({
      opentable: false,
    });
  };
  approve = () => {
    if (this.state.currentpayment.Status == "Approved") {
      obj.createNotification("warning", "Already Approved");
    } else {
      this.setState(
        {
          loader: true,
        },
        () => {
          this.props
            .approvePayment({
              actionTakenBy: this.state.adminId,
              paymentID: this.state.currentpayment.paymentID,
              courseID: this.state.currentpayment.CourseID,
              userID: this.state.currentpayment.UserID,
            })
            .then((res) => {
              let variable = [...this.state.alldata];
              if (this.state.singledata.length > 0) {
                variable[this.state.singledata]["Status"] = "Approved";
              } else {
                const index = variable.findIndex(
                  (x) =>
                    parseInt(x["Application ID"]) ===
                    this.state.currentpayment.paymentID
                );
                variable[index]["Status"] = "Approved";
              }
              this.setState({ alldata: variable, loader: false });
              this.closeModal();
            });
        }
      );
    }
  };
  loadData = () => {
    this.getCoursePaymentsRest();
    this.getCoursePaymentCountrest();
  };

  setApprovedState = () => {
    this.searchEmailInput.current.value = "";
    this.searchNameInput.current.value = "";
    this.searchPecInput.current.value = "";
    this.searchDisciplineInput.current.value = "";
    this.searchIdInput.current.value = "";

    this.setState({
      page: 0,
      pendingPage: 0,
      approvedPage: 0,
      rejectedPage: 0,
      offset: 0,
      setActiveTab: "Approved",
      allowPagination: true,
      simpleOptionsState: true,
      filterOptionsState: false,
      searchOptionsState: false,
    });
  };

  loadPendingData = () => {
    this.getcoursePaymentCount();
    this.getCoursePayments();
  };

  setPendingState = () => {
    this.searchEmailInput.current.value = "";
    this.searchNameInput.current.value = "";
    this.searchPecInput.current.value = "";
    this.searchDisciplineInput.current.value = "";
    this.searchIdInput.current.value = "";

    this.setState({
      page: 0,
      pendingPage: 0,
      approvedPage: 0,
      rejectedPage: 0,
      offset: 0,
      setActiveTab: "Pending",
      allowPagination: true,
      simpleOptionsState: true,
      filterOptionsState: false,
      searchOptionsState: false,
    });
  };

  loadRejectedData = () => {
    this.getCoursePaymentCountRejected();
    this.getCoursePaymentRejected();
  };

  setRejectedState = () => {
    this.searchEmailInput.current.value = "";
    this.searchNameInput.current.value = "";
    this.searchPecInput.current.value = "";
    this.searchDisciplineInput.current.value = "";
    this.searchIdInput.current.value = "";

    this.setState({
      page: 0,
      pendingPage: 0,
      approvedPage: 0,
      rejectedPage: 0,
      offset: 0,
      setActiveTab: "Rejected",
      allowPagination: true,
      simpleOptionsState: true,
      filterOptionsState: false,
      searchOptionsState: false,
    });
  };

  reject = () => {
    if (this.state.currentpayment.Status == "Rejected") {
      obj.createNotification("warning", "Already Rejected");
    } else {
      this.setState({ loader: true }, () => {
        this.props
          .rejectPayment({
            actionTakenBy: this.state.adminId,
            paymentID: this.state.currentpayment.paymentID,
            courseID: this.state.currentpayment.CourseID,
            userID: this.state.currentpayment.UserID,
          })
          .then((res) => {
            let variable = [...this.state.alldata];
            if (this.state.singledata.length > 0) {
              variable[this.state.singledata]["Status"] = "Rejected";
            } else {
              const index = variable.findIndex(
                (x) =>
                  parseInt(x["Application ID"]) ===
                  this.state.currentpayment.paymentID
              );
              variable[index]["Status"] = "Approved";
            }
            this.setState({ alldata: variable, loader: false });
            this.closeModal();
          });
      });
    }
  };
  changePageSearch = (tableState) => {
    this.setState(
      {
        filterOptionsState:
          tableState.searchText === null ||
          tableState.searchText === "" ||
          !tableState.searchText
            ? false
            : true,
        allowPagination:
          tableState.searchText === null ||
          tableState.searchText === "" ||
          !tableState.searchText
            ? true
            : false,
      },
      () => {}
    );
  };

  searchFilter = (selectedOption) => {
    this.setState({
      selectedType: selectedOption,
      // searchOptionsState: true,
      // filterOptionsState: false,
      // simpleOptionsState: false,
    });
  };

  searchData = () => {
    let searchEmail = this.searchEmailInput?.current?.value;

    let searchName = this.searchNameInput?.current?.value;

    let searchTextValue = this.searchTextInput?.current?.value;

    let searchPecValue = this.searchPecInput?.current?.value;

    let searchDisciplineValue = this.searchDisciplineInput?.current?.value;

    let searchIdValue = this.searchIdInput?.current?.value;

    let activeTab = this.state.setActiveTab;

    if (
      searchEmail ||
      searchName ||
      (searchTextValue && this.state.selectedType !== "") ||
      searchPecValue ||
      searchDisciplineValue ||
      searchIdValue
    ) {
      this.setState(
        {
          loader: true,
          loader1: true,
        },
        () => {
          let data = [];

          let searchTypeValue = this.state.selectedType;

          // Push these values inside data array

          data.push({ type: searchTypeValue });
          data.push({ search: searchTextValue });
          data.push({ email: searchEmail });
          data.push({ name: searchName });
          data.push({ pecNo: searchPecValue });
          data.push({ discipline: searchDisciplineValue });
          data.push({ id: searchIdValue });

          // Create a new array to push these values
          // Eash index should have a "key: value"
          // These values will be used inside query

          let newdata = [];
          let itemType = "";
          data.map((item, index) => {
            itemType = item["type"]?.label;

            if (!(item.length === 0)) {
              // console.log("Item: ", item);
              switch (index) {
                case 0: {
                  if (itemType && itemType === "Email") {
                    newdata.push({ "userinfo.PrimaryEmail": searchTextValue });
                  } else if (itemType && itemType === "Full Name") {
                    newdata.push({ "userinfo.FullName": searchTextValue });
                  }
                  break;
                }
                // case 1: {
                //   if (item['discipline'] !== "") {
                //     newdata.push({ "userinfo.discipline": item['discipline'] });
                //   }
                //   break;
                // }
                case 2: {
                  if (item["email"]) {
                    newdata.push({ "userinfo.PrimaryEmail": item["email"] });
                  }
                  break;
                }
                case 3: {
                  if (item["name"]) {
                    newdata.push({ "userinfo.FullName": item["name"] });
                  }
                  break;
                }
                case 4: {
                  if (item["pecNo"]) {
                    newdata.push({ "userinfo.PEC_REG_NO": item["pecNo"] });
                  }
                  break;
                }
                case 5: {
                  if (item["discipline"]) {
                    newdata.push({ "userinfo.discipline": item["discipline"] });
                  }
                  break;
                }
                case 6: {
                  if (item["id"]) {
                    newdata.push({ "course_payment.paymentID": item["id"] });
                  }
                  break;
                }
              }
            }
          });

          let filterActiveTab = this.state.setActiveTab;

          newdata.push({ "course_payment.Status": filterActiveTab });

          // console.log("NEW DATA: ", newdata);

          this.props
            .getPaymentSearch(newdata)
            .then((res) => {
              console.log("COURSE PAYMENT RES: ", res);
              let searchLength = res.length;
              // console.log("Search Count: ", searchLength);
              let PaymentsLists = this.coursePaymentIterator(res);

              this.setState({
                filterOptionsState: false,
                searchOptionsState: true,
                simpleOptionsState: false,
                allowPagination: true,
                newdata: PaymentsLists,
                alldata: PaymentsLists,
                responsedata: PaymentsLists,
                count: searchLength,
                loader: false,
                loader1: false,
              });
            })
            .catch((err) => {
              this.setState({
                load: false,
              });
              console.log("Search Error: ", err);
            });
        }
      );
    }
  };

  render() {
    // console.log('-----------Re-Render----------');
    const lowercasedFilter = this.state.filter.toLowerCase();
    const filteredData = this.state.newdata.filter((item) => {
      return Object.keys(item).some(
        (key) =>
          typeof item[key] === "string" &&
          item[key].toLowerCase().includes(lowercasedFilter)
      );
    });

    const theme = createMuiTheme({
      overrides: {
        MUIDataTableToolbar: {
          filterPaper: {
            width: "450px !important",
            height: "auto !important",
          },
        },
      },
    });

    const columns1 = [
      {
        name: "Application ID",
        options: {
          filter: false,
        },
      },
      {
        name: "Name of Engineer",
        options: {
          filter: false,
        },
      },
      {
        name: "PEC Number",
        options: {
          filter: true,
        },
      },
      {
        name: "Discipline",
        options: {
          filter: true,
        },
      },
      {
        name: "Mobile Number",
        options: {
          filter: false,
        },
      },
      {
        name: "Date of Challan Submission",
        options: {
          filter: false,
        },
      },
      {
        name: "Challan Number",
        options: {
          filter: false,
          customBodyRender: (value) =>
            value === null || value === "null" || !value ? "Not Found" : value,
        },
      },
      {
        name: "CPD Course Name",
        options: {
          filter: true,
        },
      },
      {
        name: "Amount submitted",
        options: {
          filter: false,
        },
      },
      {
        name: "Status",
        options: {
          filter: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <QualityCell
                value={value}
                index={tableMeta.columnIndex}
                change={(event) => updateValue(event)}
              />
            );
          },
        },
      },
      {
        name: "View Challan Tab",
        options: {
          filter: false,
        },
      },
    ];
    const columns = [
      {
        name: "Application ID",
        options: {
          filter: false,
        },
      },
      {
        name: "Name of Engineer",
        options: {
          filter: false,
        },
      },
      {
        name: "PEC Number",
        options: {
          filter: true,
        },
      },
      {
        name: "Discipline",
        options: {
          filter: false,
        },
      },
      {
        name: "Mobile Number",
        options: {
          filter: false,
        },
      },
      {
        name: "Date of Challan Submission",
        options: {
          filter: false,
        },
      },
      {
        name: "Challan Number",
        options: {
          filter: false,
          customBodyRender: (value) =>
            value === null || value === "null" || !value ? "Not Found" : value,
        },
      },
      {
        name: "CPD Course Name",
        options: {
          filter: true,
        },
      },
      {
        name: "Amount submitted",
        options: {
          filter: false,
        },
      },
      {
        name: "Status",
        options: {
          filter: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <QualityCell
                value={value}
                index={tableMeta.columnIndex}
                change={(event) => updateValue(event)}
              />
            );
          },
        },
      },
      {
        name: "View Challan Tab",
        options: {
          filter: false,
        },
      },
    ];
    const pendingOptions = {
      filter: true,
      responsive: "vertical",
      tableBodyHeight: "600px",
      tableBodyMaxHeight: "",
      pagination: this.state.allowPagination,
      rowsPerPage: this.state.perPage,
      rowsPerPageOptions: [25, 50, 75, 100],
      serverSide:
        this.state.filterOptionsState || this.state.searchOptionsState
          ? false
          : true,
      count: this.state.count,
      page: this.state.pendingPage,
      jumpToPage: true,
      filterType: "dropdown",
      customToolbarSelect: () => {},
      onChangeRowsPerPage: (num) => {
        if (
          this.state.filterOptionsState === true ||
          this.state.searchOptionsState === true
        ) {
        } else {
          this.setState({ perPage: num, pendingPage: 0 });
          this.changeRowsPending(num);
        }
      },
      onTableChange: (action, tableState) => {
        this.state.filterOptionsState || this.state.searchOptionsState
          ? ""
          : action === "changePage"
          ? this.changePagePending(tableState.page, tableState.sortOrder)
          : "";

        if (action === "filterChange") {
          this.changeFilter(tableState.filterList);
        }
        if (action === "search") {
          this.changePageSearch(tableState);
        }
      },
      onFilterChange: (changedColumn, filterList, type) => {
        if (type === "reset") {
          this.resetFiltersPending();
        }
      },
      onFilterChipClose: (index, removedFilter, filterList) => {
        let temp = [];
        filterList.map((value, index) => {
          for (let i = 0; i <= value.length; i++) {
            temp.push(i);
          }
        });

        if (temp.length === 11) {
          this.resetFiltersPending();
        }
      },
    };
    const approvedOptions = {
      filter: true,
      responsive: "vertical",
      tableBodyHeight: "600px",
      tableBodyMaxHeight: "",
      pagination: this.state.allowPagination,
      rowsPerPage: this.state.perPage,
      rowsPerPageOptions: [25, 50, 75, 100],
      serverSide:
        this.state.filterOptionsState || this.state.searchOptionsState
          ? false
          : true,
      count: this.state.count,
      page: this.state.approvedPage,
      jumpToPage: true,
      filterType: "dropdown",
      customToolbarSelect: () => {},
      onChangeRowsPerPage: (num) => {
        if (
          this.state.filterOptionsState === true ||
          this.state.searchOptionsState === true
        ) {
        } else {
          this.setState({ perPage: num, approvedPage: 0 });
          this.changeRowsApproved(num);
        }
      },
      onTableChange: (action, tableState) => {
        this.state.filterOptionsState || this.state.searchOptionsState
          ? ""
          : action === "changePage"
          ? this.changePageApproved(tableState.page, tableState.sortOrder)
          : "";

        if (action === "filterChange") {
          this.changeFilter(tableState.filterList);
        }
        if (action === "search") {
          this.changePageSearch(tableState);
        }
      },
      onFilterChange: (changedColumn, filterList, type) => {
        if (type === "reset") {
          this.resetFiltersApproved();
        }
      },
      onFilterChipClose: (index, removedFilter, filterList) => {
        let temp = [];
        filterList.map((value, index) => {
          for (let i = 0; i <= value.length; i++) {
            temp.push(i);
          }
        });

        if (temp.length === 11) {
          this.resetFiltersApproved();
        }
      },
    };
    const rejectedOptions = {
      filter: true,
      responsive: "vertical",
      tableBodyHeight: "600px",
      tableBodyMaxHeight: "",
      pagination: this.state.allowPagination,
      rowsPerPage: this.state.perPage,
      rowsPerPageOptions: [25, 50, 75, 100],
      serverSide:
        this.state.filterOptionsState || this.state.searchOptionsState
          ? false
          : true,
      count: this.state.count,
      page: this.state.rejectedPage,
      jumpToPage: true,
      filterType: "dropdown",
      customToolbarSelect: () => {},
      onChangeRowsPerPage: (num) => {
        if (
          this.state.filterOptionsState === true ||
          this.state.searchOptionsState === true
        ) {
        } else {
          this.setState({ perPage: num, rejectedPage: 0 });
          this.changeRowsRejected(num);
        }
      },
      onTableChange: (action, tableState) => {
        this.state.filterOptionsState || this.state.searchOptionsState
          ? ""
          : action === "changePage"
          ? this.changePageRejected(tableState.page, tableState.sortOrder)
          : "";

        if (action === "filterChange") {
          this.changeFilter(tableState.filterList);
        }
        if (action === "search") {
          this.changePageSearch(tableState);
        }
      },
      onFilterChange: (changedColumn, filterList, type) => {
        if (type === "reset") {
          this.resetFiltersRejected();
        }
      },
      onFilterChipClose: (index, removedFilter, filterList) => {
        let temp = [];
        filterList.map((value, index) => {
          for (let i = 0; i <= value.length; i++) {
            temp.push(i);
          }
        });

        if (temp.length === 11) {
          this.resetFiltersRejected();
        }
      },
    };

    return (
      <React.Fragment>
        <section className="mtb-20">
          <Modal
            isOpen={this.state.modalIsOpen}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={this.closeModal}
            style={customStyles}
            contentLabel="Chat Modal"
          >
            <a className="modalbtn float-right" onClick={this.closeModal}>
              X
            </a>
            <div className="container">
              <h6>Accept or Reject payment.</h6>
              <textarea
                required
                className="remarks_textarea"
                rows="6"
                value={this.state.remarks}
                name="description"
                placeholder={`Reasons for the rejection of the payment`}
                onChange={this.handleChange}
              />
              <div className="modal_row">
                <div className="imageitem text-right">
                  {this.props.permissions.Delete ? (
                    <a
                      onClick={this.reject}
                      className="modalbutton co_crimson "
                    >
                      Reject
                    </a>
                  ) : (
                    ""
                  )}
                </div>
                <div className="imageitem text-left">
                  {this.props.permissions.Approve ? (
                    <a onClick={this.approve} className="modalbutton ">
                      Accept
                    </a>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </Modal>
          <Modal
            isOpen={this.state.opentable}
            onRequestClose={this.closetable}
            style={customStyles1}
            contentLabel="Payment Modal"
          >
            {/* {console.log('Single Data: ', this.state.singledata)}
            {console.log('Pending Page: ', this.state.pendingPage)} */}
            <a className="modalbtn float-right" onClick={this.closetable}>
              X
            </a>
            <div className="container">
              <div
                className="row justify-content-center"
                style={{ paddingTop: "10px" }}
              >
                <h4>Challan Details</h4>
              </div>
              <div
                className="row justify-content-start"
                style={{ paddingTop: "10px" }}
              >
                <div className="col" style={{ paddingTop: "10px" }}>
                  <label>Applicant Name</label>
                </div>
                <div className="col" style={{ paddingTop: "10px" }}>
                  {this.state.alldata[this.state.singledata] &&
                    this.state.alldata[this.state.singledata][
                      "Name of Engineer"
                    ]}
                </div>
                <div className="col" style={{ paddingTop: "10px" }}>
                  <label>Bank</label>
                </div>
                <div className="col" style={{ paddingTop: "10px" }}>
                  {(this.state.responsedata[this.state.singledata] &&
                    this.state.responsedata[this.state.singledata][
                      "bankName"
                    ]) === "undefined"
                    ? "-"
                    : this.state.responsedata[this.state.singledata] &&
                      this.state.responsedata[this.state.singledata][
                        "bankName"
                      ]}
                </div>
              </div>
              <div
                className="row justify-content-start"
                style={{ paddingTop: "10px" }}
              >
                <div className="col">
                  <label>Challan Date</label>
                </div>
                <div className="col">
                  {this.state.alldata[this.state.singledata] &&
                    this.state.alldata[this.state.singledata][
                      "Date of Challan Submission"
                    ]}
                </div>
                <div className="col">
                  <label>Doc No</label>&nbsp; &nbsp; &nbsp;<span></span>
                </div>
                <div className="col">
                  {(this.state.alldata[this.state.singledata] &&
                    this.state.alldata[this.state.singledata][
                      "Challan Number"
                    ]) === "undefined"
                    ? "-"
                    : this.state.alldata[this.state.singledata] &&
                      this.state.alldata[this.state.singledata][
                        "Challan Number"
                      ]}
                </div>
              </div>
              <div
                className="row justify-content-start"
                style={{ paddingTop: "10px" }}
              >
                <div className="col">
                  <label>PEC Number</label>
                </div>
                <div className="col">
                  {this.state.alldata[this.state.singledata] &&
                    this.state.alldata[this.state.singledata]["PEC Number"]}
                </div>
                <div className="col">
                  <label>Challan Amount</label>
                </div>
                <div className="col">
                  {this.state.alldata[this.state.singledata] &&
                    this.state.alldata[this.state.singledata][
                      "Amount submitted"
                    ]}
                </div>
              </div>
              <div
                className="row justify-content-start"
                style={{ paddingTop: "10px" }}
              >
                <div className="col">
                  <label>Verification Status</label>
                </div>
                <div className="col">
                  {this.props.permissions.Edit ? (
                    <span onClick={this.openModal1}>
                      {this.state.alldata[this.state.singledata] &&
                        this.state.alldata[this.state.singledata][
                          "Status"
                        ]}{" "}
                      <i className="fas fa-edit co_admin"></i>
                    </span>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col"></div>
                <div className="col"></div>
              </div>
            </div>
            <div className="container" style={{ "margin-top": "50px" }}>
              <table>
                <tr style={{ borderBottom: "1px solid black" }}>
                  <td className="coursechallan">Challan Number</td>
                  <td className="courseimage">Bank Name</td>
                  <td className="coursefee">Total Fee</td>
                  <td className="coursedate">Date</td>
                  <td className="coursebank">Image</td>
                </tr>
                <tr>
                  <td className="coursechallan">
                    {(this.state.alldata[this.state.singledata] &&
                      this.state.alldata[this.state.singledata][
                        "Challan Number"
                      ]) === "undefined"
                      ? "-"
                      : this.state.alldata[this.state.singledata] &&
                        this.state.alldata[this.state.singledata][
                          "Challan Number"
                        ]}
                  </td>
                  <td className="coursebank">
                    {(this.state.responsedata[this.state.singledata] &&
                      this.state.responsedata[this.state.singledata][
                        "bankName"
                      ]) === "undefined"
                      ? "-"
                      : this.state.responsedata[this.state.singledata] &&
                        this.state.responsedata[this.state.singledata][
                          "bankName"
                        ]}
                  </td>
                  <td className="coursefee">
                    {this.state.alldata[this.state.singledata] &&
                      this.state.alldata[this.state.singledata][
                        "Amount submitted"
                      ]}
                  </td>
                  <td className="coursedate">
                    {this.state.alldata[this.state.singledata] &&
                      this.state.alldata[this.state.singledata][
                        "Date of Challan Submission"
                      ]}
                  </td>
                  <td className="courseimage">
                    {this.state.alldata[this.state.singledata] &&
                      this.state.alldata[this.state.singledata][
                        "View Challan Tab"
                      ]}
                  </td>
                </tr>
              </table>
              <div
                className="row justify-content-center"
                style={{ marginTop: "20px" }}
              >
                <button
                  className="btn admin_btn_default"
                  onClick={this.previous}
                  {...(this.state.previousbtn
                    ? { disabled: true }
                    : { disabled: false })}
                >
                  <i class="fas fa-arrow-left"></i>
                </button>
                <button
                  className="btn btn-save   black bold "
                  onClick={this.next}
                  {...(this.state.nextbtn
                    ? { disabled: true }
                    : { disabled: false })}
                >
                  {" "}
                  <i class="fas fa-arrow-right"></i>
                </button>
              </div>
            </div>
          </Modal>
          <div className="col-md-12 mtb-25">
            <div className="row lrm-0">
              <div className="col-md-9 lrp-0">
                <h4 className="regular_font fs-26 bm-0">Course Details</h4>
              </div>
            </div>
          </div>
          <ul
            className="nav nav-tabs adm_desc_nav_tabs"
            id="myTab"
            role="tablist"
          >
            <li className="nav-item pill">
              <a
                className={
                  "nav-link  admin_desc_navlink " + this.state.topNavPending
                }
                id="profile-tab"
                data-toggle="tab"
                href="#pendingPayments"
                role="tab"
                aria-controls="pendingPayments"
                aria-selected="false"
                onClick={() => {
                  this.setPendingState();
                  this.loadPendingData();
                }}
              >
                Pending Payments
              </a>
            </li>
            <li className="nav-item pill">
              <a
                className={
                  "nav-link  admin_desc_navlink " + this.state.topNavApproved
                }
                id="home-tab"
                data-toggle="tab"
                href="#approverejPayments"
                role="tab"
                aria-controls="approverejPayments"
                aria-selected="true"
                onClick={() => {
                  this.setApprovedState();
                  this.loadData();
                }}
              >
                Approved Payments
              </a>
            </li>
            <li className="nav-item pill">
              <a
                className={
                  "nav-link  admin_desc_navlink " + this.state.topNavRejected
                }
                id="home-tab"
                data-toggle="tab"
                href="#rejectedPayments"
                role="tab"
                aria-controls="rejectedPayments"
                aria-selected="true"
                onClick={() => {
                  this.setRejectedState();
                  this.loadRejectedData();
                }}
              >
                Rejected Payments
              </a>
            </li>
          </ul>
          <div className="mtb-25">
            <div className="col-md-12" style={{ zIndex: 999 }}>
              <div className="row">
                {/* <div className="col-md-3">
                  <label className="bm-0">Status</label>
                  <Select
                    className="mtb-10"
                    options={this.state.statusSet}
                    value={this.state.SelectedstatusSet}
                    onChange={this.handleStatus}
                    placeholder="Status"
                  />
                </div> */}
                <div className="col-md-2">
                  <label className="bm-0">Courses</label>
                  <Select
                    className="mtb-10"
                    options={this.state.courseSet}
                    value={this.state.SelectedCourseSet}
                    onChange={this.handleCourses}
                    placeholder="Courses"
                  />
                </div>
                <div className="col-md-2">
                  <label className="bm-0">Application ID</label>
                  <div className="input-group md-form form-sm form-2 mtb-10">
                    <input
                      type="text"
                      className="form-control"
                      name="search"
                      // value={this.state.search}
                      // onChange={this.handleTextInput}
                      ref={this.searchIdInput}
                      placeholder={"Search By ID"}
                      aria-label="Text input with dropdown button"
                    />
                  </div>
                </div>

                <div className="col-md-2">
                  <label className="bm-0">Email</label>
                  <div className="input-group md-form form-sm form-2 mtb-10">
                    <input
                      type="text"
                      className="form-control"
                      name="search"
                      // value={this.state.search}
                      // onChange={this.handleTextInput}
                      ref={this.searchEmailInput}
                      placeholder={"Search By Email"}
                      aria-label="Text input with dropdown button"
                    />
                  </div>
                </div>

                <div className="col-md-2">
                  <label className="bm-0">Full Name</label>
                  <div className="input-group md-form form-sm form-2 mtb-10">
                    <input
                      type="text"
                      className="form-control"
                      name="search"
                      // value={this.state.search}
                      // onChange={this.handleTextInput}
                      ref={this.searchNameInput}
                      placeholder={"Search By Name"}
                      aria-label="Text input with dropdown button"
                    />
                  </div>
                </div>

                <div className="col-md-2 ">
                  <label className="bm-0">PEC No</label>
                  <div className="input-group md-form form-sm form-2 mtb-10">
                    <input
                      type="text"
                      className="form-control"
                      name="search"
                      // value={this.state.search}
                      // onChange={this.handleTextInput}
                      ref={this.searchPecInput}
                      placeholder={"Search By PEC No"}
                      aria-label="Text input with dropdown button"
                    />
                  </div>
                </div>

                <div className="col-md-2 ">
                  <label className="bm-0">Discipline</label>
                  <div className="input-group md-form form-sm form-2 mtb-10">
                    <input
                      type="text"
                      className="form-control"
                      name="search"
                      // value={this.state.search}
                      // onChange={this.handleTextInput}
                      ref={this.searchDisciplineInput}
                      placeholder={"Search By Discipline"}
                      aria-label="Text input with dropdown button"
                    />
                  </div>
                </div>

                {/* <div className="col-md-3 ">
                  <label className="bm-0">Search Application ID</label>
                  <div className="input-group md-form form-sm form-2 mtb-10">
                    <input
                      type="text"
                      className="form-control"
                      name="applicationSearch"
                      value={this.state.applicationSearch}
                      placeholder="Search By Application ID"
                      aria-label="Text input with dropdown button"
                      onChange={this.handleApplication}
                    />
                  </div>
                </div> */}
                {/* <div className="col-md-3 ">
                  <label className="bm-0">Search By Name</label>
                  <div className="input-group md-form form-sm form-2 mtb-10">
                    <input
                      type="text"
                      className="form-control"
                      name="search"
                      value={this.state.search}
                      placeholder="Search By Name"
                      aria-label="Text input with dropdown button"
                      onChange={this.handleNameChange}
                    />
                  </div>
                </div> */}
              </div>
            </div>

            <hr></hr>

            <div className="col-md-12">
              <div className="row">
                {/* <div className='col-md-2 '>
                  <label className='bm-0'>Type</label>
                  <Select
                    className=' mtb-10'
                    options={this.state.searchType}
                    value={this.state.selectedType}
                    onChange={this.searchFilter}
                    ref={this.searchTypeInput}
                  />
                </div> */}

                {/* <div className='col-md-2 '>
                  <label className='bm-0'>Email Or Name</label>
                  <div className='input-group md-form form-sm form-2 mtb-10'>
                    <input
                      type='text'
                      className='form-control'
                      name='search'
                      // value={this.state.search}
                      // onChange={this.handleTextInput}
                      ref={this.searchTextInput}
                      placeholder={
                        "Search By Value"
                      }
                      aria-label='Text input with dropdown button'
                    />
                  </div>
                </div> */}

                <div className="col-md-2">
                  <label className="bm-0"></label>
                  <button
                    className="btn admin_btn white w-150p float-right bold mtb-10"
                    onClick={() => {
                      this.searchData();
                    }}
                  >
                    Search
                  </button>
                </div>
                <div className="col-md-2">
                  <label className="bm-0"></label>
                  {/* {console.log("ACTIVE TAB RESET: ", this.state.setActiveTab)} */}
                  <button
                    className="btn admin_btn_new text-center white w-150p float-right bold mtb-10"
                    onClick={() => {
                      this.state.setActiveTab === "Pending"
                        ? this.resetFiltersPending()
                        : this.state.setActiveTab === "Approved"
                        ? this.resetFiltersApproved()
                        : this.resetFiltersRejected();
                    }}
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>

            <hr></hr>
            <div className="tab-content" id="myTabContent">
              <div
                className={
                  "tab-pane fs-14 fade " + this.state.showActiveApproved
                }
                id="approverejPayments"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                <div className="mtb-25">
                  {this.state.loader1 ? (
                    <div className="loaderDiv text-center">
                      <Loader
                        type="Oval"
                        color="#06580e"
                        height={80}
                        width={80}
                      />
                    </div>
                  ) : (
                    <MuiThemeProvider theme={theme}>
                      <MUIDataTable
                        title={"Course Payment Approved"}
                        data={this.state.newdata}
                        columns={columns1}
                        options={approvedOptions}
                      />
                    </MuiThemeProvider>
                  )}
                </div>
              </div>
              <div
                className={
                  "tab-pane fs-14 fade " + this.state.showActiveRejected
                }
                id="rejectedPayments"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                <div className="mtb-25">
                  {this.state.loader1 ? (
                    <div className="loaderDiv text-center">
                      <Loader
                        type="Oval"
                        color="#06580e"
                        height={80}
                        width={80}
                      />
                    </div>
                  ) : (
                    <MuiThemeProvider theme={theme}>
                      <MUIDataTable
                        title={"Course Payment Rejected"}
                        data={this.state.newdata}
                        columns={columns1}
                        options={rejectedOptions}
                      />
                    </MuiThemeProvider>
                  )}
                </div>
              </div>

              <div
                className={
                  "tab-pane fs-14 fade  " + this.state.showActivePending
                }
                id="pendingPayments"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                <div className="mtb-25">
                  {this.state.loader ? (
                    <div className="loaderDiv text-center">
                      <Loader
                        type="Oval"
                        color="#06580e"
                        height={80}
                        width={80}
                      />
                    </div>
                  ) : (
                    <MuiThemeProvider theme={theme}>
                      <MUIDataTable
                        title={"Pending Course Payment"}
                        data={this.state.newdata}
                        columns={columns}
                        options={pendingOptions}
                      />
                    </MuiThemeProvider>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    payments: state.financialData.coursepayments,
    registrations: state.financialData.registrations,
  };
}
export default connect(mapStateToProps, {
  getcoursepayments,
  getcoursepaymentsrest,
  getCoursePaymentCount,
  getFilterPaymentsData,
  getPaymentSearch,
  getCoursePaymentCountRest,
  getcoursepaymentsRejected,
  getCoursePaymentCountRejected,
  approvePayment,
  rejectPayment,
})(CoursePayments);
