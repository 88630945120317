import React from 'react';
import {connect} from 'react-redux';
import {getAttendence,approveAttendence,rejectAttendence} from '../../redux/admin/cpdActions/cpdactions';
import MUIDataTable from "mui-datatables"; 
import moment from 'moment';
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

import WebNotif from "../WebNotif";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
var obj = new WebNotif();


const columns = [ "id",
                  "Full Name",
                  "Course Name", 
                  "Attendence",
                  "Session Name",
                  "Session StartDate",
                  "Session EndDate",
                  "Status",
                  "Approve",
                  "Reject"
                  
                 
                ];

class AttendenceCPD extends React.Component {
    state={
        data:[]
    }
    awardCPD=(item)=>{
        if(item.Status=='Approved'){
            obj.createNotification('warning',"Already Approved")
        }else{
            confirmAlert({
                title: 'Confirm to submit',
                message: 'Are you sure to approve this attendence?',
                buttons: [
                  {
                    label: 'Yes',
                    onClick: () => {
                            this.props.approveAttendence({id:item.attendence_id}).then(
                                res=>{
                                    window.location.reload()
                                }
                            ).catch(
                                err=>{
                                    obj.createNotification('warning',err.response.message) 
                                }
                            )
                       
                     
                   }
                  },
                  {
                    label: 'No',
                    onClick: () => 
                    {}
                  }
                 
                ]
              });
            
    
        }
      
    }
    reject=(item)=>{
        if(item.Status=='Rejected'){
            obj.createNotification('warning',"Already Rejected")


        }else{

            confirmAlert({
                title: 'Confirm to submit',
                message: 'Are you sure to reject this attendence?',
                buttons: [
                  {
                    label: 'Yes',
                    onClick: () => {
                        this.props.rejectAttendence({id:item.attendence_id}).then(
                            res=>{
                                window.location.reload()
                            }
                        ).catch(
                            err=>
                            obj.createNotification('warning',err.response.message) 
                        )
                     
                   }
                  },
                  {
                    label: 'No',
                    onClick: () => 
                    {}
                  }
                 
                ]
              });

        }
       
        

    }
    componentDidMount(){
        this.props.getAttendence().then(res=>{
            {
              console.log(res);
                let temp=[]
                for(let i =0; i< res.data.length;i++){
    
                   temp.push( {
                        'id': res.data[i].attendence_id,
                        "Full Name":  res.data[i].FullName,
                        "Course Name": res.data[i].Course_Name,
                        "Attendence": res.data[i].attendence+"%",
                        "Session Name" : res.data[i].Session_Name,
                        "Session StartDate":moment(res.data[i].Session_StartDate).format('hh:mm a - DD/MM/YYYY '),
                        "Session EndDate":moment(res.data[i].Session_EndDate).format('hh:mm a - DD/MM/YYYY '),
                        "Status":res.data[i].Status,
                        "Approve": <a onClick={()=>{this.awardCPD(res.data[i])}}> <i className="icon fa fa-check fa-2x co_g20A84C "></i></a>,
                        "Reject": <a onClick={()=>{this.reject(res.data[i])}}> <i className="icon fa fa-times fa-2x co_g20A84C "></i></a>

                   })
    
                }
                this.setState({
                  data:temp
                })
          }
        })
    }
    render(){
        const theme = createMuiTheme({
            overrides: {
              MUIDataTableSelectCell: {
                expandDisabled: {
                  visibility: "hidden",
                },
              },
            },
          });
        const options= {
            responsive:"vertical",
            tableBodyHeight:"600px",
            tableBodyMaxHeight:"",
            filter: true,
            filterType: "dropdown"
        }
        return(
            <React.Fragment>
                <h4 className="regular_font fs-26 mtb-35">Attendence</h4>
                <MuiThemeProvider theme={theme}>
                <MUIDataTable
                   data={this.state.data}
                   columns={columns}
                   options={options}
               /> 
                 </MuiThemeProvider>
            </React.Fragment>
        )
    }

}
function mapStateToProps (state){
    return{

    }
}
export default connect (mapStateToProps,{getAttendence,approveAttendence,rejectAttendence}) (AttendenceCPD);