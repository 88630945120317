import React, { Component } from "react";
import { connect } from "react-redux";
import MUIDataTable from "mui-datatables";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Loader from "react-loader-spinner";
import moment from "moment";
import {
  getFreeWebinarsRegistrations,
  getFilterPaymentsData,
  getSearchPaymentData,
  get_webinar_registration_count,
} from "../../redux/admin/financial/financialactions";
import Select from "react-select";
const columns = [
  {
    name: "ID",
    label: "ID",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "PEC No",
    label: "PEC No",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "Discipline",
    label: "Discipline",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "Full Name",
    label: "Full Name",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "Phone Number",
    label: "Phone Number",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "Email",
    label: "Email",
    options: {
      filter: false,
      sort: true,
    },
  },

  {
    name: "Course Name",
    label: "Course Name",
    options: {
      filter: true,
    },
  },
  {
    name: "Fee",
    label: "Fee",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "Date Created",
    label: "Date Created",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "Session Name",
    label: "Session Name",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "Session StartDate",
    label: "Session StartDate",
    options: {
      filter: false,
      sort: true,
    },
  },
];

class WebinarRegistrations extends Component {
  state = {
    allowPagination: true,
    filterOptionsState: false,
    simpleOptionsState: true,
    searchOptionsState: false,
    load: false,
    freeWebinars: [],
    realData: [],
    offset: 0,
    perPage: 25,
    limit: 25,
    count: 1,
    page: 0,
    searchType: [
      { label: "Email", value: "userinfo.PrimaryEmail" },
      { label: "Full Name", value: "userinfo.FullName" },
    ],
    selectedType: "",
    search: "",
  };

  constructor(props) {
    super(props);

    this.searchEmailInput = React.createRef();
    this.searchNameInput = React.createRef();
    this.searchTypeInput = React.createRef();
    this.searchTextInput = React.createRef();
    this.searchPecInput = React.createRef();
    this.searchDisciplineInput = React.createRef();
  }

  changePage = (page, sortOrder) => {
    this.setState(
      {
        offset: page * this.state.perPage,
        page: page,
      },
      () => {
        this.getRegistrations();
      }
    );
  };
  changeRowsWebinars = (num) => {
    this.setState(
      {
        limit: num,
      },
      () => {
        this.getRegistrations();
      }
    );
  };

  changeFilter = (data) => {
    console.log("INSIDE changeFilter: ", data);

    let newdata = [];
    data.map((item, index) => {
      if (!(item.length === 0)) {
        switch (index) {
          case 1: {
            newdata.push({ "userinfo.PEC_REG_NO": item[0] });
            break;
          }
          case 2: {
            newdata.push({ "userinfo.discipline": item[0] });
            break;
          }
          case 6: {
            newdata.push({ "courses.Course_Name": item[0] });
            break;
          }
          case 9: {
            newdata.push({ "course_session.Session_Name": item[0] });
            break;
          }
        }
      }
    });
    if (newdata.length === 0) {
      console.log("INSIDE IF: ", newdata);
      this.setState({
        freeWebinars: this.state.realData,
        allowPagination: true,
        load: false,
      });
    } else {
      console.log("ELSE IF: ", newdata);

      this.props
        .getFilterPaymentsData(newdata)
        .then((res) => {
          // console.log('Get filter admins: ', res);

          let filterCount = res.length;
          newdata.push({ limit: this.state.limit, offset: this.state.offset });

          let temp = [];
          for (let i = 0; i < res.length; i++) {
            temp.push({
              ID: res[i].paymentID,
              "Full Name": res[i].FullName,
              "Phone Number": res[i].Primary_Contact_No,
              Email: res[i].PrimaryEmail,
              "PEC No": res[i].PEC_REG_NO,
              Discipline: res[i].discipline,
              "Course Name": res[i].Course_Name,
              Fee: res[i].price === "" ? "---" : res[i].price,
              "Date Created": moment(res[i].DateCreated).format(
                "hh:mm a - DD/MM/YYYY "
              ),
              "Session Name": res[i].Session_Name,
              "Session StartDate": moment(res[i].Session_StartDate).format(
                "hh:mm a - DD/MM/YYYY "
              ),
            });
          }
          this.setState({
            filterOptionsState: true,
            simpleOptionsState: false,
            searchOptionsState: false,
            freeWebinars: temp,
            count: filterCount,
            perPage: 25,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  getRegistrations = () => {
    this.setState({
      load: true,
    });
    let temp = [];
    this.props
      .getFreeWebinarsRegistrations({
        limit: this.state.limit,
        offset: this.state.offset,
      })
      .then((res) => {
        console.log("FREE WEBINARS RESPONSE: ", res);
        for (let i = 0; i < res.data.length; i++) {
          temp.push({
            ID: res.data[i].paymentID,
            "Full Name": res.data[i].FullName,
            "Phone Number": res.data[i].Primary_Contact_No,
            Email: res.data[i].PrimaryEmail,
            "PEC No": res.data[i].PEC_REG_NO,
            Discipline: res.data[i].discipline,
            "Course Name": res.data[i].Course_Name,
            Fee: res.data[i].price === "" ? "---" : res.data[i].price,
            "Date Created": moment(res.data[i].DateCreated).format(
              "hh:mm a - DD/MM/YYYY "
            ),
            "Session Name": res.data[i].Session_Name,
            "Session StartDate": moment(res.data[i].Session_StartDate).format(
              "hh:mm a - DD/MM/YYYY "
            ),
          });
        }
        this.setState({
          freeWebinars: temp,
          realData: temp,
          load: false,
        });
      });

    // this.searchTextInput.current.value = "";
  };
  getRegistrationCount = () => {
    this.props
      .get_webinar_registration_count()
      .then((res) => {
        let count = Math.floor(res.data[0].count);
        this.setState({ count: count });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  resetWebinarFilters = () => {
    this.searchEmailInput.current.value = "";
    this.searchNameInput.current.value = "";
    this.searchPecInput.current.value = "";
    this.searchDisciplineInput.current.value = "";

    this.setState(
      {
        allowPagination: true,
        filterOptionsState: false,
        searchOptionsState: false,
        simpleOptionsState: true,
      },
      () => {
        this.getRegistrationCount();
        this.getRegistrations();
      }
    );
  };
  componentDidMount() {
    this.getRegistrationCount();
    this.getRegistrations();
  }

  searchData = () => {
    let searchEmail = this.searchEmailInput?.current?.value;

    let searchName = this.searchNameInput?.current?.value;

    let searchTextValue = this.searchTextInput?.current?.value;

    let searchPecValue = this.searchPecInput?.current?.value;

    let searchDisciplineValue = this.searchDisciplineInput?.current?.value;

    if (
      searchEmail ||
      searchName ||
      (searchTextValue && this.state.selectedType !== "") ||
      searchPecValue ||
      searchDisciplineValue
    ) {
      this.setState(
        {
          load: true,
        },
        () => {
          let data = [];

          let searchTypeValue = this.state.selectedType;

          data.push({ type: searchTypeValue });
          data.push({ search: searchTextValue });
          data.push({ email: searchEmail });
          data.push({ name: searchName });
          data.push({ pecNo: searchPecValue });
          data.push({ discipline: searchDisciplineValue });

          let newdata = [];
          let itemType = "";
          data.map((item, index) => {
            itemType = item["type"]?.label;

            if (!(item.length === 0)) {
              console.log("Item: ", item);
              switch (index) {
                case 0: {
                  if (itemType && itemType === "Email") {
                    newdata.push({ "userinfo.PrimaryEmail": searchTextValue });
                  } else if (itemType && itemType === "Full Name") {
                    newdata.push({ "userinfo.FullName": searchTextValue });
                  }
                  break;
                }
                // case 1: {
                //   if (item['discipline'] !== "") {
                //     newdata.push({ "userinfo.discipline": item['discipline'] });
                //   }
                //   break;
                // }
                case 2: {
                  if (item["email"]) {
                    newdata.push({ "userinfo.PrimaryEmail": item["email"] });
                  }
                  break;
                }
                case 3: {
                  if (item["name"]) {
                    newdata.push({ "userinfo.FullName": item["name"] });
                  }
                  break;
                }
                case 4: {
                  if (item["pecNo"]) {
                    newdata.push({ "userinfo.PEC_REG_NO": item["pecNo"] });
                  }
                  break;
                }
                case 5: {
                  if (item["discipline"]) {
                    newdata.push({ "userinfo.discipline": item["discipline"] });
                  }
                  break;
                }
              }
            }
          });

          console.log("NEW DATA: ", newdata);

          this.props
            .getSearchPaymentData(newdata)
            .then((res) => {
              console.log("Search res: ", res);

              let temp = [];

              let searchLength = res.length;

              for (let i = 0; i < res.length; i++) {
                temp.push({
                  ID: res[i].paymentID,
                  "Full Name": res[i].FullName,
                  "Phone Number": res[i].Primary_Contact_No,
                  Email: res[i].PrimaryEmail,
                  "PEC No": res[i].PEC_REG_NO,
                  Discipline: res[i].discipline,
                  "Course Name": res[i].Course_Name,
                  Fee: res[i].price === "" ? "---" : res[i].price,
                  "Date Created": moment(res[i].DateCreated).format(
                    "hh:mm a - DD/MM/YYYY "
                  ),
                  "Session Name": res[i].Session_Name,
                  "Session StartDate": moment(res[i].Session_StartDate).format(
                    "hh:mm a - DD/MM/YYYY "
                  ),
                });
              }
              this.setState({
                load: false,
                freeWebinars: temp,
                count: searchLength,
                allowPagination: true,
                searchOptionsState: true,
                filterOptionsState: false,
                simpleOptionsState: false,
              });
            })
            .catch((err) => {
              this.setState({
                load: false,
              });
              console.log("Search Error: ", err);
            });
        }
      );
    }
  };

  searchFilter = (selectedOption) => {
    this.setState({
      selectedType: selectedOption,
      // searchOptionsState: true,
      // filterOptionsState: false,
      // simpleOptionsState: false,
    });
  };

  handleTextInput = (event) => {
    this.setState({ ...this.state, search: event.target.value });
  };

  changePageSearch = (tableState) => {
    this.setState(
      {
        filterOptionsState:
          tableState.searchText === null ||
          tableState.searchText === "" ||
          !tableState.searchText
            ? false
            : true,
      },
      () => {
        // console.log('Server Side State: ', this.state.renderServerSide);
        // console.log('Search Text: ', tableState.searchText);
      }
    );
  };
  render() {
    const theme = createMuiTheme({
      overrides: {
        MUIDataTableToolbar: {
          filterPaper: {
            width: "450px !important",
            height: "auto !important",
          },
        },
      },
    });

    const options = {
      search: true,
      responsive: "vertical",
      tableBodyHeight: "600px",
      tableBodyMaxHeight: "",
      filter: true,
      pagination: this.state.allowPagination,
      rowsPerPage: this.state.perPage,
      serverSide:
        this.state.filterOptionsState || this.state.searchOptionsState
          ? false
          : true,
      rowsPerPageOptions: [25, 50, 75, 100],
      count: this.state.count,
      page: this.state.page,
      filterType: "dropdown",
      customToolbarSelect: () => {},
      onTableChange: (action, tableState) => {
        this.state.filterOptionsState || this.state.searchOptionsState
          ? ""
          : action === "changePage"
          ? this.changePage(tableState.page, tableState.sortOrder)
          : "";

        if (action === "filterChange") {
          this.changeFilter(tableState.filterList);
        }
        if (action === "search") {
          this.changePageSearch(tableState);
        }
      },
      onChangeRowsPerPage: (num) => {
        if (
          this.state.filterOptionsState === true ||
          this.state.searchOptionsState === true
        ) {
        } else {
          this.setState({ perPage: num, page: 0 });
          this.changeRowsWebinars(num);
        }
      },
      onFilterChange: (changedColumn, filterList, type) => {
        console.log("Inside onFilterChange");
        if (type === "reset") {
          this.resetWebinarFilters();
        }
      },
      onFilterChipClose: (index, removedFilter, filterList) => {
        let temp = [];
        filterList.map((value, index) => {
          for (let i = 0; i <= value.length; i++) {
            temp.push(i);
          }
        });
        if (temp.length === 11) {
          this.resetWebinarFilters();
        }
      },
    };
    return (
      <React.Fragment>
        <>
          <h4 className="regular_font fs-26 mtb-35">Course Registrations</h4>
          <hr></hr>
          <div className="col-md-12">
            <div className="row">
              {/* <div className='col-md-2 '>
              <label className='bm-0'>Type</label>
              <Select
                className=' mtb-10'
                options={this.state.searchType}
                value={this.state.selectedType}
                onChange={this.searchFilter}
                ref={this.searchTypeInput}
              />
            </div> */}

              <div className="col-md-2">
                <label className="bm-0">Email</label>
                <div className="input-group md-form form-sm form-2 mtb-10">
                  <input
                    type="text"
                    className="form-control"
                    name="search"
                    // value={this.state.search}
                    // onChange={this.handleTextInput}
                    ref={this.searchEmailInput}
                    placeholder={"Search By Email"}
                    aria-label="Text input with dropdown button"
                  />
                </div>
              </div>

              <div className="col-md-2">
                <label className="bm-0">Full Name</label>
                <div className="input-group md-form form-sm form-2 mtb-10">
                  <input
                    type="text"
                    className="form-control"
                    name="search"
                    // value={this.state.search}
                    // onChange={this.handleTextInput}
                    ref={this.searchNameInput}
                    placeholder={"Search By Name"}
                    aria-label="Text input with dropdown button"
                  />
                </div>
              </div>

              {/* <div className='col-md-2 '>
              <label className='bm-0'>Email Or Name</label>
              <div className='input-group md-form form-sm form-2 mtb-10'>
                <input
                  type='text'
                  className='form-control'
                  name='search'
                  // value={this.state.search}
                  // onChange={this.handleTextInput}
                  ref={this.searchTextInput}
                  placeholder={
                    "Search By Value"
                  }
                  aria-label='Text input with dropdown button'
                />
              </div>
            </div> */}

              <div className="col-md-2 ">
                <label className="bm-0">PEC No</label>
                <div className="input-group md-form form-sm form-2 mtb-10">
                  <input
                    type="text"
                    className="form-control"
                    name="search"
                    // value={this.state.search}
                    // onChange={this.handleTextInput}
                    ref={this.searchPecInput}
                    placeholder={"Search By PEC No"}
                    aria-label="Text input with dropdown button"
                  />
                </div>
              </div>

              <div className="col-md-2 ">
                <label className="bm-0">Discipline</label>
                <div className="input-group md-form form-sm form-2 mtb-10">
                  <input
                    type="text"
                    className="form-control"
                    name="search"
                    // value={this.state.search}
                    // onChange={this.handleTextInput}
                    ref={this.searchDisciplineInput}
                    placeholder={"Search By Discipline"}
                    aria-label="Text input with dropdown button"
                  />
                </div>
              </div>

              <div className="col-md-2">
                <label className="bm-0"></label>
                <button
                  className="btn admin_btn white w-150p float-right bold mtb-10"
                  onClick={() => {
                    this.searchData();
                  }}
                >
                  Search
                </button>
              </div>

              <div className="col-md-2">
                <label className="bm-0"></label>
                <button
                  className="btn admin_btn_new text-center white w-150p float-right bold mtb-10"
                  onClick={() => {
                    this.resetWebinarFilters();
                  }}
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
        </>

        {this.state.load === true ? (
          <div className="loaderDiv text-center">
            <Loader type="Oval" color="#06580e" height={80} width={80} />
          </div>
        ) : (
          <>
            <hr></hr>
            <div className="mt-50">
              <MuiThemeProvider theme={theme}>
                <MUIDataTable
                  title={"Course Registrations"}
                  data={this.state.freeWebinars}
                  columns={columns}
                  options={options}
                />
              </MuiThemeProvider>
            </div>
          </>
        )}
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    // payments: state.financialData.coursepayments,
    // registrations: state.financialData.registrations,
  };
}
export default connect(mapStateToProps, {
  getFreeWebinarsRegistrations,
  getFilterPaymentsData,
  getSearchPaymentData,
  get_webinar_registration_count,
})(WebinarRegistrations);
