import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import CpdActivitiesSIDEbar from "./CpdActivitiesSIDEbar";

class Introduction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [
        {
          name: "Home",
          url: "/",
        },
        {
          name: "Introduction to CPD ",
          url: "/Introduction",
        },
        {
          name: "CPD Byelaws",
          url: "/byelaws",
        },
        {
          name: "Engineering Professional Development Committee (EPDC)",
          url: "epdc",
          type: "button",
          child: [
            {
              name: "Introduction",
              url: "/epdc/intro",
            },
            {
              name: "Organogram",
              url: "/Organogram",
            },
          ],
        },
        {
          name: "CPD Guideline Manual",
          url: "/GuideLineMauals",
        },
        {
          name: "CPD Relaxed Policy",
          url: "/CPDRelaxPolicy",
        },
        {
          name: "CPD Brief",
          url: "/CPDBriefs",
        },
        {
          name: " PEC Approved Resource Person",
          url: "psrp",
          type: "button",
          child: [
            {
              name: "List of Resourse Person",
              url: "/CPDListofResourcePerson",
            },
            {
              name: "Criteria for Resourse Person",
              url: "/CPDResourcePersonCriteria",
            },
            // ,
            // // {
            //   name: "Application Form",
            //   url: "/ApplicationForm",
            // }
          ],
        },
        {
          name: "Important News for Engineers",
          url: "/important-news",
        },
      ],
    };
  }
  componentDidMount() {
    document.title = "Introduction";
  }
  render() {
    return (
      <React.Fragment>
        <section className="backco_white" style={{ fontFamily: "Arial" }}>
          <Header />
          <div className="row col_margin">
            <div
              className="col-md-3 col_padding sidebar_container"
              style={{ padding: "2%" }}
            >
              <CpdActivitiesSIDEbar data={this.state.data} />
            </div>
            <div className="col-md-7 col_padding intr_mr">
              <h2
                className="text-center"
                style={{
                  margin: "28px 0px 0px 1px",
                  fontWeight: 700,
                  fontSize: "1.5em",
                }}
              >
                Introduction to CPD
              </h2>
              <div className="row">
                <div
                  className="col-md-12"
                  style={{ padding: "2%", marginLeft: "30px" }}
                >
                  <h2
                    // className="text-center"
                    style={{
                      margin: "10px 0px 0px 1px",
                      fontWeight: 700,
                      fontSize: "1.5em",
                    }}
                  >
                    Introduction to CPD (Continuing Professional Development)
                  </h2>
                  <div className="into-p">
                    {/* <p>
                      Pakistan Engineering Council (PEC) under PEC Act and CPD
                      Byelaws-2008, is earnestly working for the professional
                      growth and skill enhancement of ever growing engineering
                      community.
                      <br />
                      Under CPD framework, the engineers (both PEs and REs) are
                      required to learn innovative and soft skills to be more
                      effective in a playing productive role towards nation
                      building. With these objectives, CPD framework of PEC sets
                      parameters through number of developmental activities
                      (short courses, trainings, workshops, research
                      publications, professional memberships, Member of any
                      Technical Committee, etc.) for the ultimate benefits of
                      engineers in their career development. <br />
                      At the same time it stipulates requirements for renewal of
                      PEC registration as well as for elevation from Registered
                      Engineer (RE) to Professional Engineer (PE) and then
                      International Professional Engineer (Int PE). PEC is
                      therefore working through various means and options taking
                      advantage of latest IT and communication tools to
                      facilitate the engineers. Under New Vision of CPD,
                      specialized job-oriented and global certification courses
                      are being introduced to enhance employability and on-job
                      performance. For wider coverage and remote outreach;
                      webinars, online courses and video-conferencing facilities
                      have been integrated. 
                    </p> */}
                    <p>
                      In a rapidly changing world where legislative, social and
                      economic developments directly affect the environment in
                      which we live and work, and where technological
                      advancements provide radically different ways of working,
                      Continuing Professional Development (CPD) provides a means
                      whereby we can keep abreast of these changes, broaden our
                      skills and be more effective in our professional work.
                    </p>
                    <p>
                      Continuing Professional Development (CPD) or Continuing
                      Professional Education (CPE) is the means by which members
                      of professional bodies maintain, improve and broaden their
                      knowledge and skills for developing personal qualities
                      required in their professional lives.
                    </p>
                    <p>
                      Pakistan Engineering Council under its Act has mandate for
                      introducing and ensuring continued professional
                      development activities amongst its growing community of
                      engineers and has devised a comprehensive framework titled
                      “Professional Development of Engineers (Bye Laws – 2008)”
                      approved by the Government of Pakistan.
                    </p>
                    <p>
                      The main objective is to develop competence and ability of
                      engineers for the application of theoretical knowledge to
                      practical situations and to evolve innovative solutions to
                      real life problems while adhering to professional ethics
                      and acquisition of a broader understating of their
                      obligations to society.
                    </p>
                  </div>
                  <h2
                    // className="text-center"
                    style={{
                      margin: "28px 0px 0px 1px",
                      fontWeight: 700,
                      fontSize: "1.5em",
                    }}
                  >
                    Strategy / Plan for Professional Development Activities
                  </h2>
                  <div className="into-p">
                    <p>
                      As per Bye-Law 16 of Professional Development of Engineers
                      Bye-Laws, 2008; from July 2010 onwards, apart from PEC,
                      most of Continuing Professional Development activities
                      (courses, training workshops, etc.) are being undertaken
                      by the Professional Engineering Bodies registered with
                      PEC. The courses / CPD activities are being planned by the
                      PEBs according to the specified criteria and guidelines.
                      Such bodies also provide schedule of courses and share
                      verified list of participants with PEC to maintain
                      transparent record of CPD activities and CPD credit points
                      for all the Registered and Professional Engineers. PEC
                      Regional Offices facilitates PEBs in organizing CPD
                      activities.
                    </p>
                    <p>
                      PEC also conducts CPD courses itself thought the country
                      in coordination with Regional and Branch Offices with
                      nominal charges from engineer participants. It is the
                      prerogative of PEBs to decide the amount of fee according
                      to the CPD activity, however they must ensure to charge
                      the fee as minimum as possible from the engineers.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-1"></div>
          </div>
          {/*<Footer/>*/}
        </section>
      </React.Fragment>
    );
  }
}
export default Introduction;
