import React, { Component } from 'react'
import { connect } from "react-redux";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { getWebinarRegistered } from "../../redux/admin/users/adminuseractions";
import MUIDataTable from "mui-datatables";
const columns = [
  "Course Name",
  "Name",
  "Email",
  "PEC NO",
  "Discipline",
  "Address",
];
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "500px",
    backgroundColor: "#e0eadf",
    border: "none",
    color: "black",
    height: "300px",
    overflowY: "hidden",
    "z-index": 9999,
  },
  overlay: {
    zIndex: 1000,
    backgroundColor: "rgba(0, 0, 0, 0.35)",
  },
};
class WebinarRegistered extends Component {
  state = {
    data: [],
  }

  componentDidMount() {
    let data = []
    this.props.getWebinarRegistered(this.props.location.state.id).then((res) => {
      for (let i = 0; i < res.data.length; i++) {
        data.push({
          "Name": res.data[i].FullName,
          "Email": res.data[i].PrimaryEmail,
          "PEC NO": res.data[i].PEC_REG_NO,
          "Discipline": res.data[i].discipline,
          "Address": res.data[i].Primary_Address,
          "Course Name": res.data[i].Course_Name,
        })
      }
      this.setState({ data: data });
    }).catch((err) => {
      console.log(err);
    });
  }
  render() {

    const options = {

      responsive: "vertical",
      tableBodyHeight: "600px",
      tableBodyMaxHeight: "",
      filter: true,
      filterType: "dropdown",


    };
    const theme = createMuiTheme({
      overrides: {
        MUIDataTableSelectCell: {
          expandDisabled: {
            visibility: "hidden",
          },
        },
      },
    });
    return (
      <React.Fragment>
        <h4 className="regular_font fs-26 mtb-35">Webinar Registered</h4>
        <MuiThemeProvider theme={theme}>
          <MUIDataTable
            // title={"CPD Forms"}
            data={this.state.data}
            columns={columns}
            options={options}
          // components={components}
          />
        </MuiThemeProvider>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    payments: state.financialData.coursepayments,
    registrations: state.financialData.registrations,
  };
}
export default connect(mapStateToProps, {
  getWebinarRegistered
  // getcoursepayments,
  // getFreeWebinarsRegistrations,
  // approvePayment,
  // rejectPayment,
})(WebinarRegistered);
