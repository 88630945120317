const initstate = {
  cpddata: null,
  gotuserclaim: null,
  cpdrow: null,
};

export default function CPDData(state = initstate, action = {}) {
  switch (action.type) {
    case "GOT_CPD_FORM":
      return { ...state, cpddata: action.data };
    case "CPD_STATUS_UPDATED":
      return { ...state };
    case "GOT_CPD_FORM_COUNT":
      return { ...state };
    case "GOT_CPD_FORM_COUNT_PR":
      return { ...state };
    case "GOT_FILTER_CLAIM_DATA":
      return { ...state };
    case "GOT_FILTER_CLAIM_COUNT":
      return { ...state };
    case "GOT_YEAR_APPROVED_POINTS":
      return { ...state };
    case "GOT_CPD_FORM_EXPENDABLE":
      return { ...state };
    case "GOT_CPD_DATA_ROW":
      return { ...state, cpdrow: action.data };
    case "GOT_CPD_DATA_ROW_PR":
      return { ...state, cpdrow: action.data };
    case "GOT_CPD_DATA_ROW_REJECTED":
      return { ...state };
    case "SEARCH_CLAIMS":
      return { ...state };
    case "GOT_CPD_FORM_COUNT_REJECTED":
      return { ...state };
    case "CPD_STATUS_REJECTED":
      return { ...state };
    case "GOT_CLAIM_USER":
      return { ...state, gotuserclaim: action.data };
    case "GOT_YEAR_PROFILE_DATA":
      return { ...state, gotuserclaim: action.data };
    case "GOT_ATTENDENCE_DATA":
      return { ...state };

    default:
      return state;
  }
}
