import React, { Component } from "react";
import Dropzone from "react-dropzone";
import Loader from "react-loader-spinner";
import Feedback_Webinar from "./Feedback_Webinar";
import { connect } from "react-redux";
import { deleteFile } from "../../redux/admin/webinar/webinarActions";
import WebNotif from "../WebNotif";
import img1 from "../../assets/image/designer_icon/Upload-files-here.png";
import img2 from "../../assets/image/designer_icon/Upload-files-here.png";
import img3 from "../../assets/image/designer_icon/Upload-files-here.png";
var obj = new WebNotif();

const acceptedFileTypes =
  "image/x-png, image/png, image/jpg, image/jpeg, image/gif, video/mp4, video/m4a, video/mov,video/3gp, video/ogg, video/ogx, video/wmv, video/flv, video/avi ,application/vnd.ms-powerpoint,application/pdf,application/x-zip-compressed, application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel";
const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item) => {
  return item.trim();
});
class FreeCC extends Component {
  state = {
    loading: false,
    webinarExtraResources: {
      files: [],
    },
    fd: {},
    isEdit: false,
  };

  delete = (item) => {
    const data = { fileId: item.extra_resource_id, c_id: item.course_id };
    this.props.deleteFile(data);
    obj.createNotification("success", "file deleted");
  };

  verifyFiles = (files) => {
    if (files && files.length > 0) {
      const currentFile = files[0];
      const currentFileType = currentFile.type;
      const currentFileSize = currentFile.size;
      if (currentFileSize > 2e6) {
        obj.createNotification("error", "File cant be larger than 2mbs");
        return false;
      }
      if (!acceptedFileTypesArray.includes(currentFileType)) {
        obj.createNotification(
          "error",
          "This file type is not allowed, Only documents,excel,pdfs,ppt etc are allowed"
        );
        return false;
      }
      return true;
    }
  };
  handleOnDropFiles = (files, rejectedFiles) => {
    if (rejectedFiles && rejectedFiles.length > 0) {
      this.verifyFiles(rejectedFiles);
    }
    if (files && files.length > 0) {
      if (this.verifyFiles(files)) {
        const fd = new FormData();
        var j = 1;
        for (var i = 0; i < files.length; i++) {
          fd.append("files", files[i]);
          j++;
        }

        this.setState(
          {
            webinarExtraResources: { files: files },
            hasFiles: true,
            fd: fd,
          },
          () => {
            {
              this.state.webinarExtraResources.files.map((item, key) => {
                // console.log(item.name, key);
              });
            }
          }
        );
      }
    }
  };

  saveForm = () => {
    if (this.state.webinarExtraResources.files.length > 0) {
      this.toggleLoading();
      if (this.props.isEdit == true) {
        this.props.AddMoreExtraFiles({
          webinarFiles: this.state.webinarExtraResources.files,
          fd: this.state.fd,
        });
        this.setState({
          webinarExtraResources: { files: [] },
        });
      } else {
        if (this.props.flag == true) {
          this.props.AddMoreExtraFiles({
            webinarFiles: this.state.webinarExtraResources.files,
            fd: this.state.fd,
          });

          this.setState({
            webinarExtraResources: { files: [] },
          });
        } else {
          this.props.setWebinarFiles({
            webinarFiles: this.state.webinarExtraResources.files,
            fd: this.state.fd,
          });

          this.setState({
            webinarExtraResources: { files: [] },
          });
        }
      }
    } else {
      //call parent method for next

      this.props.nextifEmpty();
    }
  };
  componentWillMount() {
    this.setState({ isEdit: this.props.isEdit }, () => {});
  }
  componentDidMount() {
    this.props.onRef(this);
  }
  toggleLoading = () => {
    this.setState({ loading: !this.state.loading }, () => {});
  };
  render() {
    return (
      <React.Fragment>
        <div className=" col-md-12">
          <div className="row">
            <div className="col-md-5">
              <h4 className="card-title bold regular_font">Files </h4>
              <div className="col lrp-0">
                <span>
                  {/* <b>Course title:</b>
                  {this.state.courseTitle != undefined
                    ? " (" + this.state.courseTitle + ")"
                    : ""} */}
                </span>
              </div>
            </div>
            <div className="col-md-7 lrp-0">
              &nbsp; &nbsp;
              {this.state.contentType != null &&
              this.state.courseType == "1" ? (
                <button
                  className="btn admin-btn-green fix_width_btn white fz_13 bold"
                  disabled="true"
                >
                  Add File
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <hr />

        {this.state.loading == true ? (
          <div className="loaderDiv text-center">
            <Loader type="Oval" color="#06580e" height={80} width={80} />
          </div>
        ) : (
          <ul className="files-list">
            <div className="text-center mt_20 fs-20"> Files </div>
            {this.props.files.length > 0 ? (
              this.props.files.map((item) => (
                <div className="row card-body" key={item.extra_resource_id}>
                  <div className="col-md-12 live_web_border_0">
                    <div className="row ptb-20">
                      <div className="col-md-4 col_padding ">
                        <div className="pl-lg-5">
                          <img src={img1} />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <p className="fz_16 pt-2 pl-4">{item.file_path}</p>
                      </div>
                      <div className="col-md-4 flex_center col_padding">
                        <div className="">
                          <button
                            className="cancel-file-btn"
                            onClick={() => this.delete(item)}
                          >
                            <i
                              className="fa fa-trash delbutton"
                              aria-hidden="true"
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="text-center mt_20 fs-20"> No files saved </div>
            )}
          </ul>
        )}

        <h5 className="card-title bold mtb-20 regular_font text-center">
          Upload Extra Resources for Webinar{" "}
        </h5>

        <div className="col-md-6 mtb-20 offset-md-3 file_upload_admin ptb_4v ">
          <Dropzone
            name="dropzone2"
            onDrop={this.handleOnDropFiles}
            maxSize={5e7}
            multiple={true}
          >
            {({ getRootProps, getInputProps }) => (
              <section>
                <div className="text-center" {...getRootProps()}>
                  <input name="myFiles" {...getInputProps()} />
                  <img src={img2} className="height_7v mtb-10" alt="Download" />
                  <h6 className="mb_unset">Upload your file here</h6>
                  <br />
                  Click or Drag Documents,Presentations,Images, PDFs to upload!
                </div>
              </section>
            )}
          </Dropzone>
        </div>
        {this.state.webinarExtraResources.files.length > 0 && (
          <ul className="files-list">
            {this.state.webinarExtraResources.files.length > 0 ? (
              this.state.webinarExtraResources.files.map((item, key) => (
                <div className="row card-body" key={key}>
                  <div className="col-md-12 live_web_border_0">
                    <div className="row ptb-20">
                      <div className="col-md-4 col_padding ">
                        <div className="pl-lg-5">
                          <img src={img3} />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <p className="fz_16 pt-2 pl-4">{item.name}</p>
                      </div>
                      {/* <div className="col-md-4 flex_center col_padding">
                       <div className="">
                          <button className="cancel-file-btn" onClick={()=>this.delete(key)}><i className="fa fa-trash delbutton" aria-hidden="true" /></button>
                        </div>
                     </div> */}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="text-center mt_20 fs-20"> No Files Selected</div>
            )}
          </ul>
        )}
        {/* //comment  */}
        {this.props.isEdit == true
          ? (this.props.User.RoleID === 8 ||
              this.props.User.RoleID === 7 ||
              this.props.User.RoleID === 3 ||
              this.props.User.RoleID === 4 ||
              this.props.User.RoleID === 5) && (
              <Feedback_Webinar
                webinarId={
                  this.props.currentWebinarData &&
                  this.props.currentWebinarData.CourseID
                }
                owner={
                  this.props.currentWebinarData &&
                  this.props.currentWebinarData.user_id
                }
              />
            )
          : ""}
        {(this.props.User.RoleID !== 7 ||
          this.props.User.RoleID !== 8 ||
          this.props.User.RoleID === 3 ||
          this.props.User.RoleID === 4) && (
          <div className="col-md-12">
            <button
              className="btn btn-save float-right mt-23 bold "
              onClick={this.saveForm}
            >
              {this.props.isEdit == true ? "Update and Next" : "Save and Next"}
            </button>
          </div>
        )}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    files: state.webinarData.webinarFiles,
    flag: state.webinarData.FirstTimeSave.files,
    webinarDetails: state.webinarData.webinarDetails,
    User: state.userData.admin,
  };
}

export default connect(mapStateToProps, { deleteFile })(FreeCC);
