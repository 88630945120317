import React from 'react';
import Header from './Header';
import { connect } from 'react-redux';
import { getgallery } from '../redux/user/useraction';
import { urlImageUrl } from '../config/urlapi';
import { Link } from 'react-router-dom';


class Album extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            events: [],
            thumbs: [],
            allImages: [],

            modalIsOpen: false

        }
    }
    toggleModal = () => {
        this.setState(state => ({ modalIsOpen: !state.modalIsOpen }));
    }
    location = (item) => {
        console.log("clicked")
        this.props.history.push({
            pathname: `/gallery/${item}`,
            state: { images: this.state.allImages }
        })

    }
    componentDidMount() {

        window.scrollTo(0, 0);

        this.props.getgallery().then(res => {
            let temp = []
            for (let i = 0; i < res.length; i++) {
                temp.push(res[i].event_name)

            }
            let Events = [...new Set(temp)]

            let thumbs = [];

            for (let j = 0; j < Events.length; j++) {

                for (let k = 0; k < res.length; k++) {

                    if (Events[j] == res[k].event_name) {
                        thumbs.push(res[k].pic_url)
                        break;
                    }

                }


            }

            this.setState({ events: Events, thumbs: thumbs, allImages: res })


        })



    }
    render() {
        return (
            <React.Fragment>
                <section className="backco_white" style={{ fontFamily: 'Arial' }}>
                    <Header />
                    <div className="container">
                        <h3 className="mt-4">Albums</h3>
                        <hr />
                        <div className="mtb_7v">
                            <div className="row ">

                                {this.state.events.map((item, index) => (
                                    <div className="col-md-4">

                                        <div className="card" style={{ width: "18rem", minHeight: "363px" }}>
                                            <img className="card-img-top"
                                                src={urlImageUrl + this.state.thumbs[index]} alt="Card image cap" />
                                            <div className="card-body">
                                                <h5 className="card-title">{item}</h5>
                                                <button
                                                    onClick={() => this.location(item)}
                                                    style={{ background: '#009800' }} className="btn btn-primary">View Album
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))}



                            </div>





                        </div>
                    </div>

                </section>
            </React.Fragment>

        );

    }
}

function mapStateToProps(state) {
    return {
        images: state.userData.gallery
    }
}
export default connect(mapStateToProps, { getgallery })(Album);