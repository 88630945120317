import React, { useState, useEffect, useRef } from "react";
import * as yup from "yup";
import { connect } from "react-redux";
import Header from "../Header";
import Loader from "react-loader-spinner";
import api from "../../api";
import WebNotif from "../../components/WebNotif";
import { PowerInputSharp } from "@material-ui/icons";
import Dropzone from "react-dropzone";
import moment from "moment";
import {
  saveEpeFormRedux,
  getEpeHistory,
  getSubDiscipline,
  getCurrentExam,
  checkEpePoints,
} from "../../redux/user/useraction";
import disciplineData from "../../config/discipline.json";
var obj = new WebNotif();
import axios from "axios";
import parsePhoneNumber from "libphonenumber-js";
import img from "../../assets/image/designer_icon/Upload-files-here.png";
import { AccountBoxOutlined } from "@material-ui/icons";
import { urlapiEPE } from "../../config/urlapi";

const EngineerEpeForm = (props) => {
  const [load, setLoad] = React.useState(false);
  const [image64, setImage64] = React.useState(null);
  const [imageFile, setImageFile] = React.useState(null);
  const [currentFileTypeofData, setCurrentFileTypeofData] = React.useState("");
  const [currentFileNameofData, setCurrentFileNameofData] = React.useState("");
  const [userIsQualfied, setUserIsQualified] = React.useState(false);
  const [currentExamId, setCurrentExamId] = React.useState("");

  const [form, setForm] = useState({
    userProfile: "",
    name: "",
    regNo: "",
    discipline: "",
    subDiscipline: "",
    dor: "",
    gender: "",
    nationality: "",
    dob: "",
    cnic: "",
    address: "",
    alternateContact: "",
    mobile: "",
    email: "",
    epeNo: "",
    rollNo: "",
    examCenter: "",
    epeSpecilization: "",
    noOfAttempt: "",
    historyExist: "",
    paperCount: "",
    paperDetail: "",
  });

  const [history, setHistory] = useState({
    status: false,
    paperCount: 0,
    paperDetail: {},
  });

  const [childs, setChilds] = useState([]);

  const [sendDiscipline, setSendDiscipline] = React.useState("");

  const subDisciplineRef = React.useRef();

  const postalAddressRef = useRef();
  const alternateRef = useRef();
  const telephoneNumberRef = useRef();
  const mobileNumberRef = useRef();
  const emailAddressRef = useRef();

  useEffect(() => {
    let disc = props.User.discipline;
    const disciplineRecord = disciplineData.find((dis) => dis.name === disc);
    if (!!disciplineRecord) {
      console.log("discipline record...", disciplineRecord);
      setSendDiscipline(disciplineRecord.value);
    } else {
      setSendDiscipline(disc);
    }
  }, [props.User.discipline]);

  // React.useEffect(() => {
  //   let disc = props.User.discipline;
  //   const electrical_and_allied_disciplines = [
  //     "ELECT",
  //     "ELECTRO",
  //     "TELE",
  //     "AVION",
  //     "MECHATRO",
  //     "BIOMEDICAL",
  //     "COMP",
  //     "ENGG.SCI.",
  //   ];
  //   if (electrical_and_allied_disciplines.includes(disc)) {
  //     setSendDiscipline("ELECT");
  //   } else {
  //     setSendDiscipline(disc);
  //   }
  // }, [props.User.discipline]);

  const acceptedImageFileTypes =
    "image/x-png, image/png, image/jpg, image/jpeg";
  const acceptedImageFileTypesArray = acceptedImageFileTypes
    .split(",")
    .map((item) => {
      return item.trim();
    });

  const checkEpePointsHandler = async (userId) => {
    try {
      const screeningData = await api.user.checkEpePoints({ id: userId });

      const {
        hasValidExperience,
        hasValidCPDPoints,
        hasValidWorkBasedPoints,
        isNotPE,
        isValidGraduate,
      } = screeningData?.data;
      if (
        hasValidExperience === false ||
        hasValidCPDPoints === false ||
        hasValidWorkBasedPoints === false ||
        isNotPE === false ||
        isValidGraduate === false
      ) {
        // props.history.push("/engineer/engineer-epe-form");
        // TODO: Uncomment the code below for engineer application validation
        props.history.push({
          pathname: "/engineer/access-denied",
          state: {
            title: "You are not eligible to appear in EPE",
            hasValidCPDPoints: hasValidCPDPoints,
            hasValidExperience: hasValidExperience,
            hasValidWorkBasedPoints: hasValidWorkBasedPoints,
            isNotPE: isNotPE,
            isValidGraduate: isValidGraduate,
          },
        });
        // setLoad(false);
      } else {
        setUserIsQualified(true);
      }
    } catch (err) {
      console.log("Error: ", err);
    }
  };

  const checkApplicationStatus = async (cnic, examId) => {
    try {
      localStorage.setItem("updateApplication", false);
      console.log("INSIDE checkApplicationStatus");

      const data = {
        cnic,
        examId,
      };
      const response = await axios.post(
        `${urlapiEPE}/applicant/check-user-status`,
        data
      );

      console.log("RESPONSE .... ", response);

      if (response.data) {
        const {
          hasAppliedBefore,
          hasPendingApplication,
          hasApplicationRejected,
          hasPendingPayment,
          hasRejectedPayment,
          applicationData,
        } = response.data;

        if (hasAppliedBefore === true) {
          obj.createNotification(
            "error",
            "Your application has been already accepted"
          );
          props.history.push("/EPECustom");
          return;
        }

        if (hasPendingPayment === true) {
          obj.createNotification("error", "Your payment scrutiny is pending");
          props.history.push("/EPECustom");
          return;
        }

        if (hasPendingApplication === true) {
          obj.createNotification(
            "error",
            "Your application scrutiny is pending"
          );
          props.history.push("/EPECustom");
          return;
        }

        if (hasRejectedPayment === true) {
          localStorage.setItem("updateApplication", false);
        }

        if (hasApplicationRejected) {
          localStorage.setItem("updateApplication", true);
          setForm({ ...form, subDiscipline: applicationData?.subDiscipline });
          localStorage.setItem("application", JSON.stringify(applicationData));
        }
      }
    } catch (err) {
      console.log("Error: ", err);
      obj.createNotification(
        "error",
        err?.response?.data?.message
          ? err?.response?.data?.message
          : "Something went wrong"
      );
    } finally {
      setLoad(false);
    }
  };

  /**
   * * Screening  user profile
   */

  React.useEffect(() => {
    // if (props.User?.UserID) {
    //   setLoad(true);
    //   checkEpePointsHandler(props.User?.UserID);
    // } else {
    //   setLoad(false);
    // }
    getCurrentExamHandler();
  }, [props.User]);

  React.useEffect(() => {
    // getCurrentExamHandler();
    if (
      props.User?.PEC_REG_NO &&
      userIsQualfied === true &&
      currentExamId !== ""
    ) {
      console.log("props.User?.PEC_REG_NO ... ", props.User?.CNIC);
      console.log("userIsQualfied ... ", userIsQualfied);
      console.log("currentExamId ... ", currentExamId);
      setLoad(true);
      checkApplicationStatus(props.User?.CNIC, currentExamId);
    }
  }, [userIsQualfied, props.User, currentExamId]);

  const getCurrentExamHandler = async () => {
    // const data = await api.user.getCurrentExam();
    try {
      setLoad(true);
      const data = await api.user.getActiveExam();
      console.log("current exammmmmmmmm", data);
      if (!data.data[0]?.id) {
        obj.createNotification("error", "Exam does not exist");
        props.history.push("/EPECustom");
        return;
      } else {
        setCurrentExamId(data.data[0]?.id);
        localStorage.setItem("getLatestExamId", data.data[0]?.id);
        localStorage.setItem("getLatestExamName", data.data[0]?.exam_name);
        localStorage.setItem(
          "getLatestExamConductDate",
          data.data[0]?.conduct_date
        );
        await checkEpePointsHandler(props.User?.UserID);
      }
    } catch (error) {
      obj.createNotification("error", "Exam does not exist");
      props.history.push("/EPECustom");
    }
  };

  React.useEffect(() => {
    /**
     * * Get Current Exam
     */
    const reduxEpeForm = localStorage.getItem("reduxEpeForm");

    if (reduxEpeForm) {
      const parsedForm = JSON.parse(reduxEpeForm);
      if (!!parsedForm.form) {
        const { name, ...form } = parsedForm.form;

        setForm({ ...form, name: name || "" });

        if (parsedForm.form.userProfile) {
          setImage64(parsedForm.form.userProfile);
          setCurrentFileNameofData(parsedForm.form.currentFileNameofData);
          setCurrentFileTypeofData(parsedForm.form.currentFileTypeofData);
        }
      }
    } else {
      const address = props.User.postal_address
        ? props.User.postal_address
        : props.User.Primary_Address;

      // getCurrentExamHandler();

      let dateOfReg = props.User.PEC_REG_DATE.split(" ");

      let dateOfBirth = props.User.DOB.split(" ");

      setForm({
        userProfile: image64,
        name: props.User.FullName,
        regNo: props.User.PEC_REG_NO,
        discipline: props.User.discipline,
        subDiscipline: "",
        dor: dateOfReg[0],
        gender: "female",
        nationality: "pakistan",
        dob: dateOfBirth[0] || "1/1/1",
        cnic: props.User.CNIC,
        address: address,
        alternateContact:
          props.User.Primary_Contact_NO || props.User?.Primary_Contact_No,
        mobile: props.User.Primary_Contact_NO || props.User?.Primary_Contact_No,
        email: props.User.PrimaryEmail,
        historyExist: false,
        paperCount: 3,
        paperDetail: {},
      });

      if (postalAddressRef?.current) {
        postalAddressRef.current.value = address;
      }
    }
  }, []);

  React.useEffect(() => {
    if (sendDiscipline) {
      props
        .getSubDiscipline(sendDiscipline)
        .then((res) => {
          const specializations = res?.childs?.filter(
            (ch) => ch.type === "specialization"
          );
          setChilds(specializations);
          setForm((prevForm) => ({
            ...prevForm,
            userProfile: image64,
            name: props.User.FullName,
            regNo: props.User.PEC_REG_NO,
            discipline: props.User.discipline,
            disciplineId: res?.data?.id,
            // subDiscipline: "",
            dor: moment(
              new Date(
                props.User.PEC_REG_DATE.substr(
                  0,
                  props.User.PEC_REG_DATE.indexOf(" ")
                )
              )
            ).format("YYYY-MM-DD"),

            gender: parseInt(props.User.gender) === 1 ? "male" : "female",
            nationality: "pakistan",
            dob:
              moment(
                new Date(props.User.DOB.substr(0, props.User.DOB.indexOf(" ")))
              ).format("YYYY-MM-DD") || "1/1/1",
            cnic: props.User.CNIC,
            address: props.User.postal_address || props?.User?.Primary_Address,
            mobile:
              props.User?.Primary_Contact_NO || props.User?.Primary_Contact_No,
            alternateContact:
              props.User.Primary_Contact_NO || props.User?.Primary_Contact_No,
            email: props.User.PrimaryEmail,
            historyExist: history.status,
            paperCount: history.paperCount,
            paperDetail: history.paperDetail,
          }));
        })
        .catch((err1) => {
          // setLoad(false);
        });
    }
  }, [sendDiscipline]);

  const selectChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  // const phoneValidation = () => {
  //   const regex = /^((\+92)?(0092)?(92)?(0)?)(3)([0-9]{9})$/gm;
  //   return !(!this.state.mobile || regex.test(this.state.mobile) === false);
  // }

  // function that grab form data
  const onSubmit = (e) => {
    e.preventDefault();

    if (image64 === null) {
      obj.createNotification("error", "Profile Picture Is Required");
    } else if (form.address === "" || !form.address) {
      postalAddressRef.current.focus();
      postalAddressRef.current.style.border = "3px solid #20A84C";
      obj.createNotification("error", "Postal Address Is Required");
    } else if (form.mobile === "" || form.mobile === undefined) {
      mobileNumberRef.current.focus();
      mobileNumberRef.current.style.border = "3px solid #20A84C";
      obj.createNotification("error", "Mobile Number Is Required");
    } else if (form.subDiscipline === "") {
      subDisciplineRef.current.focus();
      subDisciplineRef.current.style.border = "3px solid #20A84C";
      obj.createNotification("error", "Area Of Specialization Is Required");
    } else {
      let checkNumber = form.mobile;
      const phoneNumber = parsePhoneNumber(checkNumber, "PK");
      if (phoneNumber.isValid() === false) {
        mobileNumberRef.current.focus();
        mobileNumberRef.current.style.border = "3px solid #20A84C";
        obj.createNotification("error", "Mobile Number Is Not Valid");
      } else {
        let reduxEpeForm = JSON.parse(localStorage.getItem("reduxEpeForm"));
        const updatedFormData = {
          ...reduxEpeForm,
          form: {
            ...form,
            currentFileNameofData,
            currentFileTypeofData,
          },
        };
        localStorage.setItem("reduxEpeForm", JSON.stringify(updatedFormData));
        props.history.push("/engineer/center-form");
      }
    }
  };

  const setChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const removeImage = () => {
    setImage64(null);
    setCurrentFileNameofData("");
    setCurrentFileTypeofData("");
  };

  const verifyImageFile = (files) => {
    if (files && files.length > 0) {
      const currentFile = files[0];
      const currentFileType = currentFile.type;
      const currentFileSize = currentFile.size;
      if (currentFileSize > 2e6) {
        obj.createNotification(
          "error",
          "File should be less than 2mbs, Try Again"
        );
        return false;
      }
      if (!acceptedImageFileTypesArray.includes(currentFileType)) {
        obj.createNotification(
          "error",
          "Only these image types are accepted: x-png, png, jpg, jpeg"
        );
        return false;
      }
      return true;
    }
  };

  const handleOnDropImage = (files, rejectedFiles) => {
    if (rejectedFiles && rejectedFiles.length > 0) {
      verifyImageFile(rejectedFiles);
    }
    if (files && files.length > 0) {
      if (verifyImageFile(files)) {
        const currentFile = files[0];
        setImageFile(currentFile);
        const reader = new FileReader();
        reader.addEventListener(
          "load",
          () => {
            setImage64(reader.result);
            setForm({ ...form, userProfile: reader.result });
          },
          false
        );
        reader.readAsDataURL(currentFile);

        obj.createNotification("success", "File Dropped");

        setCurrentFileTypeofData(currentFile.type);
        setCurrentFileNameofData(currentFile.name);
      }
    }
  };

  return (
    <React.Fragment>
      {load ? (
        <div style={{ textAlign: "center" }} className="container-fluid">
          <h1 style={{ marginTop: "50px" }}>Loading...</h1>
        </div>
      ) : (
        <section className="backco_white" style={{ fontFamily: "Arial" }}>
          <Header />
          <div className="App">
            <div className="container-fluid">
              <h3 className="text-center ptb-30 bold payment_heading">
                EPE Application
              </h3>
              <br />
              {/* <p className="text-center font-weight-bold">
                Please fill your details below
              </p> */}
              <div className="mt-4 px-5 py-3 font-16">
                <div className="row">
                  <div className="col-xl-2 col-lg-2 col-md-2"></div>
                  <div className="col-xl-8 col-lg-8 col-md-8 message-background text-left px-5">
                    <div className="general-height-10 "></div>
                    <form onSubmit={onSubmit}>
                      <h5 className="mt-3 co_g20A84C font-weight-bold font-16 mb-4">
                        Step 1: Personal Information
                      </h5>
                      {/* Full name start */}
                      <div className="container mt-40">
                        <label className="mb-2 font-14">
                          Profile Picture{" "}
                          <span style={{ color: "#06580e" }}>*</span>
                        </label>
                        <div className="col-md-6 offset-md-3 file_upload_admin ptb_4v ">
                          {image64 !== null &&
                          currentFileTypeofData?.match("image.*") ? (
                            <>
                              <div
                                onClick={removeImage}
                                className="pointer"
                                style={{
                                  display: "block",
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                  color: "#20A84C",
                                  height: "200px",
                                  width: "200px",
                                  // borderRadius: '50px'
                                }}
                              >
                                <img
                                  className=""
                                  style={{
                                    height: "100%",
                                    width: "100%",
                                    objectFit: "cover",
                                  }}
                                  src={image64}
                                />
                                <i
                                  className="fa fa-times-circle preview-close-icon fa-lg"
                                  style={{ color: "#20A84C" }}
                                />
                              </div>
                              <p style={{ marginTop: "10px" }}>
                                {currentFileNameofData}
                              </p>
                            </>
                          ) : image64 !== null &&
                            currentFileTypeofData === "application/pdf" ? (
                            <>
                              <div
                                onClick={removeImage}
                                className="pointer"
                                // style={{
                                //   display: "block",
                                //   marginLeft: "auto",
                                //   marginRight: "auto",
                                //   color: "#20A84C",
                                //   height: "300px",
                                //   width: "300px",
                                // }}
                              >
                                <i className="far fa-file-pdf fa-3x pb-10" />
                                <i
                                  className="fa fa-times-circle preview-close-icon fa-lg"
                                  style={{ color: "#20A84C" }}
                                />
                              </div>
                              <p style={{ marginTop: "10px" }}>
                                {currentFileNameofData}
                              </p>
                            </>
                          ) : (
                            ""
                          )}
                          {image64 == null && (
                            <>
                              <label>
                                Only these image types are accepted: x-png, png,
                                jpg, jpeg
                              </label>
                              <Dropzone
                                name="dropzone1"
                                onDrop={handleOnDropImage}
                                maxSize={1e7}
                                thumbnailWidth={200}
                                thumbnailHeight={200}
                              >
                                {({
                                  getRootProps,
                                  getInputProps,
                                  isDragActive,
                                }) => (
                                  <section>
                                    <div
                                      className="text-center"
                                      {...getRootProps()}
                                    >
                                      <input
                                        // ref={fileUploadInput}
                                        name="myFiles"
                                        {...getInputProps()}
                                      />
                                      <AccountBoxOutlined
                                        style={{ fontSize: 70 }}
                                      />
                                      {/* <img
                                        src={img}
                                        className="height_7v mtb-10"
                                        alt="Download"
                                      /> */}
                                      {isDragActive ? (
                                        <h5 className="mb_unset">
                                          Drop Image File Here!
                                        </h5>
                                      ) : (
                                        <h5 className="mb_unset">
                                          Please Upload Your Profile Picture
                                          <br /> (Size should be less than 2
                                          mbs){" "}
                                        </h5>
                                      )}
                                    </div>
                                  </section>
                                )}
                              </Dropzone>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="form-group mt-4">
                        <label className="mb-2 font-14">Full Name</label>
                        <input
                          type="text"
                          name="fullName"
                          value={form.name.toLowerCase()}
                          pattern="/^[a-zA-Z]+$/g"
                          className={`form-control form-control-sm disabled text-capitalize`}
                          disabled
                        />
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-4">
                          <label className="mb-2 font-14">Discipline</label>
                          <select
                            name="discipline"
                            className={`form-control form-control-sm disabled`}
                            disabled
                          >
                            <option value={form.discipline}>
                              {form.discipline}
                            </option>
                            {/* {store.allTopics.map((item) => (
                          <option value={item.id}>{item.topicName}</option>
                        ))} */}
                            disabled
                          </select>
                        </div>
                        <div className="form-group col-md-4">
                          <label className="mb-2 font-14">
                            Registration No
                          </label>

                          <input
                            type="number"
                            name="regNo"
                            value={form.regNo}
                            className={`form-control form-control-sm disabled `}
                            disabled
                          />
                        </div>
                        {/* Discipline end */}
                        {/* Sub-Discipline strt */}

                        <div className="form-group col-md-4">
                          <label className="mb-2 font-14">
                            Area Of Specialization{" "}
                            <span style={{ color: "#06580e" }}>*</span>
                          </label>
                          <select
                            ref={subDisciplineRef}
                            name="subDiscipline"
                            className={`form-control form-control-sm text-capitalize`}
                            onChange={selectChange}
                            value={form.subDiscipline}
                          >
                            <option disabled selected value="">
                              Select Area Of Specialization
                            </option>

                            {childs?.map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.name.toLowerCase()}
                              </option>
                            ))}
                          </select>
                        </div>
                        {/* Sub-Discipline end */}
                      </div>
                      {/* Date of Registration strt */}
                      <div className="form-group ">
                        <label className="mb-2 font-14">
                          Date of Registration
                        </label>
                        <input
                          type="text"
                          name="regDate"
                          value={form.dor}
                          className={`form-control form-control-sm disabled `}
                          disabled
                        />
                      </div>
                      {/* Date of Registration end */}
                      <div className="form-row">
                        {/* Gender Start */}
                        <div className="form-group col-md-4">
                          <label className="mb-2 font-14">Gender</label>
                          <select
                            name="gender"
                            className={`form-control form-control-sm disabled text-capitalize`}
                            disabled
                          >
                            <option value={form.gender}>{form.gender}</option>
                            disabled
                          </select>
                        </div>
                        {/* Gender end */}
                        {/* Nationality start */}
                        <div className="form-group col-md-4">
                          <label className="mb-2 font-14">Nationality</label>
                          <select
                            name="nationality"
                            className={`form-control form-control-sm disabled text-capitalize`}
                            disabled
                          >
                            <option value={form.nationality}>
                              {form.nationality}
                            </option>
                            disabled
                          </select>
                        </div>
                        {/* Nationality end */}
                        {/* Date of Birth start */}
                        <div className="form-group col-md-4">
                          <label className="mb-2 font-14">Date of Birth</label>
                          <input
                            type="text"
                            name="dob"
                            value={form.dob}
                            className={`form-control form-control-sm disabled `}
                            disabled
                          />
                        </div>
                      </div>
                      {/* Date of Birth End */}
                      {/* CNIC start */}
                      <div className="form-group">
                        <label className="mb-2 font-14">CNIC No.</label>

                        <input
                          type="text"
                          name="cnicNo"
                          value={form.cnic}
                          className={`form-control form-control-sm disabled `}
                          placeholder="xxxxxxxxxxxxx"
                          disabled
                        />
                      </div>
                      {/* CNIC end */}
                      <h5 className="mt-4 co_g20A84C font-weight-bold font-16 mb-4">
                        Disclaimer: Changes are only for this exam
                      </h5>
                      {/* Postal Address strt  */}
                      <div className="form-group">
                        <label className="mb-2 font-14">
                          Postal Address{" "}
                          <span style={{ color: "#06580e" }}>*</span>
                        </label>
                        <input
                          ref={postalAddressRef}
                          type="text"
                          value={form.address}
                          onChange={setChange}
                          name="address"
                          className={`form-control form-control-sm`}
                          // disabled
                        />
                      </div>
                      {/* Postal Address end  */}
                      {/* Phone no start */}
                      <div className="form-row">
                        {/* <div className="form-group col-md-4">
                          <label className="mb-2 font-14">
                            Alternate Contact
                          </label>
                          <input
                            ref={alternateRef}
                            type="text"
                            name="alternate"
                            pattern="\d*"
                            value={form.alternate}
                            onChange={setChange}
                            className={`form-control form-control-sm `}
                          />
                        </div> */}
                        <div className="form-group col-md-6">
                          <label className="mb-2 font-14">
                            Primary Contact{" "}
                            <span style={{ color: "#06580e" }}>*</span>
                          </label>
                          <input
                            ref={mobileNumberRef}
                            type="text"
                            name="mobile"
                            pattern="[0-9]{11}"
                            value={form.mobile}
                            onChange={setChange}
                            className={`form-control form-control-sm `}
                            // disabled
                          />
                        </div>
                        <div className="form-group col-md-6">
                          <label className="mb-2 font-14">
                            Alternate Contact (Optional)
                          </label>
                          <input
                            ref={telephoneNumberRef}
                            type="text"
                            name="alternateContact"
                            pattern="\d*"
                            value={form.alternateContact}
                            onChange={setChange}
                            className={`form-control form-control-sm `}
                          />
                        </div>{" "}
                        {/* Phone no end  */}
                        {/* Mobile no start */}
                        {/* Mobile no end */}
                      </div>
                      {/* Email Address start */}
                      <div className="form-group ">
                        <label className="mb-2 font-14">Email Address</label>
                        <input
                          ref={emailAddressRef}
                          type="email"
                          name="email"
                          value={form.email}
                          onChange={setChange}
                          className={`form-control form-control-sm `}
                          disabled
                        />
                      </div>
                      {/* Email Address end */}
                      {/* Add steps */}
                      {history.status === false ? (
                        <div>
                          <h5 className="co_g20A84C font-weight-bold font-16 mtb-35">
                            Step 2: History of Last EPE
                          </h5>
                          <p className="text-center">No EPE History</p>
                        </div>
                      ) : (
                        <div>
                          {/* History of Last EPE */}
                          <h5 className="co_g20A84C font-weight-bold font-16 mtb-35">
                            Step 2: History of Last EPE
                          </h5>
                          {/* Epe no start */}
                          <div className="form-group ">
                            <label className="mb-2 font-14">EPE No.</label>
                            <input
                              type="text"
                              name="epeNo"
                              value={form.epeNo}
                              className={`form-control form-control-sm disabled`}
                              disabled
                            />
                          </div>
                          {/* Epe no end */}
                          {/* Roll no start */}
                          <div className="form-group ">
                            <label className="mb-2 font-14">Roll No.</label>
                            <input
                              type="text"
                              name="rollNo"
                              value={form.rollNo}
                              className={`form-control form-control-sm disabled`}
                              disabled
                            />
                          </div>
                          {/* Roll no end */}
                          {/* Exam Center start */}
                          <div className="form-row">
                            <div className="form-group col-md-6">
                              <label className="mb-2 font-14">
                                Exam Center
                              </label>
                              <input
                                type="text"
                                name="examCenter"
                                value={form.examCenter}
                                className={`form-control form-control-sm disabled$`}
                                disabled
                              />
                            </div>
                            {/* Exam Center End */}
                            {/* Area of Specialization in last EPE start */}
                            <div className="form-group col-md-6">
                              <label className="mb-2 font-14">
                                Area of Specialization in last EPE
                              </label>
                              <select
                                name="epeSpecilization"
                                className={`form-control form-control-sm disabled`}
                              >
                                <option value={form.epeSpecilization}>
                                  {form.epeSpecilization}
                                </option>
                                disabled
                              </select>
                            </div>
                            {/* Area of Specialization in last EPE end */}
                          </div>
                          {/* No. of Attempts start */}
                          <div className="form-group">
                            <label className="mb-2 font-14">
                              No. of Attempts
                            </label>
                            <input
                              type="text"
                              name="noOfAttempt"
                              value={form.noOfAttempt}
                              className={`form-control form-control-sm disabled`}
                              disabled
                            />
                          </div>
                          {/* No. of Attempts end */}
                          {/* Results */}
                          <h6 className="text-#20A84C font-14">
                            Result of EPE
                          </h6>
                          {/* Part - 1 */}
                          <div className="form-row">
                            <div className="form-group col-md-6">
                              <label className="mb-2 font-14">Part - 1</label>
                              <br />
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input disabled"
                                  type="radio"
                                  name="QualifiedPart1"
                                  id="QualifiedPart1"
                                  value="QualifiedPart1"
                                  checked
                                  disabled
                                />
                                <label
                                  className="form-check-label pt-1"
                                  htmlFor="QualifiedPart1"
                                >
                                  Qualified
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input disabled"
                                  type="radio"
                                  name="notQualifiedPart1"
                                  id="notQualifiedPart1"
                                  value="NotQualifiedPart1"
                                  disabled
                                />
                                <label
                                  className="form-check-label pt-1"
                                  htmlFor="notQualifiedPart1"
                                >
                                  Not Qualified
                                </label>
                              </div>
                            </div>
                            <div className="form-group col-md-6">
                              <label className="mb-2 font-14">Part - 2</label>
                              <br />
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input disabled"
                                  type="radio"
                                  name="QualifiedPart2"
                                  id="QualifiedPart2"
                                  value="QualifiedPart2"
                                  checked
                                  disabled
                                />

                                <label
                                  className="form-check-label pt-1"
                                  htmlFor="QualifiedPart2"
                                >
                                  Qualified
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input disabled"
                                  type="radio"
                                  name="notQualifiedPart2"
                                  id="notQualifiedPart2"
                                  value="Not QualifiedPart2"
                                  disabled
                                />
                                <label
                                  className="form-check-label pt-1"
                                  htmlFor="notQualifiedPart2"
                                >
                                  Not Qualified
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {/* Part 1 end */}
                      {/* Part 2 start */}
                      {/* Part 2 end */}
                      {/* Dropdowns */}
                      {/* Priority */}
                      {/* button start */}
                      <div className="form-group">
                        <button
                          type="submit"
                          className="btn admin_btn_default btn-sm fontSize1Rem float-right"
                          id="submit"
                        >
                          Save
                        </button>
                      </div>{" "}
                      {/* button end */}
                    </form>
                    &nbsp;
                  </div>
                  <div className="col-xl-2 col-lg-2 col-md-2"></div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </React.Fragment>
  );
};

function mapStateToProps(state) {
  return {
    User: state.userData.user,
    userForm: state.userData.reduxForm,
  };
}

export default connect(mapStateToProps, {
  saveEpeFormRedux,
  getEpeHistory,
  getSubDiscipline,
  checkEpePoints,
})(EngineerEpeForm);
