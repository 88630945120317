import React, { createRef, Fragment, Component } from "react";
import Header from "../Header";
import Cookies from "universal-cookie";
import WebNotif from "../../components/WebNotif";
import moment from "moment";
import Dropzone from "react-dropzone";
import { EngineerPaymentPdf as routes } from "../../constants/routes";
import { urlapiEPE } from "../../config/urlapi";
import { connect } from "react-redux";
import { compose } from "redux";
import {
  saveEpeFormRedux,
  submitEpePayment,
  getCurrentExam,
  getPapersOfExam,
  checkEpePoints,
} from "../../redux/user/useraction";
import axios from "axios";
// import FormData from "form-data";
import fs from "fs";
import api from "../../api";
import Loader from "react-loader-spinner";
import Modal from "react-modal";
import "react-confirm-alert/src/react-confirm-alert.css";
import { confirmAlert } from "react-confirm-alert";
import img from "../../assets/image/designer_icon/Upload-files-here.png";
import jsPDF from "jspdf";
import Button from "@material-ui/core/Button";
import html2canvas from "html2canvas";
import Select from "react-select";
import {
  DateRangeOutlined,
  FilterTiltShift,
  ThreeSixtyTwoTone,
} from "@material-ui/icons";
import DatePicker from "react-datepicker";
import { withRouter } from "react-router-dom";

Modal.setAppElement("#root");
const acceptedImageFileTypes =
  "image/x-png, image/png, image/jpg, image/jpeg, image/gif, application/pdf";
const acceptedImageFileTypesArray = acceptedImageFileTypes
  .split(",")
  .map((item) => {
    return item.trim();
  });
const cookies = new Cookies();
const newarray = [];
// import $ from 'jquery';
var obj = new WebNotif();

class EngineerPayment extends Component {
  constructor(props) {
    super(props);

    this.paymentMethod = createRef();
    this.applicantType = createRef();
    this.bankInput1 = createRef();
    this.bankInput2 = createRef();
    this.totalAmount = createRef();
    this.challanInput = createRef();
    this.fileUploadInput = createRef();
    this.submissionDateRef = createRef();
    this.paperPartRef = createRef();
    this.paperTypeRef = createRef();

    // this.minDate = moment(new Date("01/01/2022")).format('mm/dd/yyyy');
    // this.maxDate = moment(new Date().format('mm/dd/yyyy'));
  }

  state = {
    pageLoader: false,
    reduxEpeForm: JSON.parse(localStorage.getItem("reduxEpeForm")),
    comp: false,
    loading: false,
    selectedOption: "",
    modalIsOpen2: false,
    image64: null,
    imageFile: null,
    userID: this.props.user.PEC_REG_NO,
    userEmail: this.props.user.PrimaryEmail,
    checkEpePointsID: this.props.user.UserID,
    currentFileTypeofData: "",
    currentFileNameofData: "",
    // paperId: ,
    examId: "",
    examName: "",
    conductDate: "",
    netcourseprice: "0",
    refno: "",
    bank: "",
    bankName: "",
    selectedbankoption: { value: 0, label: "Select Payment Method" },
    selectedApplicantOption: { value: 0, label: "Select Applicant Type" },
    selectedPaperPart: { value: 0, label: "Select Attempt Number" },
    selectedPaperType: { value: 0, label: "Select Paper Type" },
    bankoption: [
      { value: 1, label: "Bank Challan Payment" },
      // { value: 2, label: "Direct Bank Payment" },
    ],
    applicantOptions: [
      { value: 1, label: "New Candidates" },
      { value: 2, label: "Re-Appearing In Single Part" },
    ],
    paperPartOptions: [
      { value: 1, label: "First Attempt" },
      { value: 2, label: "Second Attempt" },
      { value: 3, label: "Third Attempt" },
    ],
    paperTypeOptions: [
      { value: 1, label: "Part I" },
      { value: 2, label: "Part II" },
    ],
    submissionDate: "",
    examData: null,
    examYear: "",
    examSeason: "",
    updateFormState: localStorage.getItem("updateApplication"),
  };

  componentDidMount() {
    const examConductDate = localStorage.getItem("getLatestExamConductDate");
    const examName = localStorage.getItem("getLatestExamName");

    let isSpring = false;

    if (examName.includes("spring" || "Spring")) {
      isSpring = true;
    } else {
      isSpring = false;
    }

    const examArr = examConductDate.split("-");
    if (examConductDate) {
      this.setState({
        examYear: examArr[0],
        examSeason: isSpring ? "spring" : "fall",
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.selectedApplicantOption.value !==
      prevState.selectedApplicantOption.value
    ) {
      if (this.state.selectedApplicantOption.value === 1) {
        this.setState({ netcourseprice: "5000" });
      } else if (this.state.selectedApplicantOption.value === 2) {
        this.setState({ netcourseprice: "2500" });
      } else if (this.state.selectedApplicantOption.value === 0) {
        this.setState({ netcourseprice: "0" });
      }
    }
  }

  removeImage = () => {
    this.setState({ image64: null }, () => {});
  };

  delete = () => {
    this.setState({ image64: null }, () => {});
  };

  handletext = (event) => {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
    });
  };
  handleChallanAmount = (event) => {
    this.setState({
      ...this.state,
      // netcourseprice: event.target.value,
    });
  };

  bankhandler = (selectedOption) => {
    this.setState({
      bank: "HBL",
      selectedbankoption: selectedOption,
    });
  };

  applicantHandler = (selectedOption) => {
    this.setState({
      selectedApplicantOption: selectedOption,
    });
  };

  paperPartHandler = (selectedOption) => {
    console.log("paperpart........", selectedOption);
    this.setState({
      selectedPaperPart: selectedOption,
    });
  };

  paperTypeHandler = (selectedOption) => {
    this.setState({
      selectedPaperType: selectedOption,
    });
  };

  closeModal2 = () => {
    this.setState({
      modalIsOpen2: false,
      isEditable: false,
    });
  };
  verifyImageFile = (files) => {
    if (files && files.length > 0) {
      const currentFile = files[0];
      const currentFileType = currentFile.type;
      const currentFileSize = currentFile.size;
      if (currentFileSize > 2e6) {
        obj.createNotification(
          "error",
          "File should be less than 2mbs, Try Again"
        );
        return false;
      }
      if (!acceptedImageFileTypesArray.includes(currentFileType)) {
        obj.createNotification(
          "error",
          "This file type is not allowed, Images and PDF are allowed"
        );
        return false;
      }
      return true;
    }
  };

  handleOnDropImage = (files, rejectedFiles) => {
    if (rejectedFiles && rejectedFiles.length > 0) {
      this.verifyImageFile(rejectedFiles);
    }
    if (files && files.length > 0) {
      if (this.verifyImageFile(files)) {
        const currentFile = files[0];
        this.setState({ imageFile: currentFile });
        const reader = new FileReader();
        // this.setState({image64: reader.result});
        reader.addEventListener(
          "load",
          () => {
            this.setState({ image64: reader.result });
          },
          false
        );
        reader.readAsDataURL(currentFile);

        obj.createNotification("success", "File Dropped");

        this.setState({
          currentFileTypeofData: currentFile.type,
          currentFileNameofData: currentFile.name,
        });
      }
    }
  };

  handleOptionChange = (changeEvent) => {
    console.log("handleOptionChange ... ", changeEvent.target.value);
    this.setState({
      bank: changeEvent.target.value,
      selectedOption: "Bank Transfer",
    });
  };
  submitApplication = () => {
    console.log("INSIDE submitApplication");
    return confirmAlert({
      customUI: ({ onClose }) => (
        <div className="custom-payment-modal">
          <h2>Undertaking!</h2>
          <ul>
            <li>
              I wish to take the EPE for {this.state.examSeason} year{" "}
              {this.state.examYear}
            </li>
            <li>
              I have no objection against my EPE process being stopped, should
              there be inaccuracy in the submitted information.
            </li>
            <li>
              I also undertake to abide by the PEC Act, Bye-Laws, relevant rules
              and Codes of Ethics and Conduct, prescribed for the members of the
              Council.
            </li>
            <li>
              I certify that the above particulars mentioned by me are true and
              correct.
            </li>
          </ul>
          <div className="custom-payment-buttons">
            <button
              className="btn btn-sm btn-success"
              onClick={async () => {
                try {
                  onClose();
                  const examId = localStorage.getItem("getLatestExamId");

                  const reduxEpeForm = JSON.parse(
                    localStorage.getItem("reduxEpeForm")
                  );

                  this.setState({ pageLoader: true });

                  let bankNameField =
                    this.state.selectedbankoption.value === 1
                      ? this.state.bank
                      : this.state.selectedbankoption.value === 2
                      ? this.state.bankName
                      : "";

                  const application = {
                    applicationType: this.state.selectedApplicantOption,
                    userProfile: reduxEpeForm?.form?.userProfile,
                    pecNo: reduxEpeForm?.form?.regNo,
                    cnic: reduxEpeForm?.form?.cnic,
                    examId: examId,
                    fullName: reduxEpeForm?.form?.name,
                    regNo: reduxEpeForm?.form?.regNo,
                    discipline: reduxEpeForm?.form.discipline,
                    subDiscipline: reduxEpeForm?.form?.subDiscipline,
                    alliedDiscipline: reduxEpeForm?.form?.alliedDiscipline,
                    registrationDate: reduxEpeForm?.form?.registrationDate,
                    postalAddress: reduxEpeForm?.form?.postalAddress,
                    alternateContact: reduxEpeForm?.form?.alternateContact,
                    mobile: reduxEpeForm?.form?.mobile,
                    email: reduxEpeForm?.form?.email,
                    nationality: reduxEpeForm?.form?.nationality,
                    DOB: reduxEpeForm?.form?.DOB,
                    centerPrority_1: reduxEpeForm.form.centerPrority_1,
                    centerPrority_2: reduxEpeForm.form.centerPrority_2,
                    centerPrority_3: reduxEpeForm.form.centerPrority_3,
                    paper_count: reduxEpeForm?.form?.paper_count,
                    historyExist: reduxEpeForm?.form?.historyExist,
                    cpdPointsData: reduxEpeForm?.form?.cpdPointsData,
                    rejectionReason: "",
                    paymentImage: this.state.image64,
                    transactionType: this.state.selectedbankoption?.label,
                    bankName: bankNameField,
                    challanNumber: this.state.refno,
                    challanAmount: this.state.netcourseprice,
                    submissionDate: this.state.submissionDate,
                    applicantType: this.state.selectedApplicantOption.label,
                    singlePaperPart:
                      this.state.selectedPaperType.value === 0
                        ? ""
                        : this.state.selectedPaperType.label,
                    selectedPaperAttempt:
                      this.state.selectedPaperPart.value === 0
                        ? ""
                        : this.state.selectedPaperPart.label,
                  };
                  const response = await axios.post(
                    `${urlapiEPE}/applicant`,
                    application
                  );
                  if (response.status === 200) {
                    this.setState({ pageLoader: false });
                    obj.createNotification(
                      "success",
                      `${response?.data?.message}`
                    );
                    localStorage.removeItem("reduxEpeForm");
                    this.props.history.push("/cpd/CpdFormProfile");
                  } else if (response.status === 400) {
                    this.setState({ pageLoader: false });
                    obj.createNotification("error", `${response?.message}`);
                    localStorage.removeItem("reduxEpeForm");
                    this.props.history.push("/cpd/CpdFormProfile");
                  } else {
                    setLoad(this.setState({ pageLoader: false }));
                  }
                } catch (err) {
                  this.setState({ pageLoader: false });

                  obj.createNotification(
                    "error",
                    err?.response?.data?.message
                      ? err?.response?.data?.message
                      : "Something went wrong"
                  );
                }
              }}
            >
              Accept
            </button>
            <button className="btn btn-sm btn-dark" onClick={onClose}>
              Close
            </button>
          </div>
        </div>
      ),
    });
  };

  updateApplication = () => {
    console.log("INSIDE updateApplication");
    return confirmAlert({
      customUI: ({ onClose }) => (
        <div className="custom-payment-modal">
          <h2>Undertaking!</h2>
          <ul>
            <li>
              I wish to take the EPE for {this.state.examSeason} year{" "}
              {this.state.examYear}
            </li>
            <li>
              I have no objection against my EPE process being stopped, should
              there be inaccuracy in the submitted information.
            </li>
            <li>
              I also undertake to abide by the PEC Act, Bye-Laws, relevant rules
              and Codes of Ethics and Conduct, prescribed for the members of the
              Council.
            </li>
            <li>
              I certify that the above particulars mentioned by me are true and
              correct.
            </li>
          </ul>
          <div className="custom-payment-buttons">
            <button
              className="btn btn-sm btn-success"
              onClick={async () => {
                try {
                  onClose();
                  const examId = localStorage.getItem("getLatestExamId");

                  const reduxEpeForm = JSON.parse(
                    localStorage.getItem("reduxEpeForm")
                  );

                  this.setState({ pageLoader: true });

                  let bankNameField =
                    this.state.selectedbankoption.value === 1
                      ? this.state.bank
                      : this.state.selectedbankoption.value === 2
                      ? this.state.bankName
                      : "";

                  const application = {
                    userProfile: reduxEpeForm?.form?.userProfile,
                    pecNo: reduxEpeForm?.form?.regNo,
                    cnic: reduxEpeForm?.form?.cnic,
                    examId: examId,
                    fullName: reduxEpeForm?.form?.name,
                    regNo: reduxEpeForm?.form?.regNo,
                    discipline: reduxEpeForm?.form.discipline,
                    alliedDiscipline: reduxEpeForm?.form.alliedDiscipline,
                    subDiscipline: reduxEpeForm?.form?.subDiscipline,
                    registrationDate: reduxEpeForm?.form?.registrationDate,
                    postalAddress: reduxEpeForm?.form?.postalAddress,
                    alternateContact: reduxEpeForm?.form?.alternateContact,
                    mobile: reduxEpeForm?.form?.mobile,
                    email: reduxEpeForm?.form?.email,
                    nationality: reduxEpeForm?.form?.nationality,
                    DOB: reduxEpeForm?.form?.DOB,
                    centerPrority_1: reduxEpeForm.form.centerPrority_1,
                    centerPrority_2: reduxEpeForm.form.centerPrority_2,
                    centerPrority_3: reduxEpeForm.form.centerPrority_3,
                    paper_count: reduxEpeForm?.form?.paper_count,
                    historyExist: reduxEpeForm?.form?.historyExist,
                    cpdPointsData: reduxEpeForm?.form?.cpdPointsData,
                    rejectionReason: "",
                  };

                  const rejectedApplication =
                    localStorage.getItem("application");
                  if (!!rejectedApplication) {
                    const parsedApplication = JSON.parse(rejectedApplication);
                    application["applicantId"] = parsedApplication.applicant_id;
                  }
                  const response = await axios.put(
                    `${urlapiEPE}/applicant/update-application`,
                    application
                  );
                  if (response.status === 200) {
                    this.setState({ pageLoader: false });
                    obj.createNotification(
                      "success",
                      `${response?.data?.message}`
                    );
                    localStorage.removeItem("reduxEpeForm");
                    localStorage.removeItem("application");
                    this.props.history.push("/cpd/CpdFormProfile");
                  } else if (response.status === 400) {
                    this.setState({ pageLoader: false });
                    obj.createNotification("error", `${response?.message}`);
                    localStorage.removeItem("reduxEpeForm");
                    localStorage.removeItem("application");
                    this.props.history.push("/cpd/CpdFormProfile");
                  } else {
                    setLoad(this.setState({ pageLoader: false }));
                  }
                } catch (err) {
                  this.setState({ pageLoader: false });

                  obj.createNotification(
                    "error",
                    err?.response?.data?.message
                      ? err?.response?.data?.message
                      : "Something went wrong"
                  );
                }
              }}
            >
              Accept
            </button>
            <button className="btn btn-sm btn-dark" onClick={onClose}>
              Close
            </button>
          </div>
        </div>
      ),
    });
  };

  checkout = () => {
    this.setState({ loading: true });

    let errorMessages;
    let formFields;

    if (this.state.updateFormState === "false") {
      if (this.state.selectedApplicantOption.value === 0) {
        this.setState({ loading: false });
        this.applicantType.current.focus();
        return obj.createNotification(
          "error",
          "Choose Applicant Type Is Required"
        );
      }

      if (this.state.selectedbankoption.value === 0) {
        this.setState({ loading: false });
        this.paymentMethod.current.focus();
        return obj.createNotification("error", "Payment Method Is Required");
      }

      if (this.state.selectedApplicantOption.value === 1) {
        errorMessages = {
          submissionDate: "Challan submission date is required",
          challanInput:
            this.state.selectedbankoption.value === 1
              ? "Challan Number Is Required"
              : "Bank Name Is Required",
        };

        formFields = {
          submissionDate: {
            value: this.state.submissionDate,
            ref: this.submissionDateRef,
          },
          challanInput: {
            value:
              this.state.selectedbankoption.value === 1
                ? this.state.refno
                : this.state.bankName,
            ref: this.challanInput,
          },
        };
      } else if (this.state.selectedApplicantOption.value === 2) {
        errorMessages = {
          attemptNumber: "Attempt Number Is Required",
          paperType: "Paper Type Is Required",
          bankInput2: "Bank Option Is Required",
          challanInput:
            this.state.selectedbankoption.value === 1
              ? "Challan Number Is Required"
              : "Bank Name Is Required",
          submissionDate: "Challan Submission Date Is Required",
        };

        formFields = {
          attemptNumber: {
            value: this.state.selectedPaperPart,
            ref: this.paperPartRef,
          },
          paperType: {
            value: this.state.selectedPaperType,
            ref: this.paperTypeRef,
          },
          bankInput2: { value: this.state.bank, ref: this.bankInput2 },
          challanInput: {
            value:
              this.state.selectedbankoption.value === 1
                ? this.state.refno
                : this.state.bankName,
            ref: this.challanInput,
          },
          submissionDate: {
            value: this.state.submissionDate,
            ref: this.submissionDateRef,
          },
        };

        if (this.state.selectedPaperPart.value === 0) {
          this.setState({ loading: false });
          this.paperPartRef.current.focus();
          return obj.createNotification(
            "error",
            "Choose Attempt Number Is Required"
          );
        }

        if (this.state.selectedPaperType.value === 0) {
          this.setState({ loading: false });
          this.paperTypeRef.current.focus();
          return obj.createNotification(
            "error",
            "Choose Paper Part Is Required"
          );
        }
      }

      // Check if form fields are empty
      for (const field in formFields) {
        if (
          !formFields[field].value ||
          formFields[field].value === "0" ||
          formFields[field].value === "" ||
          formFields[field].value === 0
        ) {
          obj.createNotification("error", errorMessages[field]);
          if (field === "challanInput") {
            formFields[field].ref.current.style.border = "3px solid #20A84C";
          }
          formFields[field].ref.current.focus();
          this.setState({ loading: false });
          return;
        }
      }

      if (this.state.image64 === null) {
        return obj.createNotification("error", "Challan image is required");
      }

      try {
        this.submitApplication();
      } catch (err) {
        console.log("Error: ", err);
        return obj.createNotification("error", "Something went wrong");
      }
    } else {
      try {
        this.updateApplication();
      } catch (err) {
        console.log("Error: ", err);
        return obj.createNotification("error", "Something went wrong");
      }
    }
  };

  handlePDF = (account, bank) => {
    if (this.state.netcourseprice === "0") {
      this.applicantType.current.focus();
      obj.createNotification(
        "error",
        "Total Amount Is Required, Select Applicant Type"
      );
    } else {
      const examName = localStorage.getItem("getLatestExamName");
      const examConductDate = localStorage.getItem("getLatestExamConductDate");

      newarray.push({ PaperName: this.state.examName });
      newarray.push({ price: this.state.netcourseprice });
      newarray.push({ Date: this.state.conductDate });
      newarray.push({ Name: this.props.user.FullName });
      newarray.push({ RegNo: this.props.user.PEC_REG_NO });
      newarray.push({ account: account });
      newarray.push({ discipline: this.props.user.discipline });
      newarray.push({ bank: bank });
      newarray.push({ examName: examName });
      newarray.push({ examConductDate: examConductDate });

      // Check if the "epe-payment" cookie already exists, and remove it if it does
      if (cookies.get("epe-payment")) {
        cookies.remove("epe-payment");
      }

      cookies.set("epe-payment", JSON.stringify(newarray));
      window.open( routes, "_blank");
      newarray.length = 0;
    }
  };

  handleSubmissionDate = (e) => {
    this.setState({ ...this.state, submissionDate: e.target.value });
  };

  render() {
    return (
      <>
        {this.state.pageLoader ? (
          <Fragment>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "80vh",
              }}
            >
              <Loader type="Oval" color="#06580e" height={80} width={80} />
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <Header />
            <div className=" mtb-25 payment-container">
              <h3 className="text-center ptb-30 bold payment_heading">
                EPE Application
              </h3>
              {/* <p className="text-center font-weight-bold">
              Please fill your details below
            </p> */}
              <h5 className="text-center mt-3 co_g20A84C font-weight-bold font-16 mb-4">
                Step 4: Fee Challan
              </h5>
              <div>
                {this.state.updateFormState === "true" ? (
                  <div className="row mtb-15">
                    <div className="col-md-12 d-flex align-items-center justify-content-center">
                      <div className="approved-payment-container text-center">
                        <b style={{ textAlign: "center", color: "#06580e" }}>
                          Note: Your payment has already been approved, please
                          submit your application
                        </b>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <div>
                      <div
                        className="container"
                        style={{ textAlign: "center" }}
                      >
                        {/* <br /> */}
                        <div className="row mtb-15">
                          <div className="col-md-1"></div>
                          <div className="col-md-4">
                            <label
                              style={{
                                fontWeight: "bold",
                                marginTop: "5px",
                                marginLeft: "55px",
                              }}
                            >
                              Choose Applicant Type{" "}
                              <span style={{ color: "#06580e" }}>*</span>
                            </label>
                          </div>
                          <div className="col-md-4">
                            <Select
                              ref={this.applicantType}
                              options={this.state.applicantOptions}
                              value={this.state.selectedApplicantOption}
                              onChange={this.applicantHandler}
                            />
                          </div>
                          <div className="col-md-3"></div>
                        </div>
                        <b style={{ textAlign: "center", color: "#06580e" }}>
                          Note: Rs. 5000/- for new candidates and Rs. 2500/- for
                          reappearing candidates in single part
                        </b>
                      </div>

                      {this.state.selectedApplicantOption?.value === 2 && (
                        <div
                          className="container"
                          style={{ textAlign: "center", marginTop: "55px" }}
                        >
                          <div className="row mtb-15">
                            <div className="col-md-1"></div>
                            <div className="col-md-4">
                              <label
                                style={{
                                  fontWeight: "bold",
                                  marginTop: "5px",
                                  marginLeft: "55px",
                                }}
                              >
                                Choose Attempt Number{" "}
                                <span style={{ color: "#06580e" }}>*</span>
                              </label>
                            </div>
                            <div className="col-md-4">
                              <Select
                                ref={this.paperPartRef}
                                options={this.state.paperPartOptions}
                                value={this.state.selectedPaperPart}
                                onChange={this.paperPartHandler}
                              />
                            </div>
                            <div className="col-md-3"></div>
                          </div>
                        </div>
                      )}

                      {this.state.selectedApplicantOption?.value === 2 && (
                        <div
                          className="container"
                          style={{ textAlign: "center", marginTop: "55px" }}
                        >
                          <div className="row mtb-15">
                            <div className="col-md-1"></div>
                            <div className="col-md-4">
                              <label
                                style={{
                                  fontWeight: "bold",
                                  marginTop: "5px",
                                  marginLeft: "55px",
                                }}
                              >
                                Choose Paper Part{" "}
                                <span style={{ color: "#06580e" }}>*</span>
                              </label>
                            </div>
                            <div className="col-md-4">
                              <Select
                                ref={this.paperTypeRef}
                                options={this.state.paperTypeOptions}
                                value={this.state.selectedPaperType}
                                onChange={this.paperTypeHandler}
                              />
                            </div>
                            <div className="col-md-3"></div>
                          </div>
                        </div>
                      )}

                      <div
                        className="container"
                        style={{ textAlign: "center", marginTop: "55px" }}
                      >
                        <div className="row mtb-15">
                          <div className="col-md-1"></div>
                          <div className="col-md-4">
                            <label
                              style={{
                                fontWeight: "bold",
                                marginTop: "5px",
                                marginLeft: "55px",
                              }}
                            >
                              Choose Payment Method{" "}
                              <span style={{ color: "#06580e" }}>*</span>
                            </label>
                          </div>
                          <div className="col-md-4">
                            <Select
                              ref={this.paymentMethod}
                              options={this.state.bankoption}
                              value={this.state.selectedbankoption}
                              onChange={this.bankhandler}
                            />
                          </div>
                          <div className="col-md-3"></div>
                        </div>
                        <b style={{ textAlign: "center", color: "#06580e" }}>
                          Note: Only payments made via HBL will be processed
                        </b>
                        {this.state.selectedbankoption?.value === 1 ||
                        this.state.selectedbankoption?.value === 2 ? (
                          <div className="row mtb-15">
                            <div className="col-sm"></div>
                            <div
                              style={{ textAlign: "end" }}
                              className="col-sm"
                            ></div>
                            {/* <div style={{ textAlign: "end" }} className="col-sm">
                          <label>
                            <input
                              ref={this.bankInput1}
                              type="radio"
                              name="react-tips"
                              value="MCB"
                              checked={this.state.bank === "MCB"}
                              onChange={this.handleOptionChange}
                              className="form-check-input"
                            />
                            MCB
                          </label>
                        </div> */}
                            <div
                              className="col-sm"
                              style={{ textAlign: "left" }}
                            >
                              <label>
                                <input
                                  ref={this.bankInput2}
                                  type="radio"
                                  name="react-tips"
                                  value="HBL"
                                  checked={this.state.bank === "HBL"}
                                  onChange={this.handleOptionChange}
                                  className="form-check-input"
                                />
                                HBL
                              </label>
                            </div>
                            <div className="col-sm"></div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="container mt-40">
                      {(this.state.bank == "MCB" &&
                        this.state.selectedbankoption?.value === 1) ||
                        (this.state.selectedbankoption?.value === 2 && (
                          <div className="row col_margin mtb-20 text_center_mob detailbox">
                            <div className="col-md-6 bold"> Bank Details </div>
                            <div className="col-md-6 text-right"> MCB TBD </div>
                            <div className="col-md-6 bold"> Account Title </div>
                            <div className="col-md-6 text-right">
                              {" "}
                              Pakistan Engineering Council Islamabad{" "}
                            </div>
                            <div className="col-md-6 bold"> Ac/No </div>
                            <div className="col-md-6 text-right">
                              {" "}
                              0685583041005497{" "}
                            </div>
                            {/* <div className="col-md-6 bold"> Swift Code </div>
                        <div className="col-md-6 text-right"> </div> */}
                            {/* <div className="col-md-6 bold"> Download PDF </div>
                        <div className="col-md-6 text-right">
                          <button
                            type="button"
                            onClick={() =>
                              this.handlePDF("0685583041005497", "MCB")
                            }
                            className="btn btn-success"
                          >
                            Generate Bank Deposit Slip
                          </button>
                        </div> */}
                          </div>
                        ))}
                      {this.state.bank == "HBL" &&
                        this.state.selectedbankoption?.value === 1 && (
                          <div className="row col_margin mtb-20 text_center_mob detailbox">
                            <div className="col-md-6 bold"> Bank Details </div>
                            <div className="col-md-6 text-right"> HBL </div>
                            <div className="col-md-6 bold"> Account Title </div>
                            <div className="col-md-6 text-right">
                              {" "}
                              Pakistan Engineering Council
                            </div>
                            <div className="col-md-6 bold"> Ac/No </div>
                            <div className="col-md-6 text-right">
                              {" "}
                              00427901578603{" "}
                            </div>
                            {/* <div className="col-md-6 bold"> Swift Code </div>
                        <div className="col-md-6 text-right"> </div> */}
                            {/* <div className="col-md-6 bold"> Download PDF </div>
                        <div className="col-md-6 text-right">
                          <button
                            type="button"
                            onClick={() =>
                              this.handlePDF("00427901578603", "HBL")
                            }
                            class="btn btn-success"
                          >
                            Generate Bank Deposit Slip
                          </button>
                        </div> */}
                          </div>
                        )}
                      {this.state.selectedOption == "JazzCash" && (
                        <div className="row col_margin mtb-20 text_center_mob  detailbox">
                          <div className="col-md-6 bold"> Account Detail </div>
                          <div className="col-md-6 text-right"> 12312321 </div>
                        </div>
                      )}
                    </div>

                    <div className="container" style={{ textAlign: "center" }}>
                      <p style={{ fontWeight: "bold" }}>Challan Information</p>
                    </div>
                    <div
                      className="container mt-2"
                      style={{ textAlign: "center" }}
                    >
                      <div className="row mtb-15">
                        <div className="col-md-2">
                          <label
                            style={{
                              fontWeight: "bold",
                              marginTop: "3px",
                              marginLeft: "55px",
                            }}
                          >
                            Total Amount{" "}
                            <span
                              style={{ fontWeight: "bold", color: "#06580e" }}
                            >
                              *
                            </span>
                          </label>
                        </div>
                        <div className="col-md-1">
                          <label
                            style={{
                              borderRadius: "5px",
                              fontSize: "1.24rem",
                              fontWeight: "700",
                              marginTop: "3px",
                              color: "#06580e",
                            }}
                          >
                            Rs. {this.state.netcourseprice}
                          </label>
                        </div>
                        <div className="col-md-2">
                          <label
                            style={{
                              fontWeight: "bold",
                              marginTop: "5px",
                              marginLeft: "55px",
                            }}
                          >
                            {this.state.selectedbankoption.value === 1
                              ? "Enter Challan No"
                              : this.state.selectedbankoption.value === 2
                              ? "Bank Name"
                              : "Choose Payment"}{" "}
                            <span style={{ color: "#06580e" }}>*</span>
                          </label>
                        </div>
                        <div className="col-md-2">
                          {this.state.selectedbankoption.value === 1 ? (
                            <>
                              <input
                                id="challanInputId"
                                onKeyPress={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                                ref={this.challanInput}
                                type="text"
                                name="refno"
                                value={this.state.refno}
                                // placeholder={`00270520000453798`}
                                placeholder="Enter Challan No"
                                min="0"
                                className="form-control"
                                onChange={this.handletext}
                                required
                              />
                            </>
                          ) : this.state.selectedbankoption.value === 2 ? (
                            <>
                              <input
                                id="bankInputWidth"
                                ref={this.challanInput}
                                type="text"
                                name="bankName"
                                value={this.state.bankName}
                                placeholder="Enter Your Bank Name"
                                className="form-control"
                                onChange={this.handletext}
                                required
                              />
                            </>
                          ) : (
                            <input
                              // ref={this.challanInput}
                              type=""
                              name=""
                              value=""
                              placeholder="Choose Payment Method"
                              className="form-control"
                              // onChange={this.handletext}
                              // required
                            />
                          )}
                        </div>
                        <div className="col-md-2">
                          <label
                            style={{
                              fontWeight: "bold",
                              marginTop: "5px",
                              marginLeft: "40px",
                            }}
                          >
                            Date of Submisson{" "}
                            <span style={{ color: "#06580e" }}>*</span>
                          </label>
                        </div>
                        <div className="col-md-2">
                          <input
                            id="bankInputDate"
                            type="date"
                            className="form-control"
                            ref={this.submissionDateRef}
                            value={this.state.submissionDate}
                            onChange={this.handleSubmissionDate}
                            min={moment(new Date("01/01/2020")).format(
                              "YYYY-MM-DD"
                            )}
                            max={moment(new Date()).format("YYYY-MM-DD")}
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      className="container mt-40"
                      style={{ textAlign: "center" }}
                    >
                      <b style={{ textAlign: "center" }}>
                        Upload your payment slip
                      </b>
                    </div>

                    <div className="container mt-40">
                      <div className="col-md-6 offset-md-3 file_upload_admin ptb_4v ">
                        {this.state.image64 !== null &&
                        this.state.currentFileTypeofData.match("image.*") ? (
                          <>
                            <div
                              onClick={this.removeImage}
                              className="pointer"
                              style={{
                                display: "block",
                                marginLeft: "auto",
                                marginRight: "auto",
                                // textAlign: 'center',
                                // paddingLeft: "50px",
                                // marginLeft: "10px",
                                color: "#20A84C",
                                height: "300px",
                                width: "300px",
                              }}
                            >
                              <img
                                className=""
                                style={{
                                  height: "100%",
                                  width: "100%",
                                  objectFit: "cover",
                                }}
                                src={this.state.image64}
                              />
                              <i
                                className="fa fa-times-circle preview-close-icon fa-lg"
                                style={{ color: "#20A84C" }}
                              />
                            </div>
                            <p style={{ marginTop: "10px" }}>
                              {this.state.currentFileNameofData}
                            </p>
                          </>
                        ) : this.state.image64 !== null &&
                          this.state.currentFileTypeofData ===
                            "application/pdf" ? (
                          <>
                            <div onClick={this.removeImage} className="pointer">
                              <i className="far fa-file-pdf fa-3x pb-10" />
                              <i
                                className="fa fa-times-circle preview-close-icon fa-lg"
                                style={{ color: "#20A84C" }}
                              />
                            </div>
                            <p style={{ marginTop: "10px" }}>
                              {this.state.currentFileNameofData}
                            </p>
                          </>
                        ) : (
                          ""
                        )}
                        {this.state.image64 == null && (
                          <>
                            <label>
                              Only these file types are accepted: x-png, png,
                              jpg, jpeg, gif, pdf
                            </label>
                            <Dropzone
                              name="dropzone1"
                              onDrop={this.handleOnDropImage}
                              maxSize={1e7}
                              thumbnailWidth={300}
                              thumbnailHeight={300}
                            >
                              {({
                                getRootProps,
                                getInputProps,
                                isDragActive,
                              }) => (
                                <section>
                                  <div
                                    className="text-center"
                                    {...getRootProps()}
                                  >
                                    <input
                                      ref={this.fileUploadInput}
                                      name="myFiles"
                                      {...getInputProps()}
                                    />
                                    <img
                                      src={img}
                                      className="height_7v mtb-10"
                                      alt="Download"
                                    />
                                    {isDragActive ? (
                                      <h5 className="mb_unset">
                                        Drop Image/PDF File Here!
                                      </h5>
                                    ) : (
                                      <h5 className="mb_unset">
                                        Take a screenshot of the transaction /
                                        deposit slip and drop here
                                        <br /> (Size should be less than 2 mbs){" "}
                                      </h5>
                                    )}
                                  </div>
                                </section>
                              )}
                            </Dropzone>
                          </>
                        )}
                      </div>
                    </div>
                  </>
                )}

                <div className="row col_margin mt-40">
                  <div className="col-md-3"></div>
                  <div className="col-md-6 text-center">
                    <button
                      type="button"
                      className="btn btn-success bold  btn-checkout text-capitalize"
                      onClick={this.checkout}
                    >
                      Submit Application
                    </button>
                  </div>
                  <div className="col-md-3"></div>
                </div>
              </div>
            </div>
          </Fragment>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.userData.user,
    userForm: state.userData.reduxForm,
  };
}

// export default connect(mapStateToProps, {
//   saveEpeFormRedux,
//   submitEpePayment,
//   getCurrentExam,
//   getPapersOfExam,
// })(EngineerPayment);

export default compose(
  withRouter,
  connect(mapStateToProps, {
    saveEpeFormRedux,
    submitEpePayment,
    getCurrentExam,
    getPapersOfExam,
    checkEpePoints,
  })
)(EngineerPayment);
