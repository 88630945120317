import React, { Component } from "react";
import "../assets/css/main.css";
import Header from "./Header";
import axios from "axios";
import $ from "jquery";
import Loader from "react-loader-spinner";
import { Link } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Moment from "react-moment";
import { connect } from "react-redux";
import { urlapi, urlImageUrl } from "../config/urlapi";
import WebNotif from "../components/WebNotif";
import news1 from "../assets/image/PEC Photos/covid19.png";
import news2 from "../assets/image/PEC Photos/lockdown-business-continuity.png";
import news3 from "../assets/image/PEC Photos/leadership-lecture.png";
import news4 from "../assets/image/PEC Photos/corona-safety.png";
import news5 from "../assets/image/PEC Photos/newsletter.png";
import news6 from "../assets/image/PEC Photos/self-learning-webinar.png";
import news7 from "../assets/image/PEC Photos/Results-2.png";
import news8 from "../assets/image/PEC Photos/CPD.png";
import news9 from "../assets/image/PEC Photos/PEC.jpg";
import news10 from "../assets/image/PEC Photos/youtube-channel.jpg";
import news11 from "../assets/image/PEC Photos/Power.jpg";
import news12 from "../assets/image/PEC Photos/leadership.png";
import peclogo from "../assets/image/logo/Group 6827.png";
import moment from "moment";
import { getAllPublishedWebinars } from "../redux/admin/webinar/webinarActions";
import ReactTooltip from "react-tooltip";
import Pdf from "../assets/pdf/15th_EPE_Result_2.pdf";
import Select from "react-select";
import img1 from "../assets/image/photo-1528731708534-816fe59f90cb.jpg";
import img2 from "../assets/image/photo-1528731708534-816fe59f90cb.jpg";
import img3 from "../assets/image/photo-1528731708534-816fe59f90cb.jpg";
import epeimg from "../assets/image/17thEPEAd.91a59e02.jpg";
var obj = new WebNotif();
let allWebinars = [];
let pic = "";
class Home extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      Email: "",
      Name: "",
      Subject: "",
      Message: "",
      idchange: "none",
      sub_input: "",
      slider: {},
      sub1_input: "",
      Phone: "",
      to: {
        value: "cpd@pec.org.pk",
        label: "Continuing Professional Development (CPD)",
      },
      options: [
        {
          value: "cpd@pec.org.pk",
          label: "Continuing Professional Development (CPD)",
        },

        {
          value: "peb@pec.org.pk",
          label: "Professional Engineering Bodies (PEB)",
        },

        {
          value: "epe@pec.org.pk",
          label: "Engineering Practice Examination (EPE)",
        },
      ],
      select: { value: "", label: "Select Your Profession" },
      cat1Set: [
        { value: "Electrical Engineer", label: "Electrical Engineer" },
        { value: "Civil Engineer", label: "Civil Engineer" },
        { value: "Mechanical Engineer", label: "Mechanical Engineer" },
        { value: "Sofware Engineer", label: "Software Engineer" },
        { value: "Electronics Engineer", label: "Electronics Engineer" },
        { value: "Computer Engineer", label: "Computer Engineer" },
        { value: "Telecom Engineer", label: "Telecom Engineer" },
        { value: "Non-Engineer", label: "Non-Engineer" },
        { value: "Student", label: "Student" },
        { value: "Member", label: "Member" },
      ],
      selectedOption: "",
      disable: false,
    };
  }

  handleSelect = (selectedOption) => {
    this.setState({ to: selectedOption, toemail: selectedOption.value });
  };

  contactus = (event) => {
    event.preventDefault();
    if (
      this.state.Name == "" ||
      this.state.Email == "" ||
      this.state.Message == ""
    ) {
      obj.createNotification("warning", "Fill the required fields.");
    } else {
      var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (this.state.Email.match(mailformat)) {
        this.setState({ loading: true, disable: true }, () => {
          document.getElementsByName("Name").disabled = true;
          document.getElementsByName("Email").disabled = true;
          document.getElementsByName("Subject").disabled = true;
          document.getElementsByName("Phone").disabled = true;
          document.getElementsByName("Message").disabled = true;
          axios
            .post(urlapi + "/admin/contact_us", {
              name: this.state.Name,
              email: this.state.Email,
              subject: this.state.Subject,
              message: this.state.Message,
              phone: this.state.Phone,
              toemail: this.state.toemail,
            })
            .then((res) => {
              obj.createNotification(
                "success",
                "Thankyou for contacting us, your message has been delivered."
              );
              this.setState({
                loading: false,
                disable: false,
                Email: "",
                Name: "",
                Subject: "",
                Message: "",
              });
            })
            .catch((err) => this.setState({ loading: false, disable: false }));
        });
      } else {
        obj.createNotification("warning", "Please enter a correct email.");
      }
    }
  };
  onsub = (event) => {
    event.preventDefault();
    if (this.state.sub_input == "" || this.state.select === "") {
      obj.createNotification("warning", "Fill the required fields.");
    } else {
      var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (this.state.sub_input.match(mailformat)) {
        axios
          .post(urlapi + "/admin/subscription", {
            email: this.state.sub_input,
            profession: this.state.selectedOption,
          })
          .then((res) => {
            obj.createNotification(
              "success",
              "You have successfully subscribed to CPD PEC Pakistan."
            );
            this.setState({ sub_input: "", selectedOption: "" });
          })
          .catch((err) => this.setState({ loading: false }));
      } else {
        obj.createNotification("warning", "Please enter a correct email.");
      }
    }
  };
  componentDidMount() {
    // $('di').hover(function () {
    //   $('#none').toggle('slide')
    // })
    document.title = "CPD";
    this.props
      .getAllPublishedWebinars()
      .then((res) => {
        let c =
          res.length > 0 &&
          res.filter(
            (item) => moment(item.Session_StartDate).valueOf() > Date.now()
          );
        let d = { value: 0, item: "" };
        c.map((item, index) => {
          if (index === 0) {
            d.value = moment(item.Session_StartDate).valueOf() - Date.now();
            d.item = item;
          } else {
            if (
              moment(item.Session_StartDate).valueOf() - Date.now() <
              d.value
            ) {
              d.value = moment(item.Session_StartDate).valueOf() - Date.now();
              d.item = item;
            }
          }
        });
        pic = d.item.BannerImage_URL;
        this.setState({ slider: d.item }, () => {
          console.log(this.state.slider);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  handleDropdownChange = (selectedOption) => {
    this.setState({
      selectedOption: selectedOption.label,
      select: {
        label: selectedOption.label,
        value: selectedOption.value,
      },
    });
  };

  statechange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  statePhonechange = (phone) => {
    this.setState({ Phone: phone });
  };

  stateMessagechange = (event) => {
    if (/^\s/.test(event.target.value)) {
      event.target.value = "";
    } else if (/^[a-zA-Z ]*$/.test(event.target.value)) {
      this.setState({ [event.target.name]: event.target.value });
    } else {
    }
  };
  stateSubjectchange = (event) => {
    if (/^\s/.test(event.target.value)) {
      event.target.value = "";
    } else if (/^[a-zA-Z ]*$/.test(event.target.value)) {
      this.setState({ [event.target.name]: event.target.value });
    } else {
    }
  };
  stateNamechange = (event) => {
    if (/^\s/.test(event.target.value)) {
      event.target.value = "";
    } else if (/^[a-zA-Z ]*$/.test(event.target.value)) {
      this.setState({ [event.target.name]: event.target.value });
    } else {
    }
    // else{
    //   console.log("else called")
    //   this.setState({ [event.target.name]: event.target.value });
    // }
  };
  join = (event) => {
    event.preventDefault();
    this.props.history.push("/Register");
  };
  hover = () => {
    document.getElementById("mymarquee").stop();
  };
  outhover = () => {
    document.getElementById("mymarquee").start();
  };
  idnone = () => {
    this.setState({ idchange: "block" });
  };
  idagainnone = () => {
    this.setState({ idchange: "none" });
  };
  render() {
    allWebinars = this.props.allWebinars;
    return (
      <React.Fragment>
        <section className="backco_white" style={{ fontFamily: "Arial" }}>
          <Header />
          {/* <img
            src={"http://localhost:5000/" + this.state.slider.BannerImage_URL}
          /> */}
          <div className="row mr_unset col_margin none_other">
            <div className="col-md-10 mr_unset pr_7">
              <div
                id="carouselExampleInterval"
                className="carousel slide"
                data-ride="carousel"
                style={{ width: "100%" }}
              >
                <ol class="carousel-indicators">
                  <li
                    data-target="#carouselExampleInterval"
                    data-slide-to="0"
                    class="active"
                  ></li>
                  <li
                    data-target="#carouselExampleInterval"
                    data-slide-to="1"
                  ></li>
                  <li
                    data-target="#carouselExampleInterval"
                    data-slide-to="2"
                  ></li>
                  <li
                    data-target="#carouselExampleInterval"
                    data-slide-to="3"
                  ></li>
                </ol>

                <div className="carousel-inner">
                  <div className="carousel-item active" data-interval={1000}>
                    <header className="masthead  bg_col1">
                      <div className="container h-100">
                        <div className="row h-100 align-items-center">
                          <div className="col-12 co_white inner_master">
                            <h1 className="font-weight-light pb_10">
                              ABOUT CPD
                            </h1>
                            <p className="lead">
                              Pakistan Engineering Council (PEC) under PEC Act
                              and CPD Byelaws-2008, is earnestly working for the
                              professional growth and skill enhancement of ever
                              growing engineering community. Under CPD
                              framework, the engineers (both PEs and REs) are
                              required to learn innovative and soft skills to be
                              more effective in playing productive role towards
                              nation building
                            </p>
                            <br />
                            <button
                              type="button"
                              className=" btn btn-success font_sans bold btn_carousel  fz_14 fw_sans8"
                              onClick={() =>
                                this.props.history.push("/Introduction")
                              }
                            >
                              READ MORE
                            </button>
                          </div>
                        </div>
                      </div>
                    </header>
                  </div>
                  {this.state.slider ? (
                    <div className="carousel-item" data-interval={5000}>
                      {pic !== "" &&
                        console.log(urlImageUrl + pic?.replace(/ /g, "%20"))}
                      <header
                        className="masthead"
                        style={{
                          backgroundImage:
                            "url(" +
                            urlImageUrl +
                            pic?.replace(/ /g, "%20") +
                            ")",
                        }}
                      >
                        <div className="container h-100">
                          <div className="row h-100 align-items-center">
                            <div className="col-12  inner_master co_white">
                              <h1 className="font-weight-light pb_10">
                                {this.state.slider.Course_Name}
                              </h1>
                              <p className="lead">
                                {this.state.slider.Course_Description}
                              </p>
                              <br />
                              <button
                                type="button"
                                className=" btn btn-success font_sans bold btn_carousel  fz_14 fw_sans8 "
                                onClick={() =>
                                  this.props.history.push(
                                    "/LiveWebinar/" + this.state.slider.CourseID
                                  )
                                }
                              >
                                REGISTER NOW
                              </button>
                            </div>
                          </div>
                        </div>
                      </header>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="carousel-item" data-interval={5000}>
                    <header className="masthead  bg_col2">
                      <div className="container h-100">
                        <div className="row h-100 align-items-center">
                          <div className="col-12 co_white inner_master">
                            <h1 className="font-weight-light pb_10">
                              Live Webinar
                            </h1>
                            <p className="lead">
                              As part of CPD activities being offered by PEC,
                              Webinars are also organized to provide an
                              opportunity to engineers from anywhere in the
                              world to attend short lectures and trainings on
                              specialized and emerging topics to cater
                              innovative needs of the engineering profession.
                            </p>
                            <br />
                            <button
                              type="button"
                              className="btn btn-success font_sans bold btn_carousel fz_14 fw_sans8"
                              onClick={() =>
                                this.props.history.push(
                                  "/WebinarSearch/livewebinar"
                                )
                              }
                            >
                              READ MORE
                            </button>
                          </div>
                        </div>
                      </div>
                    </header>
                  </div>
                  <div className="carousel-item" data-interval={5000}>
                    <header className="masthead  bg_col3">
                      <div className="container h-100">
                        <div className="row h-100 align-items-center">
                          <div className="col-12 co_white inner_master">
                            <h1 className="font-weight-light pb_10">
                              Short Course and Training
                            </h1>
                            <p className="lead">
                              In order to provide quality training and skills to
                              the engineers (both RE & PE), PEC regularly
                              organizes short courses and trainings on emerging
                              topics as well as technical and management
                              aspects.
                            </p>
                            <br />
                            <button
                              type="button"
                              className="btn btn-success font_sans bold btn_carousel fz_14 fw_sans8"
                              onClick={() =>
                                this.props.history.push("/CpdShortCourse")
                              }
                            >
                              READ MORE
                            </button>
                          </div>
                        </div>
                      </div>
                    </header>
                  </div>
                  <div className="carousel-item" data-interval={5000}>
                    <header className="masthead  bg_col4">
                      <div className="container h-100">
                        <div className="row h-100 align-items-center">
                          <div className="col-12 co_white inner_master">
                            <h1 className="font-weight-light pb_10">
                              Engineer's CPD Profile
                            </h1>
                            <p className="lead">
                              The CPD programs include additional
                              qualifications, professional skills, relevant
                              management and communication skills acquired
                              through additional training and experience
                            </p>
                            <br />
                            <button
                              type="button"
                              className="btn btn-success font_sans bold btn_carousel fz_14 fw_sans8"
                              onClick={() =>
                                this.props.history.push("/CpdProfile")
                              }
                            >
                              READ MORE
                            </button>
                          </div>
                        </div>
                      </div>
                    </header>
                  </div>
                </div>
                <a
                  className="carousel-control-prev"
                  href="#carouselExampleInterval"
                  role="button"
                  data-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  />
                  <span className="sr-only">Previous</span>
                </a>
                <a
                  className="carousel-control-next"
                  href="#carouselExampleInterval"
                  role="button"
                  data-slide="next"
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  />
                  <span className="sr-only">Next</span>
                </a>
              </div>
              <br />
            </div>

            <div className="col-md-2 marque_bord col_padding">
              <h5 className="news_heading   bold ">News and Updates</h5>
              {/* <marquee
                behavior="scroll"
                id="mymarquee"
                direction="up"
                scrollamount="4"
                onMouseOver={this.hover}
                onMouseOut={this.outhover}
                className="mymarqueeid"
              > */}
              <div className="scroll_news">
                <ul>
                  <li>
                    <a
                      href="https://yp2g.knowledgenow.info/pec/pectemplate.html"
                      className="co_news"
                      add
                      target="_blank"
                    >
                      <div className="flex-container1 plr_flexcontainer">
                        <div>
                          <img
                            src={news12}
                            style={{
                              maxWidth: "100%",
                              height: "160px",
                              borderRadius: "10px",
                            }}
                          />
                        </div>
                        <div className="fz_12 co_news text-center">
                          CPD Launches Leadership Program for Engineers.
                        </div>

                        <div>
                          <button className="btn_readmore lrm-5">
                            Read More
                          </button>
                        </div>
                        <hr className="hr_mar" />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href={epeimg} className="co_news" add target="_blank">
                      <div className="flex-container1 plr_flexcontainer">
                        <div>
                          <img
                            src={peclogo}
                            style={{
                              maxWidth: "100%",
                              height: "160px",
                              borderRadius: "10px",
                            }}
                          />
                        </div>
                        <div className="fz_12 co_news text-center">
                          17th Engineering Practise Exam Advertisment
                        </div>
                        <div>
                          <button className="btn_readmore lrm-5">
                            Read More
                          </button>
                        </div>
                        <hr className="hr_mar" />
                      </div>
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://www.youtube.com/channel/UC7_LzcT2iiQnY_3B6JTb5Zg"
                      className="co_news"
                      add
                      target="_blank"
                    >
                      <div className="flex-container1 plr_flexcontainer">
                        <div>
                          <img
                            src={news10}
                            style={{
                              maxWidth: "100%",
                              height: "160px",
                              borderRadius: "10px",
                            }}
                          />
                        </div>
                        <div className="fz_12 co_news text-center">
                          PEC launched youtube channel for Engineers namely
                          "Voice of Engineers"
                        </div>

                        <div>
                          <button className="btn_readmore lrm-5">
                            Read More
                          </button>
                        </div>
                        <hr className="hr_mar" />
                      </div>
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://pec.org.pk/downloads/August%2022,%202020%20-%20MHPP-%20CPD.jpg"
                      className="co_news"
                      add
                      target="_blank"
                    >
                      <div className="flex-container1 plr_flexcontainer">
                        <div>
                          <img
                            src={news11}
                            style={{
                              maxWidth: "100%",
                              height: "160px",
                              borderRadius: "10px",
                            }}
                          />
                        </div>
                        <div className="fz_12 co_news text-center">
                          Online CPD Course on Power System
                        </div>

                        <div>
                          <button className="btn_readmore lrm-5">
                            Read More
                          </button>
                        </div>
                        <hr className="hr_mar" />
                      </div>
                    </a>
                  </li>

                  <li>
                    <a href="/" className="co_news" add target="_blank">
                      <div className="flex-container1 plr_flexcontainer">
                        <div>
                          <img
                            src={news1}
                            style={{
                              maxWidth: "100%",
                              height: "160px",
                              borderRadius: "10px",
                            }}
                          />
                        </div>
                        <div className="fz_12 co_news text-center">
                          The 16th EPE has been postponed due to COVID-19
                          situation and the new date would be shared as the
                          situation improves”
                        </div>

                        <div>
                          <button className="btn_readmore lrm-5">
                            Read More
                          </button>
                        </div>
                        <hr className="hr_mar" />
                      </div>
                    </a>
                  </li>

                  <li>
                    <a
                      className="co_news"
                      href="https://www.pec.org.pk/downloads/cpd/Workshop on Business Continuity Recovery Management.doc"
                      add
                      target="_blank"
                    >
                      <div className="flex-container1 plr_flexcontainer">
                        <div>
                          <img
                            src={news2}
                            style={{
                              maxWidth: "100%",
                              height: "180px",
                              borderRadius: "10px",
                            }}
                          />
                        </div>
                        <div className="fz_12 co_news  text-center">
                          Lockdown Business Continuity and Recovery One Week
                          Free Online Video Conference CPD Workshop 9 AM Mon –
                          Fri Apr 20-24 40-Minutes Each Day
                        </div>
                        <div>
                          <button className="btn_readmore lrm-5">
                            Read More
                          </button>
                        </div>{" "}
                        <hr className="hr_mar" />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.youtube.com/watch?v=5YoY_bPs8Uc&t=2s"
                      className="co_news"
                      add
                      target="_blank"
                    >
                      <div className="flex-container1 plr_flexcontainer">
                        <div>
                          <img
                            src={news3}
                            style={{
                              maxWidth: "100%",
                              height: "180px",
                              borderRadius: "10px",
                            }}
                          />
                        </div>
                        <div className="fz_12 co_news  text-center">
                          Recorded Lecture on "Servant Leadership" by Engr. Dr.
                          Attaullah Shah
                        </div>
                        <div>
                          <button className="btn_readmore lrm-5">
                            Read More
                          </button>
                        </div>{" "}
                        <hr className="hr_mar" />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a
                      href="http://hireengr.pec.org.pk/"
                      className="co_news"
                      add
                      target="_blank"
                    >
                      <div className="flex-container1 plr_flexcontainer">
                        <div>
                          <img
                            src={news4}
                            style={{
                              maxWidth: "100%",
                              height: "180px",
                              borderRadius: "10px",
                            }}
                          />
                        </div>
                        <div className="fz_12 co_news  text-center">
                          Safety Measures by PEC from Corona Virus Fast Track
                          Search and Hire Engineers!
                        </div>
                        <div>
                          <button className="btn_readmore lrm-5">
                            Read More
                          </button>
                        </div>{" "}
                        <hr className="hr_mar" />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://pec.org.pk/Downloadables/PEC%20Newsletter_Mar2020.pdf"
                      className="co_news"
                      add
                      target="_blank"
                    >
                      <div className="flex-container1 plr_flexcontainer">
                        <div>
                          <img
                            src={news5}
                            style={{
                              maxWidth: "100%",
                              height: "180px",
                              borderRadius: "10px",
                            }}
                          />
                        </div>
                        <div className="fz_12 co_news  text-center">
                          News Letter March 2020
                        </div>
                        <div>
                          <button className="btn_readmore lrm-5">
                            Read More
                          </button>
                        </div>
                        <hr className="hr_mar" />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a
                      className="co_news"
                      href="https://pec.org.pk/Downloadables/Self%20learning%20Webinars%20for%20Engineers.pdf"
                      add
                      target="_blank"
                    >
                      <div className="flex-container1 plr_flexcontainer">
                        <div>
                          <img
                            src={news6}
                            style={{
                              maxWidth: "100%",
                              height: "180px",
                              borderRadius: "10px",
                            }}
                          />
                        </div>
                        <div className="fz_12 co_news  text-center">
                          Webinar on Self Learning
                        </div>
                        <div>
                          <button className="btn_readmore lrm-5">
                            Read More
                          </button>
                        </div>
                        <hr className="hr_mar" />
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row mr_unset col_margin display_ipad">
            <div className="col-md-9 mr_unset pr_7">
              <div
                id="carouselExampleInterval"
                className="carousel slide"
                data-ride="carousel"
                style={{ width: "100%" }}
              >
                <div className="carousel-inner">
                  <div className="carousel-item active" data-interval={5000}>
                    <header className="masthead  bg_col1">
                      <div className="container h-100">
                        <div className="row h-100 align-items-center">
                          <div className="col-12 co_white inner_master">
                            <h1 className="font-weight-light pb_10">
                              ABOUT CPE
                            </h1>
                            <p className="lead">
                              Pakistan Engineering Council (PEC) under PEC Act
                              and CPD Byelaws-2008, is earnestly working for the
                              professional growth and skill enhancement of ever
                              growing engineering community. Under CPD
                              framework, the engineers (both PEs and REs) are
                              required to learn innovative and soft skills to be
                              more effective in playing productive role towards
                              nation building
                            </p>
                            <br />
                            <button
                              type="button"
                              className=" btn btn-success font_sans bold btn_carousel  fz_14 fw_sans8"
                              onClick={() =>
                                this.props.history.push("/Introduction")
                              }
                            >
                              READ MORE
                            </button>
                          </div>
                        </div>
                      </div>
                    </header>
                  </div>
                  <div className="carousel-item" data-interval={5000}>
                    <header className="masthead  bg_col2">
                      <div className="container h-100">
                        <div className="row h-100 align-items-center">
                          <div className="col-12 co_white inner_master">
                            <h1 className="font-weight-light pb_10">
                              Live Webinars
                            </h1>
                            <p className="lead">
                              As part of CPD activities being offered by PEC,
                              Webinars are also organized to provide an
                              opportunity to engineers from anywhere in the
                              world to attend short lectures and trainings on
                              specialized and emerging topics to cater
                              innovative needs of the engineering profession.
                            </p>
                            <br />
                            <button
                              type="button"
                              className="btn btn-success font_sans bold btn_carousel fz_14 fw_sans8"
                              onClick={() =>
                                this.props.history.push(
                                  "WebinarSearch/livewebinar"
                                )
                              }
                            >
                              READ MORE
                            </button>
                          </div>
                        </div>
                      </div>
                    </header>
                  </div>
                  <div className="carousel-item" data-interval={5000}>
                    <header className="masthead  bg_col3">
                      <div className="container h-100">
                        <div className="row h-100 align-items-center">
                          <div className="col-12 co_white inner_master">
                            <h1 className="font-weight-light pb_10">
                              CPD Short Courses
                            </h1>
                            <p className="lead">
                              In order to provide quality training and skills to
                              the engineers (both RE & PE), PEC regularly
                              organizes short courses and trainings on emerging
                              topics as well as technical and management
                              aspects.
                            </p>
                            <br />
                            <button
                              type="button"
                              className="btn btn-success font_sans bold btn_carousel fz_14 fw_sans8"
                              onClick={() =>
                                this.props.history.push("/CpdShortCourse")
                              }
                            >
                              READ MORE
                            </button>
                          </div>
                        </div>
                      </div>
                    </header>
                  </div>
                  <div className="carousel-item" data-interval={5000}>
                    <header className="masthead  bg_col4">
                      <div className="container h-100">
                        <div className="row h-100 align-items-center">
                          <div className="col-12 co_white inner_master">
                            <h1 className="font-weight-light pb_10">
                              CPD Profile
                            </h1>
                            <p className="lead">
                              The CPD programs include additional
                              qualifications, professional skills, relevant
                              management and communication skills acquired
                              through additional training and experience
                            </p>
                            <br />
                            <button
                              type="button"
                              className="btn btn-success font_sans bold btn_carousel fz_14 fw_sans8"
                              onClick={() =>
                                this.props.history.push("/CpdProfile")
                              }
                            >
                              READ MORE
                            </button>
                          </div>
                        </div>
                      </div>
                    </header>
                  </div>
                </div>
                <a
                  className="carousel-control-prev"
                  href="#carouselExampleInterval"
                  role="button"
                  data-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  />
                  <span className="sr-only">Previous</span>
                </a>
                <a
                  className="carousel-control-next"
                  href="#carouselExampleInterval"
                  role="button"
                  data-slide="next"
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  />
                  <span className="sr-only">Next</span>
                </a>
              </div>
              <br />
            </div>
            <div className="col-md-3 marque_bord col_padding">
              <h5 className="news_heading bold">News and Updates</h5>
              <marquee
                behavior="scroll"
                id="mymarquee"
                direction="up"
                scrollamount="2"
                onMouseOver={this.hover}
                onMouseOut={this.outhover}
                className="mymarqueeid"
              >
                <div className="flex-container1 plr_flexcontainer">
                  <div className="fz_12 co_black text-center">
                    <p>
                      Free Live Webinar on “Machine Learning for Engineers “ ,
                      on April 17th, 2020, 9:00pm Pakistan Time by Knowledge now
                      in Collaboration with PEC
                    </p>
                  </div>
                  <div className="">
                    <img className="card-img-top card2" src={img1} alt="" />
                  </div>
                  <hr className="hr_mar" />
                </div>
                <div className="flex-container1 plr_flexcontainer">
                  <div className="fz_12 co_black text-center">
                    <p>Updated Correigendum - Bids PEC-Lahore</p>
                  </div>
                  <div className="">
                    <img className="card-img-top card2" src={img2} alt="" />
                  </div>
                  <hr className="hr_mar" />
                </div>
                <div className="flex-container1 plr_flexcontainer">
                  <div className="fz_12 co_black text-center">
                    <p>
                      "Lockdown Business Continuity and Recovery One Week Free
                      Online Video Conference CPD Workshop 9 AM Mon – Fri Apr
                      20-24 40-Minutes Each Day"
                    </p>
                  </div>
                  <div className="">
                    <img className="card-img-top card2" src={img3} alt="" />
                  </div>
                  <hr className="hr_mar" />
                </div>
              </marquee>
            </div>
          </div>
          <div>
            <h1 className="text-center mr_3 bold">CPD Activities</h1>
            <div className="row col_margin d-flex justify-content-center">
              <div className="col-auto col_padding"></div>

              <div className="col-md-5 col_padding text-center box mr_24 bg_col1">
                <Link className="cpd_activities_links" to="/CpdShortCourse">
                  <h5 className="bold">Short Courses and Training</h5>
                  <p className="fz_14 hei_5v">
                    To learn technical managements and soft skills
                  </p>
                  <button
                    type="button"
                    className="btn btn-success font_sans bold fz_12 cpd_Act_btn"
                    onClick={() => this.props.history.push("/CpdShortCourse")}
                  >
                    VIEW DETAILS
                  </button>
                </Link>
              </div>

              <br />

              <div className="col-md-5 col_padding text-center box mr_24 bg_col2">
                <Link
                  className="cpd_activities_links"
                  to="/WebinarSearch/livewebinar"
                >
                  <h5 className="bold">Live Webinars</h5>
                  <p className="fz_14 hei_5v">
                    To learn innovative techniques and global trends
                  </p>
                  <button
                    type="button"
                    className="btn btn-success font_sans bold fz_12 cpd_Act_btn"
                    // onClick={() => this.props.history.push("/WebinarSearch/livewebinar")}
                  >
                    VIEW DETAILS
                  </button>
                </Link>
              </div>

              <div className="col-auto col_padding"></div>
            </div>
            <br />
            <div className="row col_margin d-flex justify-content-center ">
              <div className="col-auto col_padding"></div>

              <div className="col-md-5 col_padding text-center box mr_24 bg_col3">
                <Link className="cpd_activities_links" to="/onlineCourse">
                  <h5 className="bold">Online Courses</h5>
                  <p className="fz_14 hei_5v">
                    CPD courses for wider coverage and outreach
                  </p>
                  <button
                    type="button"
                    className="btn btn-success font_sans bold fz_12 cpd_Act_btn"
                    onClick={() => this.props.history.push("/onlineCourse")}
                  >
                    VIEW DETAILS
                  </button>
                </Link>
              </div>

              <br />

              <div className="col-md-5 col_padding text-center box mr_24 bg_col4">
                <Link className="cpd_activities_links" to="/CpdProfile">
                  <h5 className="bold">Engineer's CPD Profile</h5>
                  <p className="fz_14 hei_5v">
                    To check and update CPD record and engineers
                  </p>
                  <button
                    type="button"
                    className="btn btn-success font_sans bold fz_12 cpd_Act_btn"
                    onClick={() => this.props.history.push("/CpdProfile")}
                  >
                    VIEW DETAILS
                  </button>
                </Link>
              </div>

              <div className="col-auto col_padding"></div>
            </div>
          </div>
          <br />
          <br />
          <div className="row col_margin">
            <div className="col-md-12 text-center pd_80 bg_stick">
              <h2 className="co_white bold">
                PEC working towards capacity building and skill enhancement of
                engineers
              </h2>
            </div>
          </div>
          <h1 className="text-center mr_3 bold" id="upcomingCourses">
            Upcoming Courses
          </h1>
          <div className="container none_other">
            <div className="row  d-flex justify-content-center">
              {this.props.allWebinars.length > 0 &&
                this.props.allWebinars
                  .reverse()
                  .slice(0, 4)
                  .map((item, index) => (
                    <div className="col-md-3 newfilxx flex_center" key={item.CourseID}>
                      {moment(item.Session_StartDate).valueOf() > Date.now() ? (
                        <Link
                          className="co_black"
                          to={{
                            pathname: `/LiveWebinar/${item.CourseID}`,
                          }}
                        >
                          <div
                            className="card card_webinar"
                            data-tip={item.Course_Name}
                            style={{ width: "17rem" }}
                          >
                            <img
                              src={
                                urlImageUrl +
                                item.Image_URL.slice(0, 7) +
                                "\\" +
                                item.Image_URL.slice(7)
                              }
                              className="card-img-top_webinar"
                              alt="..."
                            />

                            <div className="card-body card-bod">
                              <h5 className="bold title">{item.Course_Name}</h5>

                              <h6 className="card-text">
                                {" "}
                                {item.Instructor_Name}
                              </h6>

                              <i className="fas fa-clock fa-align-justify i_clock">
                                {" "}
                              </i>
                              <p className="card-text mb_unset fz_12">
                                PST&nbsp;
                                <Moment format="hh:mm:ss a">
                                  {item.Session_StartDate}
                                </Moment>
                              </p>

                              <i className="far fa-calendar fa-align-justify i_clock"></i>
                              <span className="card-text mb_unset fz_12">
                                {" "}
                                <Moment format="DD-MMMM-YYYY">
                                  {item.Session_StartDate}
                                </Moment>
                              </span>

                              <span className="co_green price">
                                {item.pricing_type == "Free"
                                  ? "Free"
                                  : "Rs " + item.price}{" "}
                              </span>
                            </div>
                          </div>{" "}
                          <ReactTooltip />
                        </Link>
                      ) : (
                        " "
                      )}
                    </div>
                  ))}
            </div>
          </div>
          <br />
          <br />
          <div className="bg_col">
            <h1 className="text-center pd_3 co_white">News and Updates</h1>
            <div className="container">
              <div className="row col_margin d-flex justify-content-center">
                <div className="col-md-4 ">
                  <a
                    className="co_black"
                    href={`https://pec.org.pk/Downloadables/PEC%20Newsletter_Mar2020.pdf`}
                  >
                    <div>
                      <img
                        className="news_block"
                        style={{ width: "100%", height: "270px" }}
                        src={news5}
                        alt=""
                      />
                      <div className="card-body pl_0">
                        <h6 className="card-title co_white">
                          News Letter March 2020
                        </h6>
                        <p className="card-text co_white fz_12">
                          <a
                            className="card-text co_white fz_12"
                            href="https://pec.org.pk/Downloadables/PEC%20Newsletter_Mar2020.pdf"
                          >
                            Read More
                          </a>
                        </p>
                        <i className="fas fa-clock co_green float_l"></i>
                        <p className="co_white float_l fz_12 ml_10px">
                          March,2020
                        </p>
                        <br /> <br />
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-md-4 ">
                  <a href={Pdf}>
                    <div>
                      <img
                        className="news_block"
                        style={{ width: "100%", height: "270px" }}
                        src={news7}
                        alt=""
                      />
                      <div className="card-body pl_0">
                        <h6 className="card-title co_white">EPE Result 2019</h6>
                        <p className="card-text co_white fz_12">
                          <a
                            className="card-text co_white fz_12"
                            href={Pdf}
                            target="_blank"
                            download
                          >
                            15th EPE Result 2019
                          </a>
                        </p>
                        <i className="fas fa-clock co_green float_l"></i>
                        <p className="co_white float_l fz_12 ml_10px">2019</p>
                        <br /> <br />
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-md-4 ">
                  <div>
                    <img
                      className="news_block"
                      style={{ width: "100%", height: "270px" }}
                      src={news8}
                      alt=""
                    />
                    <div className="card-body pl_0">
                      <h6 className="card-title co_white">CPD Portal Live</h6>
                      <p className="card-text co_white fz_12">
                        CPD Portal is now Live
                      </p>
                      <i className="fas fa-clock co_green float_l"></i>
                      <p className="co_white float_l fz_12 ml_10px">2020</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />
          </div>
          {/* <div className="bg_col">
            <h1 className="text-center pd_3 co_white">News and Updates</h1>
            <div className="container">
              
                <div className="newsupdatediv">
                  <ul className="newslist">
                    <li className="newslitsitem">
                        
                          <div className="newslitsitem_news">Recorder webinar to earn CPD points</div>
                          <div className="newslistitem_button"><a className="btn btn-success font_sans bold fz_12 cpd_Act_btn">Read More</a></div>
                          
                        
                    </li>
                    <li className="newslitsitem">
                        
                          <div className="newslitsitem_news">Coursera for Campus - A community response to COVID-19</div>
                          <div className="newslistitem_button">
                            <a 
                            href="https://www.coursera.org/coronavirus?utm_campaign=website&utm_content=c4cv-top-banner-dotorg&utm_medium=coursera&utm_source=home-page" 
                            className="btn btn-success font_sans bold fz_12 cpd_Act_btn">Read More</a></div>
                          
                       
                    </li>
                    <li className="newslitsitem">
                        
                          <div className="newslitsitem_news">Live Webinar by PEC on Implementing 4.0 with SOLID WORKS ecosystem in Collaboration with Dassult Systems And Quality Solutions  </div>
                          <div className="newslistitem_button">
                            <a 
                            href="https://register.gotowebinar.com/webinarOver"
                            className="btn btn-success font_sans bold fz_12 cpd_Act_btn">Read More</a></div>
                          
                        
                    </li>
                    <li className="newslitsitem">
                        
                        <div className="newslitsitem_news">PEC recently launches “Pakistan Journal of Engineering”   </div>
                        <div className="newslistitem_button"><a 
                        href="https://register.gotowebinar.com/webinarOver"
                        className="btn btn-success font_sans bold fz_12 cpd_Act_btn">Read More</a></div>
                        
                      
                  </li>
                  </ul>
                    </div>  
              
         
          </div>
          </div> */}
          <div className="bg_col">
            <div className="container">
              <div
                className="row col_margin d-flex justify-content-center "
                style={{ padding: "50px 0px" }}
              >
                <div className="col-md-3  text-left co_white our_num margin_2">
                  <h2>We are growing together</h2>
                </div>
                <div className="col-md-3 text-center margin_2">
                  <div className style={{ border: "1px solid #757A88" }}>
                    <i className="fas fa-user-graduate fa-3x co_white pt_100" />
                    <h1 className="co_white pt_5 pb_5 bold">290092</h1>
                    <div className="hl"></div>
                    <p className="co_white pt_20 bold">
                      REGISTERED ENGINEERS TO DATE
                    </p>
                  </div>
                </div>
                <br />
                <div className="col-md-3 text-center margin_2">
                  <div className style={{ border: "1px solid #757A88" }}>
                    <i className="fas fa-suitcase fa-3x co_white pt_100" />
                    <h1 className="co_white pt_5 pb_5 bold">378</h1>
                    <div className="hl"></div>
                    <p className="co_white pt_20 bold">COURSES TO DATE</p>
                  </div>
                </div>
                <div className="col-md-3 text-center margin_2">
                  <div className style={{ border: "1px solid #757A88" }}>
                    <i className="fas fa-graduation-cap fa-3x co_white pt_100" />
                    <h1 className="co_white pt_5 pb_5 bold">27431</h1>
                    <div className="hl"></div>
                    <p className="co_white pt_20 bold text-uppercase">
                      PARTICIPANTS TO DATE
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ backgroundColor: "#06580E" }}>
            <div className="container">
              <div className="row col_margin d-flex justify-content-center sub_new">
                <div className="col-md-5 co_white pt_7ip">
                  <h2>Subscribe to Our Newsletter</h2>
                </div>
                <div className="col-md-2">
                  <input
                    type="text"
                    className="form-control place_white"
                    style={{
                      backgroundColor: "transparent",
                      color: "white",
                      border: "1px solid",
                    }}
                    placeholder="Your Email *"
                    name="sub_input"
                    value={this.state.sub_input}
                    onChange={this.statechange}
                  />
                </div>
                <div className="col-md-3 cd">
                  <Select
                    name="cat1Set"
                    options={this.state.cat1Set}
                    value={this.state.select}
                    onChange={this.handleDropdownChange}
                  />
                  <br />
                </div>
                <div className="col-md-2 col_padding text-center">
                  <button
                    type="button"
                    className="btn btn-success co_green bold mb_5 font_sans fz_14 "
                    onClick={this.onsub}
                    style={{
                      backgroundColor: "white",
                      width: "92%",
                      height: "40px",
                    }}
                  >
                    SUBSCRIBE
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <h1 className="text-center pd_3 bold">CPD Features and Working</h1>
            <div className="row col_margin d-flex justify-content-center ">
              <div className="col-md-3 text-center">
                <i className="fas fa-trophy fa-2x bc_green circle" />
                <h6 className="co_black pt_20 bold">Browse Our Course</h6>
                <p className="co_black fz_12">To see the list of courses</p>
              </div>
              <div className="col-md-3 text-center">
                <i className="fas fa-suitcase fa-2x bc_green circle" />
                <h6 className="co_black pt_20 bold">Choose the Plan</h6>
                <p className="co_black fz_12">
                  To enroll for the course/webinar
                </p>
              </div>
              <div className="col-md-3 text-center">
                <i className="fas fa-graduation-cap fa-2x bc_green circle " />
                <h6 className="co_black pt_20 bold">Enroll in the Course</h6>
                <p className="co_black fz_12">To enroll upcoming course</p>
              </div>
              <div className="col-md-3 text-center">
                <i className="fas fa-trophy fa-2x bc_green circle" />
                <h6 className="co_black pt_20 bold">Boost your Skill Set</h6>
                <p className="co_black fz_12">
                  To see a list of recored Webinars/courses
                </p>
              </div>
            </div>
            <br />
          </div>
          <div>
            <div style={{ backgroundColor: "#4F5667" }}>
              <div className="container">
                <div
                  className="row col_margin d-flex justify-content-center "
                  style={{ padding: "35px 0px" }}
                >
                  <div className="col-md-1 flex_center col_padding pt_3">
                    <i
                      className="fas fa-users fa-2x co_white"
                      style={{
                        padding: "10px",
                        border: "1px solid #757A88",
                        borderRadius: "50%",
                      }}
                    />
                  </div>
                  <div className="col-md-9">
                    <div className="co_white">
                      <h3 className="Pt_10 fz_2re icon_center">
                        290092 Engineers have already joined, take the first
                        step!
                      </h3>
                    </div>
                  </div>
                  <div className="col-md-2 flex_center col_padding">
                    <a
                      href="https://www.pec.org.pk/"
                      className="btn font_sans bold fz_14 bordersilver join col_padding"
                      style={{
                        width: "100%",
                        border: "1px solid #828793",
                        paddingLeft: "30px",
                        paddingRight: "30px",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                      }}
                    >
                      JOIN US
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
          <div className="container">
            <div className="row col_margin d-flex justify-content-center ">
              <div className="col-md-4  text-left">
                <h3>Contact Us</h3>
                <div className="row Pt_10">
                  <div className="col-1">
                    <i className="fas fa-map-marker-alt co_green mt_10"></i>
                  </div>
                  <div className="col-10 pt_3">
                    <a
                      target="_blank"
                      href="https://www.google.com/maps/place/Pakistan+Engineering+Council/@33.7239847,73.0909047,17z/data=!3m1!4b1!4m5!3m4!1s0x38dfc07e98fce659:0xbf9fcfb8daab700b!8m2!3d33.7239803!4d73.0930934"
                      className="fz_12 co_black"
                    >
                      Pakistan Engineering Council Ataturk Avenue (East), G-5/2
                      Islamabad
                    </a>
                  </div>
                </div>
                <div className="row ">
                  <div className="col-1">
                    <i
                      className="fa fa-phone co_green fz_13 fa-rotate-90 mt-2"
                      aria-hidden="true"
                    ></i>
                  </div>
                  <div className="col-10 pt_3">
                    <a
                      target="_blank"
                      href="tel:(+92-51) 111-111-732"
                      className="fz_12 co_black"
                    >
                      (+92-51) 111-111-732
                    </a>
                  </div>
                </div>
                <div className="row ">
                  <div className="col-1">
                    <i className="fas fa-envelope co_green mt-0_5"></i>
                  </div>
                  <div className="col-10 pt_3">
                    <a
                      target="_blank"
                      href="mailto:cpd@pec.org.pk"
                      className="fz_12 co_black"
                    >
                      cpd@pec.org.pk
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                <div className="Pt_10">
                  <div style={{ width: "85%", marginBottom: "10px" }}>
                    <label>
                      To<span className="co_red">*</span>
                    </label>
                    <Select
                      options={this.state.options}
                      value={this.state.to}
                      onChange={this.handleSelect}
                    />
                  </div>
                  <input
                    type="text"
                    className="form-control mr_5 co_black"
                    name="Name"
                    value={this.state.Name}
                    style={{ width: "40%", float: "left", fontSize: "14px" }}
                    placeholder="Name *"
                    onChange={this.stateNamechange}
                    disabled={this.state.disable}
                  />
                  <input
                    type="email"
                    className="form-control mr_5 co_black"
                    name="Email"
                    value={this.state.Email}
                    style={{ width: "40%", float: "left", fontSize: "14px" }}
                    placeholder="Email *"
                    disabled={this.state.disable}
                    onChange={this.statechange}
                  />
                  <input
                    type="text"
                    className="form-control mtb-10 mr_5 co_black"
                    name="Subject"
                    value={this.state.Subject}
                    style={{ width: "40%", float: "left", fontSize: "14px" }}
                    placeholder="Subject"
                    disabled={this.state.disable}
                    onChange={this.statechange}
                  />

                  {/* <input
                    type="text"
                    className="form-control mtb-10 mr_5 co_black"
                    name="Subject"
                    value={this.state.Subject}
                    style={{ width: "40%", float: "left", fontSize: "14px" }}
                    placeholder="Subject"
                    onChange={this.stateSubjectchange}
                  />
                  <input
                    type="number"
                    className="form-control mtb-10 co_black"
                    name="Phone"
                    value={this.state.Phone}
                    style={{ width: "40%", float:"left", fontSize: "14px" }}
                    placeholder="Phone"
                    onChange={this.statePhonechange}
                  /> */}
                  <PhoneInput
                    className="form-control mtb-10 co_black"
                    name="Phone"
                    style={{
                      width: "40%",
                      float: "left",
                      fontSize: "14px",
                      "margin-top": "10px",
                    }}
                    country={"pk"}
                    //value={this.state.Phone}
                    disabled={this.state.disable}
                    onChange={(phone) => this.statePhonechange(phone)}
                  />
                  <textarea
                    className="form-control mt_10 co_black"
                    name="Message"
                    value={this.state.Message}
                    rows={5}
                    placeholder="Message *"
                    onChange={this.statechange}
                    disabled={this.state.disable}
                    style={{ fontSize: "14px", width: "85%" }}
                  />
                  {this.state.loading ? (
                    <div className="mt-15 mb-40 center ">
                      <Loader
                        type="Oval"
                        color="#06580e"
                        height={80}
                        width={80}
                      />
                    </div>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-success bold font_sans mt_10 fz_14"
                      style={{ width: "250px", height: "40px" }}
                      onClick={this.contactus}
                    >
                      SUBMIT A MESSAGE
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <br />
          <br /> <br />
          {/*<Footer/>*/}
        </section>
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    allWebinars: state.webinarData.publishedWebinars,
  };
}
export default connect(mapStateToProps, { getAllPublishedWebinars })(Home);
