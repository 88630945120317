export const webinar = "/admin/dashboard/webinar";
export const webinarForm = "/admin/dashboard/webinar/form";

// export const adminpayments = "/admin/dashboard/coursePayments/:id";
// export const courseattendees = "/admin/dashboard/courseattendees/:id";
export const admincoursepayment = "/admin/dashboard/admincoursePayment";
export const usermanagement = "/admin/dashboard/usermanagement";
export const cpdclaims = "/admin/dashboard/cpdclaims";
export const cpdpoints = "/admin/dashboard/cpdpoints";
export const attendence = "/admin/dashboard/attendence";
export const webinarattendees = "/admin/dashboard/webinarattendees";
export const webinarregistered = "/admin/dashboard/webinarregistered";
export const AdminPermission = "/admin/dashboard/adminpermission";
export const WebinarRegistrations = "/admin/dashboard/webinarregistrations";
export const WebinarAttendeesAll = "/admin/dashboard/allwebinarattendees";
export const EngineerList = "/admin/dashboard/engineerlist";

export const AdminSummary = "/admin/dashboard/summary";