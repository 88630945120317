import React, { Component } from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { urlapi, urlImageUrl } from "../../config/urlapi";
import WebNotif from "../WebNotif";
import { connect } from "react-redux";
import ModalImage from "react-modal-image";
import Loader from "react-loader-spinner";
import FileViewer from "react-file-viewer";
import moment from "moment";
import Button from "@material-ui/core/Button";
import Modal from "react-modal";
import { withStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import {
  get_cpd_form_expendable,
  get_approved_points_year,
  updateCpdStatus,
  rejectCpdStatus,
  getUpdatedCpdPoints,
  getUserSubmission,
  getClaimBasedSubmission,
} from "../../redux/admin/cpdActions/cpdactions";
var obj = new WebNotif();

const customStyles1 = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "400px",
    backgroundColor: "#fff",
    border: "none",
    color: "black",
  },
  overlay: {
    zIndex: 1000,
    backgroundColor: "rgba(0, 0, 0, 0.35)",
  },
};
Modal.setAppElement("#root");
const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "green",
    },
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "#20a84c",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#20a84c",
      },
    },
  },
})(TextField);
class CpdTable extends Component {
  state = {
    submissionID: "",
    newpath: "",
    newtype: "",
    userpoints: "",
    loader: false,
    perYear: [
      { name: "Formal Education", value: 4 },
      { name: "Work Based Activities", value: 2 },
      { name: "Developmental Activities", value: 3 },
      { name: "Individual  Activities", value: 3 },
    ],
    calc: "",
    status: "",
    newdata: [],
    modalIsOpen1: false,
    modalIsOpen2: false,
    updateCpdPoints: "",
    cpdUserClaim: "",
    approvedAdminClaim: "",
    rejectionReason: "",
    approvedBy: "",
    formdata: {
      UserClaim: "",
      PecCalculator: "",
      ApprovedClaim: "",
      ApprovedPoints: "",
      submissionID: "",
      Reason: "",
    },
    adminId: "",
    claimBasedUserId: "",
    userClaimDate: "",
    claimBasedCategory: "",
    claimBasedFieldId: "",
  };
  reload = () => window.location.reload();
  handlereject = (val) => {
    this.setState({ loader: true }, () => {
      this.props
        .rejectCpdStatus({
          SubmissionId: val,
          reason: this.state.formdata.Reason,
          pecNo: parseInt(this.props.pecNo),
          actionTakenBy: this.state.adminId,
        })
        .then((res) => {
          this.props.rejectStatus(val);
          this.setState({ modalIsOpen2: false, loader: false }, () => {
            obj.createNotification("success", "Status Updated Successfully");
          });
          // setTimeout(() => {
          //   this.reload();
          // }, 750);
          this.props.refreshList();
        })
        .catch((err) => {
          this.setState({ loader: false }, () => {
            obj.createNotification("error", "Something went wrong");
          });
        });
    });
  };

  submitcpd = (e) => {
    e.preventDefault();
    console.log("inside handleEditFinalPoints");
    console.log("User claim date state ... ", this.state.userClaimDate);
    console.log("Date of submission ... ", this.props.dateOfSubmission);
    const claimCategory = this.state.claimBasedCategory;
    const UserID = this.state.claimBasedUserId;
    const claimBasedDate = this.state.userClaimDate
      ? this.state.userClaimDate
      : this.props.dateOfSubmission;
    const claimFieldId = this.state.claimBasedFieldId;

    const data = {
      claimCategory: this.props.category,
      UserID: this.props.id,
      claimBasedDate,
      claimFieldId,
    };

    this.setState({ loader: true }, () => {
      this.props
        .getClaimBasedSubmission(data)
        .then((res) => {
          console.log("CLAIM BASED RESSSS ... ", res);
          let arr = res?.data?.filter(
            (result) => result.category === this.props.category
          );
          let points = [];
          points.push(
            arr.filter((result) => result.final_approved_points !== null)
          );
          let sum = 0;
          points[0].map((item, index) => {
            sum += item.final_approved_points;
          });
          // if (this.props.category === "Formal Education" && sum >= 4) {
          //   this.setState({ loader: false });
          //   obj.createNotification(
          //     "error",
          //     "This candidate has already claimed 4 credit points for this year"
          //   );
          // } else if (
          //   this.props.category === "Work Based Activities" &&
          //   sum >= 2
          // ) {
          //   this.setState({ loader: false });
          //   obj.createNotification(
          //     "error",
          //     "This candidate has already claimed 2 credit points for this year"
          //   );
          // } else
          if (this.props.category === "Developmental Activities" && sum >= 3) {
            this.setState({ loader: false });
            obj.createNotification(
              "error",
              "This candidate has already claimed 2 credit points for this year"
            );
          } else if (
            this.props.category === "Individual  Activities" &&
            sum >= 3
          ) {
            this.setState({ loader: false });
            obj.createNotification(
              "error",
              "This candidate has already claimed 2 credit points for this year"
            );
          } else {
            if (!this.state.formdata.ApprovedClaim) {
              this.setState({ loader: false });
              obj.createNotification(
                "error",
                "Approved claim is a required field"
              );
              return;
            }
            this.props
              .updateCpdStatus({
                apporvedPoints: this.state.newdata["CPD Points(PEC)"],
                reason: this.state.formdata.Reason,
                SubmissionId: this.state.formdata.submissionID,
                pecNo: parseInt(this.props.pecNo),
                updatedApproved: parseInt(this.state.formdata.ApprovedClaim),
                actionTakenBy: this.state.adminId,
              })
              .then((res) => {
                this.props.sendData(this.state.formdata.submissionID);
                this.setState({ modalIsOpen1: false, loader: false }, () => {
                  obj.createNotification(
                    "success",
                    "Status Updated Successfully"
                  );
                });
                this.props.refreshList();
              })
              .catch((err) => {
                this.setState({ loader: false }, () => {
                  obj.createNotification("error", "Something went wrong");
                });
              });
          }
        })
        .catch((err) => {
          console.log("ERROR: ", err);
        });
    });
  };

  handleFinalPointsChange = (e) => {
    this.setState({ approvedAdminClaim: e.target.value });
  };

  openModal1 = (userpoint, calc, appr, submissionID) => {
    this.setState({
      formdata: {
        ...this.state.formdata,
        UserClaim: userpoint,
        PecCalculator: calc,
        ApprovedClaim: appr,
        submissionID: submissionID,
      },
    });

    this.setState({ modalIsOpen1: true }, () => {});
  };
  openModal2 = (submissionID) => {
    this.setState({
      formdata: {
        ...this.state.formdata,
        submissionID: submissionID,
      },
    });
    this.setState({ modalIsOpen2: true }, () => {});
  };
  closeModal1 = () => {
    this.setState({
      modalIsOpen1: false,
      isEditable: false,
      formdata: {
        ...this.state.formdata,
        ApprovedPoints: "",
      },
    });
  };
  closeModal2 = () => {
    this.setState({
      modalIsOpen2: false,
      isEditable: false,
    });
  };
  onChange = (e) => {
    if (e.target.name === "ApprovedClaim") {
      // if (this.props.category === "Formal Education") {
      //   if (e.target.value > 4) {
      //     return;
      //   }
      // } else if (this.props.category === "Work Based Activities") {
      //   if (e.target.value > 2) {
      //     return;
      //   }
      // } else
      if (this.props.category === "Developmental Activities") {
        if (e.target.value > 3) {
          return;
        }
      } else if (this.props.category === "Individual  Activities") {
        if (e.target.value > 3) {
          return;
        }
      }
    }
    this.setState({
      formdata: { ...this.state.formdata, [e.target.name]: e.target.value },
    });
  };

  componentDidMount() {
    // console.log(this.props);
    const adminInfo = localStorage.getItem("admin_info");

    const { UserID } = JSON.parse(adminInfo);

    this.setState({
      ...this.state,
      adminId: UserID,
    });
  }

  componentWillMount() {
    console.log("CPD TABLE THIS PROPS ... ", this.props);
    let submissionID;
    let newpath;
    let newtype;
    let userpoints;
    let calc;
    let newdata = {};
    let status;
    let finalpoints = 0;
    let userFieldValue;
    let adminFieldValue;
    let rejectReason;
    let changePoints;
    let approvedBy;
    let yearData = { id: this.props.id, cat: this.props.category };
    console.log("THIS CERTIFICATE NUM ... ", this.props.certificateNumber);
    let certificateNo =
      this.props.certificateNumber === 0 || !this.props.certificateNumber
        ? "NIL"
        : this.props.certificateNumber;
    this.props
      .get_approved_points_year(yearData)
      .then((res) => {
        console.log("get_approved_points_year ... ", res);
        Object.keys(res.data).map((item) => {
          finalpoints += res.data[item].final_approved_points;
          changePoints = res.data[item].final_approved_points;
        });
        this.setState(
          {
            finalpoints,
            approvedAdminClaim: changePoints,
          },
          () => {
            let perYear = this.state.perYear;
            //points shown to admin logic starts
            perYear.map((item) => {
              if (item.name === this.props.category) {
                newdata["Points Limit of Current Category / Year"] = item.value;
                newdata["Final Approved Points / Year"] =
                  this.state.approvedAdminClaim;
              }
            });
            // points shown to admin logic ends

            //expandable row api and logic start
            this.props
              .get_cpd_form_expendable(this.props.id)
              .then((res) => {
                console.log("get_cpd_form_expendable RESSSSS .... ", res);

                const fieldsToCheck = [9, 18, 63, 56, 28, 44, 41];

                const expandedDataFields = res?.data?.fields;

                const fieldValues = {};

                for (const key in expandedDataFields) {
                  const field = expandedDataFields[key];
                  if (fieldsToCheck.includes(field.field_id)) {
                    fieldValues["claimDate"] = field.field_value;
                  }
                }

                submissionID = this.props.id;
                status = res.data.other.status;
                newpath = res.data.other.file_path;
                newtype = res.data.other.file_type;
                // userFieldValue = res.data.other.user_field_value;
                // adminFieldValue = res.data.other.admin_field_value;
                rejectReason = res.data.other.rejectionReason;
                approvedBy = res.data.other.approvedBy
                  ? res.data.other.approvedBy
                  : "NIL";

                this.setState({ rejectionReason: rejectReason });
                this.setState({ approvedBy: approvedBy });

                Object.keys(res.data.fields).map((item, key) => {
                  // if(res.data.fields[item].field_name === 'cpd_points_user'){
                  //   console.log(res.data.fields[item].field_value)
                  //   userpoints = res.data.fields[item].field_value
                  //   calc = res.data.fields[item].field_value
                  // }
                  // else {
                  if (res.data.fields[item].field_name === "to_date") {
                    newdata["To Date"] = moment(
                      res.data.fields[item].field_value
                    ).format("DD-MM-YYYY");
                    this.setState({
                      userClaimDate: res.data.fields[item].field_value,
                      claimBasedFieldId: "9",
                    });
                  } else if (
                    res.data.fields[item].field_name === "suggestion"
                  ) {
                    newdata["Suggestion / FeedBack"] =
                      res.data.fields[item].field_value;
                  } else if (res.data.fields[item].field_name === "from_date") {
                    newdata["Start Date"] = moment(
                      res.data.fields[item].field_value
                    ).format("DD-MM-YYYY");
                  } else if (
                    res.data.fields[item].field_name === "select_category"
                  ) {
                    newdata["Selected Category"] =
                      res.data.fields[item].field_value;
                  } else if (
                    res.data.fields[item].field_name === "sub_category"
                  ) {
                    newdata["Selected SubCategory"] =
                      res.data.fields[item].field_value;
                  } else if (
                    res.data.fields[item].field_name === "uni_inst_name"
                  ) {
                    newdata["University / Institution Name"] =
                      res.data.fields[item].field_value;
                  } else if (res.data.fields[item].field_name === "inst_type") {
                    newdata["Institution Type"] =
                      res.data.fields[item].field_value;
                  } else if (
                    res.data.fields[item].field_name === "mode_education"
                  ) {
                    newdata["Mode of Education"] =
                      res.data.fields[item].field_value;
                  } else if (
                    res.data.fields[item].field_name === "title_degree"
                  ) {
                    newdata["Title of Degree"] =
                      res.data.fields[item].field_value;
                  } else if (res.data.fields[item].field_name === "period") {
                    newdata["Period in Days"] =
                      res.data.fields[item].field_value;
                  } else if (
                    res.data.fields[item].field_name === "cpd_points_pec"
                  ) {
                    newdata["CPD Points(PEC)"] =
                      res.data.fields[item].field_value;
                  } else if (
                    res.data.fields[item].field_name === "cpd_points_user"
                  ) {
                    newdata["CPD Points(User)"] =
                      res.data.fields[item].field_value;
                    this.setState({
                      cpdUserClaim: res.data.fields[item].field_value,
                    });
                  } else if (res.data.fields[item].field_name === "org_type") {
                    newdata["Organization Type"] =
                      res.data.fields[item].field_value;
                  } else if (
                    res.data.fields[item].field_name === "designation"
                  ) {
                    newdata["Designation of User"] =
                      res.data.fields[item].field_value;
                  } else if (
                    res.data.fields[item].field_name === "nature_activities"
                  ) {
                    newdata["Nature of Activities"] =
                      res.data.fields[item].field_value;
                  } else if (res.data.fields[item].field_name === "n_p_body") {
                    newdata["Name of Professional Body"] =
                      res.data.fields[item].field_value;
                  } else if (res.data.fields[item].field_name === "year_reg") {
                    newdata["Year of Registration"] =
                      res.data.fields[item].field_value;
                    this.setState({
                      userClaimDate: res.data.fields[item].field_value,
                      claimBasedFieldId: "18",
                    });
                  } else if (
                    res.data.fields[item].field_name === "renew_upto"
                  ) {
                    newdata["Renew Upto"] = moment(
                      res.data.fields[item].field_value
                    ).format("DD-MM-YYYY");
                  } else if (
                    res.data.fields[item].field_name === "membership_type"
                  ) {
                    newdata["Membership Type"] =
                      res.data.fields[item].field_value;
                  } else if (
                    res.data.fields[item].field_name === "title_training"
                  ) {
                    newdata["Title of Training"] =
                      res.data.fields[item].field_value;
                  } else if (
                    res.data.fields[item].field_name === "title_degree"
                  ) {
                    newdata["Title of Degree"] =
                      res.data.fields[item].field_value;
                  } else if (
                    res.data.fields[item].field_name === "title_lecture"
                  ) {
                    newdata["Title of Lecture"] =
                      res.data.fields[item].field_value;
                  } else if (
                    res.data.fields[item].field_name === "course_work"
                  ) {
                    newdata["Course Work"] = res.data.fields[item].field_value;
                  } else if (
                    res.data.fields[item].field_name === "title_symposium"
                  ) {
                    newdata["Title of Symposium"] =
                      res.data.fields[item].field_value;
                  } else if (
                    res.data.fields[item].field_name ===
                    "title_research_article"
                  ) {
                    newdata["Title of Research Article"] =
                      res.data.fields[item].field_value;
                  } else if (res.data.fields[item].field_name === "n_journal") {
                    newdata["Name of Journal"] =
                      res.data.fields[item].field_value;
                  } else if (
                    res.data.fields[item].field_name === "publish_date"
                  ) {
                    newdata["Publish Date"] = res.data.fields[item].field_value;
                    this.setState({
                      userClaimDate: res.data.fields[item].field_value,
                      claimBasedFieldId: "28",
                    });
                  } else if (
                    res.data.fields[item].field_name === "t_conference"
                  ) {
                    newdata["Title of Conference"] =
                      res.data.fields[item].field_value;
                  } else if (
                    res.data.fields[item].field_name === "name_host_org"
                  ) {
                    newdata["Name of Host Organization"] =
                      res.data.fields[item].field_value;
                  } else if (res.data.fields[item].field_name === "t_book") {
                    newdata["Title of Book"] =
                      res.data.fields[item].field_value;
                  } else if (
                    res.data.fields[item].field_name === "book_publisher"
                  ) {
                    newdata["Book Publisher"] =
                      res.data.fields[item].field_value;
                  } else if (
                    res.data.fields[item].field_name === "approving_authority"
                  ) {
                    newdata["Approving Authority"] =
                      res.data.fields[item].field_value;
                  } else if (
                    res.data.fields[item].field_name === "nature_social_work"
                  ) {
                    newdata["Nature of Social Work"] =
                      res.data.fields[item].field_value;
                  } else if (
                    res.data.fields[item].field_name === "nature_committee"
                  ) {
                    newdata["Nature of Committee"] =
                      res.data.fields[item].field_value;
                  } else if (
                    res.data.fields[item].field_name === "role_committee"
                  ) {
                    newdata["Role of Committee"] =
                      res.data.fields[item].field_value;
                  } else if (
                    res.data.fields[item].field_name === "nature_event"
                  ) {
                    newdata["Nature of Committee"] =
                      res.data.fields[item].field_value;
                  } else if (
                    res.data.fields[item].field_name === "role_event"
                  ) {
                    newdata["Role in Event"] =
                      res.data.fields[item].field_value;
                  } else if (
                    res.data.fields[item].field_name === "tors_committee"
                  ) {
                    newdata["Tors of Committee"] =
                      res.data.fields[item].field_value;
                  } else if (
                    res.data.fields[item].field_name === "notifying_authority"
                  ) {
                    newdata["Notifying Authority"] =
                      res.data.fields[item].field_value;
                  } else if (
                    res.data.fields[item].field_name === "year_task_completed"
                  ) {
                    newdata["Year of Task Completed"] = moment(
                      res.data.fields[item].field_value
                    ).format("YYYY");
                    this.setState({
                      userClaimDate: res.data.fields[item].field_value,
                      claimBasedFieldId: "41",
                    });
                  } else if (
                    res.data.fields[item].field_name === "year_task_assigned"
                  ) {
                    newdata["Year of Task Assigned"] = moment(
                      res.data.fields[item].field_value
                    ).format("YYYY");
                  } else if (
                    res.data.fields[item].field_name === "r_social_work"
                  ) {
                    newdata["Role in Social Work"] =
                      res.data.fields[item].field_value;
                  } else if (
                    res.data.fields[item].field_name === "d_activity"
                  ) {
                    newdata["Date of Activity"] = moment(
                      res.data.fields[item].field_value
                    ).format("DD-MM-YYYY");
                    this.setState({
                      userClaimDate: res.data.fields[item].field_value,
                      claimBasedFieldId: "44",
                    });
                  } else if (
                    res.data.fields[item].field_name === "name_inst_pbody"
                  ) {
                    newdata[
                      "Name of Institution/ Professional Engineering Body"
                    ] = res.data.fields[item].field_value;
                  } else if (
                    res.data.fields[item].field_name === "location_event"
                  ) {
                    newdata["Location of Event"] =
                      res.data.fields[item].field_value;
                  } else if (
                    res.data.fields[item].field_name === "event_type"
                  ) {
                    newdata["Event Type"] = res.data.fields[item].field_value;
                  } else if (
                    res.data.fields[item].field_name === "membership_type"
                  ) {
                    newdata["Membership Type"] =
                      res.data.fields[item].field_value;
                  } else if (
                    res.data.fields[item].field_name ===
                    "professional_body_type"
                  ) {
                    newdata["Professional Body Type"] =
                      res.data.fields[item].field_value;
                  } else if (
                    res.data.fields[item].field_name === "nature_participation"
                  ) {
                    newdata["Nature of Participation"] =
                      res.data.fields[item].field_value;
                  } else if (
                    res.data.fields[item].field_name === "title_diploma"
                  ) {
                    newdata["Title of Diploma"] =
                      res.data.fields[item].field_value;
                  } else if (
                    res.data.fields[item].field_name ===
                    "title_conference_paper"
                  ) {
                    newdata["Title of Conference Paper"] =
                      res.data.fields[item].field_value;
                  } else if (
                    res.data.fields[item].field_name === "name_organization"
                  ) {
                    newdata["Name of Organization"] =
                      res.data.fields[item].field_value;
                  } else if (
                    res.data.fields[item].field_name === "publishing_date"
                  ) {
                    newdata["Publishing Date"] = moment(
                      res.data.fields[item].field_value
                    ).format("DD-MM-YYYY");
                  } else if (res.data.fields[item].field_name === "loco") {
                    newdata["Location"] = res.data.fields[item].field_value;
                  } else if (
                    res.data.fields[item].field_name === "date_event"
                  ) {
                    newdata["Date of Event"] = moment(
                      res.data.fields[item].field_value
                    ).format("DD-MM-YYYY");
                    this.setState({
                      userClaimDate: res.data.fields[item].field_value,
                      claimBasedFieldId: "56",
                    });
                  } else if (
                    res.data.fields[item].field_name === "publication_type"
                  ) {
                    newdata["Publication Type"] =
                      res.data.fields[item].field_value;
                  } else if (
                    res.data.fields[item].field_name === "organizing_body"
                  ) {
                    newdata["Organizing Body"] =
                      res.data.fields[item].field_value;
                  } else if (
                    res.data.fields[item].field_name === "paper_type"
                  ) {
                    newdata["Paper Type"] = res.data.fields[item].field_value;
                  } else if (
                    res.data.fields[item].field_name === "title_seminar"
                  ) {
                    newdata["Title of Seminar"] =
                      res.data.fields[item].field_value;
                  } else if (
                    res.data.fields[item].field_name === "title_Workshop"
                  ) {
                    newdata["Title of Workshop"] =
                      res.data.fields[item].field_value;
                  } else if (
                    res.data.fields[item].field_name === "Title Of Short Course"
                  ) {
                    newdata["Title of Short Course"] =
                      res.data.fields[item].field_value;
                  } else if (
                    res.data.fields[item].field_name === "d_admission"
                  ) {
                    newdata["Date of Admission"] = moment(
                      res.data.fields[item].field_value
                    ).format("DD-MM-YYYY");
                  } else if (
                    res.data.fields[item].field_name === "participation_date"
                  ) {
                    newdata["Participation Date"] = moment(
                      res.data.fields[item].field_value
                    ).format("DD-MM-YYYY");
                    this.setState({
                      userClaimDate: res.data.fields[item].field_value,
                      claimBasedFieldId: "63",
                    });
                  } else if (certificateNo) {
                    newdata["Certificate Number"] = certificateNo;
                  }
                  // else if (rejectReason) {
                  //   console.log(
                  //     "INSIDE REJECTED STATUS .... ",
                  //     this.state.status
                  //   );
                  //   newdata["Rejection Reason"] =
                  //     rejectReason === null || !rejectReason
                  //       ? "Not Found"
                  //       : rejectReason;
                  // } else if (approvedBy) {
                  //   newdata["Rejected By"] =
                  //     approvedBy === null || !approvedBy
                  //       ? "Not Found"
                  //       : approvedBy;
                  // }

                  // }
                });
                this.setState({
                  newdata,
                  newtype,
                  newpath,
                  submissionID,
                  status,
                });
              })
              .catch((err) => {
                console.log(err);
              });
            //expandable row api and logic end
          }
        );
      })
      .catch((err) => {
        console.log(err);
      });

    this.setState({
      ...this.state,
      userpoints: userpoints,
      calc: calc,
      colSpan: 7,
    });
  }

  handleEditFinalPoints = (e) => {
    e.preventDefault();
    console.log("inside handleEditFinalPoints");
    console.log("User claim date state ... ", this.state.userClaimDate);
    console.log("Date of submission ... ", this.props.dateOfSubmission);
    const claimCategory = this.state.claimBasedCategory;
    const UserID = this.state.claimBasedUserId;
    const claimBasedDate = this.state.userClaimDate
      ? this.state.userClaimDate
      : this.props.dateOfSubmission;
    const claimFieldId = this.state.claimBasedFieldId;

    const data = {
      claimCategory: this.props.category,
      UserID: this.props.id,
      claimBasedDate,
      claimFieldId,
    };

    this.setState({ loader: true }, () => {
      this.props
        .getClaimBasedSubmission(data)
        .then((res) => {
          console.log("CLAIM BASED RESSSS ... ", res);

          let arr = res?.data?.filter(
            (result) => result.category === this.props.category
          );
          let points = [];
          points.push(
            arr.filter((result) => result.final_approved_points !== null)
          );
          let sum = 0;
          points[0].map((item, index) => {
            sum += item.final_approved_points;
          });
          if (this.props.category === "Formal Education" && sum >= 4) {
            this.setState({ loader: false });
            obj.createNotification(
              "error",
              "You have already claimed 4 credit points for this year"
            );
          } else if (
            this.props.category === "Work Based Activities" &&
            sum >= 2
          ) {
            this.setState({ loader: false });
            obj.createNotification(
              "error",
              "You have already claimed 2 credit points for this year"
            );
          } else if (
            this.props.category === "Developmental Activities" &&
            sum >= 3
          ) {
            this.setState({ loader: false });
            obj.createNotification(
              "error",
              "You have already claimed 3 credit points for this year"
            );
          } else if (
            this.props.category === "Individual  Activities" &&
            sum >= 3
          ) {
            this.setState({ loader: false });
            obj.createNotification(
              "error",
              "You have already claimed 3 credit points for this year"
            );
          } else {
            this.props
              .getUpdatedCpdPoints({
                id: this.props.id,
                cat: this.props.category,
                // finalApprovedPoints: this.state.approvedAdminClaim,
              })
              .then((res) => {
                this.setState({ loader: false }, () => {
                  obj.createNotification(
                    "success",
                    "Claim Updated Successfully"
                  );
                });
              })
              .catch((err) => {
                this.state({ loader: false }, () => {
                  obj.createNotification("error", "Something went wrong");
                });
              });
          }
        })
        .catch((err) => {
          console.log("ERROR: ", err);
        });
    });
  };

  addDefaultSrc(ev) {
    ev.target.src = "some default image url";
  }

  approvalClaimMessage = () => {
    console.log("INSIDE approvalClaimMessage");
    if (this.props.category === "Formal Education") {
      console.log("Formal Education approvalClaimMessage");
      return "Formal Education has maximum limit of 4 points per year";
    } else if (this.props.category === "Work Based Activities") {
      console.log("Work Based Activities approvalClaimMessage");
      return "Work Based Activities has maximum limit of 2 points per year";
    } else if (this.props.category === "Developmental Activities") {
      console.log("Developmental Activities approvalClaimMessage");
      return "Developmental Activities has maximum limit of 3 points per year";
    } else if (this.props.category === "Individual  Activities") {
      console.log("Individual Activities approvalClaimMessage");
      return "Individual Activities has maximum limit of 3 points per year";
    }
  };

  render() {
    return (
      <TableRow>
        <TableCell colSpan={this.state.colSpan}>
          <div className="container">
            <div className="row">
              {console.log("NEW DATAAAAAA ....... ", this.state.newdata)}
              {console.log("CURRENT STATUS ,,,,, ", this.state.status)}
              {Object.keys(this.state.newdata).map((item, index) => (
                <>
                  {this.state.status !== "Approved" ? (
                    <>
                      <div
                        className={
                          item === "Final Approved Points / Year"
                            ? ""
                            : "col-6 mb-20"
                        }
                      >
                        <div className="fw-600-fs-15">
                          {item === "Final Approved Points / Year" ? "" : item}
                        </div>
                        <div>
                          {" "}
                          {item === "Final Approved Points / Year"
                            ? ""
                            : this.state.newdata[item]}
                        </div>
                      </div>
                      {this.state.status === "Rejected" && index === 0 && (
                        <>
                          <div className="col-6 mb-20">
                            <div className="fw-600-fs-15">Rejection Reason</div>
                            <div className="mt-1">
                              {this.state.rejectionReason === null ||
                              !this.state.rejectionReason
                                ? "Not Found"
                                : this.state.rejectionReason}
                            </div>
                          </div>
                          <div className="col-6 mb-20">
                            <div className="fw-600-fs-15">Rejected By</div>
                            <div className="mt-1">
                              {this.state.approvedBy === null ||
                              !this.state.approvedBy
                                ? "Not Found"
                                : this.state.approvedBy}
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <div className="col-6 mb-20">
                        <div className="fw-600-fs-15">{item}</div>
                        <div> {this.state.newdata[item]}</div>
                      </div>
                      {index === 0 && (
                        <div className="col-6 mb-20">
                          <div className="fw-600-fs-15">Approved By</div>
                          <div>
                            {this.state.approvedBy === null ||
                            !this.state.approvedBy
                              ? "Not Found"
                              : this.state.approvedBy}
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </>
              ))}
            </div>

            {/* {this.state.status === "Rejected" ? (
              <>
                <div className="row">
                  <div className="col-6 mb-20">
                    <div className="fw-600-fs-15">Rejection Reason</div>
                    <div className="mt-1">
                      {this.state.rejectionReason === null ||
                        !this.state.rejectionReason
                        ? "Not Found"
                        : this.state.rejectionReason}
                    </div>
                  </div>
                  <div className="col-6 mb-20">
                    <div className="fw-600-fs-15">Rejected By</div>
                    <div className="mt-1">
                      {this.state.approvedBy === null || !this.state.approvedBy
                        ? "Not Found"
                        : this.state.approvedBy}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ""
            )} */}
          </div>
        </TableCell>
        <div className="mtb-50 preview-files">
          {this.state.newtype === "jpeg" ||
          this.state.newtype === "jpg" ||
          this.state.newtype === "png" ? (
            <>
              <ModalImage
                onError={this.addDefaultSrc}
                className="payment-image"
                small={urlImageUrl + this.state.newpath || "/img/error.png"}
                medium={urlImageUrl + this.state.newpath || "/img/error.png"}
                large={urlImageUrl + this.state.newpath || "/img/error.png"}
                alt="user claim"
              />
            </>
          ) : (
            <a href={urlImageUrl + this.state.newpath} target="_blank">
              {this.state.newtype === "docx" && (
                <i className="far fa-file-word co_admin  fa-3x" />
              )}

              {this.state.newtype === "pdf" && (
                <i className="far fa-file-pdf co_admin  fa-4x" />
              )}
            </a>
          )}
        </div>

        <div className="row">
          {!(this.state.status === "Rejected") &&
            (this.props.permissions.Delete ? (
              <button
                size="small"
                value="Not Approved"
                className="btn btn-outline-danger mr-25 btn-sm "
                onClick={() => this.openModal2(this.state.submissionID)}
              >
                Reject
              </button>
            ) : (
              ""
            ))}
          {!(this.state.status === "Approved") &&
            (this.props.permissions.Approve ? (
              <button
                onClick={() =>
                  this.openModal1(
                    this.state.newdata["CPD Points(User)"],
                    this.state.newdata["CPD Points(PEC)"],
                    // this.state.newdata["CPD Points(PEC)"],
                    0,
                    this.state.submissionID
                  )
                }
                value="Approved"
                class="btn btn-outline-success btn-sm claim-btn"
              >
                Approve
              </button>
            ) : (
              ""
            ))}
        </div>

        <Modal
          closeIcon
          isOpen={this.state.modalIsOpen1}
          onRequestClose={this.closeModal1}
          style={customStyles1}
          contentLabel="Approve"
        >
          <form>
            <div className="form-group">
              <CssTextField
                type="number"
                fullWidth={true}
                label="As Per Pec Calculator"
                variant="outlined"
                name="PecCalculator"
                value={this.state.formdata.PecCalculator}
                disabled
              />
            </div>
            <div className="form-group">
              <CssTextField
                type="text"
                autoFocus={true}
                fullWidth={true}
                label="As Per User Claim"
                variant="outlined"
                disabled
                value={this.state.formdata.UserClaim}
                name="UserClaim"
              ></CssTextField>
            </div>
            <div className="form-group">
              <CssTextField
                type="text"
                autoFocus={true}
                fullWidth={true}
                label="Approved Claim"
                variant="outlined"
                value={this.state.formdata.ApprovedClaim}
                name="ApprovedClaim"
                onChange={this.onChange}
              ></CssTextField>
            </div>
            <div className="form-group">
              <p
                style={{
                  fontSize: "12px",
                }}
              >
                Note:{" "}
                {this.props.category === "Formal Education"
                  ? "Formal Education has maximum limit of 4 points per year"
                  : this.props.category === "Work Based Activities"
                  ? "Work Based Activities has maximum limit of 2 points per year"
                  : this.props.category === "Developmental Activities"
                  ? "Developmental Activities has maximum limit of 3 points per year"
                  : "Individual Activities has maximum limit of 3 points per year"}
              </p>
            </div>
            <div className="form-group">
              <CssTextField
                type="text"
                fullWidth={true}
                label="Reason"
                variant="outlined"
                name="Reason"
                onChange={this.onChange}
              />
            </div>

            <div className="row">
              <div className="col-md-4 ml-20p">
                {this.state.loader === true ? (
                  <Loader type="Oval" color="#06580e" height={40} width={40} />
                ) : (
                  <button
                    onClick={this.submitcpd}
                    // type="submit"
                    className="btn text-center btn-submit black bold"
                  >
                    Approve
                  </button>
                )}
              </div>
            </div>
          </form>
        </Modal>
        <Modal
          closeIcon
          isOpen={this.state.modalIsOpen2}
          onRequestClose={this.closeModal2}
          style={customStyles1}
          contentLabel="Not Approved"
        >
          <form>
            <div className="form-group">
              <CssTextField
                type="text"
                fullWidth={true}
                label="Reason"
                variant="outlined"
                name="Reason"
                onChange={this.onChange}
              />
            </div>
            <div className="row">
              <div className="col-md-4 ml-20p">
                {this.state.loader === true ? (
                  <Loader type="Oval" color="#06580e" height={40} width={40} />
                ) : (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      this.handlereject(this.state.submissionID);
                    }}
                    // type="submit"
                    className="btn text-center btn-submit black bold"
                  >
                    Reject
                  </button>
                )}
              </div>
            </div>
          </form>
        </Modal>
      </TableRow>
    );
  }
}
function mapStateToProps(state) {
  return {
    cpdData: state.CPDData.cpddata,
  };
}

export default connect(mapStateToProps, {
  get_cpd_form_expendable,
  get_approved_points_year,
  updateCpdStatus,
  rejectCpdStatus,
  getUpdatedCpdPoints,
  getUserSubmission,
  getClaimBasedSubmission,
})(CpdTable);

// export default CpdTable;
