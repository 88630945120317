import React from "react";
import "../assets/css/talhacss/webinarSearch.css";
import Header from "./Header";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import Loader from "react-loader-spinner";
import { urlImageUrl } from "../config/urlapi";
import Moment from "react-moment";
import ReactPaginate from "react-paginate";
import $ from "jquery";
import { connect } from "react-redux";
import FuzzySearch from "fuzzy-search";
import {
  getAllPublishedWebinars,
  getWebinarByType,
  getInstructors,
  getWebinarCount,
} from "../redux/admin/webinar/webinarActions";
import Select from "react-select";
import CpdActivitiesSIDEbar from "./CpdActivitiesSIDEbar";
import ReactTooltip from "react-tooltip";
import { reverse } from "lodash";
const colourStyles = {
  control: (styles) => ({ ...styles, border: "1px solid black" }),
};
// import { Tabs,Sonnet,Tab } from 'react-bootstrap';
class WebinarSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      data1: [],
      currentSelected: [],
      webinartype: "Live Webinar",
      webinarName: "Live Webinar By PEC",
      elements: [],
      perPage: 12,
      currentPage: 0,
      loading: true,
      search: "",
      SelectedInstructor: { value: "", label: "All" },
      dateSort: "",
      // pagination: 0,
      InstructorSet: [],
      result: [],
      data: [
        {
          name: "CPD Short Courses and Training",
          url: "/CpdShortCourse",
        },
        {
          name: "Webinars",
          url: "",
          type: "button",
          child: [
            {
              name: "Live Webinars By PEC",
              url: "/WebinarSearch/livewebinar",
            },
            {
              name: "Recorded Webinars BY PEC",
              url: "/WebinarSearch/recordedwebinar",
            },
            {
              name: "Webinars by PEB (Professional Engineering Bodies)",
              url: "/WebinarSearch/pebwebinar",
            },
          ],
        },
        {
          name: "Online Courses",
          url: "/onlineCourse",
        },
        {
          name: "Engineer's CPD Profile",
          url: "Ecp",
          type: "button",
          child: [
            {
              name: " CPD Profile",
              url: "/CpdProfile",
            },
            {
              name: "Calculator of CPD Points",
              url: "/CalculatorofCPD",
            },
          ],
        },
      ],
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.setState(
        {
          search: "",
          SelectedInstructor: { value: "", label: "All" },
          selectedInstructor: { value: "", label: "All" },
          dateSort: "",
        },
        () => {
          if (this.props.match.params.id == "livewebinar") {
            this.setState(
              {
                webinartype: "Live Webinar",
                webinarName: "Live Webinar By PEC",
              },
              () => {
                this.getCountbyType();
              }
            );
          } else if (this.props.match.params.id == "recordedwebinar") {
            this.setState(
              {
                webinartype: "Recorded Webinar",
                webinarName: "Recorded Webinar By PEC",
              },
              () => {
                this.getCountbyType();
              }
            );
          } else {
            this.setState(
              {
                webinartype: "Webinar by PEBs",
                webinarName: "Webinar by PEBs",
              },
              () => {
                this.getCountbyType();
              }
            );
          }
        }
      );
    }
  }

  getWebinarsbyType = () => {
    let data = {
      webinartype: this.state.webinartype,
      OffSet: this.state.perPage * this.state.currentPage,
    };
    console.log(this.state.webinartype);
    this.props.getWebinarByType(data).then((res) => {
      // console.log(res);
      this.setState(
        {
          data1: res,
          result: res,
          loading: false,
        },
        () => {
          let currentSelected = [];
          for (let i = 0; i < this.state.data1.length; i++) {
            currentSelected.push(this.state.data1[i]);
          }
          this.setState(
            { result: currentSelected, currentSelected: currentSelected },
            () => {
              this.setElementsForCurrentPage();
            }
          );
        }
      );
    });
  };

  getCountbyType = () => {
    this.props
      .getWebinarCount(this.state.webinartype)
      .then((res) => {
        // console.log(res);
        this.setState(
          {
            pageCount: Math.ceil(res[0].count / this.state.perPage),
          },
          () => {
            this.getWebinarsbyType();
          }
        );
      })
      .catch((err) => console.log(err));
  };

  componentDidMount() {
    document.title = "Webinar Search";
    window.scrollTo(0, 0);
    // var selector = ".nav a";
    // $(selector).on("click", function () {
    //   $(selector).removeClass("active");
    //   // $(this).addClass('active');
    // });
    this.props.getAllPublishedWebinars();
    this.props.getInstructors().then((res) => {
      var temp = [];
      temp.push({ value: "", label: "All" });
      for (var i = 0; i < res.length; i++) {
        var obj = { value: res[i].FullName, label: res[i].FullName };
        temp.push(obj);
      }
      this.setState({ InstructorSet: temp });
    });
    if (this.props.match.params.id == "livewebinar") {
      this.setState(
        { webinartype: "Live Webinar", webinarName: "Live Webinar By PEC" },
        () => {
          this.getCountbyType();
          //this.getWebinarsbyType();
        }
      );
    } else if (this.props.match.params.id == "recordedwebinar") {
      this.setState(
        {
          webinartype: "Recorded Webinar",
          webinarName: "Recorded Webinar By PEC",
        },
        () => {
          this.getCountbyType();
          //this.getWebinarsbyType();
        }
      );
    } else {
      this.setState(
        { webinartype: "Webinar by PEBs", webinarName: "Webinar by PEBs" },
        () => {
          this.getCountbyType();
          // this.getWebinarsbyType();
        }
      );
    }
  }

  handleInstructor = (selectedOption) => {
    this.setState({ SelectedInstructor: selectedOption }, () => {
      var searcher;
      if (this.state.result.length == 0) {
        // searcher= new FuzzySearch(this.props.allWebinars,['Course_Name'], {});
        searcher = new FuzzySearch(
          this.state.currentSelected,
          ["Course_Name"],
          {}
        );
      } else {
        if (this.state.search == "") {
          // searcher= new FuzzySearch(this.props.allWebinars,['Course_Name'], {});

          searcher = new FuzzySearch(
            this.state.currentSelected,
            ["Course_Name"],
            {}
          );
        } else {
          searcher = new FuzzySearch(this.state.result, ["Course_Name"], {});
        }
      }

      const result = searcher.search(this.state.search);

      this.setState({ result: result }, () => {
        const searcher = new FuzzySearch(this.state.result, ["FullName"], {});
        const result = searcher.search(selectedOption.value);

        this.setState({ result: result }, () => {
          this.setElementsForCurrentPage();
        });
      });
    });
  };

  handleChange1 = (event) => {
    this.setState(
      { ...this.state, [event.target.name]: event.target.value },
      () => {
        var searcher;
        if (this.state.SelectedInstructor.value == "") {
          // searcher = new FuzzySearch(this.props.allWebinars, ['FullName'], {});
          searcher = new FuzzySearch(
            this.state.currentSelected,
            ["FullName"],
            {}
          );
        } else {
          if (this.state.result.length == 0) {
            //searcher = new FuzzySearch(this.props.allWebinars, ['FullName'], {});
            searcher = new FuzzySearch(
              this.state.currentSelected,
              ["FullName"],
              {}
            );
          } else {
            searcher = new FuzzySearch(this.state.result, ["FullName"], {});
          }
        }
        console.log(this.state.SelectedInstructor.value);
        const result = searcher.search(this.state.SelectedInstructor.value);
        this.setState({ result: result }, () => {
          const searcher = new FuzzySearch(
            this.state.result,
            ["Course_Name"],
            {}
          );
          const result = searcher.search(this.state.search);

          this.setState({ result: result }, () => {
            this.setElementsForCurrentPage();
          });
        });
      }
    );
  };
  statechange = (event) => {
    this.setState(
      { ...this.state, [event.target.name]: event.target.value },
      () => {
        if (this.state.dateSort == "Earliest") {
          if (this.state.result && this.state.result.length > 0) {
            this.setState(
              { currentSelected: this.state.result.reverse() },
              () => {
                this.setElementsForCurrentPage();
              }
            );
          } else {
            this.setState(
              { currentSelected: this.state.currentSelected.reverse() },
              () => {
                this.setElementsForCurrentPage();
              }
            );
          }
        } else {
          if (this.state.result && this.state.result.length > 0) {
            this.setState(
              { currentSelected: this.state.result.reverse() },
              () => {
                this.setElementsForCurrentPage();
              }
            );
          } else {
            this.setState(
              { currentSelected: this.state.currentSelected.reverse() },
              () => {
                this.setElementsForCurrentPage();
              }
            );
          }
        }
      }
    );
  };

  handlePageClick = (data) => {
    const selectedPage = data.selected;
    const offset = selectedPage * this.state.perPage;
    this.setState({ currentPage: selectedPage, offset: offset }, () => {
      this.getWebinarsbyType();
    });
  };
  setElementsForCurrentPage() {
    // console.log("SET ELEMENTS FOR CURRENT PAGE");
    // const array1 = ["one", "two", "three"];
    // console.log(this.state.result);
    // let g = [...this.state.result].reverse();
    // console.log(g);
    // console.log(array1.reverse());
    if (this.state.result) {
      let elements =
        this.state.result.length > 0 ? (
          this.state.result.map((item, key) => (
            <div className="col-md-4 flex_center newfilxxx">
              <Link
                className="co_black"
                to={{
                  pathname: `/LiveWebinar/${item.CourseID}`,
                }}
              >
                <div
                  className="card card_webinar"
                  data-tip={item.Course_Name}
                  style={{ width: "20rem" }}
                >
                  <img
                    src={
                      urlImageUrl +
                      item.Image_URL.slice(0, 7) +
                      "\\" +
                      item.Image_URL.slice(7)
                    }
                    className="card-img-top_webinar"
                    alt="Webinar Featured Image"
                  />
                  <div className="card-body card-bod">
                    <h6 className="card-text-1" style={{ float: "right" }}>
                      <span className="fz_12">CPD Points:</span>{" "}
                      {item.CPDPoints}
                    </h6>
                    <h5 className="bold title">{item.Course_Name}</h5>
                    <h6 className="card-text"> {item.FullName}</h6>
                    <i className="fas fa-clock fa-align-justify i_clock"> </i>
                    <p className="card-text mb_unset fz_12">
                      PST&nbsp;
                      <Moment format="hh a">{item.Session_StartDate}</Moment>
                    </p>
                    <i className="far fa-calendar fa-align-justify i_clock "></i>
                    <span className="card-text mb_unset fz_12">
                      {" "}
                      <Moment format="Do-MMM-YYYY">
                        {item.Session_StartDate}
                      </Moment>
                    </span>
                    <div>
                      <i className="fas fa-hourglass-half fa-align-justify i_clock "></i>
                      <p className="card-text mb_unset fz_12">
                        {" "}
                        <Moment
                          duration={item.Session_StartDate}
                          date={item.Session_EndDate}
                        />
                        {/* <Moment format="Do-MMM-YYYY">
                        {item.Session_StartDate - item.Session_EndDate}
                      </Moment> */}
                      </p>
                    </div>
                    <p
                      className="card-text mb_unset fz_12"
                      style={{ marginLeft: "1px" }}
                    >
                      {/* <i
                        style={{ marginRight: "23px" }}
                        class="fas fa-map-marker-alt fa-align-justify i_clock fa-lg"
                      ></i>
                      <span className="card-text mb_unset fz_12">Online</span> */}
                      <span className="co_green price">
                        {item.pricing_type == "Free"
                          ? "Free"
                          : "Rs " + item.price}{" "}
                      </span>
                    </p>
                  </div>
                </div>
                <ReactTooltip />
              </Link>
            </div>
          ))
        ) : (
          <div className="fs-24 text-center w-100 h-225p ">
            No Results Found.
          </div>
        );
      this.setState({ elements: elements });
    } else {
    }
  }

  render() {
    let paginationElement;
    if (this.state.result.length > 0 && this.state.pageCount > 1) {
      paginationElement = (
        <ReactPaginate
          previousLabel={"←"}
          nextLabel={"→"}
          breakLabel={<span className="gap">...</span>}
          pageCount={this.state.pageCount}
          onPageChange={this.handlePageClick}
          initialPage={0}
          className={this.state.pageCount === 1 ? "active" : "not active"}
          // forcePage={this.state.currentPage}
          containerClassName={"pagination"}
          previousLinkClassName={"previous_page"}
          nextLinkClassName={"next_page"}
          disabledClassName={"disabled"}
          activeClassName={"active"}
          activeLinkClassName={"pagination__link--active"}
          // pageLinkClassName={"active"}
        />
      );
    }
    return (
      <React.Fragment>
        <section className="backco_white" style={{ fontFamily: "Arial" }}>
          <Header />

          <div className="row col_margin">
            <div className="col-md-3 col_padding" style={{ padding: "2%" }}>
              <CpdActivitiesSIDEbar data={this.state.data} />
            </div>
            <div className="col-md-9 col_padding intr_mr">
              <div id="UpcomingWebinars" className="container tab-pane ">
                <div className="row">
                  <div className="col-md-11">
                    <div className="row col_margin">
                      <div className="col-md-12  mtb_8v">
                        <h3 className="text-left mt-23 bold">
                          {" "}
                          {this.state.webinarName}
                        </h3>
                      </div>
                    </div>
                    <div className="row  mtb_8v">
                      <div className="col-md-4">
                        <div className="input-group tm-10 text_border">
                          <input
                            type="text"
                            className="form-control"
                            style={{ border: "unset" }}
                            placeholder="Search Webinar Here"
                            name="search"
                            onChange={this.handleChange1}
                            value={this.state.search}
                          />
                          <div className="input-group-btn">
                            <button className="btn btn-default" type="submit">
                              <i className="fas fa-search" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <h5 className="filter_title tm-5 text-center">
                          Instructor
                        </h5>
                      </div>
                      <div className="col-md-4">
                        <Select
                          className="mtb-10"
                          styles={colourStyles}
                          name="selectedInstructor"
                          options={this.state.InstructorSet}
                          value={this.state.selectedInstructor}
                          onChange={this.handleInstructor}
                        />
                      </div>
                      <div className="col-md-2">
                        <div className="tm-10">
                          <select
                            value={this.state.dateSort}
                            onChange={this.statechange}
                            name="dateSort"
                            className="form-control co_black text_border"
                          >
                            <option>By Date </option>
                            <option value="Earliest">Newest Last </option>
                            <option value="Latest">Newest First </option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="row col_margin mp-8">
                      {this.state.loading == true ? (
                        <div className="loaderDiv text-center">
                          <Loader
                            type="Oval"
                            color="#06580e"
                            height={80}
                            width={80}
                          />
                        </div>
                      ) : (
                        this.state.elements
                      )}
                    </div>
                    {paginationElement}
                  </div>
                  <div className="cold-md-1"></div>
                </div>
              </div>
            </div>
          </div>

          {/*<Footer/>*/}
        </section>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    allWebinars: state.webinarData.publishedWebinars,
  };
}
export default connect(mapStateToProps, {
  getAllPublishedWebinars,
  getWebinarByType,
  getInstructors,
  getWebinarCount,
})(WebinarSearch);
