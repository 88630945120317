import React from "react";
import Header from "../../Home/Header";
import { connect } from "react-redux";
import { adminLogin, adminlogout } from "../../redux/user/useraction";
import { passwordReset } from "../../redux/admin/users/adminuseractions";
import WebNotif from "../../components/WebNotif";

var obj = new WebNotif();

class AdminLogin extends React.Component {
  constructor() {
    super();
    this.state = {
      username: "",
      password: "",

      enabled: false,
    };
  }
  statechange = (event) => {
    this.setState({ [event.target.name]: event.target.value }, () => {
      // console.log(this.state)
    });
  };

  reset = () => {
    //console.log("reset clicked")
    // this.props.passwordReset( {email: "peccpd@gmail.com"}).then((res)=>{
    //     console.log(res);
    // }).catch((err)=>{
    //     console.log(err);
    // });
    this.props.history.push("/loginReset");
  };
  adminlogin = (e) => {
    e.preventDefault();
    this.props
      .adminLogin({ uname: this.state.username, pass: this.state.password })
      .then((res) => {
        localStorage.setItem("admin_info", JSON.stringify(res?.data));
        obj.createNotification("success", "Login Successful");
        this.props.history.push("/admin/dashboard");
        window.location.reload();
      })
      .catch((err) => {
        //  console.log(err.response.data.error)
        obj.createNotification("error", err.response.data.error);
      });
  };
  logout = () => {
    localStorage.removeItem("admin_info");
    this.props.adminlogout();
    this.props.history.push("/admin");
  };

  render() {
    const { username, password } = this.state;
    const enabled = username.length > 0 && password.length > 0;
    return (
      <React.Fragment>
        <Header />
        <div className="container">
          {this.props.User ? (
            <div>
              <div className="row coming-soon">
                <h2>You are Already logged In</h2>
              </div>

              <div className="row coming-soon ">
                <button
                  type="button"
                  onClick={this.logout}
                  class="btn btn-dark"
                >
                  <i className="fas fa-sign-out-alt"></i> Log out
                </button>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-md-6 offset-md-3">
                <form
                  className="form-horizontal form-paddin-register needs-validation"
                  data-toggle="validator"
                  role="form"
                >
                  <h3 className="center bold">Admin Login</h3>
                  <div className="col-sm-12">
                    <p className="center fz_14">
                      Please enter your account credentials to log into
                      Continuing Professional Development Admin Portal.
                    </p>
                  </div>

                  <div className="pl_12">
                    <div className="form-group">
                      <label
                        className="pl-18px control-label ipd_col_pad"
                        for="emailr"
                      >
                        Enter Email<span className="co_red">*</span>
                      </label>
                      <div className="col-sm-10 ipd_col_pad">
                        <input
                          required
                          id="username"
                          value={this.state.cnic}
                          type="text"
                          name="username"
                          className="form-control co_black"
                          placeholder="username"
                          onChange={this.statechange}
                        />
                      </div>
                      <div className="invalid-feedback">
                        Please fill out this field.
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="pl-18px control-label ipd_col_pad">
                        Enter Password<span className="co_red">*</span>
                      </label>
                      <div className="col-sm-10 ipd_col_pad">
                        <input
                          type="password"
                          className="form-control co_black"
                          placeholder="Password "
                          onChange={this.statechange}
                          required
                          value={this.state.password}
                          name="password"
                        />
                      </div>
                    </div>

                    <div className="form-group col-sm-10">
                      <div className="custom-control custom-checkbox check-pl">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="customCheck"
                          name="example1"
                        />
                        <label
                          className="custom-control-label lb-font pt_3 "
                          htmlFor="customCheck"
                          style={{ color: "black", cursor: "pointer" }}
                        >
                          Remember me
                        </label>
                        <a
                          style={{ cursor: "pointer" }}
                          target="_blank"
                          className="forget float_r pt_3"
                          onClick={this.reset}
                        >
                          {" "}
                          Forgot my password
                        </a>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="col-sm-offset-2 col-sm-10">
                        <button
                          type="submit"
                          disabled={!enabled}
                          className="btn btn-success bold"
                          style={{ width: "100%" }}
                          onClick={this.adminlogin}
                        >
                          LOGIN
                        </button>
                      </div>
                    </div>
                    <div className="form-group text-center">
                      <div className="col-sm-10">
                        <ul className="nav nav-tabs" role="tablist">
                          {/* <a className="forget a_width" data-toggle="tab" href="#UpcomingWebinars">Don't have an account? <u>Sign up now</u></a> */}
                        </ul>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    User: state.userData.admin,
  };
}
export default connect(mapStateToProps, {
  adminLogin,
  adminlogout,
  passwordReset,
})(AdminLogin);
