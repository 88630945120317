import React, { Component } from 'react';
import { connect } from 'react-redux';
import MUIDataTable from "mui-datatables";
import { GotAttendenceData } from "../../redux/admin/users/adminuseractions";

const columns = [
    "Full Name",
    "Email Address",
    "Course Name",
    "Time Joined",
    "Time Left",
];
const options = {

    responsive: "vertical",
    tableBodyHeight: "600px",
    tableBodyMaxHeight: "",
    filter: true,
    filterType: "dropdown",


};
class Attendence extends Component {
    state = {
        attendenceData: [],
    }
    componentDidMount() {
        this.props.GotAttendenceData().then((res => {
            for (let i = 0; i < res.data.length; i++) {
                let temp = [];
                temp.push({
                    "Full Name": res.data[i].FullName,
                    "Email Address": res.data[i].PrimaryEmail,
                    "Course Name": res.data[i].Course_Name,
                    "Time Joined": res.data[i].TimeJoined,
                    "Time Left": res.data[i].TimeLeft,
                })
                this.setState({
                    attendenceData: temp
                })
            }
        })).catch((err) => {
            console.log(err);
        });
    }
    render() {
        return (
            <React.Fragment>
                <h4 className="regular_font fs-26 mtb-35">Attendence</h4>
                <MUIDataTable
                    title={"Attendence"}
                    data={this.state.attendenceData}
                    columns={columns}
                    options={options}
                />
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        admins: state.UserManageData.alladmins
    }
}
export default connect(mapStateToProps, { GotAttendenceData })(Attendence);