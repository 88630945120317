import React from "react";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import * as adminRoutes from "../../constants/adminRoutes";
import ProtectedAdminRoute from "../ProtectedAdminRoute";
//components
import AdminSidebar from "./AdminSidebar";
import Webinars from "./Webinars";
import CoursePayments from "./CoursePayments";
import UserManagement from "./UserManagement";
import EngineerList from "./EngineersList";
import Attendence from "./Attendence";
import WebinarForm from "./WebinarForm";
import dashboard from "./dashboard";
import { connect } from "react-redux";
import CPDClaims from "./CPDClaims";
import AttendenceCPD from "./AttendenceCPD";
import WebinarAttendees from "./WebinarAttendees";
import AdminPermission from "./AdminPermission";
import WebinarRegistrations from "./WebinarRegistrations";
import WebinarRegistered from "./WebinarRegistered";
import WebinarAttendeesAll from "./WebinarAttendeesAll";
import AdminSummary from "./AdminSummary";
import { getRolePermissions } from "../../redux/user/useraction";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import cpdLogo from "../../assets/image/new_logos/cpd.png";
import pecLogo from "../../assets/image/new_logos/pec_logo.jpg";

class AdminMain extends React.Component {
  state = {
    permissions: null,
    permission_rules: null,
  };
  componentDidMount() {
    // console.log(this.props);
    this.props
      .getRolePermissions(this.props.admindetails.RoleID)
      .then((res) => {
        let comp = [];
        let permission_rules = {};
        for (let i = 0; i < res.data.length; i++) {
          comp.push(res.data[i].component);
        }
        for (let j = 0; j < res.data.length; j++) {
          for (let k = 0; k < comp.length; k++) {
            if (res.data[j].component == comp[k]) {
              permission_rules = {
                ...permission_rules,
                [comp[k]]: res.data[j],
              };
            }
          }
        }

        // Use concat and assign the result back to comp
        comp = comp.concat("AdminSummary");

        permission_rules = {
          ...permission_rules,
          AdminSummary: {
            Add: 1,
            Approve: 1,
            Delete: 1,
            Edit: 1,
            Publish: 0,
            View: 1,
            component: "AdminSummary",
            p_id: 9,
            role_id: 3,
          },
        };

        this.setState({
          permissions_comp: comp,
          permission_rules: permission_rules,
        });
      });
  }
  render() {
    // const classes = useStyles();

    return (
      <React.Fragment>
        <div className="container-fluid">
          <div className="row">
            <AppBar
              style={{
                backgroundColor: "#fff",
                paddingTop: "5px",
                boxShadow: "none",
              }}
              position="static"
            >
              <Toolbar>
                <Grid container spacing={1}>
                  <Grid item lg={4} md={4} sm={4}>
                    <Link to="/admin/dashboard/">
                      <img
                        alt="companyLogo"
                        src={pecLogo}
                        className="company-logo"
                      />
                    </Link>
                  </Grid>
                  <Grid item lg={4} md={4} sm={4}>
                    <h5 className="centerh">
                      Continuing Professional <br/> Development (CPD)
                    </h5>
                  </Grid>

                  <Grid item lg={4} md={4} sm={4}>
                    <div style={{ float: "right" }}>
                      <img
                        alt="departmentLogo"
                        src={cpdLogo}
                        className="department-logo"
                      />
                    </div>
                  </Grid>
                </Grid>
              </Toolbar>
            </AppBar>
          </div>
          <div className="row">
            <AdminSidebar />
            <div className="col-md-10" id="main">
              <Switch>
                {this.state.permissions_comp &&
                  this.state.permissions_comp.includes("AdminSummary") && (
                    <ProtectedAdminRoute
                      exact
                      path="/admin/dashboard/summary"
                      component={AdminSummary}
                      permissions={this.state.permission_rules.Webinar}
                    />
                  )}
                {this.state.permissions_comp &&
                  this.state.permissions_comp.includes("Webinar") && (
                    <ProtectedAdminRoute
                      exact
                      path="/admin/dashboard/"
                      component={dashboard}
                      permissions={this.state.permission_rules.Webinar}
                    />
                  )}
                {this.state.permissions_comp &&
                this.state.permissions_comp.includes("Webinar") &&
                this.state.permission_rules.Webinar.View === 1 ? (
                  <ProtectedAdminRoute
                    exact
                    path={adminRoutes.webinar}
                    component={Webinars}
                    permissions={this.state.permission_rules.Webinar}
                    courseType={2}
                  />
                ) : (
                  ""
                )}
                {this.state.permissions_comp &&
                this.state.permissions_comp.includes("Webinar") &&
                this.state.permission_rules.Webinar.View === 1 ? (
                  <ProtectedAdminRoute
                    exact
                    path={adminRoutes.webinarattendees}
                    component={WebinarAttendees}
                    permissions={this.state.permission_rules.Webinar}
                    courseType={2}
                  />
                ) : (
                  ""
                )}
                {this.state.permissions_comp &&
                this.state.permissions_comp.includes("Webinar") &&
                this.state.permission_rules.Webinar.View === 1 ? (
                  <ProtectedAdminRoute
                    exact
                    path={adminRoutes.webinarregistered}
                    component={WebinarRegistered}
                    permissions={this.state.permission_rules.Webinar}
                    courseType={2}
                  />
                ) : (
                  ""
                )}
                {this.state.permissions_comp &&
                this.state.permissions_comp.includes("AP") &&
                this.state.permission_rules.AP.View === 1 ? (
                  <ProtectedAdminRoute
                    exact
                    path={adminRoutes.AdminPermission}
                    component={AdminPermission}
                    permissions={this.state.permission_rules.AP}
                  />
                ) : (
                  ""
                )}
                {this.state.permissions_comp &&
                this.state.permissions_comp.includes("Webinar") &&
                this.state.permission_rules.Webinar.View === 1 ? (
                  <ProtectedAdminRoute
                    exact
                    path={adminRoutes.webinarForm}
                    component={WebinarForm}
                    permissions={this.state.permission_rules.Webinar}
                    courseType={2}
                    // permissions={this.state.permission_rules.Webinar}
                  />
                ) : (
                  ""
                )}
                {this.state.permissions_comp &&
                this.state.permissions_comp.includes("Payment") &&
                this.state.permission_rules.Payment.View === 1 ? (
                  <ProtectedAdminRoute
                    exact
                    path={adminRoutes.admincoursepayment}
                    component={CoursePayments}
                    permissions={this.state.permission_rules.Payment}
                  />
                ) : (
                  ""
                )}

                {this.state.permissions_comp &&
                this.state.permissions_comp.includes("Attendence") &&
                this.state.permission_rules.Attendence.View === 1 ? (
                  <ProtectedAdminRoute
                    exact
                    path={adminRoutes.WebinarAttendeesAll}
                    component={WebinarAttendeesAll}
                    permissions={this.state.permission_rules.Attendence}
                  />
                ) : (
                  ""
                )}

                {this.state.permissions_comp &&
                this.state.permissions_comp.includes("Payment") &&
                this.state.permission_rules.Payment.View === 1 ? (
                  <ProtectedAdminRoute
                    exact
                    path={adminRoutes.WebinarRegistrations}
                    component={WebinarRegistrations}
                    permissions={this.state.permission_rules.Payment}
                  />
                ) : (
                  ""
                )}

                {this.state.permissions_comp &&
                this.state.permissions_comp.includes("AdminUser") &&
                this.state.permission_rules.AdminUser.View === 1 ? (
                  <ProtectedAdminRoute
                    exact
                    path={adminRoutes.usermanagement}
                    component={UserManagement}
                    permissions={this.state.permission_rules.AdminUser}
                  />
                ) : (
                  ""
                )}
                {this.state.permissions_comp &&
                this.state.permissions_comp.includes("AdminUser") &&
                this.state.permission_rules.AdminUser.View === 1 ? (
                  <ProtectedAdminRoute
                    exact
                    path={adminRoutes.EngineerList}
                    component={EngineerList}
                    permissions={this.state.permission_rules.AdminUser}
                  />
                ) : (
                  ""
                )}
                {this.state.permissions_comp &&
                this.state.permissions_comp.includes("UC") &&
                this.state.permission_rules.UC.View === 1 ? (
                  <ProtectedAdminRoute
                    exact
                    path={adminRoutes.cpdclaims}
                    component={CPDClaims}
                    permissions={this.state.permission_rules.UC}
                  />
                ) : (
                  ""
                )}
                {this.state.permissions_comp &&
                this.state.permissions_comp.includes("Attendence") &&
                this.state.permission_rules.Attendence.View === 1 ? (
                  <ProtectedAdminRoute
                    exact
                    path={adminRoutes.attendence}
                    component={Attendence}
                    permissions={this.state.permission_rules.Attendence}
                  />
                ) : (
                  ""
                )}
                {this.state.permissions_comp &&
                this.state.permissions_comp.includes("CPA") &&
                this.state.permission_rules.CPA.View === 1 ? (
                  <ProtectedAdminRoute
                    exact
                    path={adminRoutes.cpdpoints}
                    component={AttendenceCPD}
                  />
                ) : (
                  ""
                )}
              </Switch>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    admindetails: state.userData.admin,
    permissions: state.userData.admin_permissions,
  };
}

export default connect(mapStateToProps, { getRolePermissions })(AdminMain);
