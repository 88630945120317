import React, { Component } from 'react'
import Header from './Header';
import Footer from './Footer';
import { Link } from 'react-router-dom';
import CpdActivitiesSIDEbar from './CpdActivitiesSIDEbar'

export default class ComingSoon extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [
                {
                    name: "Introduction",
                    url: "/ProfessionalEngnrBodies",
                    type: "a"
                },
                {
                    name: "Criteria for Registration",
                    url: "/ProfessionalEngnrBodies#crieteria",
                    type: "a"
                },
                {
                    name: "Procedure for Registration",
                    url: "/ProfessionalEngnrBodies#procedure",
                    type: "a"
                },
                {
                    name: "List of PEBs",
                    url: "/PEB"
                },
                {
                    name: "PEB Dashboard",
                    url: "PEBDashboard",
                    type: "button",
                    "child": [
                        {
                            name: "Apply for Renewal",
                            url: "/comingsoon",
                        },
                        {
                            name: "CPD Calendar",
                            url: "/PEBs_calendar_2020",
                        },
                        {
                            name: "CPD Returns",
                            url: "/comingsoon",
                        },
                        {
                            name: "Contact Us",
                            url: "/comingsoon",
                        }
                    ]
                }
            ]
        }
    }
    componentDidMount() {
        document.title = "Registration of Professional Engineering Bodies";
    }
    render() {
        return (
            <React.Fragment>
                <section className="backco_white" style={{ fontFamily: 'Arial' }}>
                    <Header />
                    <div className="row col_margin">
                        <div className="col-md-3 col_padding" style={{ padding: '2%' }}>
                            <CpdActivitiesSIDEbar data={this.state.data} />
                        </div>

                        <div className="col-md-7 coming-soon" id="comingsoon" >
                            <h1>Coming Soon</h1>
                        </div>
                    </div>
                    {/*<Footer/>*/}
                </section>
            </React.Fragment>
        )
    }
}
