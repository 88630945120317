import React, { Component } from "react";
import { connect } from "react-redux";
import MUIDataTable from "mui-datatables";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { getWebinarAttendees } from "../../redux/admin/users/adminuseractions";
import { red } from "@material-ui/core/colors";
const columns = [
  "Course Name",
  "Name",
  "Email",
  "PEC NO",
  "Discipline",
  "Address",
];
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "500px",
    backgroundColor: "#e0eadf",
    border: "none",
    color: "black",
    height: "300px",
    overflowY: "hidden",
    "z-index": 9999,
  },
  overlay: {
    zIndex: 1000,
    backgroundColor: "rgba(0, 0, 0, 0.35)",
  },
};
class WebinarAttendees extends Component {
  state = {
    data: [],
    filterOptionsState: false,
    perPage: 25,
    page: 0,
    limit: 25,
    offset: 0,
  };
  componentDidMount() {
    // console.log('Inside WEBINAR ATTENDEES')
    let data = [];
    this.props
      .getWebinarAttendees({id: this.props.location.state.id, limit: this.state.limit, offset: this.state.offset})
      .then((res) => {
        for (let i = 0; i < res.data.length; i++) {
          data.push({
            Name: res.data[i].FullName,
            Email: res.data[i].PrimaryEmail,
            "PEC NO": res.data[i].PEC_REG_NO,
            Discipline: res.data[i].discipline,
            Address: res.data[i].Primary_Address,
            "Course Name": res.data[i].Course_Name,
          });
        }
        this.setState({ data: data });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  render() {
    const options = {
      search: true,
      responsive: "vertical",
      tableBodyHeight: "600px",
      tableBodyMaxHeight: "",
      filter: true,
      filterType: "dropdown",
      rowsPerPage: this.state.perPage,
      rowsPerPageOptions: [25, 50, 75, 100],
      pagination: this.state.filterOptionsState ? false : true,
      serverSide: this.state.filterOptionsState ? false : true,
      count: this.state.count,
      page: this.state.page,
      onTableChange: (action, tableState) => {
        if (action === "changePage") {
          this.changePage(tableState.page, tableState.sortOrder);
        } if (action === "filterChange") {
          this.setState({filterOptionsState: true});
        }
      },
      onChangeRowsPerPage: (num) => {
        this.setState({ perPage: num, page: 0 });
        this.changeAdminRows(num);
      },
      onFilterChange: (changedColumn, filterList, type) => {
        if (type === "reset") { 
          this.resetAdminFilters();
        }
      },
      onFilterChipClose: (index, removedFilter, filterList) => {
        let temp = [];
        filterList.map((value, index) => {
          for (let i = 0; i <= value.length; i++) {
            temp.push(i);
          }
        })
        // console.log("TEMP LENGTH: ", temp.length);
        if (temp.length === 7) {
          this.setState({filterOptionsState: false});
        } else {
          this.setState({filterOptionsState: true});
        }
      }
      };
    const theme = createMuiTheme({
      overrides: {
        MUIDataTableSelectCell: {
          expandDisabled: {
            visibility: "hidden",
          },
        },
        MUIDataTableToolbar: {
          filterPaper: {
            width: "450px !important",
            height: "auto !important"
          }
      },
      },
    });
    return (
      <React.Fragment>
        <h4 className="regular_font fs-26 mtb-35">Webinar Attendees</h4>
        <MuiThemeProvider theme={theme}>
          <MUIDataTable
            // title={"CPD Forms"}
            data={this.state.data}
            columns={columns}
            options={options}
            // components={components}
          />
        </MuiThemeProvider>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    payments: state.financialData.coursepayments,
    registrations: state.financialData.registrations,
  };
}
export default connect(mapStateToProps, {
  getWebinarAttendees,
  // getcoursepayments,
  // getFreeWebinarsRegistrations,
  // approvePayment,
  // rejectPayment,
})(WebinarAttendees);
