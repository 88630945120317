import React from 'react';
import Header from './Header';
import {connect} from 'react-redux';
import {urlImageUrl} from '../config/urlapi';
import Carousel, { Modal, ModalGateway } from 'react-images';

//import CpdActivitiesSIDEbar from './CpdActivitiesSIDEbar'

class Gallery extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            image_reel: null,
            carousel_images:null,
              
              modalIsOpen:false
     
        }
    }
    
    toggleModal = () => {
      this.setState(state => ({ modalIsOpen: !state.modalIsOpen }));
    }
    UNSAFE_componentWillMount(){
      if(this.props.location.state){
        const propsImages = this.props.location.state.images;
        const album=this.props.match.params.id;
        let albumImages=[];
        let viewImages=[] 
        for(let i =0 ; i<propsImages.length; i++){
          if(propsImages[i].event_name==album){
            albumImages.push(urlImageUrl+propsImages[i].pic_url)
            viewImages.push({source:urlImageUrl+propsImages[i].pic_url})
          }
        }

        this.setState({image_reel:albumImages,carousel_images:viewImages})
      
      }
    }
    
    render() {
      const { modalIsOpen } = this.state;
        return (
       <React.Fragment>
      <section  className="backco_white" style={{ fontFamily: 'Arial' }}>
          <Header />
          <div className="container">
          <h3 className="mt-4">Gallery</h3>
          <hr/>
              <div className="mtb_7v">
                  <div className="row ">
                    
                
                  
                      {this.state.image_reel && this.state.image_reel.length>0 && 
                      this.state.image_reel.map(item => 
                        <div className="col-md-4 ptb-30">
                        <div className="card gallery_cards" onClick={this.toggleModal} style={{width: '18rem'}}>
                        <img className="card-img-top" src={item} alt="Card image cap"/>
                        <div className="card-body">

                         
                        </div>
                        </div>
                        </div>
                      )}
                   
                  
                   <ModalGateway>
                   {modalIsOpen ? (
                     <Modal onClose={this.toggleModal}>

                       <Carousel views={this.state.carousel_images}/>

                     </Modal>
                   ) : null}
                 </ModalGateway>

                
                    </div>


                    

                      {/* <div className="col-md-4">
                              <div className="card">
                              <img className="card-img-top card_gallery_img" src={require('../assets/image/cpd/gallery/1gal.jpg')} alt="Card image cap" />
                              <div className="card-body card_gallery_body">
                                <h5 className="card-title">CPD short Course on advance network simulation using ns3</h5>
                                <p className="card-text"></p>
                               <Link to="/sub_gallery" className="btn btn-success bold">VIEW ALL PHOTOS</Link>
                              </div>
                            </div>
                            <br/>
                      </div> */}
                     
           
              </div>
              </div>
          {/*<Footer/>*/}
      </section>
  </React.Fragment>

        );
        
    }
}

function mapStateToProps(state){
  return {
    images : state.userData.gallery
       }
  }
export default connect (mapStateToProps,)(Gallery);