import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import CpdActivitiesSIDEbar from "./CpdActivitiesSIDEbar";

class News extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [
        {
          name: "Home",
          url: "/",
        },
        {
          name: "Introduction to CPD ",
          url: "/Introduction",
        },
        {
          name: "CPD Byelaws",
          url: "/byelaws",
        },
        {
          name: "Engineering Professional Development Committee (EPDC)",
          url: "epdc",
          type: "button",
          child: [
            {
              name: "Introduction",
              url: "/epdc/intro",
            },
            {
              name: "Organogram",
              url: "/Organogram",
            },
          ],
        },
        {
          name: "CPD Guideline Manual",
          url: "/GuideLineMauals",
        },
        {
          name: "CPD Relaxed Policy",
          url: "/CPDRelaxPolicy",
        },
        {
          name: "CPD Brief",
          url: "/CPDBriefs",
        },
        {
          name: " PEC Approved Resource Person",
          url: "psrp",
          type: "button",
          child: [
            {
              name: "List of Resourse Person",
              url: "/CPDListofResourcePerson",
            },
            {
              name: "Criteria for Resourse Person",
              url: "/CPDResourcePersonCriteria",
            },
            // ,
            // // {
            //   name: "Application Form",
            //   url: "/ApplicationForm",
            // }
          ],
        },
        {
          name: "Important News for Engineers",
          url: "/important-news",
        },
      ],
    };
  }
  componentDidMount() {
    document.title = "Important News for Engineers";
  }
  render() {
    return (
      <React.Fragment>
        <section className="backco_white" style={{ fontFamily: "Arial" }}>
          <Header />
          <div className="row col_margin">
            <div
              className="col-md-3 col_padding sidebar_container"
              style={{ padding: "2%" }}
            >
              <CpdActivitiesSIDEbar data={this.state.data} />
            </div>
            <div className="col-md-7 col_padding intr_mr">
              <h2
                className="text-center"
                style={{ margin: "28px 0px 0px 1px" }}
              >
                Important News for Engineers
              </h2>
              <div className="row">
                <div
                  className="col-md-12"
                  style={{ padding: "2%", marginLeft: "30px" }}
                >
                  {/* <h2
                    style={{
                      margin: "10px 0px 0px 1px",
                      fontWeight: 700,
                      fontSize: "1.5em",
                    }}
                  >
                    Introduction to CPD (Continuing Professional Development)
                  </h2> */}
                  <div className="into-p">
                    <p>
                      On a great initiative taken by Engr. Muhammad Najeeb
                      Haroon, Chairman PEC and the Management Committee, a
                      series of Leadership and Management topics have been
                      incorporated in the training sessions being conducted by
                      PEC under Continuing Professional Development (CPD)
                      Program.
                    </p>
                    <p>
                      The objective of this program is to harness Engineers of
                      all disciplines with latest Leadership and Management
                      Practices direly needed for their effective and efficient
                      working Patterns. The Topics have been designed to focus
                      on Personality Development of Engineers and to inculcate
                      Leadership qualities with inherent knowledge of Management
                      sciences to make them excel in their respective careers.
                    </p>
                    <p>
                      Leadership and Management studies Program has commenced
                      from December 2023 at PEC Head Office Islamabad and it
                      will be conducted in all regions starting from January
                      2024. The Knowledge Areas in this Program are as under:
                      <ul style={{ paddingLeft: "40px", paddingTop: "10px" }}>
                        <li>Presentation Skills with Practice Sessions</li>
                        <li>Leadership- A new Perspective</li>
                        <li>Management Knowledge and Practices</li>
                        <li>
                          Project Management - MS Project with Practice Sessions
                        </li>
                      </ul>
                    </p>
                    <p>
                      All Engineers are welcome to avail this great opportunity
                      to transform themselves into vibrant and dynamic leaders
                      to embrace modern world challenges.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-1"></div>
          </div>
          {/*<Footer/>*/}
        </section>
      </React.Fragment>
    );
  }
}
export default News;
