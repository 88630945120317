import api from "../../api";

export const UserLoggedIn = (data) => ({
  type: "SUCCESSFUL_LOGIN",
  data,
});

export const UserLoggedOut = () => ({
  type: "LOGOUT",
});

export const AdminLoggedOut = () => ({
  type: "LOGOUT",
});
export const PaymentSubmitted = (data) => ({
  type: "PAYMENT_SUBMITTED",
  data,
});

export const EpePointsChecked = (data) => ({
  type: "EPE_POINTS_CHECKED",
  data,
});
export const RegisteredFreeWebinar = (data) => ({
  type: "REGISTERED_FREE_WEBINAR",
  data,
});
export const GotStatus = (data) => ({
  type: "GOTSTATUS",
  data,
});
// export const GotUserData = (data) => ({
//   type: "GOT_COMPLETE_USER_DATA",
//   data,
// });
export const AdminLoggedIn = (data) => ({
  type: "ADMIN_LOGIN_SUCCESSFUL",
  data,
});
export const EpeLoggedIn = (data) => ({
  type: "EPE_LOGIN",
  data,
});

export const EpePaymentSubmitted = (data) => ({
  type: "EPE_PAYMENT_SUBMITTED",
  data,
});

export const GotCurrentExam = (data) => ({
  type: "GOT_CURRENT_EXAM",
  data,
});
("");
export const GotEpeHistory = (data) => ({
  type: "GOT_EPE_HISTORY",
  data,
});
export const GotSubDiscipline = (data) => ({
  type: "GOT_SUB_DISCIPLINE",
  data,
});

export const GotEpeCenter = (data) => ({
  type: "GOT_EPE_CENTER",
  data,
});

export const ReduxFormSaved = (data) => ({
  type: "SAVED_REDUX_FORM",
  data,
});

export const EpeCenterSaved = (data) => ({
  type: "EPE_CENTER_SAVED",
  data,
});

// export const login = (data) => (dispatch) =>
//       api.user.login(data).then (res =>
//         {   if(res.status==200){
//             localStorage.setItem('CPD_User_LoginData', JSON.stringify(res.data));
//             dispatch(UserLoggedIn(res.data));
//             }
//             return res

//     });

export const loginEpe = (data) => (dispatch) =>
  api.user.loginEpe(data).then((res) => {
    dispatch(EpeLoggedIn(res.data));
    return res;
  });

export const login = (data) => (dispatch) =>
  api.user
    .login(data)
    .then((res) => {
      var a =
        res.data["soap:Envelope"]["soap:Body"].sEngineerLoginResponse
          .sEngineerLoginResult._text;
      a = a.replace(/[\[\]"{}]+/g, "").split(":");

      if (a[1]) {
        // console.log("login success")
        return { success: true, id: a[1] };
      } else {
        return false;
      }
    })
    .catch((error) => {
      console.log(error);
    });
export const getUser = (id) => (dispatch) =>
  api.user.getEngineerInfo(id).then((res) => {
    // console.log("ENGINEER INFORMATION GET USER ... ", res.data);
    var userdata = {};
    // console.log(res);

    var parsedData = JSON.parse(
      res.data["soap:Envelope"]["soap:Body"].sEngineerInformationResponse
        .sEngineerInformationResult._text
    );

    function spreadEngineerInformation(data) {
      // console.log("spreadEngineerInformation INPUT ... ", data);
      const {
        engineerInformation: [
          {
            engineerId,
            name,
            contactNumber,
            engineerValidity,
            engineerValidityExpired,
            email,
            address,
            pecNo,
            discipline,
            cnic,
            disciplineId,
            institute,
            yearOfGraduation,
            city,
            province,
            dob,
            gender,
            employmentSector,
            registrationDate,
            engineerType,
            IsVoterList,
            disciplineFullTitle,
            profilePicture,
            qualificationInfo = {},
          } = {},
        ] = [],
      } = data;

      return {
        engineerId,
        name,
        contactNumber,
        engineerValidity,
        engineerValidityExpired,
        email,
        address,
        pecNo,
        discipline,
        cnic,
        disciplineId,
        institute,
        yearOfGraduation,
        city,
        province,
        dob,
        gender,
        employmentSector,
        registrationDate,
        engineerType,
        IsVoterList,
        disciplineFullTitle,
        profilePicture,
        ...(qualificationInfo.programLevel === "Graduation" && {
          qualificationInfo,
        }),
      };
    }

    const graduationInfo = spreadEngineerInformation(parsedData);

    var b =
      res.data["soap:Envelope"]["soap:Body"].sEngineerInformationResponse
        .sEngineerInformationResult._text;
    b = b.replace(/[\[\]{}]+/g, "").split('","');
    // console.table(b);
    for (var i = 0; i < b.length; i++) {
      var c = b[i].replace(/[""]+/g, "").split(":");
      const key = c[0];
      var value = c[1];
      if (c[0] === "engineerInformation") {
        value = c[2];
      }
      // console.log(c)
      userdata = { ...userdata, [key]: value };
    }
    // console.log("USER DATAAA ... ", userdata);
    // dispatch(GotUserData(userdata));
    // console.log("GRADDD INFOOOO .... ", graduationInfo);
    return graduationInfo;
  });

export const SaveUser = (userdata) => (dispatch) =>
  api.user.saveUserData(userdata).then((res) => {
    const { gender } = userdata;
    const token = res.data.token;
    const resData = res.data.data;

    const userData = { data: { ...resData, gender }, token };

    const objectStringify = JSON.stringify(userdata);

    if (res.status) {
      localStorage.setItem("CPD_User_LoginData", token);
      localStorage.setItem("User_Data_Reserve", objectStringify);
      dispatch(UserLoggedIn(userData));
      return userData;
    } else {
      return false;
    }
  });

export const checkEpePoints = (userdata) => (dispatch) =>
  api.user.checkEpePoints(userdata).then((res) => {
    // console.log("this is save user data response", res);
    dispatch(EpePointsChecked(res.data));
    return res;
  });

export const submitpayment = (data) => (dispatch) =>
  api.user.submitpayment(data).then((res) => {
    dispatch(PaymentSubmitted(res.data));

    return res;
  });

export const submitEpePayment = (data) => (dispatch) =>
  api.user.submitEpePayment(data).then((res) => {
    dispatch(EpePaymentSubmitted(res.data));

    return res;
  });

export const getCurrentExam = (data) => (dispatch) =>
  api.user.getCurrentExam(data).then((res) => {
    dispatch(GotCurrentExam(console.log("gotcurrentExam", res.data)));
    return res;
  });

export const getPapersOfExam = () => (dispatch) =>
  api.user.getPapersOfExam().then((res) => {
    dispatch(GotCurrentExam);
    return res;
  });

export const getEpeHistory = (data) => (dispatch) =>
  api.user.getEpeHistory(data).then((res) => {
    dispatch(GotEpeHistory(console.log("gotepehistory", res.data)));
    return res;
  });

export const getSubDiscipline = (data) => (dispatch) =>
  api.user.getSubDiscipline(data).then((res) => {
    // console.log('getSubDiscipline: ', res);
    dispatch(GotSubDiscipline(res.data));
    return res;
  });

export const getEpECenter = (data) => (dispatch) =>
  api.user.getEpECenter(data).then((res) => {
    dispatch(GotEpeCenter(res.data));
    return res;
  });

export const saveCenterEpe = (data) => (dispatch) => {
  dispatch(EpeCenterSaved(data));
  return data;
};

export const saveEpeFormRedux = (data) => (dispatch) => {
  dispatch(ReduxFormSaved(data));
  localStorage.setItem("reduxEpeForm", JSON.stringify(data));
  return data;
};

export const logout = () => (dispatch) => {
  localStorage.removeItem("CPD_User_LoginData");
  localStorage.removeItem("User_Data_Reserve");
  localStorage.removeItem("globalForms");
  localStorage.removeItem("formMui");
  localStorage.removeItem("formList");
  localStorage.removeItem("reduxEpeForm");
  dispatch(UserLoggedOut());
  return true;
};

export const registerforfree = (data) => (dispatch) =>
  api.user.registerforfree(data).then((res) => {
    dispatch(RegisteredFreeWebinar(res.data));
    return res;
  });

export const getStatusFreeWebinar = (data) => (dispatch) =>
  api.user.get_status_free_webinars(data).then((res) => {
    dispatch(GotStatus(res));
    return res;
  });
export const gotAttendedWebinarProfile = (data) => ({
  type: "GOT_ATTENDED_WEBINAR_PROFILE",
  data,
});
export const attended_webinars_profile = (data) => (dispatch) =>
  api.user.attended_webinars_profile(data).then((res) => {
    dispatch(gotAttendedWebinarProfile(res.data));
    return res;
  });

export const gotRegisteredWebinarProfile = (data) => ({
  type: "GOT_REGISTERED_WEBINAR_PROFILE",
  data,
});
export const registered_webinar_profile = (data) => (dispatch) =>
  api.user.registered_webinar_profile(data).then((res) => {
    dispatch(gotRegisteredWebinarProfile(res.data));
    // console.log(gotRegisteredWebinarProfile(res.data));
    return res;
  });
export const getStatusPricedWebinar = (data) => (dispatch) =>
  api.user.get_status_priced_webinars(data).then((res) => {
    dispatch(GotStatus(res));
    return res;
  });

export const adminLogin = (data) => (dispatch) =>
  api.user.admin_login(data).then((res) => {
    // console.log('admin login resssss: ', res.data);
    if (res.status == 200) {
      // localStorage.setItem("CPD_Admin_Info", res.data);
      localStorage.setItem("CPD_Admin_LoginData", res.data.token);
      dispatch(AdminLoggedIn(res.data));

      return res.data;
    } else {
      return false;
    }
  });

export const adminlogout = () => (dispatch) => {
  // localStorage.removeItem("CPD_Admin_Info");
  localStorage.removeItem("CPD_Admin_LoginData");
  dispatch(AdminLoggedOut());
  return true;
};

export const GotGallery = (data) => ({
  type: "GOT_GALLERY",
  data,
});
export const getgallery = () => (dispatch) =>
  api.user.get_gallery().then((res) => {
    dispatch(GotGallery(res.data));
    return res.data;
  });

//mubashir cpd actions
export const GotAllFields = (data) => ({
  type: "GOT_FIELDS",
  data,
});
export const getFields = () => (dispatch) =>
  api.user.get_all_fields().then((res) => {
    //  console.log(res.data);
    dispatch(GotAllFields(res.data));
    return res.data;
  });

export const GotSubCategories = (data) => ({
  type: "GOT_SUBCATEGORIES",
  data,
});
export const getSubCategories = (data) => (dispatch) =>
  api.user.get_subcategories(data).then((res) => {
    // console.log(res.data);
    dispatch(GotSubCategories(res.data));
    return res.data;
  });

export const GotSubFields = (data) => ({
  type: "GOT_SUBFIELDS",
  data,
});
export const getSubFields = (data) => (dispatch) =>
  api.user.get_subfields(data).then((res) => {
    //   console.log(res.data);
    dispatch(GotSubFields(res.data));
    return res.data;
  });

export const GotPoints = (data) => ({
  type: "GOT_POINTS",
  data,
});
export const getPoints = (data) => (dispatch) =>
  api.user.get_points(data).then((res) => {
    //  console.log(res.data);
    dispatch(GotPoints(res.data));
    return res.data;
  });

export const CPDFORMSUBMITTED = () => ({
  type: "CPD_FORM_SUMITTED",
});
export const submitCPDForm = (data) => (dispatch) =>
  api.user
    .submitCPDForm(data)
    .then((res) => {
      dispatch(CPDFORMSUBMITTED());
      return res;
    })
    .catch((err) => console.log("Error: ", err.response));
export const GOTUSERSUBMISSION = (data) => ({
  type: "GOT_USER_SUBMISSION",
  data,
});
export const GOTUSERCLAIMBASED = (data) => ({
  type: "GOT_USER_CLAIM_BASED",
  data,
});
export const DUPLICATE_RECORDS_CHECK = (data) => ({
  type: "DUPLICATE_RECORDS_CHECK",
  data,
});

export const getUserSubmission = (data) => (dispatch) =>
  api.user.get_user_submission(data).then((res) => {
    dispatch(GOTUSERSUBMISSION());
    return res;
  });

export const getClaimBasedSubmission = (data) => (dispatch) =>
  api.user.get_claim_based_points(data).then((res) => {
    dispatch(GOTUSERCLAIMBASED(data));
    return res;
  });

export const getUserDuplicateRecords = (data) => (dispatch) =>
  api.user.get_duplicate_records(data).then((res) => {
    dispatch(DUPLICATE_RECORDS_CHECK(data));
    return res;
  });

//get permissions by Role ID

export const GotPermissions = (data) => ({
  type: "GOT_PERMISSIONS_BY_ROLE",
  data,
});

export const getRolePermissions = (role_id) => (dispatch) =>
  api.user.get_permissions(role_id).then((res) => {
    dispatch(GotPermissions(res.data));
    return res;
  });

//joined webinar
export const JoinedWebinar = () => ({
  type: "JOINED_WEBINAR",
});
export const joinedWebinar = (data) => (dispatch) =>
  api.user.join_webinar(data).then((res) => {
    dispatch(JoinedWebinar());
    return res;
  });

export const LeftWebinar = () => ({
  type: "LEFT_WEBINAR",
});

export const leftwebinar = (data) => (dispatch) =>
  api.user.leave_webinar(data).then((res) => {
    dispatch(LeftWebinar());
    return res;
  });

//add postal address
export const AddedPostalAddress = (data) => ({
  type: "ADDED_POSTAL",
  data,
});
export const addpostalAddress = (data) => (dispatch) =>
  api.user.add_postal(data).then((res) => {
    dispatch(AddedPostalAddress(res.data));
  });
