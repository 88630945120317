import React from "react";
import { TextField, MenuItem } from "@material-ui/core";
import WebNotif from "../WebNotif";
import * as yup from "yup";
import Loader from "react-loader-spinner";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import {
  passwordResetSet,
} from "../../redux/admin/users/adminuseractions";
import { toggleFullscreen } from "video-react/lib/actions/player";
import { ThreeSixtyOutlined, Tune } from "@material-ui/icons";

var obj = new WebNotif();

const emailSchema = yup.object().shape({
  email: yup.string().email().required(),
});
const passwordSchema = yup.object().shape({
  pass: yup
    .string()
    .required("Please enter your password")
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Password must contain at least 8 characters, one uppercase, one number and one special case character"
    ),
  confirmPass: yup
    .string()
    .required("Please confirm your password")
    .when("password", {
      is: (password) => (password && password.length > 0 ? true : false),
      then: yup.string().oneOf([yup.ref("password")], "Password doesn't match"),
    }),
});

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "green",
    },
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "#20a84c",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#20a84c",
      },
    },
  },
})(TextField);

class ResetAdminPassword extends React.Component {
  state = {
    email: "",
    pass: "",
    confirmPass: "",
    loading: false,
    next: false,
    emailFlag: false,
    token: null,
    tokenFlag: false,
    jwtHasExpired: false
  };
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  componentDidMount() {
    console.log("This Props Params: ", this.props.match.params);
    console.log("This Props Email: ", this.props.match.params.email);
    console.log("This Props Token: ", this.props.match.params.token);

    if (this.props.match.params.token && this.props.match.params.token) {
      this.setState({
        email: this.props.match.params.email,
        tokenFlag: true,
        token: this.props.match.params.token,
      });
    } else {
      this.setState({
        emailFlag: true,
      });
    }
  }
  // proceed = () => {
  //   this.props
  //     .passwordReset({ email: this.state.email })
  //     .then((res) => {
  //       console.log("Res Data passwordReset: ", res.data);
  //       this.setState({
  //         token: res.data,
  //         next: true,
  //         emailFlag: false,
  //         tokenFlag: true,
  //       });
  //     })
  //     .catch((err) => {
  //       obj.createNotification(
  //         "error",
  //         "Email does not exist. Please enter correct email"
  //       );
  //     });
    // emailSchema.validate(this.state)
    // .then(
    //   validation=>{

    //     console.log(validation)
    //     this.setState({loading:true},
    //       ()=>{
    //         this.props.verifyEmail(this.state.email).then(res=>
    //           {
    //             console.log(res.data)
    //               obj.createNotification('success','Email Found, Set Your Password Now!')
    //               this.setState({loading:false,next:true})

    //             }).catch(err=>{
    //             console.log(err)
    //               obj.createNotification('error',err.response.data.message)
    //               this.setState({
    //                 loading:false
    //               })
    //           }
    //             );
    //       })

    //   }).catch(
    //     err=>
    //    {
    //     console.log(err)
    //     obj.createNotification('error','Enter Correct Email Type')}
    //   )
  // };

  setPass = () => {
    passwordSchema
      .validate(this.state)
      .then((validation) => {
        this.setState(
          {
            loading: true,
          },
          () => {
            if (this.state.pass !== this.state.confirmPass) {
              obj.createNotification("error", "Password doesn't match");
              this.setState({ loading: false })
            } else {
              this.props
                .passwordResetSet({
                  validate: validation,
                  token: this.state.token,
                })
                .then((res) => {
                  this.setState({ loading: false }, () => {
                    obj.createNotification(
                      "success",
                      "Password updated successfully"
                    );
                    this.props.history.push("/admin");
                  });
                })
                .catch((err) => {
                  obj.createNotification(
                    "error",
                    "Token is invalid or expired, please click on forgot my password"
                  );
                  this.props.history.push("/admin");
                  this.setState({ loading: false, jwtHasExpired: true }, () => {
                    // console.log('JWT HAS EXPIRED: ', this.state.jwtHasExpired)
                    console.log(err);
                  });
                });
            }
          }
        );
      })
      .catch((err) => {
        console.log(err);
        obj.createNotification("error", err.message);
      });

    //   passwordSchema.validate(this.state).then(
    //     validation=>{
    //       console.log(validation)
    //       this.setState({
    //         loading:true
    //       },()=>{

    //         this.props.passwordReset(validation).then(res=>{

    //           console.log(res)
    //           this.setState({loading:false},()=>{
    //               this.props.history.push('/admin')
    //           })

    //         }).catch(err=>{
    //           console.log(err)

    //         })
    //       })

    //     }
    // ).catch(
    //   err=>{
    //     console.log(err)
    //      obj.createNotification("error",err.message)
    //   }
    // )
  };

  render() {
    console.log("Inside ResetAdminPassword.jsx");
    return (
      <React.Fragment>
        <div className="row ptb-20 d-flex justify-content-center align-items-center Total-Body">
          <div className="col-md-6  ">
            <h4 className="ptb-20">
                Please Reset Your Password.
            </h4>
             {this.state.jwtHasExpired ? (
              <div className="row">
              <div className="col-md-4 offset-md-4">
                  <button
                    onClick={
                      this.props.history.push('/loginReset')
                    }
                    type="submit"
                    className="btn text-center btn-submit black bold fs-20"
                  >
                    Reset Password
                  </button>
              </div>
            </div>
             ) : (
              <>
              <div>
                  <div className="form-group">
                    <CssTextField
                      type="password"
                      fullWidth={true}
                      label="Password"
                      variant="outlined"
                      name="pass"
                      value={this.state.password}
                      onChange={this.onChange}
                    />
                  </div>
                  <div className="form-group">
                    <CssTextField
                      type="password"
                      fullWidth={true}
                      label="ConfirmPassword"
                      variant="outlined"
                      name="confirmPass"
                      value={this.state.confirmPass}
                      onChange={this.onChange}
                    />

                  </div>
                </div>
            

            {this.state.loading === true ? (
              <>
                <div className="loaderDiv text-center">
                  <Loader type="Oval" color="#06580e" height={80} width={80} />
                </div>
              </>
            ) : (
              <div className="row">
                <div className="col-md-4 offset-md-4">
                    <button
                      onClick={
                        this.state.tokenFlag && this.setPass
                      }
                      type="submit"
                      className="btn text-center btn-submit black bold fs-20"
                    >
                      Reset Password
                    </button>
                </div>
              </div>
            )}
              </>
             )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {};
}
export default connect(mapStateToProps, {  passwordResetSet })(
  ResetAdminPassword
);
