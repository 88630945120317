import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import * as adminroutes from "../../constants/adminRoutes";
import { connect } from "react-redux";
import { adminlogout, getRolePermissions } from "../../redux/user/useraction";
import ListSubheader from "@material-ui/core/ListSubheader";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

class AdminSidebar extends Component {
  state = {
    allowedComponents: null,
    viewcomponents: null,
    openWebinarPayment: false,
    openUserClaims: false,
    claimType: "",
    paymentType: "",
  };
  logout = () => {
    this.props.adminlogout();
    this.props.history.push("/admin");
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    console.log("ADMIN SIDE BAR PROPS .... ", nextProps);
    let comp = [];
    if (nextProps.permissions) {
      for (let i = 0; i < nextProps.permissions.length; i++) {
        if (nextProps.permissions[i].View === 1) {
          comp.push(nextProps.permissions[i].component);
        }
      }
      this.setState({
        allowedComponents: comp,
      });
    }
  }

  handlePaymentTypeChange = (type) => {
    this.setState({ paymentType: type });
  };

  handleClaimTypeChange = (type) => {
    this.setState({ claimType: type });
  };

  render() {
    return (
      <React.Fragment>
        <div className="col-md-2 sticky-top flex-grow-1 bg-green sidebarScroll">
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader
                className="text-white font-weight-bold bg-green sidebarHeading font-16"
                component="div"
                id="nested-list-subheader"
              >
                Admin Panel
              </ListSubheader>
            }
            className="sidebarRoot"
          >
            <List component="div" disablePadding>
              <ListItem
                button
                component={NavLink}
                to={adminroutes.AdminSummary}
                className="sidebarNormalLink"
                activeClassName="sidebarActiveLink"
              >
                <ListItemText
                  primary="Summary"
                  className="sidebarListItemText"
                />
              </ListItem>

              {this.state.allowedComponents &&
                this.state.allowedComponents.includes("Webinar") && (
                  <ListItem
                    button
                    component={NavLink}
                    to={adminroutes.webinar}
                    className=" sidebarNormalLink"
                    activeClassName="sidebarActiveLink"
                  >
                    <ListItemText
                      primary="Webinars"
                      className="sidebarListItemText"
                    />
                  </ListItem>
                )}

              {this.state.allowedComponents &&
                this.state.allowedComponents.includes("AdminUser") && (
                  <ListItem
                    button
                    component={NavLink}
                    to={adminroutes.usermanagement}
                    className=" sidebarNormalLink"
                    activeClassName="sidebarActiveLink"
                  >
                    <ListItemText
                      primary="Admin Users"
                      className="sidebarListItemText"
                    />
                  </ListItem>
                )}

              {this.state.allowedComponents &&
                this.state.allowedComponents.includes("Payment") && (
                  <ListItem
                    button
                    component={NavLink}
                    exact
                    to={adminroutes.WebinarRegistrations}
                    className=" sidebarNormalLink"
                    activeClassName="sidebarActiveLink"
                  >
                    <ListItemText
                      primary="Webinar Registrations"
                      className="sidebarListItemText"
                    />
                  </ListItem>
                )}
            </List>
            {this.state.allowedComponents &&
              this.state.allowedComponents.includes("Payment") && (
                <>
                  <ListItem
                    button
                    // component={NavLink}
                    onClick={() => {
                      this.setState({
                        openWebinarPayment: !this.state.openWebinarPayment,
                      });
                    }}
                    className="sidebarNestedLink"
                  >
                    <ListItemText
                      primary="Webinar Payments"
                      className="sidebarListItemText"
                    />
                    {this.state.openWebinarPayment ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )}
                  </ListItem>

                  {this.state.allowedComponents &&
                    this.state.allowedComponents.includes("Payment") && (
                      <Collapse
                        in={this.state.openWebinarPayment}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          <ListItem
                            button
                            exact
                            onClick={() => {
                              this.handlePaymentTypeChange("pending");
                            }}
                            to={{
                              pathname: adminroutes.admincoursepayment,
                              state: {
                                pending: "pending",
                              },
                            }}
                            component={NavLink}
                            className="sidebarNormalLink"
                            activeClassName={
                              this.state.paymentType === "pending"
                                ? "sidebarActiveLink"
                                : ""
                            }
                          >
                            <ListItemText
                              primary="Pending Payments"
                              className="sidebarListItemText"
                            ></ListItemText>
                          </ListItem>
                          <ListItem
                            button
                            exact
                            onClick={() => {
                              this.handlePaymentTypeChange("approved");
                            }}
                            to={{
                              pathname: adminroutes.admincoursepayment,
                              state: {
                                approved: "approved",
                              },
                            }}
                            component={NavLink}
                            className="sidebarNormalLink"
                            activeClassName={
                              this.state.paymentType === "approved"
                                ? "sidebarActiveLink"
                                : ""
                            }
                          >
                            <ListItemText
                              primary="Approved Payments"
                              className="sidebarListItemText"
                            ></ListItemText>
                          </ListItem>
                          <ListItem
                            button
                            exact
                            onClick={() => {
                              this.handlePaymentTypeChange("rejected");
                            }}
                            to={{
                              pathname: adminroutes.admincoursepayment,
                              state: {
                                rejected: "rejected",
                              },
                            }}
                            component={NavLink}
                            className="sidebarNormalLink"
                            activeClassName={
                              this.state.paymentType === "rejected"
                                ? "sidebarActiveLink"
                                : ""
                            }
                          >
                            <ListItemText
                              primary="Rejected Payments"
                              className="sidebarListItemText"
                            ></ListItemText>
                          </ListItem>
                        </List>
                      </Collapse>
                    )}
                </>
              )}

            {this.state.allowedComponents &&
              this.state.allowedComponents.includes("Attendence") && (
                <ListItem
                  exact
                  button
                  component={NavLink}
                  to={adminroutes.WebinarAttendeesAll}
                  className="sidebarNormalLink"
                  activeClassName="sidebarActiveLink"
                >
                  <ListItemText
                    primary="Webinar Attendees"
                    className="sidebarListItemText"
                  />
                </ListItem>
              )}

            {this.state.allowedComponents &&
              this.state.allowedComponents.includes("UC") && (
                <>
                  <ListItem
                    button
                    // component={NavLink}
                    onClick={() => {
                      this.setState({
                        openUserClaims: !this.state.openUserClaims,
                      });
                    }}
                    className="sidebarNestedLink"
                  >
                    <ListItemText
                      primary="User Claims"
                      className="sidebarListItemText"
                    />
                    {this.state.openUserClaims ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )}
                  </ListItem>

                  <Collapse
                    in={this.state.openUserClaims}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      <ListItem
                        button
                        exact
                        onClick={() => {
                          this.handleClaimTypeChange("pending");
                        }}
                        to={{
                          pathname: adminroutes.cpdclaims,
                          state: {
                            pending: "pending",
                          },
                        }}
                        component={NavLink}
                        className="sidebarNormalLink"
                        activeClassName={
                          this.state.claimType === "pending"
                            ? "sidebarActiveLink"
                            : ""
                        }
                      >
                        <ListItemText
                          primary="Pending Claims"
                          className="sidebarListItemText"
                        ></ListItemText>
                      </ListItem>
                      <ListItem
                        button
                        exact
                        onClick={() => {
                          this.handleClaimTypeChange("approved");
                        }}
                        to={{
                          pathname: adminroutes.cpdclaims,
                          state: {
                            approved: "approved",
                          },
                        }}
                        component={NavLink}
                        className="sidebarNormalLink"
                        activeClassName={
                          this.state.claimType === "approved"
                            ? "sidebarActiveLink"
                            : ""
                        }
                      >
                        <ListItemText
                          primary="Approved Claims"
                          className="sidebarListItemText"
                        ></ListItemText>
                      </ListItem>
                      <ListItem
                        button
                        exact
                        onClick={() => {
                          this.handleClaimTypeChange("rejected");
                        }}
                        to={{
                          pathname: adminroutes.cpdclaims,
                          state: {
                            rejected: "rejected",
                          },
                        }}
                        component={NavLink}
                        className="sidebarNormalLink"
                        activeClassName={
                          this.state.claimType === "rejected"
                            ? "sidebarActiveLink"
                            : ""
                        }
                      >
                        <ListItemText
                          primary="Rejected Claims"
                          className="sidebarListItemText"
                        ></ListItemText>
                      </ListItem>
                    </List>
                  </Collapse>
                </>
              )}

            {this.state.allowedComponents &&
              this.state.allowedComponents.includes("AdminUser") && (
                <ListItem
                  exact
                  button
                  component={NavLink}
                  to={adminroutes.EngineerList}
                  className="sidebarNormalLink"
                  activeClassName="sidebarActiveLink"
                >
                  <ListItemText
                    primary="Engineers List"
                    className="sidebarListItemText"
                  />
                </ListItem>
              )}

            {this.props.admindetails.RoleID === 3 ? (
              <ListItem
                exact
                button
                component={NavLink}
                to={adminroutes.AdminPermission}
                className="sidebarNormalLink"
                activeClassName="sidebarActiveLink"
              >
                <ListItemText
                  primary="Users Permissions"
                  className="sidebarListItemText"
                />
              </ListItem>
            ) : (
              ""
            )}

            <ListItem
              exact
              button
              component={NavLink}
              to={"/"}
              target="_blank"
              className="sidebarNormalLink"
              activeClassName="sidebarActiveLink"
            >
              <ListItemText
                primary="Main CPD Website"
                className="sidebarListItemText"
              />
            </ListItem>

            <ListItem
              exact
              button
              to={"/admin"}
              component={NavLink}
              onClick={this.logout}
              className="sidebarNormalLink"
              activeClassName="sidebarActiveLink"
            >
              <ListItemText primary="Log out" className="sidebarListItemText" />
            </ListItem>
          </List>
        </div>
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    admindetails: state.userData.admin,
    permissions: state.userData.admin_permissions,
  };
}

export default withRouter(
  connect(mapStateToProps, { adminlogout, getRolePermissions })(AdminSidebar)
);
