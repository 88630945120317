import React from 'react';
import {TextField,MenuItem} from '@material-ui/core';
import WebNotif from "../WebNotif";
import * as yup from 'yup';
import Loader from 'react-loader-spinner';
import {
    withStyles
  } from '@material-ui/core/styles';
  import {connect} from 'react-redux';
  import {setPassWord,verifyEmail} from '../../redux/admin/users/adminuseractions';

  var obj = new WebNotif();

  
  const emailSchema = yup.object().shape({
    email: yup
        .string()
        .email()
        .required()
    
    
  });
  const passwordSchema = yup.object().shape({
    pass: yup
    .string()
    .required("Please enter your password")
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Password must contain at least 8 characters, one uppercase, one number and one special case character"
    ),
    confirmPass: yup
    .string()
    .required("Please confirm your password")
    .when("password", {
      is: password => (password && password.length > 0 ? true : false),
      then: yup.string().oneOf([yup.ref("password")], "Password doesn't match")
     })
    
  });

const CssTextField = withStyles({
    root: {
        '& label.Mui-focused': {
            color: 'green',
          },
      '& .MuiOutlinedInput-root': {
        
        '&:hover fieldset': {
          borderColor: '#20a84c',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#20a84c',
        },
      },
    },
  })(TextField);




class SetPassword extends React.Component {

    state={
        email:'',
        pass:'',
        confirmPass:'',
        loading:false,
        next:false
    }
    onChange=(e)=>{
        this.setState(
            {[e.target.name]: e.target.value})
    }
    proceed=()=>{
      emailSchema.validate(this.state)
      .then(
        validation=>{

          this.setState({loading:true},
            ()=>{
              this.props.verifyEmail(this.state.email).then(res=>
                {   
                    obj.createNotification('success','Email Found, Set Your Password Now!')
                    this.setState({loading:false,next:true})

                
                  }).catch(err=>{
                  console.log(err)
                    obj.createNotification('error',err.response.data.message)
                    this.setState({
                      loading:false
                    })
                }
                  );
            })
          
        }).catch( 
          err=>
         { 
          console.log(err)
          obj.createNotification('error','Enter Correct Email Type')}
        )

    }

    setPass=()=>{
        passwordSchema.validate(this.state).then(
          validation=>{
            this.setState({
              loading:true
            },()=>{
             
              this.props.setPassWord(validation).then(res=>{
                this.setState({loading:false},()=>{
                    this.props.history.push('/admin/dashboard')
                })

              }).catch(err=>{
                console.log(err)
  
              })
            })
           

          }
      ).catch(
        err=>{
          console.log(err)
           obj.createNotification("error",err.message)
        }
      )
       
    }

    render(){
        console.log('Inside setPassword.js');
        return(
        <React.Fragment>

            <div className="row ptb-20 d-flex justify-content-center align-items-center Total-Body">



                <div className="col-md-6  ">
                  <h4 className="ptb-20">

                    {this.state.next ? 
                    "Set Your Password."
                    :
                    "Dear Admin, Please Enter Your Email To Proceed."}
                    </h4>

                  {this.state.next ? 
                        <div>
                        <div className="form-group">
                          <CssTextField                         
                          type="password" 
                          fullWidth={true}
                          label="Password" 
                          variant="outlined"
                          name="pass"
                          value={this.state.password}
                          onChange={this.onChange}
                          />
                        </div>
                       <div className="form-group">
                         <CssTextField 
                          type="password" 
                          fullWidth={true}
                          label="ConfirmPassword" 
                          variant="outlined"
                          name="confirmPass"
                          value={this.state.confirmPass}
                          onChange={this.onChange}
                          />      
                        
                        </div>
                     </div>
                      :
                      <div className="form-group">
                    
                      <CssTextField 
                    
                      type="email" 
                      fullWidth={true}
                      label="Email" 
                      variant="outlined"
                      name="email"
                      value={this.state.email}
                      onChange={this.onChange}
                      />
                      </div>
                }
                


                        <div className="row">
                              <div className="col-md-4 offset-md-4">
                              {this.state.loading ? 
                                  <div className="mb-40">
                                    <Loader type="Oval" color="#06580e" height={80} width={80}/>
                                  </div>
                                  :
                                  <button 
                                  onClick={this.state.next? this.setPass : this.proceed}
                                  type="submit" 
                                  className="btn text-center btn-submit black bold fs-20"                    
                                  >
                                 {this.state.next? "Set Password ": "Proceed"} </button>
                                  }
                              </div>
                            </div>
   

                </div>  
            </div>



        </React.Fragment>)
    }
}

function mapStateToProps(state){
  return {
    
  }
}
export default connect (mapStateToProps,{setPassWord,verifyEmail})( SetPassword);