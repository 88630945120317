import React, { Component } from "react";
import MUIDataTable from "mui-datatables";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Loader from "react-loader-spinner";
import {
  engineerlist,
  getEngineerFilter,
  getEngineerSearch,
  countEngineers,
  getTenurePoints,
} from "../../redux/admin/users/adminuseractions";
import { cpd_claim_user } from "../../redux/admin/cpdActions/cpdactions";
import { connect } from "react-redux";
import moment from "moment";
import Select from "react-select";

import { data } from "jquery";

const columns = [
  {
    name: "PEC No",
    label: "PEC No",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "Discipline",
    label: "Discipline",
    options: {
      filter: true,
      display: true,
    },
  },
  {
    name: "Full Name",
    label: "Full Name",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "Current Cycle Points",
    label: "Current Cycle Points",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "Points Required for Next Renewal",
    label: "Points Required for Next Renewal",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "Status",
    label: "Status",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "Eligibility",
    label: "Eligibility",
    options: {
      filter: false,
      sort: true,
    },
  },
];
class EngineersList extends Component {
  state = {
    allowPagination: true,
    simpleOptionsState: true,
    searchOptionsState: false,
    load: false,
    data: [],
    realData: [],
    count: 1,
    offset: 0,
    page: 0,
    perPage: 25,
    filterOptionsState: false,
    limit: 25,
    searchType: [
      { label: "Email", value: "userinfo.PrimaryEmail" },
      { label: "Full Name", value: "userinfo.FullName" },
    ],
    selectedType: "",
    search: "",
  };

  constructor(props) {
    super(props);

    this.searchEmailInput = React.createRef();
    this.searchNameInput = React.createRef();
    this.searchTypeInput = React.createRef();
    this.searchTextInput = React.createRef();
    this.searchPecInput = React.createRef();
    this.searchDisciplineInput = React.createRef();

    // this.searchFilter = this.searchFilter.bind(this);
  }

  getEngineerList = () => {
    this.setState({ load: true });
    let temp = [];
    let now = moment().format("YYYY");
    let yearDiff;
    this.props
      .engineerlist({ limit: this.state.limit, offset: this.state.offset })
      .then((res) => {
        console.log(res);
        let date = new Date();
        let year = date.getFullYear();
        console.log(year);
        for (let i = 0; i < res.data.length; i++) {
          if (res.data[i].PEC_REG_DATE) {
            let str =
              res.data[i].PEC_REG_DATE &&
              res.data[i].PEC_REG_DATE.split("/").pop();
            let str2 = str && str.split(" ")[0];
            yearDiff = now - str2;
            console.log(yearDiff);
          }
          temp.push({
            "PEC No": res.data[i].PEC_REG_NO,
            Discipline: res.data[i].discipline,
            "Full Name": res.data[i].FullName,
            "Current Cycle Points":
              res.data[i].engineerType === 1
                ? yearDiff > 1 && yearDiff < 3
                  ? 9
                  : yearDiff > 3 && yearDiff < 6
                  ? 12
                  : yearDiff > 6 && yearDiff < 9
                  ? 15
                  : yearDiff > 9
                  ? 15 + (yearDiff - 9) * 3
                  : ""
                : 3,
            "Points Required for Next Renewal":
              res.data[i].engineerType === 1
                ? yearDiff > 1 && yearDiff < 3
                  ? "1/1/" + (year - 3) + "-1/1/" + year
                  : yearDiff > 3 && yearDiff < 6
                  ? "1/1/" + (year - 12) + "-1/1/" + year
                  : yearDiff > 6 && yearDiff < 9
                  ? "1/1/" + (year - 15) + "-1/1/" + year
                  : yearDiff > 9
                  ? ""
                  : ""
                : "1/1/" + (year - 3) + "-1/1/" + year,
            Status:
              res.data[i].engineerType === 1
                ? "Registered Engineer"
                : "Professional Engineer",
            Eligibility: "sdf",
          });
        }

        this.setState({
          data: temp,
          realData: temp,
          load: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          load: false,
        });
      });

    // this.searchTextInput.current.value = "";
  };

  changeFilter = (data) => {
    console.log("INSIDE changeFilter data: ", data);

    let newdata = [];

    data.map((item, index) => {
      if (!(item.length === 0)) {
        switch (index) {
          case 0: {
            newdata.push({ "userinfo.PEC_REG_NO": item[0] });
            break;
          }
          case 1: {
            newdata.push({ "userinfo.discipline": item[0] });
            break;
          }
          case 5: {
            newdata.push({ "userinfo.engineerType": item[0] });
            break;
          }
        }
      }
    });
    if (newdata.length === 0) {
      console.log("inside new data if", newdata);
      this.setState({
        data: this.state.realData,
        allowPagination: true,
        load: false,
      });
    } else {
      console.log("Inside get engineer filter else");
      let temp = [];
      let now = moment().format("YYYY");
      let yearDiff;
      this.props
        .getEngineerFilter(newdata)
        .then((res) => {
          let filterCount = res.length;
          console.log(res);
          let date = new Date();
          let year = date.getFullYear();
          console.log(year);
          for (let i = 0; i < res.length; i++) {
            if (res[i].PEC_REG_DATE) {
              let str =
                res[i].PEC_REG_DATE && res[i].PEC_REG_DATE.split("/").pop();
              let str2 = str && str.split(" ")[0];
              yearDiff = now - str2;
              console.log(yearDiff);
            }
            temp.push({
              "PEC No": res[i].PEC_REG_NO,
              Discipline: res[i].discipline,
              "Full Name": res[i].FullName,
              "Current Cycle Points":
                res[i].engineerType === 1
                  ? yearDiff > 1 && yearDiff < 3
                    ? 9
                    : yearDiff > 3 && yearDiff < 6
                    ? 12
                    : yearDiff > 6 && yearDiff < 9
                    ? 15
                    : yearDiff > 9
                    ? 15 + (yearDiff - 9) * 3
                    : ""
                  : 3,
              "Points Required for Next Renewal":
                res[i].engineerType === 1
                  ? yearDiff > 1 && yearDiff < 3
                    ? "1/1/" + (year - 3) + "-1/1/" + year
                    : yearDiff > 3 && yearDiff < 6
                    ? "1/1/" + (year - 12) + "-1/1/" + year
                    : yearDiff > 6 && yearDiff < 9
                    ? "1/1/" + (year - 15) + "-1/1/" + year
                    : yearDiff > 9
                    ? ""
                    : ""
                  : "1/1/" + (year - 3) + "-1/1/" + year,
              Status:
                res[i].engineerType === 1
                  ? "Registered Engineer"
                  : "Professional Engineer",
              Eligibility: "sdf",
            });
          }

          this.setState({
            filterOptionsState: true,
            simpleOptionsState: false,
            searchOptionsState: false,
            data: temp,
            load: false,
            perPage: 25,
            count: filterCount,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  getEngineerCount = () => {
    this.props
      .countEngineers()
      .then((res) => {
        console.log("ENGINEER COUNT: ", res.data[0].count);
        this.setState({
          count: res.data[0].count,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  componentDidMount() {
    console.log("Engineers List");
    this.getEngineerList();
    this.getEngineerCount();
  }

  searchData = () => {
    let searchEmail = this.searchEmailInput?.current?.value;

    let searchName = this.searchNameInput?.current?.value;

    let searchTextValue = this.searchTextInput?.current?.value;

    let searchPecValue = this.searchPecInput?.current?.value;

    let searchDisciplineValue = this.searchDisciplineInput?.current?.value;

    if (
      searchEmail ||
      searchName ||
      (searchTextValue && this.state.selectedType !== "") ||
      searchPecValue ||
      searchDisciplineValue
    ) {
      // console.log("Inside Search Data 2");
      this.setState(
        {
          load: true,
        },
        () => {
          let data = [];

          let searchTypeValue = this.state.selectedType;

          data.push({ type: searchTypeValue });
          data.push({ search: searchTextValue });
          data.push({ email: searchEmail });
          data.push({ name: searchName });
          data.push({ pecNo: searchPecValue });
          data.push({ discipline: searchDisciplineValue });

          let newdata = [];
          let itemType = "";
          data.map((item, index) => {
            itemType = item["type"]?.label;

            if (!(item.length === 0)) {
              switch (index) {
                case 0: {
                  if (itemType && itemType === "Email") {
                    newdata.push({ "userinfo.PrimaryEmail": searchTextValue });
                  } else if (itemType && itemType === "Full Name") {
                    newdata.push({ "userinfo.FullName": searchTextValue });
                  }
                  break;
                }
                // case 1: {
                //   if (item['discipline'] !== "") {
                //     newdata.push({ "userinfo.discipline": item['discipline'] });
                //   }
                //   break;
                // }
                case 2: {
                  if (item["email"]) {
                    newdata.push({ "userinfo.PrimaryEmail": item["email"] });
                  }
                  break;
                }
                case 3: {
                  if (item["name"]) {
                    newdata.push({ "userinfo.FullName": item["name"] });
                  }
                  break;
                }
                case 4: {
                  if (item["pecNo"]) {
                    newdata.push({ "userinfo.PEC_REG_NO": item["pecNo"] });
                  }
                  break;
                }
                case 5: {
                  if (item["discipline"]) {
                    newdata.push({ "userinfo.discipline": item["discipline"] });
                  }
                  break;
                }
              }
            }
          });

          let temp = [];
          let now = moment().format("YYYY");
          let yearDiff;

          this.props
            .getEngineerSearch(newdata)
            .then((res) => {
              let searchLength = res.length;

              let date = new Date();
              let year = date.getFullYear();

              for (let i = 0; i < res.length; i++) {
                if (res[i].PEC_REG_DATE) {
                  let str =
                    res[i].PEC_REG_DATE && res[i].PEC_REG_DATE.split("/").pop();
                  let str2 = str && str.split(" ")[0];
                  yearDiff = now - str2;
                  // console.log(yearDiff);
                }
                temp.push({
                  "PEC No": res[i].PEC_REG_NO,
                  Discipline: res[i].discipline,
                  "Full Name": res[i].FullName,
                  "Current Cycle Points":
                    res[i].engineerType === 1
                      ? yearDiff > 1 && yearDiff < 3
                        ? 9
                        : yearDiff > 3 && yearDiff < 6
                        ? 12
                        : yearDiff > 6 && yearDiff < 9
                        ? 15
                        : yearDiff > 9
                        ? 15 + (yearDiff - 9) * 3
                        : ""
                      : 3,
                  "Points Required for Next Renewal":
                    res[i].engineerType === 1
                      ? yearDiff > 1 && yearDiff < 3
                        ? "1/1/" + (year - 3) + "-1/1/" + year
                        : yearDiff > 3 && yearDiff < 6
                        ? "1/1/" + (year - 12) + "-1/1/" + year
                        : yearDiff > 6 && yearDiff < 9
                        ? "1/1/" + (year - 15) + "-1/1/" + year
                        : yearDiff > 9
                        ? ""
                        : ""
                      : "1/1/" + (year - 3) + "-1/1/" + year,
                  Status:
                    res[i].engineerType === 1
                      ? "Registered Engineer"
                      : "Professional Engineer",
                  Eligibility: "sdf",
                });
              }
              this.setState({
                load: false,
                data: temp,
                count: searchLength,
                allowPagination: true,
                searchOptionsState: true,
                filterOptionsState: false,
                simpleOptionsState: false,
              });
            })
            .catch((err) => {
              this.setState({
                load: false,
              });
              console.log("Search Error: ", err);
            });
        }
      );
    }
  };

  changePage = (page, sortOrder) => {
    this.setState(
      {
        offset: page * this.state.perPage,
        page: page,
      },
      () => {
        this.getEngineerList();
        this.getEngineerCount();
      }
    );
  };

  changeEngineerRows = (num) => {
    this.setState(
      {
        limit: num,
      },
      () => {
        this.getEngineerList();
        this.getEngineerCount();
      }
    );
  };

  resetEngineerFilters = () => {
    this.searchEmailInput.current.value = "";
    this.searchNameInput.current.value = "";
    this.searchPecInput.current.value = "";
    this.searchDisciplineInput.current.value = "";

    this.setState(
      {
        allowPagination: true,
        filterOptionsState: false,
        searchOptionsState: false,
        simpleOptionsState: true,
      },
      () => {
        this.getEngineerList();
        this.getEngineerCount();
      }
    );
  };

  changePageSearch = (tableState) => {
    this.setState(
      {
        filterOptionsState:
          tableState.searchText === null ||
          tableState.searchText === "" ||
          !tableState.searchText
            ? false
            : true,
      },
      () => {
        // console.log('Server Side State: ', this.state.renderServerSide);
        // console.log('Search Text: ', tableState.searchText);
      }
    );
  };

  handleTextInput = (event) => {
    this.setState({ ...this.state, search: event.target.value });
  };

  searchFilter = (selectedOption) => {
    this.setState({
      selectedType: selectedOption,
      // searchOptionsState: true,
      // filterOptionsState: false,
      // simpleOptionsState: false,
    });
  };

  render() {
    const theme = createMuiTheme({
      overrides: {
        MUIDataTableToolbar: {
          filterPaper: {
            width: "450px !important",
            height: "auto !important",
          },
        },
      },
    });
    const options = {
      responsive: "vertical",
      tableBodyHeight: "600px",
      tableBodyMaxHeight: "",
      filter: true,
      count: this.state.count,
      rowsPerPage: this.state.perPage,
      pagination: this.state.allowPagination,
      serverSide:
        this.state.filterOptionsState || this.state.searchOptionsState
          ? false
          : true,
      rowsPerPageOptions: [25, 50, 75, 100],
      count: this.state.count,
      page: this.state.page,
      filterType: "dropdown",
      customToolbarSelect: () => {},
      onTableChange: (action, tableState) => {
        this.state.filterOptionsState || this.state.searchOptionsState
          ? ""
          : action === "changePage"
          ? this.changePage(tableState.page, tableState.sortOrder)
          : "";

        if (action === "filterChange") {
          this.changeFilter(tableState.filterList);
        }
        if (action === "search") {
          this.changePageSearch(tableState);
        }
      },
      // onRowClick: (action) => {
      //   console.log(action);
      //   let c = action[4].split("-");
      //   console.log(c);
      //   let start = c[0];
      //   let end = c[2];
      //   console.log(start);
      //   console.log(end);
      //   let data = [];
      //   data.push({ start, end });
      //   this.props
      //     .getTenurePoints({ data })
      //     .then((res) => {
      //       console.log(res);
      //     })
      //     .catch((err) => {
      //       console.log(err);
      //     });
      // },
      onChangeRowsPerPage: (num) => {
        if (
          this.state.filterOptionsState === true ||
          this.state.searchOptionsState === true
        ) {
        } else {
          this.setState({ perPage: num, page: 0 });
          this.changeEngineerRows(num);
        }
      },
      onFilterChange: (changedColumn, filterList, type) => {
        // console.log("Inside onFilterChange");
        if (type === "reset") {
          this.resetEngineerFilters();
        }
      },
      onFilterChipClose: (index, removedFilter, filterList) => {
        let temp = [];
        filterList.map((value, index) => {
          for (let i = 0; i <= value.length; i++) {
            temp.push(i);
          }
        });
        if (temp.length === 7) {
          this.resetEngineerFilters();
        }
      },
    };
    return (
      <React.Fragment>
        <>
          <h4 className="regular_font fs-26 mtb-35">Engineers List</h4>

          <hr></hr>
          <div className="col-md-12">
            <div className="row">
              {/* <div className='col-md-2 '>
              <label className='bm-0'>Type</label>
              <Select
                className=' mtb-10'
                options={this.state.searchType}
                value={this.state.selectedType}
                onChange={this.searchFilter}
                ref={this.searchTypeInput}
              />
            </div> */}

              <div className="col-md-2">
                <label className="bm-0">Email</label>
                <div className="input-group md-form form-sm form-2 mtb-10">
                  <input
                    type="text"
                    className="form-control"
                    name="search"
                    // value={this.state.search}
                    // onChange={this.handleTextInput}
                    ref={this.searchEmailInput}
                    placeholder={"Search By Email"}
                    aria-label="Text input with dropdown button"
                  />
                </div>
              </div>

              <div className="col-md-2">
                <label className="bm-0">Full Name</label>
                <div className="input-group md-form form-sm form-2 mtb-10">
                  <input
                    type="text"
                    className="form-control"
                    name="search"
                    // value={this.state.search}
                    // onChange={this.handleTextInput}
                    ref={this.searchNameInput}
                    placeholder={"Search By Name"}
                    aria-label="Text input with dropdown button"
                  />
                </div>
              </div>

              {/* <div className='col-md-2 '>
              <label className='bm-0'>Email Or Name</label>
              <div className='input-group md-form form-sm form-2 mtb-10'>
                <input
                  type='text'
                  className='form-control'
                  name='search'
                  // value={this.state.search}
                  // onChange={this.handleTextInput}
                  ref={this.searchTextInput}
                  placeholder={
                    "Search By Value"
                  }
                  aria-label='Text input with dropdown button'
                />
              </div>
            </div> */}

              <div className="col-md-2 ">
                <label className="bm-0">PEC No</label>
                <div className="input-group md-form form-sm form-2 mtb-10">
                  <input
                    type="text"
                    className="form-control"
                    name="search"
                    // value={this.state.search}
                    // onChange={this.handleTextInput}
                    ref={this.searchPecInput}
                    placeholder={"Search By PEC No"}
                    aria-label="Text input with dropdown button"
                  />
                </div>
              </div>

              <div className="col-md-2 ">
                <label className="bm-0">Discipline</label>
                <div className="input-group md-form form-sm form-2 mtb-10">
                  <input
                    type="text"
                    className="form-control"
                    name="search"
                    // value={this.state.search}
                    // onChange={this.handleTextInput}
                    ref={this.searchDisciplineInput}
                    placeholder={"Search By Discipline"}
                    aria-label="Text input with dropdown button"
                  />
                </div>
              </div>

              <div className="col-md-2">
                <label className="bm-0"></label>
                <button
                  className="btn admin_btn white w-150p float-right bold mtb-10"
                  onClick={() => {
                    this.searchData();
                  }}
                >
                  Search
                </button>
              </div>
              <div className="col-md-2">
                <label className="bm-0"></label>
                <button
                  className="btn admin_btn_new text-center white w-150p float-right bold mtb-10"
                  onClick={() => {
                    this.resetEngineerFilters();
                  }}
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
          <hr></hr>
        </>

        {this.state.load === true ? (
          <div className="loaderDiv text-center">
            <Loader type="Oval" color="#06580e" height={80} width={80} />
          </div>
        ) : (
          <MuiThemeProvider theme={theme}>
            <MUIDataTable
              // title={"CPD Forms"}
              data={this.state.data}
              columns={columns}
              options={options}
              // components={components}
            />
          </MuiThemeProvider>
        )}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    admins: state.UserManageData.alladmins,
  };
}
export default connect(mapStateToProps, {
  engineerlist,
  getEngineerFilter,
  getEngineerSearch,
  getTenurePoints,
  countEngineers,
})(EngineersList);
