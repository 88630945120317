import React, { useState, useEffect } from "react";
import { Link, NavLink, withRouter } from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Loader from "react-loader-spinner";
import api from "../../api";
import * as adminroutes from "../../constants/adminRoutes";

const StatisticCard = ({ toLink, title, value }) => {
  return (
    <Link to={toLink}>
      <Card elevation={3} className="statistic-card">
        <CardContent>
          <Typography variant="h7" component="div">
            {title}
          </Typography>
          <Typography
            style={{ marginTop: "10px", fontWeight: "500", color: "#06580e" }}
            variant="h4"
            component="div"
          >
            {value}
          </Typography>
        </CardContent>
      </Card>
    </Link>
  );
};

const FinStatisticCard = ({ toLink, title, value }) => {
  return (
    <Link to={toLink}>
      <Card elevation={3} className="statistic-card">
        <CardContent>
          <Typography variant="h7" component="div">
            {title}
          </Typography>
          <Typography
            style={{ marginTop: "10px", fontWeight: "500", color: "#06580e" }}
            variant="h4"
            component="div"
          >
            Rs. {value}
          </Typography>
        </CardContent>
      </Card>
    </Link>
  );
};

function AdminSummary() {
  const [loading, setLoading] = useState(false);
  const [adminStats, setAdminStats] = useState([]);
  const [finAdminStats, setFinAdminStats] = useState([]);
  const [finApprovedData, setFinApproveData] = useState([]);
  const [finRejectedData, setFinRejectedData] = useState([]);
  const [currentRole, setCurrentRole] = useState({});
  const [showClaims, setShowClaims] = useState(true);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);

  const [totalPaymentCount, setTotalPaymentCount] = useState(0);
  const [totalPendingPayments, setTotalPendingPayments] = useState(0);
  const [totalApprovedPayments, setTotalApprovedPayments] = useState(0);
  const [totalRejectedPayments, setTotalRejectedPayments] = useState(0);

  const [bankDepositPayments, setBankDepositPayments] = useState(0);
  const [bankChallanPayments, setBankChallanPayments] = useState(0);
  const [directPayments, setDirectPayments] = useState(0);
  const [bankTransferPayments, setBankTransferPayments] = useState(0);

  // NEW OVERALL STATES
  const [totalClaimCount, setTotalClaimCount] = useState(0);
  const [totalPendingClaims, setTotalPendingClaims] = useState(0);
  const [totalApprovedClaims, setTotalApprovedClaims] = useState(0);
  const [totalRejectedClaims, setTotalRejectedClaims] = useState(0);
  const [activityApprovedLength, setActivityApprovedLength] = useState(0);
  const [activityRejectedLength, setActivityRejectedLength] = useState(0);

  const [formalClaims, setFormalClaims] = useState(0);
  const [workBasedClaims, setWorkBasedClaims] = useState(0);
  const [developmentClaims, setDevelopmentClaims] = useState(0);
  const [individualClaims, setIndividualClaims] = useState(0);

  const [paymentApprovedIdData, setPaymentApprovedIdData] = useState([]);
  const [totalAmountBankDeposit, setTotalAmountBankDeposit] = useState(0);
  const [totalAmountBankChallan, setTotalAmountBankChallan] = useState(0);
  const [totalAmountDirectPayment, setTotalAmountDirectPayment] = useState(0);
  const [totalAmountBankTransfer, setTotalAmountBankTransfer] = useState(0);

  const [totalActivityAmount, setTotalActivityAmount] = useState(0);
  const [totalApprovedActivityAmount, setTotalApprovedActivityAmount] =
    useState(0);
  const [totalRejectedActivityAmount, setTotalRejectedActivityAmount] =
    useState(0);

  const [totalOverallAmount, setTotalOverallAmount] = useState(0);
  const [totalPendingAmount, setTotalPendingAmount] = useState(0);
  const [totalApprovedAmount, setTotalApprovedAmount] = useState(0);
  const [totalRejectedAmount, setTotalRejectedAmount] = useState(0);

  const [superAdminData, setSuperAdminData] = useState([]);

  const adminInfo = localStorage.getItem("admin_info");

  const getFinAdminPaymentsSummary = async (UserID) => {
    try {
      setLoading(true);
      const [
        finAdminRes,
        allCountRes,
        pendingRes,
        approvedRes,
        rejectedRes,
        paymentApprovedData,
        overallPaymentsData,
      ] = await Promise.all([
        api.admin.cpd.get_finance_admin_summary(UserID),
        api.admin.cpd.get_all_payment_count(),
        api.admin.cpd.get_pending_payment_count(),
        api.admin.cpd.get_approved_payment_count(),
        api.admin.cpd.get_rejected_payment_count(),
        api.admin.financial.getPaymentDataByApprovedId(UserID),
        api.admin.financial.getPaymentAmounts(),
      ]);

      if (finAdminRes.status === 200) {
        setFinAdminStats(finAdminRes.data);
      }
      if (allCountRes.status === 200) {
        setTotalPaymentCount(allCountRes?.data[0]?.count);
      }
      if (pendingRes.status === 200) {
        setTotalPendingPayments(pendingRes?.data[0]?.count);
      }
      if (approvedRes.status === 200) {
        setTotalApprovedPayments(approvedRes?.data[0]?.count);
      }
      if (rejectedRes.status === 200) {
        setTotalRejectedPayments(rejectedRes?.data[0]?.count);
      }
      if (paymentApprovedData) {
        setPaymentApprovedIdData(paymentApprovedData);
      }
      if (overallPaymentsData) {
        setTotalOverallAmount(overallPaymentsData?.res?.totalAmount);
        setTotalPendingAmount(overallPaymentsData?.res?.pendingAmount);
        setTotalApprovedAmount(overallPaymentsData?.res?.approvedAmount);
        setTotalRejectedAmount(overallPaymentsData?.res?.rejectedAmount);
      }
    } catch (err) {
      console.log("Error: ", err);
    } finally {
      setLoading(false);
    }
  };

  const getAdminClaimsSummary = async (UserID) => {
    try {
      setLoading(true);
      const [
        adminRes,
        pendingRes,
        allCount,
        approvedRes,
        rejectedRes,
        superAdminAllData,
      ] = await Promise.all([
        api.admin.cpd.get_admin_summary(UserID),
        api.admin.cpd.get_pending_claims_count(),
        api.admin.cpd.get_all_claims_count(),
        api.admin.cpd.get_approved_claims_count(),
        api.admin.cpd.get_rejected_claims_count(),
        api.admin.cpd.get_all_admins_summary(),
      ]);
      if (adminRes.status === 200) {
        setAdminStats(adminRes.data);
      }
      if (pendingRes.status === 200) {
        setTotalPendingClaims(pendingRes?.data[0]?.count);
      }
      if (allCount.status === 200) {
        setTotalClaimCount(allCount?.data[0]?.count);
      }
      if (approvedRes.status === 200) {
        setTotalApprovedClaims(approvedRes?.data[0]?.count);
      }
      if (rejectedRes.status === 200) {
        setTotalRejectedClaims(rejectedRes?.data[0]?.count);
      }
      if (superAdminAllData?.success) {
        setSuperAdminData(superAdminAllData?.res);
      }
    } catch (err) {
      console.log("Error: ", err);
    } finally {
      setLoading(false);
    }
  };

  const getAllUserRoles = async (RoleID) => {
    try {
      setLoading(true);
      const res = await api.admin.cpd.get_all_roles();
      const userRolesArr = res.data;
      const roleDetails = userRolesArr.filter(
        (role) => role.Role_ID === RoleID
      );
      if (roleDetails[0]?.Role_ID === 3) {
        setIsSuperAdmin(true);
      }
      if (roleDetails[0]?.Role_ID === 6) {
        setShowClaims(false);
      }
      setCurrentRole(roleDetails[0]);
    } catch (err) {
      console.log("Error: ", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const { UserID, RoleID } = JSON.parse(adminInfo);
    getAdminClaimsSummary(UserID);
    getFinAdminPaymentsSummary(UserID);
    getAllUserRoles(RoleID);
  }, [adminInfo]);

  useEffect(() => {
    if (!!adminStats && adminStats.length > 0) {
      const approvedArr = adminStats.filter(
        (item) => item.status === "Approved"
      );
      const rejectedArr = adminStats.filter(
        (item) => item.status === "Rejected"
      );

      const formalArr = adminStats.filter(
        (item) => item.category === "Formal Education"
      );
      const workBasedArr = adminStats.filter(
        (item) => item.category === "Work Based Activities"
      );
      const devArr = adminStats.filter(
        (item) => item.category === "Developmental Activities"
      );
      const indArr = adminStats.filter(
        (item) => item.category === "Individual  Activities"
      );

      setFormalClaims(formalArr);
      setWorkBasedClaims(workBasedArr);
      setDevelopmentClaims(devArr);
      setIndividualClaims(indArr);

      const activityApproved = approvedArr.length;
      const activityRejected = rejectedArr.length;

      setActivityApprovedLength(activityApproved);
      setActivityRejectedLength(activityRejected);
    }
  }, [adminStats]);

  useEffect(() => {
    if (!!finAdminStats && finAdminStats.length > 0) {
      const approvedArr = finAdminStats.filter(
        (item) => item.Status === "Approved"
      );
      const rejectedArr = finAdminStats.filter(
        (item) => item.Status === "Rejected"
      );

      const bankDepositArr = finAdminStats.filter(
        (item) => item.Payment_Method === "BankDeposit"
      );
      const bankChallanArr = finAdminStats.filter(
        (item) => item.Payment_Method === "Bank Challan Payment"
      );
      const directPaymentArr = finAdminStats.filter(
        (item) => item.Payment_Method === "Direct Bank Payment"
      );
      const bankTransferArr = finAdminStats.filter(
        (item) => item.Payment_Method === "BankTransfer"
      );

      setBankDepositPayments(bankDepositArr);
      setBankChallanPayments(bankChallanArr);
      setDirectPayments(directPaymentArr);
      setBankTransferPayments(bankTransferArr);

      setFinApproveData([...approvedArr]);
      setFinRejectedData([...rejectedArr]);
    }
  }, [finAdminStats]);

  useEffect(() => {
    if (paymentApprovedIdData && paymentApprovedIdData?.length > 0) {
      const tempArray = paymentApprovedIdData;
      let sumOfBankChallan = 0;
      let sumOfBankDeposit = 0;
      let sumOfDirectBank = 0;
      let sumOfBankTransfer = 0;
      let totalSumOfPayments = 0;
      let approvedSumOfPayments = 0;
      let rejectedSumOfPayments = 0;

      tempArray.map((item, index) => {
        if (item?.Status === "Approved") {
          let price = Number(item?.price);
          approvedSumOfPayments = approvedSumOfPayments + price;
        } else if (item?.Status === "Rejected") {
          let price = Number(item?.price);
          rejectedSumOfPayments = rejectedSumOfPayments + price;
        }
        let price = Number(item?.price);
        totalSumOfPayments = totalSumOfPayments + price;
      });

      tempArray.map((item, index) => {
        if (item?.Payment_Method === "BankDeposit") {
          let price = Number(item?.price);
          sumOfBankDeposit = sumOfBankDeposit + price;
        } else if (item?.Payment_Method === "Bank Challan Payment") {
          let price = Number(item?.price);
          sumOfBankChallan = sumOfBankChallan + price;
        } else if (item?.Payment_Method === "Direct Bank Payment") {
          let price = Number(item?.price);
          sumOfDirectBank = sumOfDirectBank + price;
        } else if (item?.Payment_Method === "BankTransfer") {
          let price = Number(item?.price);
          sumOfBankTransfer = sumOfBankTransfer + price;
        }
      });

      setTotalAmountBankChallan(sumOfBankChallan);
      setTotalAmountBankDeposit(sumOfBankDeposit);
      setTotalAmountDirectPayment(sumOfDirectBank);
      setTotalAmountBankTransfer(sumOfBankTransfer);

      setTotalActivityAmount(totalSumOfPayments);
      setTotalApprovedActivityAmount(approvedSumOfPayments);
      setTotalRejectedActivityAmount(rejectedSumOfPayments);
    }
  }, [paymentApprovedIdData]);

  return (
    <>
      {!!loading ? (
        <>
          <div className="loaderDiv text-center d-flex align-items-center justify-content-center">
            <Loader type="Oval" color="#06580e" height={80} width={80} />
          </div>
        </>
      ) : (
        <>
          <h4 className="regular_font fs-26 bm-0" style={{ marginTop: "30px" }}>
            {currentRole.RoleName} Summary
          </h4>

          {showClaims && !isSuperAdmin ? (
            <>
              <div className="admin-dash-main-summary">
                <div className="admin-dash-child-summary">
                  <h5 className="co_g20A84C">Overall Claim Details</h5>

                  <div className="container" style={{ marginTop: "30px" }}>
                    <div className="row">
                      <div className="col-3 mb-20">
                        <StatisticCard
                          toLink={{
                            pathname: adminroutes.cpdclaims,
                            state: {
                              pending: "pending",
                            },
                          }}
                          title={"Total Claims"}
                          value={totalClaimCount}
                        />
                      </div>
                      <div className="col-3 mb-20">
                        <StatisticCard
                          toLink={{
                            pathname: adminroutes.cpdclaims,
                            state: {
                              pending: "pending",
                            },
                          }}
                          title={"Total Pending Claims"}
                          value={totalPendingClaims}
                        />
                      </div>
                      <div className="col-3 mb-20">
                        <StatisticCard
                          toLink={{
                            pathname: adminroutes.cpdclaims,
                            state: {
                              approved: "approved",
                            },
                          }}
                          title={"Total Approved Claims"}
                          value={totalApprovedClaims}
                        />
                      </div>
                      <div className="col-3 mb-20">
                        <StatisticCard
                          toLink={{
                            pathname: adminroutes.cpdclaims,
                            state: {
                              rejected: "rejected",
                            },
                          }}
                          title={"Total Rejected Claims"}
                          value={totalRejectedClaims}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="admin-dash-main-summary">
                <div className="admin-dash-child-summary">
                  <h5 className="co_g20A84C">Activity Overview</h5>

                  <div className="container" style={{ marginTop: "30px" }}>
                    <div className="row">
                      <div className="col-4 mb-20">
                        <StatisticCard
                          toLink={{
                            pathname: adminroutes.cpdclaims,
                            state: {
                              pending: "pending",
                            },
                          }}
                          title={"Claims Overview"}
                          value={adminStats.length}
                        />
                      </div>
                      <div className="col-4 mb-20">
                        <StatisticCard
                          toLink={{
                            pathname: adminroutes.cpdclaims,
                            state: {
                              approved: "approved",
                            },
                          }}
                          title={"Total Approved Claims"}
                          value={activityApprovedLength}
                        />
                      </div>
                      <div className="col-4 mb-20">
                        <StatisticCard
                          toLink={{
                            pathname: adminroutes.cpdclaims,
                            state: {
                              rejected: "rejected",
                            },
                          }}
                          title={"Total Rejected Claims"}
                          value={activityRejectedLength}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="admin-dash-main-summary">
                <div className="admin-dash-child-summary">
                  <h5 className="co_g20A84C">Category Wise Activity</h5>

                  <div className="container" style={{ marginTop: "30px" }}>
                    <div className="row">
                      <div className="col-3 mb-20">
                        <StatisticCard
                          toLink={{
                            pathname: adminroutes.cpdclaims,
                            state: {
                              pending: "pending",
                            },
                          }}
                          title={"Formal Education"}
                          value={formalClaims.length}
                        />
                      </div>
                      <div className="col-3 mb-20">
                        <StatisticCard
                          toLink={{
                            pathname: adminroutes.cpdclaims,
                            state: {
                              pending: "pending",
                            },
                          }}
                          title={"Work Based"}
                          value={workBasedClaims.length}
                        />
                      </div>
                      <div className="col-3 mb-20">
                        <StatisticCard
                          toLink={{
                            pathname: adminroutes.cpdclaims,
                            state: {
                              pending: "pending",
                            },
                          }}
                          title={"Development Activity"}
                          value={developmentClaims.length}
                        />
                      </div>
                      <div className="col-3 mb-20">
                        <StatisticCard
                          toLink={{
                            pathname: adminroutes.cpdclaims,
                            state: {
                              pending: "pending",
                            },
                          }}
                          title={"Individual Activity"}
                          value={individualClaims.length}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : showClaims && isSuperAdmin ? (
            <>
              <div className="admin-dash-main-summary">
                <div className="admin-dash-child-summary">
                  <h5 className="co_g20A84C">Overall Claim Details</h5>

                  <div className="container" style={{ marginTop: "30px" }}>
                    <div className="row">
                      <div className="col-3 mb-20">
                        <StatisticCard
                          toLink={{
                            pathname: adminroutes.cpdclaims,
                            state: {
                              pending: "pending",
                            },
                          }}
                          title={"Total Claims"}
                          value={totalClaimCount}
                        />
                      </div>
                      <div className="col-3 mb-20">
                        <StatisticCard
                          toLink={{
                            pathname: adminroutes.cpdclaims,
                            state: {
                              pending: "pending",
                            },
                          }}
                          title={"Total Pending Claims"}
                          value={totalPendingClaims}
                        />
                      </div>
                      <div className="col-3 mb-20">
                        <StatisticCard
                          toLink={{
                            pathname: adminroutes.cpdclaims,
                            state: {
                              approved: "approved",
                            },
                          }}
                          title={"Total Approved Claims"}
                          value={totalApprovedClaims}
                        />
                      </div>
                      <div className="col-3 mb-20">
                        <StatisticCard
                          toLink={{
                            pathname: adminroutes.cpdclaims,
                            state: {
                              rejected: "rejected",
                            },
                          }}
                          title={"Total Rejected Claims"}
                          value={totalRejectedClaims}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {superAdminData &&
                superAdminData?.length > 0 &&
                superAdminData?.map((item, index) => (
                  <div index={index}>
                    <div className="admin-dash-main-summary">
                      <div className="admin-dash-child-summary">
                        <h5 className="co_g20A84C">
                          {item?.userName} Activity Overview
                        </h5>

                        <div
                          className="container"
                          style={{ marginTop: "30px" }}
                        >
                          <div className="row">
                            <div className="col-4 mb-20">
                              <StatisticCard
                                toLink={{
                                  pathname: adminroutes.cpdclaims,
                                  state: {
                                    pending: "pending",
                                  },
                                }}
                                title={"Claims Overview"}
                                value={item?.overallClaimsCount}
                              />
                            </div>
                            <div className="col-4 mb-20">
                              <StatisticCard
                                toLink={{
                                  pathname: adminroutes.cpdclaims,
                                  state: {
                                    approved: "approved",
                                  },
                                }}
                                title={"Total Approved Claims"}
                                value={item?.approvedClaimsCount}
                              />
                            </div>
                            <div className="col-4 mb-20">
                              <StatisticCard
                                toLink={{
                                  pathname: adminroutes.cpdclaims,
                                  state: {
                                    rejected: "rejected",
                                  },
                                }}
                                title={"Total Rejected Claims"}
                                value={item?.rejectedClaimsCount}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="admin-dash-main-summary">
                      <div className="admin-dash-child-summary">
                        <h5 className="co_g20A84C">
                          {item?.userName} Category Wise Activity
                        </h5>

                        <div
                          className="container"
                          style={{ marginTop: "30px" }}
                        >
                          <div className="row">
                            <div className="col-3 mb-20">
                              <StatisticCard
                                toLink={{
                                  pathname: adminroutes.cpdclaims,
                                  state: {
                                    pending: "pending",
                                  },
                                }}
                                title={"Formal Education"}
                                value={item?.categoryCounts?.formal_education}
                              />
                            </div>
                            <div className="col-3 mb-20">
                              <StatisticCard
                                toLink={{
                                  pathname: adminroutes.cpdclaims,
                                  state: {
                                    pending: "pending",
                                  },
                                }}
                                title={"Work Based"}
                                value={
                                  item?.categoryCounts?.work_based_activities
                                }
                              />
                            </div>
                            <div className="col-3 mb-20">
                              <StatisticCard
                                toLink={{
                                  pathname: adminroutes.cpdclaims,
                                  state: {
                                    pending: "pending",
                                  },
                                }}
                                title={"Development Activity"}
                                value={
                                  item?.categoryCounts?.development_activity
                                }
                              />
                            </div>
                            <div className="col-3 mb-20">
                              <StatisticCard
                                toLink={{
                                  pathname: adminroutes.cpdclaims,
                                  state: {
                                    pending: "pending",
                                  },
                                }}
                                title={"Individual Activity"}
                                value={
                                  item?.categoryCounts?.individual_activity
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </>
          ) : (
            <>
              <div className="admin-dash-main-summary">
                <div className="admin-dash-child-summary">
                  <h5 className="co_g20A84C">Overall Payment Details</h5>

                  <div className="container" style={{ marginTop: "30px" }}>
                    <div className="row">
                      <div className="col-3 mb-20">
                        <FinStatisticCard
                          toLink={{
                            pathname: adminroutes.admincoursepayment,
                            state: {
                              pending: "pending",
                            },
                          }}
                          title={"Total Amount"}
                          value={totalOverallAmount}
                        />
                      </div>
                      <div className="col-3 mb-20">
                        <FinStatisticCard
                          toLink={{
                            pathname: adminroutes.admincoursepayment,
                            state: {
                              pending: "pending",
                            },
                          }}
                          title={"Total Pending Amount"}
                          value={totalPendingAmount}
                        />
                      </div>
                      <div className="col-3 mb-20">
                        <FinStatisticCard
                          toLink={{
                            pathname: adminroutes.admincoursepayment,
                            state: {
                              approved: "approved",
                            },
                          }}
                          title={"Total Approved Amount"}
                          value={totalApprovedAmount}
                        />
                      </div>
                      <div className="col-3 mb-20">
                        <FinStatisticCard
                          toLink={{
                            pathname: adminroutes.admincoursepayment,
                            state: {
                              rejected: "rejected",
                            },
                          }}
                          title={"Total Rejected Amount"}
                          value={totalRejectedAmount}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="admin-dash-main-summary">
                <div className="admin-dash-child-summary">
                  <h5 className="co_g20A84C">Activity Overview</h5>

                  <div className="container" style={{ marginTop: "30px" }}>
                    <div className="row">
                      <div className="col-4 mb-20">
                        <FinStatisticCard
                          toLink={{
                            pathname: adminroutes.admincoursepayment,
                            state: {
                              pending: "pending",
                            },
                          }}
                          title={"Total Amount"}
                          value={totalActivityAmount}
                        />
                      </div>
                      <div className="col-4 mb-20">
                        <FinStatisticCard
                          toLink={{
                            pathname: adminroutes.admincoursepayment,
                            state: {
                              approved: "approved",
                            },
                          }}
                          title={"Total Approved Amount"}
                          value={totalApprovedActivityAmount}
                        />
                      </div>
                      <div className="col-4 mb-20">
                        <FinStatisticCard
                          toLink={{
                            pathname: adminroutes.admincoursepayment,
                            state: {
                              rejected: "rejected",
                            },
                          }}
                          title={"Total Rejected Amount"}
                          value={totalRejectedActivityAmount}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="admin-dash-main-summary">
                <div className="admin-dash-child-summary">
                  <h5 className="co_g20A84C">Payment Method Wise Activity</h5>

                  <div className="container" style={{ marginTop: "30px" }}>
                    <div className="row">
                      <div className="col-3 mb-20">
                        <FinStatisticCard
                          toLink={{
                            pathname: adminroutes.admincoursepayment,
                            state: {
                              pending: "pending",
                            },
                          }}
                          title={"Bank Challan Payments Amount"}
                          value={totalAmountBankChallan}
                        />
                      </div>
                      <div className="col-3 mb-20">
                        <FinStatisticCard
                          toLink={{
                            pathname: adminroutes.admincoursepayment,
                            state: {
                              pending: "pending",
                            },
                          }}
                          title={"Direct Bank Payments Amount"}
                          value={totalAmountDirectPayment}
                        />
                      </div>
                      <div className="col-3 mb-20">
                        <FinStatisticCard
                          toLink={{
                            pathname: adminroutes.admincoursepayment,
                            state: {
                              pending: "pending",
                            },
                          }}
                          title={"Bank Deposit Payments Amount"}
                          value={totalAmountBankDeposit}
                        />
                      </div>
                      <div className="col-3 mb-20">
                        <FinStatisticCard
                          toLink={{
                            pathname: adminroutes.admincoursepayment,
                            state: {
                              pending: "pending",
                            },
                          }}
                          title={"Bank Transfer Payments Amount"}
                          value={totalAmountBankTransfer}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}

export default AdminSummary;
