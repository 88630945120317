import { combineReducers } from "redux";
import webinarReducer from "./admin/webinar/webinarReducer";
import userReducer from "./user/userReducer";
import financialreducer from "./admin/financial/financialreducer";
import adminuserreducer from "./admin/users/adminuserreducer";
import CPDData from "./admin/cpdActions/cpdreducer";
import dashboardreducer from "./admin/dashboard/dashboardReducer";

export default combineReducers({
  webinarData: webinarReducer,
  userData: userReducer,
  financialData: financialreducer,
  UserManageData: adminuserreducer,
  CPDData: CPDData,
  dashboardData: dashboardreducer,
});
