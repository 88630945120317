// => Production Backend Links
const urlapi = "https://cpd.pec.org.pk/api";
const urlImageUrl = "http://cpd.pec.org.pk/";

// => Development Backend Links
// const urlapi = "http://localhost:5000/api";
// const urlImageUrl = "http://localhost:5000/";

// AWS
// const urlapi = "http://13.41.240.10/api";
// const urlImageUrl = "http://13.41.240.10/";

// const urlapiEPE = "http://52.200.140.20/admin_server/api";

// Testing
// const urlapi = "http://221.120.235.62:5000/api";
// const urlImageUrl = "http://221.120.235.62:5000/";

// const urlapiEPE = "http://54.151.174.78/api";
// haseeb local ip

//export const {urlapi="http://52.86.238.121:5000/api", urlImageUrl="http://52.86.238.121:5000/"}

// const urlalpi="http://supervisory.pec.org.pk/DataService.asmx";
// export default urlapi;

// <========================================================================================================>

// Latest Links In Use:

// => Local
// const urlapi = "https://cpd.pec.org.pk/api";
// const urlImageUrl = "http://cpd.pec.org.pk/";
//const urlapiEPE = "http://epe.com/admin_server/api";

// => Staging

// const urlapi = "https://epe.pec.org.pk/beta/api";
// const urlImageUrl = "https://epe.pec.org.pk/beta/";
const urlapiEPE = "https://epe.pec.org.pk/admin_server/api";

// => Local OWN
//const urlapi = "http://localhost:5500/api";
//const urlImageUrl = "http://localhost:5500/";

// const urlapi = "http://52.60.199.122/beta/api";
// const urlImageUrl = "http://52.60.199.122/beta";

// const urlapiEPE = "http://221.120.235.59/admin_server/api";
// const urlapiEPE = "http://115.186.165.144/admin_server/api";

export { urlapi, urlImageUrl, urlapiEPE };
