// import 'react-app-polyfill/ie11';
// import 'react-app-polyfill/stable';
import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import { Provider } from "react-redux";
import {
  UserLoggedIn,
  AdminLoggedIn,
  UserLoggedOut,
  AdminLoggedOut,
} from "./redux/user/useraction";

import store from "./store";
import decode from "jwt-decode";
import registerServiceWorker from "./registerServiceWorker";
import { GlobalContextProvider } from "./context/GlobalContext";

// epe cpd beta => latest code inside epe-dev-latest
 
window.addEventListener("storage", (e) => {
  if (e.key === "CPD_User_LoginData" && e.oldValue && !e.newValue) {
    store.dispatch(UserLoggedOut());
  }
  if (e.key === "CPD_Admin_LoginData" && e.oldValue && !e.newValue) {
    store.dispatch(AdminLoggedOut());
  }
});

//const loginData = JSON.parse(localStorage.getItem('CPD_User_LoginData'))
if (localStorage.CPD_User_LoginData) {
  const loginData = decode(localStorage.CPD_User_LoginData);
  const userReserveData = localStorage.getItem("User_Data_Reserve");
  const userObject = JSON.parse(userReserveData);
  // console.log("USER GENDERRRRR ... ", userObject);
  const loginUser = {
    token: localStorage.CPD_User_LoginData,
    data: {
      UserID: loginData.user.UserID,
      FullName: loginData.user.FullName,
      PrimaryEmail: loginData.user.PrimaryEmail,
      Primary_Address: loginData.user.Primary_Address,
      postal_address: loginData.user.Primary_Address,
      PEC_REG_NO: loginData.user.PEC_REG_NO,
      PEC_REG_DATE: loginData.user.PEC_REG_DATE,
      // Primary_Contact_NO: loginData.user.Primary_Contact_No,
      Primary_Contact_NO: userObject?.contactNumber,
      DOB: loginData.user.DOB,
      ProfileImageURL: loginData.user.profilePicture,
      // gender: loginData.user.gender,
      gender: userObject?.gender,
      CNIC: loginData.user.CNIC,
      RoleID: loginData.user.RoleID,
      iat: loginData.iat,
      discipline: loginData.user.discipline,
      city: loginData.user.City,
      institute: loginData.user.Institute,
      province: loginData.user.province,
      engineerType: loginData.user.engineerType,
      engineerValidity: loginData.user.engineerValidity,
      yearOfGraduation: loginData.user.yearOfGraduation,
    },
  };

  // console.log("LOGING DATAAAAA ... ", loginData);

  store.dispatch(UserLoggedIn(loginUser));
}
if (localStorage.CPD_Admin_LoginData) {
  const loginData = decode(localStorage.CPD_Admin_LoginData);
  const loginUser = {
    token: localStorage.CPD_Admin_LoginData,
    data: {
      UserID: loginData.user.UserID,
      FullName: loginData.user.FullName,
      PrimaryEmail: loginData.user.PrimaryEmail,
      Primary_Address: loginData.user.Primary_Address,
      PEC_REG_NO: loginData.user.PEC_REG_NO,
      PEC_REG_DATE: loginData.user.PEC_REG_DATE,
      Primary_Contact_NO: loginData.user.Primary_Contact_NO,
      DOB: loginData.user.DOB,
      ProfileImageURL: loginData.user.profilePicture,
      gender: loginData.user.gender,
      CNIC: loginData.user.CNIC,
      RoleID: loginData.user.RoleID,
      RoleName: loginData.user.RoleName,
      iat: loginData.iat,
    },
  };

  store.dispatch(AdminLoggedIn(loginUser));
}

ReactDOM.render(
  <Provider store={store}>
    <GlobalContextProvider>
      <App />
    </GlobalContextProvider>
  </Provider>,
  document.getElementById("root")
);
registerServiceWorker();
