import React, { Component } from "react";
import { connect } from "react-redux";
import MUIDataTable from "mui-datatables";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Loader from "react-loader-spinner";
import moment from "moment";
import {
  getwebinarAttendees,
  getWebinarFiltersData,
  getSearchWebinarAttendees,
  allAttendeesCount,
} from "../../redux/admin/financial/financialactions";
import Select from "react-select";
const columns = [
  {
    name: "Attendence ID",
    label: "Attendence ID",
    options: {
      filter: false,
    },
  },
  {
    name: "PEC No",
    label: "PEC No",
    options: {
      filter: true,
      display: true,
    },
  },
  {
    name: "Discipline",
    label: "Discipline",
    options: {
      filter: false,
      display: true,
    },
  },
  {
    name: "User Name",
    label: "User Name",
    options: {
      filter: false,
      searchable: true,
    },
  },
  {
    name: "User Email",
    label: "User Email",
    options: {
      filter: false,
    },
  },
  {
    name: "Phone Number",
    label: "Phone Number",
    options: {
      filter: false,
    },
  },
  {
    name: "Course Name",
    label: "Course Name",
    options: {
      filter: true,
    },
  },
  {
    name: "Session Name",
    label: "Session Name",
    options: {
      filter: true,
    },
  },
  {
    name: "Session Start Time",
    label: "Session Start Time",
    options: {
      filter: false,
    },
  },
  {
    name: "Session End Time",
    label: "Session End Time",
    options: {
      filter: false,
    },
  },
  {
    name: "Duration",
    label: "Duration",
    options: {
      filter: false,
    },
  },
  {
    name: "Attendence Percent",
    label: "Attendence Percent",
    options: {
      filter: false,
    },
  },
];
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "500px",
    backgroundColor: "#e0eadf",
    border: "none",
    color: "black",
    height: "300px",
    overflowY: "hidden",
    "z-index": 9999,
  },
  overlay: {
    zIndex: 1000,
    backgroundColor: "rgba(0, 0, 0, 0.35)",
  },
};
class WebinarAttendeesAll extends Component {
  state = {
    load: false,
    data: [],
    realData: [],
    filterOptionsState: false,
    allowPagination: true,
    simpleOptionsState: true,
    searchOptionsState: false,
    limit: 25,
    offset: 0,
    perPage: 25,
    count: 1,
    renderServerSide: true,
    searchType: [
      { label: "Email", value: "userinfo.PrimaryEmail" },
      { label: "Full Name", value: "userinfo.FullName" },
    ],
    selectedType: "",
    search: "",
  };

  constructor(props) {
    super(props);

    this.searchEmailInput = React.createRef();
    this.searchNameInput = React.createRef();
    this.searchTypeInput = React.createRef();
    this.searchTextInput = React.createRef();
    this.searchPecInput = React.createRef();
    this.searchDisciplineInput = React.createRef();

    // this.searchFilter = this.searchFilter.bind(this);
  }

  componentDidMount() {
    this.allAttendeesCount();
    this.getwebinarAttendees();
  }
  getwebinarAttendees = () => {
    this.setState({ load: true });
    let temp = [];
    this.props
      .getwebinarAttendees({
        limit: this.state.limit,
        offset: this.state.offset,
      })
      .then((res) => {
        console.log("Webinar resss: ", res.data);
        for (let i = 0; i < res.data.length; i++) {
          temp.push({
            "Attendence ID": res.data[i].attendence_id,
            "User Name": res.data[i].FullName,
            "User Email": res.data[i].PrimaryEmail,
            "Phone Number": res.data[i].Primary_Contact_No,
            "Course Name": res.data[i].Course_Name,
            "Session Name": res.data[i].Session_Name,
            "Session Start Time": moment(res.data[i].Session_StartDate).format(
              "hh:mm a - DD/MM/YYYY "
            ),
            "Session End Time": moment(res.data[i].Session_EndDate).format(
              "hh:mm a - DD/MM/YYYY "
            ),
            Duration: res.data[i].duration,
            "Attendence Percent": res.data[i].percent,
            "PEC No": res.data[i].PEC_REG_NO,
            Discipline: res.data[i].discipline,
          });
        }
        this.setState({
          data: temp,
          realData: temp,
          load: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          load: false,
        });
      });

    // this.searchTextInput.current.value = "";
  };
  changePage = (page, sortOrder) => {
    this.setState(
      {
        offset: page * this.state.perPage,
        page: page,
      },
      () => {
        this.getwebinarAttendees();
      }
    );
  };
  changeFilter = (data) => {
    console.log("Inside change filter: ", data);

    let newdata = [];
    data.map((item, index) => {
      if (!(item.length === 0)) {
        switch (index) {
          case 1: {
            newdata.push({ "userinfo.PEC_REG_NO": item[0] });
            break;
          }
          case 2: {
            newdata.push({ "userinfo.discipline": item[0] });
            break;
          }
          case 6: {
            newdata.push({ "courses.Course_Name": item[0] });
            break;
          }
          case 7: {
            newdata.push({ "course_session.Session_Name": item[0] });
            break;
          }
        }
      }
    });
    if (newdata.length === 0) {
      console.log("Inside if: ", newdata);
      this.setState({
        data: this.state.realData,
        allowPagination: true,
        load: false,
      });
    } else {
      console.log("Else if: ", newdata);

      this.props
        .getWebinarFiltersData(newdata)
        .then((res) => {
          console.log("Got filter webinars: ", res);

          let filterCount = res.length;
          newdata.push({ limit: this.state.limit, offset: this.state.offset });

          let temp = [];

          for (let i = 0; i < res.length; i++) {
            temp.push({
              "Attendence ID": res[i].attendence_id,
              "User Name": res[i].FullName,
              "User Email": res[i].PrimaryEmail,
              "Phone Number": res[i].Primary_Contact_No,
              "Course Name": res[i].Course_Name,
              "Session Name": res[i].Session_Name,
              "Session Start Time": moment(res[i].Session_StartDate).format(
                "hh:mm a - DD/MM/YYYY "
              ),
              "Session End Time": moment(res[i].Session_EndDate).format(
                "hh:mm a - DD/MM/YYYY "
              ),
              Duration: res[i].duration,
              "Attendence Percent": res[i].percent,
              "PEC No": res[i].PEC_REG_NO,
              Discipline: res[i].discipline,
            });
          }
          this.setState({
            filterOptionsState: true,
            simpleOptionsState: false,
            searchOptionsState: false,
            data: temp,
            perPage: 25,
            count: filterCount,
            load: false,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  allAttendeesCount = () => {
    this.props
      .allAttendeesCount()
      .then((res) => {
        let count = Math.floor(res.data[0].count);
        this.setState({ count: count });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  changePageSearch = (tableState) => {
    this.setState(
      {
        filterOptionsState:
          tableState.searchText === null ||
          tableState.searchText === "" ||
          !tableState.searchText
            ? false
            : true,
      },
      () => {
        // console.log('Server Side State: ', this.state.renderServerSide);
        // console.log('Search Text: ', tableState.searchText);
      }
    );
  };
  changeWebinarRows = (num) => {
    this.setState(
      {
        limit: num,
      },
      () => {
        this.getwebinarAttendees();
        this.allAttendeesCount();
      }
    );
  };
  resetWebinarFilters = () => {
    this.searchEmailInput.current.value = "";
    this.searchNameInput.current.value = "";
    this.searchPecInput.current.value = "";
    this.searchDisciplineInput.current.value = "";
    this.setState(
      {
        allowPagination: true,
        filterOptionsState: false,
        searchOptionsState: false,
        simpleOptionsState: true,
      },
      () => {
        this.getwebinarAttendees();
        this.allAttendeesCount();
      }
    );
  };

  searchData = () => {
    let searchEmail = this.searchEmailInput?.current?.value;

    let searchName = this.searchNameInput?.current?.value;

    let searchTextValue = this.searchTextInput?.current?.value;

    let searchPecValue = this.searchPecInput?.current?.value;

    let searchDisciplineValue = this.searchDisciplineInput?.current?.value;

    if (
      searchEmail ||
      searchName ||
      (searchTextValue && this.state.selectedType !== "") ||
      searchPecValue ||
      searchDisciplineValue
    ) {
      this.setState(
        {
          load: true,
        },
        () => {
          let data = [];

          let searchTypeValue = this.state.selectedType;

          data.push({ type: searchTypeValue });
          data.push({ search: searchTextValue });
          data.push({ email: searchEmail });
          data.push({ name: searchName });
          data.push({ pecNo: searchPecValue });
          data.push({ discipline: searchDisciplineValue });

          let newdata = [];
          let itemType = "";
          data.map((item, index) => {
            itemType = item["type"]?.label;

            if (!(item.length === 0)) {
              switch (index) {
                case 0: {
                  if (itemType && itemType === "Email") {
                    newdata.push({ "userinfo.PrimaryEmail": searchTextValue });
                  } else if (itemType && itemType === "Full Name") {
                    newdata.push({ "userinfo.FullName": searchTextValue });
                  }
                  break;
                }
                // case 1: {
                //   if (item['discipline'] !== "") {
                //     newdata.push({ "userinfo.discipline": item['discipline'] });
                //   }
                //   break;
                // }
                case 2: {
                  if (item["email"]) {
                    newdata.push({ "userinfo.PrimaryEmail": item["email"] });
                  }
                  break;
                }
                case 3: {
                  if (item["name"]) {
                    newdata.push({ "userinfo.FullName": item["name"] });
                  }
                  break;
                }
                case 4: {
                  if (item["pecNo"]) {
                    newdata.push({ "userinfo.PEC_REG_NO": item["pecNo"] });
                  }
                  break;
                }
                case 5: {
                  if (item["discipline"]) {
                    newdata.push({ "userinfo.discipline": item["discipline"] });
                  }
                  break;
                }
              }
            }
          });

          console.log("NEW DATA: ", newdata);

          this.props
            .getSearchWebinarAttendees(newdata)
            .then((res) => {
              console.log("Search res: ", res);
              let searchLength = res.length;
              let temp = [];
              for (let i = 0; i < res.length; i++) {
                temp.push({
                  "Attendence ID": res[i].attendence_id,
                  "User Name": res[i].FullName,
                  "User Email": res[i].PrimaryEmail,
                  "Phone Number": res[i].Primary_Contact_No,
                  "Course Name": res[i].Course_Name,
                  "Session Name": res[i].Session_Name,
                  "Session Start Time": moment(res[i].Session_StartDate).format(
                    "hh:mm a - DD/MM/YYYY "
                  ),
                  "Session End Time": moment(res[i].Session_EndDate).format(
                    "hh:mm a - DD/MM/YYYY "
                  ),
                  Duration: res[i].duration,
                  "Attendence Percent": res[i].percent,
                  "PEC No": res[i].PEC_REG_NO,
                  Discipline: res[i].discipline,
                });
              }
              this.setState({
                load: false,
                data: temp,
                count: searchLength,
                allowPagination: true,
                searchOptionsState: true,
                filterOptionsState: false,
                simpleOptionsState: false,
              });
            })
            .catch((err) => {
              this.setState({
                load: false,
              });
              console.log("Search Error: ", err);
            });
        }
      );
    }
  };

  handleTextInput = (event) => {
    this.setState({ ...this.state, search: event.target.value });
  };

  searchFilter = (selectedOption) => {
    this.setState({
      selectedType: selectedOption,
      // searchOptionsState: true,
      // filterOptionsState: false,
      // simpleOptionsState: false,
    });
  };

  render() {
    const options = {
      responsive: "vertical",
      tableBodyHeight: "600px",
      tableBodyMaxHeight: "",
      filter: true,
      search: true,
      rowsPerPage: this.state.perPage,
      pagination: this.state.allowPagination,
      serverSide:
        this.state.filterOptionsState || this.state.searchOptionsState
          ? false
          : true,
      rowsPerPageOptions: [25, 50, 75, 100],
      count: this.state.count,
      page: this.state.page,
      filterType: "dropdown",
      customToolbarSelect: () => {},
      onTableChange: (action, tableState) => {
        this.state.filterOptionsState || this.state.searchOptionsState
          ? ""
          : action === "changePage"
          ? this.changePage(tableState.page, tableState.sortOrder)
          : "";

        if (action === "filterChange") {
          this.changeFilter(tableState.filterList);
        }
        if (action === "search") {
          this.changePageSearch(tableState);
        }
      },
      onChangeRowsPerPage: (num) => {
        if (
          this.state.filterOptionsState === true ||
          this.state.searchOptionsState === true
        ) {
        } else {
          this.setState({ perPage: num, page: 0 });
          this.changeWebinarRows(num);
        }
      },
      onFilterChange: (changedColumn, filterList, type) => {
        if (type === "reset") {
          this.resetWebinarFilters();
        }
      },
      onFilterChipClose: (index, removedFilter, filterList) => {
        let temp = [];
        filterList.map((value, index) => {
          for (let i = 0; i <= value.length; i++) {
            temp.push(i);
          }
        });
        // console.log("TEMP LENGTH: ", temp.length);
        if (temp.length === 12) {
          this.resetWebinarFilters();
        }
      },
    };
    const theme = createMuiTheme({
      overrides: {
        MUIDataTableSelectCell: {
          expandDisabled: {
            visibility: "hidden",
          },
        },
        MUIDataTableToolbar: {
          filterPaper: {
            width: "450px !important",
            height: "auto !important",
          },
        },
      },
    });
    return (
      <React.Fragment>
        <>
          <h4 className="regular_font fs-26 mtb-35">Webinar Attendees</h4>

          <hr></hr>
          <div className="col-md-12">
            <div className="row">
              {/* <div className="col-md-2 ">
                  <label className="bm-0">Type</label>
                  <Select
                    className=" mtb-10"
                    options={this.state.searchType}
                    value={this.state.selectedType}
                    onChange={this.searchFilter}
                    ref={this.searchTypeInput}
                  />
                </div> */}

              <div className="col-md-2">
                <label className="bm-0">Email</label>
                <div className="input-group md-form form-sm form-2 mtb-10">
                  <input
                    type="text"
                    className="form-control"
                    name="search"
                    // value={this.state.search}
                    // onChange={this.handleTextInput}
                    ref={this.searchEmailInput}
                    placeholder={"Search By Email"}
                    aria-label="Text input with dropdown button"
                  />
                </div>
              </div>

              <div className="col-md-2">
                <label className="bm-0">Full Name</label>
                <div className="input-group md-form form-sm form-2 mtb-10">
                  <input
                    type="text"
                    className="form-control"
                    name="search"
                    // value={this.state.search}
                    // onChange={this.handleTextInput}
                    ref={this.searchNameInput}
                    placeholder={"Search By Name"}
                    aria-label="Text input with dropdown button"
                  />
                </div>
              </div>

              {/* <div className="col-md-2 ">
                  <label className="bm-0">Email Or Name</label>
                  <div className="input-group md-form form-sm form-2 mtb-10">
                    <input
                      type="text"
                      className="form-control"
                      name="search"
                      // value={this.state.search}
                      // onChange={this.handleTextInput}
                      ref={this.searchTextInput}
                      placeholder={"Search By Value"}
                      aria-label="Text input with dropdown button"
                    />
                  </div>
                </div> */}

              <div className="col-md-2 ">
                <label className="bm-0">PEC No</label>
                <div className="input-group md-form form-sm form-2 mtb-10">
                  <input
                    type="text"
                    className="form-control"
                    name="search"
                    // value={this.state.search}
                    // onChange={this.handleTextInput}
                    ref={this.searchPecInput}
                    placeholder={"Search By PEC No"}
                    aria-label="Text input with dropdown button"
                  />
                </div>
              </div>

              <div className="col-md-2 ">
                <label className="bm-0">Discipline</label>
                <div className="input-group md-form form-sm form-2 mtb-10">
                  <input
                    type="text"
                    className="form-control"
                    name="search"
                    // value={this.state.search}
                    // onChange={this.handleTextInput}
                    ref={this.searchDisciplineInput}
                    placeholder={"Search By Discipline"}
                    aria-label="Text input with dropdown button"
                  />
                </div>
              </div>

              <div className="col-md-2">
                <label className="bm-0"></label>
                <button
                  className="btn admin_btn white w-150p float-right bold mtb-10"
                  onClick={() => {
                    this.searchData();
                  }}
                >
                  Search
                </button>
              </div>

              <div className="col-md-2">
                <label className="bm-0"></label>
                <button
                  className="btn admin_btn_new text-center white w-150p float-right bold mtb-10"
                  onClick={() => {
                    this.resetWebinarFilters();
                  }}
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
          <hr></hr>
        </>

        {this.state.load === true ? (
          <div className="loaderDiv text-center">
            <Loader type="Oval" color="#06580e" height={80} width={80} />
          </div>
        ) : (
          <div className="mt-50">
            <MuiThemeProvider theme={theme}>
              <MUIDataTable
                // title={"CPD Forms"}
                data={this.state.data}
                columns={columns}
                options={options}
                // components={components}
              />
            </MuiThemeProvider>
          </div>
        )}
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    payments: state.financialData.coursepayments,
    registrations: state.financialData.registrations,
  };
}
export default connect(mapStateToProps, {
  getwebinarAttendees,
  getWebinarFiltersData,
  getSearchWebinarAttendees,
  allAttendeesCount,
})(WebinarAttendeesAll);
