import React from "react";

export const GlobalContext = React.createContext();

export const GlobalContextConsumer = GlobalContext.Consumer;

export const GlobalContextProvider = ({ children }) => {
  const [cpdPointsData, setCpdPointsData] = React.useState({
    totalCpdPointsReg: null,
    currentPendingPoints: null,
    totalRejectedPoints: null,
    currentCycleTotalPoints: null,
  });

  React.useEffect(() => {
    console.log("CPD POINTS DATA ... ", cpdPointsData);
  }, [cpdPointsData]);

  return (
    <div>
      <GlobalContext.Provider
        value={{
          cpdPointsData,
          setCpdPointsData,
        }}
      >
        {children}
      </GlobalContext.Provider>
    </div>
  );
};
