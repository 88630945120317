import axios from "axios";
import { urlapi, urlapiEPE } from "./config/urlapi";

const admintoken = localStorage.getItem("CPD_Admin_LoginData");
const usertoken = localStorage.getItem("CPD_User_LoginData");

const adminAxios = axios.create({
  baseURL: urlapi,
  headers: {
    Authorization: `Bearer ${admintoken}`,
  },
});

// const adminCreateAxios = axios.create({
//   baseURL: urlapi,
//   headers: {
//     Authorization: `Bearer ${admintoken}`,
//     ...data.getHeaders()
//   },
//   data : data
// });

const userAxios = axios.create({
  baseURL: urlapi,
  headers: {
    Authorization: `Bearer ${usertoken}`,
  },
});

export default {
  admin: {
    webinar: {
      create_webinar: (data) =>
        adminAxios.post("/admin/create_webinar", { data }).then((res) => res),
      publishwebinarfeedback: (data) =>
        adminAxios
          .post("/admin/publish_web_feedback", { data })
          .then((res) => res),
      create_webinar_sessions: (data) =>
        adminAxios
          .post(`/admin/create_webinar_sessions/${data.id}`, { data })
          .then((res) => res),
      // create_webinar_pricing:(data) =>
      // axios.post(urlapi+`/admin/create_webinar_pricing/${data.id}`,{data} ).then(res=>res)
      // ,
      create_webinar_faqs: (data) =>
        adminAxios
          .post(`/admin/create_webinar_faqs/${data.id}`, { data })
          .then((res) => res),
      save_webinar_draft: (data) =>
        adminAxios
          .post(`/admin/save_webinar_draft`, { data })
          .then((res) => res),
      getallwebinars: () =>
        axios.get(urlapi + "/admin/get_all_webinars").then((res) => res),
      getAllPublishedWebinars: (data) =>
        axios
          .post(urlapi + `/admin/get_all_published_webinars`, { data })
          .then((res) => res),
      getWebinarByType: (data) =>
        axios
          .post(urlapi + `/admin/get_webinar_type`, { data })
          .then((res) => res),
      GetAllWebinarsAdmin: (data) =>
        axios
          .post(urlapi + `/admin/get_all_webinars_admin`, { data })
          .then((res) => res),
      getWebinar: (id) =>
        axios.get(urlapi + `/admin/get_webinar_data/${id}`).then((res) => res),
      edit_webinar_details: (data) =>
        adminAxios
          .post("/admin/edit_webinardetails", { data })
          .then((res) => res),
      update_assessment: (data) =>
        adminAxios
          .post("/admin/update_assessment", { data })
          .then((res) => res),
      get_sessions: (data) =>
        axios
          .get(urlapi + `/admin/getSessions/${data}`)
          .then((res) => res.data),
      get_open_sessions: (data) =>
        axios
          .get(urlapi + `/admin/getopen_Sessions/${data}`)
          .then((res) => res.data),
      edit_session: (data) =>
        adminAxios
          .post(`/admin/edit_session/${data.c_id}`, { data })
          .then((res) => res.data),

      delete_sess: (data) =>
        adminAxios
          .post(`/admin/delete_session/${data.c_id}`, { data })
          .then((res) => res.data),
      add_sess: (data) =>
        adminAxios
          .post(`/admin/add_session/${data.id}`, { data })
          .then((res) => res.data),
      add_price_plans: (data) =>
        adminAxios
          .post(`/admin/add_price_plan/${data.id}`, { data })
          .then((res) => res.data),
      get_extraresources: (data) =>
        axios.get(urlapi + `/admin/get_extra/${data}`).then((res) => res.data),
      delete_file: (data) =>
        adminAxios
          .post(`/admin/delete_file/${data.fileId}`, { data })
          .then((res) => res.data),
      add_faq: (data) =>
        adminAxios
          .post(`/admin/add_faq/${data.id}`, { data })
          .then((res) => res.data),
      get_faqs: (data) =>
        axios.get(urlapi + `/admin/get_faqs/${data}`).then((res) => res.data),
      delete_faq: (data) =>
        adminAxios
          .post(`/admin/delete_faq/${data.faq_id}`, { data })
          .then((res) => res.data),
      edit_faq: (data) =>
        adminAxios
          .post(`/admin/edit_faq/${data}`, { data })
          .then((res) => res.data),
      publishwebinar: (data) =>
        adminAxios
          .post(`/admin/publish_webinar`, { data })
          .then((res) => res.data),
      archive_webinar: (data) =>
        adminAxios
          .post(`/admin/archive_webinar`, { data })
          .then((res) => res.data),
      get_Instructors: () =>
        axios.get(urlapi + `/admin/get_instructors`).then((res) => res.data),
      get_PaymentPlan: (data) =>
        axios
          .post(urlapi + `/admin/get_PaymentPlan`, { data })
          .then((res) => res.data),
      update_PaymentPlan: (data) =>
        axios
          .post(urlapi + "/admin/update_PaymentPlan", { data })
          .then((res) => res.data),
      get_webinar_count: (data) =>
        axios
          .post(urlapi + `/admin/get_webinar_count`, { data })
          .then((res) => res.data),
      get_categories: () =>
        axios.get(urlapi + `/admin/get_categories`).then((res) => res.data),
      get_admin_webinar_count: () =>
        axios
          .get(urlapi + `/admin/get_admin_webinar_count`)
          .then((res) => res.data),
      get_webinar_id: (id) =>
        axios
          .post(urlapi + `/admin/get_webinar_id`, { id })
          .then((res) => res.data),
    },
    financial: {
      getcoursepayments: (data) =>
        axios
          .post(urlapi + `/admin/get_payments`, { data })
          .then((res) => res.data),
      getFilterPayments: (data) =>
        axios
          .post(urlapi + `/admin/get_filter_payments`, { data })
          .then((res) => res.data),
      getPaymentSearch: (data) =>
        axios
          .post(urlapi + `/admin/get_payment_search`, { data })
          .then((res) => res.data),
      getcoursepaymentsrest: (data) =>
        axios
          .post(urlapi + `/admin/get_payments_rest`, { data })
          .then((res) => res.data),
      getcoursepaymentsRejected: (data) =>
        axios
          .post(urlapi + `/admin/get_payments_rejected`, { data })
          .then((res) => res.data),
      getCoursePaymentCount: () =>
        axios
          .get(urlapi + `/admin/get_course_paymentCount`)
          .then((res) => res.data),
      getCoursePaymentCountRest: () =>
        axios
          .get(urlapi + `/admin/get_course_paymentCountRest`)
          .then((res) => res.data),
      getCoursePaymentCountRejected: () =>
        axios
          .get(urlapi + `/admin/get_course_paymentCountRejected`)
          .then((res) => res.data),
      approvePayment: (data) =>
        adminAxios
          .post(`/admin/approve_payment`, { data })
          .then((res) => res.data),
      rejectPayment: (data) =>
        adminAxios
          .post(`/admin/reject_payment`, { data })
          .then((res) => res.data),
      get_free_registrations: (data) =>
        axios
          .post(urlapi + `/admin/get_free_registrations`, { data })
          .then((res) => res),
      get_webinar_reg_count: () =>
        axios.get(urlapi + `/admin/get_webinar_reg_count`).then((res) => res),
      get_webinar_attendees: (data) =>
        axios
          .post(urlapi + `/admin/get_webinar_attendees_all`, { data })
          .then((res) => res),
      all_attendees_count: () =>
        axios.get(urlapi + `/admin/all_attendees_count`).then((res) => res),
      getWebinarFilters: (data) =>
        axios
          .post(urlapi + `/admin/get_webinar_filters`, { data })
          .then((res) => res.data),
      getWebinarSearch: (data) =>
        axios
          .post(urlapi + `/admin/get_webinar_search`, { data })
          .then((res) => res.data),
      getSearchAttendees: (data) =>
        axios
          .post(urlapi + `/admin/get_search_attendees`, { data })
          .then((res) => res.data),
      getPaymentDataByApprovedId: (data) =>
        axios
          .post(urlapi + "/admin/get_payment_data_by_approved_id", {
            approvedById: data,
          })
          .then((res) => res.data),
      getPaymentAmounts: () =>
        axios
          .get(urlapi + "/admin/get_payment_amounts")
          .then((res) => res.data),
    },
    users: {
      getWebinarRegistered: (data) =>
        adminAxios
          .post(`/admin/get_webinar_registered`, { data })
          .then((res) => res),
      getWebinarAttendees: (data) =>
        adminAxios
          .post(`/admin/get_webinar_attendees`, { data })
          .then((res) => res),
      get_admins: (data) =>
        adminAxios
          .post(urlapi + `/admin/get_admins`, { data })
          .then((res) => res),
      get_filter_admins: (data) =>
        adminAxios
          .post(urlapi + `/admin/get_filter_admins`, { data })
          .then((res) => res),
      get_admin_count: () =>
        adminAxios.get(`/admin/get_admin_count`).then((res) => res.data[0]),
      getEngineerFilter: (data) =>
        adminAxios
          .post(urlapi + `/admin/get_engineer_filter`, { data })
          .then((res) => res.data),
      getEngineerSearch: (data) =>
        adminAxios
          .post(urlapi + `/admin/get_engineer_search`, { data })
          .then((res) => res.data),
      search_admin_users: (data) =>
        adminAxios
          .post(urlapi + `/admin/search_admin_users`, { data })
          .then((res) => res.data),
      resetPassword: (data) =>
        axios
          .post(urlapi + `/admin/reset_password/`, data)
          .then((res) => res.data),
      setResetPassword: (data) =>
        axios
          .post(urlapi + `/admin/set_reset_password/`, data)
          .then((res) => res.data),
      new_reset_password: (data) =>
        axios
          .post(urlapi + `/admin/new_reset_password/`, data)
          .then((res) => res.data),
      new_reset_email: (data) =>
        axios
          .post(urlapi + `/admin/new_reset_email/`, data)
          .then((res) => res.data),
      verify_email: (data) =>
        adminAxios.post(`/admin/verify_email`, { data }).then((res) => res),

      set_password: (data) =>
        adminAxios.post(`/admin/set_password`, { data }).then((res) => res),
      get_roles: () => adminAxios.get(`/admin/get_roles`).then((res) => res),

      get_attendence_data: () =>
        adminAxios.get(`/admin/get_attendence_data`).then((res) => res),

      get_admin_permission: () =>
        adminAxios.get(`/admin/get_admin_permission`).then((res) => res),
      get_all_components: () =>
        adminAxios.get(`/admin/get_all_components`).then((res) => res),
      edit_admin_permission: (data) =>
        adminAxios
          .post(`/admin/edit_admin_permission`, { data })
          .then((res) => res),
      delete_Role: (data) =>
        adminAxios.post(`/admin/delete_role`, { data }).then((res) => res),
      add_role_admin: (data) =>
        adminAxios.post(`/admin/add_role_admin`, { data }).then((res) => res),
      add_admin: (data) =>
        adminAxios
          .post(`/admin/add_admin`, data, {
            headers: {
              accept: "application/json",
              "Accept-Language": "en-US,en;q=0.8",
              "Content-Type": `multipart/form-data ; boundary= ${data._boundary}`,
            },
          })
          .then((res) => res),

      edit_admin: (data) =>
        adminAxios
          .post(urlapi + `/admin/edit_admin`, data, {
            headers: {
              accept: "application/json",
              "Accept-Language": "en-US,en;q=0.8",
              "Content-Type": `multipart/form-data ; boundary= ${data._boundary}`,
            },
          })
          .then((res) => res),

      delete_admin: (data) =>
        adminAxios
          .post(urlapi + `/admin/delete_admin/${data.id}`)
          .then((res) => res),

      delete_admins: (data) =>
        adminAxios
          .post(urlapi + `/admin/delete_admins`, { data })
          .then((res) => res),

      edit_bulk: (data) =>
        adminAxios
          .post(urlapi + `/admin/edit_admins`, { data })
          .then((res) => res),

      engineerlist: (data) =>
        adminAxios
          .post(urlapi + `/admin/engineer_list`, { data })
          .then((res) => res),
      countEngineers: () =>
        adminAxios.get(urlapi + `/admin/engineer_count`).then((res) => res),
      getTenurePoints: (data) =>
        adminAxios
          .post(urlapi + `/admin/get_tenure_points`, { data })
          .then((res) => res),
    },

    cpd: {
      get_cpd_form_data: (data) =>
        axios.get(urlapi + `/admin/get_cpd_form_data`).then((res) => res),
      get_cpd_form_row_data: (data) =>
        axios
          .post(urlapi + `/admin/get_cpd_form_row_data`, { data })
          .then((res) => res),
      get_cpd_form_row_data_pr: (data) =>
        axios
          .post(urlapi + `/admin/get_cpd_form_row_data_PR`, { data })
          .then((res) => res),
      get_cpd_form_rows_rejected: (data) =>
        axios
          .post(urlapi + `/admin/get_cpd_form_rows_rejected`, { data })
          .then((res) => res),
      get_claims_filter: (data) =>
        axios
          .post(urlapi + `/admin/get_claims_filter`, { data })
          .then((res) => res),
      get_claims_filter_count: () =>
        axios.get(urlapi + `/admin/get_claims_filter_count`).then((res) => res),
      get_year_approved_points: (data) =>
        axios
          .post(urlapi + `/admin/get_year_approved_points`, { data })
          .then((res) => res),
      get_total_cpd_row_count: () =>
        axios.get(urlapi + `/admin/get_total_cpd_row_count`).then((res) => res),
      get_total_cpd_row_count_pr: () =>
        axios
          .get(urlapi + `/admin/get_total_cpd_row_count_pr`)
          .then((res) => res),
      get_total_cpd_count_rejected: () =>
        axios
          .get(urlapi + `/admin/get_total_cpd_count_rejected`)
          .then((res) => res),
      get_cpd_form_row_expendable: (data) =>
        axios
          .post(urlapi + `/admin/get_cpd_form_row_expendable`, { data })
          .then((res) => res),
      update_cpd_status: (data) =>
        axios
          .post(urlapi + `/admin/update_cpd_status`, { data })
          .then((res) => res),
      reject_cpd_status: (data) =>
        axios
          .post(urlapi + `/admin/reject_cpd_status`, { data })
          .then((res) => res),
      search_claims: (data) =>
        axios
          .post(urlapi + "/admin/search_claims", { data })
          .then((res) => res),
      search_claims_second: (data) =>
        axios
          .post(urlapi + "/admin/search_claims_second", { data })
          .then((res) => res),
      user_cpd_claim: (data) =>
        axios
          .post(urlapi + "/admin/get_cpd_user_claim", { data })
          .then((res) => res),
      profile_data_year: (data) =>
        axios
          .post(urlapi + "/admin/get_profile_data_year", { data })
          .then((res) => res),

      get_attendence: () =>
        axios.get(urlapi + "/admin/getCPDAttendence").then((res) => res),
      approve_attendence: (data) =>
        axios
          .post(urlapi + "/admin/approve_attendence", { data })
          .then((res) => res),
      reject_attendence: (data) =>
        axios
          .post(urlapi + "/admin/reject_attendence", { data })
          .then((res) => res),
      update_cpd_points: (data) =>
        axios
          .patch(urlapi + "/admin/update_cpd_points", { data })
          .then((res) => res),
      get_user_submission: (data) =>
        axios
          .post(urlapi + "/admin/get_user_submission", { data })
          .then((res) => res),
      get_claim_based_points: (data) =>
        axios
          .post(urlapi + "/admin/get_claim_based_points", data)
          .then((res) => res),
      get_admin_summary: async (data) =>
        axios
          .post(urlapi + "/admin/get_admin_summary", { data })
          .then((res) => res),
      get_pending_claims_count: async () =>
        axios
          .get(urlapi + "/admin/get_pending_claims_count")
          .then((res) => res),
      get_approved_claims_count: async () =>
        axios
          .get(urlapi + "/admin/get_approved_claims_count")
          .then((res) => res),
      get_rejected_claims_count: async () =>
        axios
          .get(urlapi + "/admin/get_rejected_claims_count")
          .then((res) => res),
      get_all_admins_summary: () =>
        axios
          .get(urlapi + "/admin/get_all_admins_summary")
          .then((res) => res.data),
      get_all_claims_count: async () =>
        axios.get(urlapi + "/admin/get_all_claims_count").then((res) => res),
      get_pending_payment_count: async () =>
        axios
          .get(urlapi + "/admin/get_pending_payment_count")
          .then((res) => res),
      get_approved_payment_count: async () =>
        axios
          .get(urlapi + "/admin/get_approved_payment_count")
          .then((res) => res),
      get_rejected_payment_count: async () =>
        axios
          .get(urlapi + "/admin/get_rejected_payment_count")
          .then((res) => res),
      get_all_payment_count: async () =>
        axios.get(urlapi + "/admin/get_all_payment_count").then((res) => res),
      get_finance_admin_summary: async (data) =>
        axios
          .post(urlapi + "/admin/get_finance_admin_summary", { data })
          .then((res) => res),
      get_all_roles: async () =>
        axios.get(urlapi + "/admin/get_all_roles").then((res) => res),
      get_user_claims_by_submission_id: async (data) =>
        axios
          .post(urlapi + "/admin/get_user_claims_by_submission_id", { data })
          .then((res) => res),
      get_all_admins_summary: () =>
        axios
          .get(urlapi + "/admin/get_all_admins_summary")
          .then((res) => res.data),
    },
    dashboard: {
      get_summary_webinar: () =>
        adminAxios.get("/admin/get_summary_webinar").then((res) => res),
      get_summary_stats: () =>
        adminAxios.get("/admin/get_summary_stats").then((res) => res),
    },
  },
  user: {
    login: (data) =>
      // axios.post(urlapi+`/user/login`,{data}).then(res=> res)
      axios.post(urlapi + "/user/peclogin", { data }).then((res) => res),
    loginEpe: (data) =>
      axios.post(urlapiEPE + "/engineer", { data }).then((res) => res),
    checkEpePoints: (data) =>
      axios
        .post(urlapi + "/user/check-epe-points", { data })
        .then((res) => res),
    getEngineerInfo: (data) =>
      axios
        .post(urlapi + "/user/engineerInformation", { data })
        .then((res) => res),
    saveUserData: (data) =>
      axios
        .post(urlapi + "/user/saveEngineerLogin", { data })
        .then((res) => res),

    // submitpayment: (data)=> userAxios.post(urlapi+"/user/submit_payment",{data}).then((res)=>res),
    submitpayment: (data) =>
      axios
        .post(urlapi + `/user/submit_payment`, data, {
          headers: {
            // Authorization: `Bearer ${usertoken}`,
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
            "Content-Type": `multipart/form-data ; boundary= ${data._boundary}`,
          },
        })
        .then((res) => res.data),

    // epe apis
    // submitEpePayment: (data) =>
    //   axios
    //     .post(urlapiEPE + `/engineer/submit-payment`, data)
    //     .then((res) => res.data),
    submitEpePayment: (data) =>
      axios
        .post(urlapiEPE + `/engineer/submit-payment`, { data })
        .then((res) => console.log("submit payment >>>>", res)),
    getEpeHistory: (data) =>
      axios
        .post(urlapiEPE + `/engineer/get-epe-history`, data)
        .then((res) => console.log("getepehistory_from_api.js>>>>>>", res)),
    getSubDiscipline: (name) =>
      axios
        .get(urlapiEPE + `/discipline/${name}`)
        .then((res) => res.data)
        .catch((err) => err),
    getEpECenter: (examId) =>
      // axios.get(urlapiEPE + `/exams/centers`).then((res) => res),
      axios
        .get(urlapiEPE + `/center/exam/${examId}`)
        .then((res) => res)
        .catch((err) => console.log(err)),
    getCurrentExam: () => axios.get(urlapiEPE + `/exam`).then((res) => res),

    getActiveExam: () =>
      axios.get(urlapiEPE + `/exam/active-exam`).then((res) => res),

    registerforfree: (data) =>
      axios
        .post(urlapi + `/user/register_free_webinar`, { data })
        .then((res) => res),

    get_status_free_webinars: (data) =>
      axios
        .post(urlapi + `/user/get_status_free_webinar`, { data })
        .then((res) => res.data),
    get_status_priced_webinars: (data) =>
      axios
        .post(urlapi + `/user/get_status_priced_webinar`, { data })
        .then((res) => res.data),

    admin_login: (data) =>
      axios.post(urlapi + `/user/admin_login`, { data }).then((res) => res),
    get_gallery: () =>
      axios.get(urlapi + `/user/get_gallery`).then((res) => res),
    join_webinar: (data) =>
      axios.post(urlapi + `/user/join_webinar`, { data }).then((res) => res),

    leave_webinar: (data) =>
      axios.post(urlapi + `/user/leave_webinar`, { data }).then((res) => res),
    get_all_fields: () =>
      axios.get(urlapi + `/user/get_all_fields`).then((res) => res),
    get_subcategories: (data) =>
      axios
        .post(urlapi + "/user/get_subcategories", { data })
        .then((res) => res),
    get_subfields: (data) =>
      axios.post(urlapi + "/user/get_subfields", { data }).then((res) => res),
    get_points: (data) =>
      axios.post(urlapi + "/user/get_points", { data }).then((res) => res),
    get_user_submission: (data) =>
      axios
        .post(urlapi + "/user/get_user_submission", { data })
        .then((res) => res),
    get_claim_based_points: (data) =>
      axios
        .post(urlapi + "/user/get_claim_based_points", data)
        .then((res) => res),
    get_duplicate_records: (data) =>
      axios
        .post(urlapi + "/user/get_duplicate_records", data)
        .then((res) => res),
    submitCPDForm: (data) =>
      axios
        .post(urlapi + "/user/submit_CPDForm", data, {
          headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
            "Content-Type": `multipart/form-data ; boundary= ${data._boundary}`,
          },
        })
        .then((res) => res),
    attended_webinars_profile: (data) =>
      axios
        .post(urlapi + "/user/get_attended_webinars_profile", { data })
        .then((res) => res),
    registered_webinar_profile: (data) =>
      axios
        .post(urlapi + "/user/registered_webinar_profile", { data })
        .then((res) => res),
    get_permissions: (id) =>
      adminAxios.get(urlapi + `/user/get_permissions/${id}`).then((res) => res),
    add_postal: (data) =>
      axios.post(urlapi + `/user/add_postal`, { data }).then((res) => res),
  },
};
