import React, { Component } from "react";
import ToggleButton from "react-toggle-button";
import { publishwebinar } from "../../redux/admin/webinar/webinarActions";
import { connect } from "react-redux";
import WebNotif from "../WebNotif";
var obj = new WebNotif();
class MyToggleButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toggle: this.props.item.Status == "1",
    };
    this.setToggle = this.setToggle.bind(this);
  }
  setToggle() {
    this.setState({
      toggle: !this.state.toggle,
    });
    this.props
      .publishwebinar(this.props.item.CourseID)
      .then(
        obj.createNotification("success", "Webinar Status Successfully Changed")
      );
  }
  componentDidMount() {
    // console.log(this.props.item)
  }
  render() {
    return (
      <div>
        <ToggleButton
          value={this.state.toggle}
          inactiveLabel={""}
          activeLabel={""}
          colors={{
            inactive: {
              base: "#eb4034",
            },
          }}
          trackStyle={{
            height: "15px",
            borderRadius: "10px",
          }}
          thumbStyle={{
            height: "20px",
            width: "20px",
          }}
          thumbAnimateRange={[-10, 36]}
          onToggle={this.setToggle}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}
export default connect(mapStateToProps, { publishwebinar })(MyToggleButton);
