import api from "../../../api";

export const GotWebinarSummary = (data) => ({
  type: "WEBINAR_SUMMARY",
  data,
});

export const getDashboardWebinars = () => (dispatch) =>
  api.admin.dashboard.get_summary_webinar().then((res) => {
    dispatch(GotWebinarSummary(res.data));
    return res;
  });

export const getDashboardStatistics = () => (dispatch) =>
  api.admin.dashboard.get_summary_stats().then((res) => res);


