import React from 'react';
import Header from './Header';
import Footer from './Footer';
import { Link } from 'react-router-dom';
import CpdActivitiesSIDEbar from './CpdActivitiesSIDEbar';
import Pdf from '../assets/pdf/15th_EPE_Result_2.pdf';
import brochure from "../assets/pdf/Doc111111.pdf";


class Documents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [{
        name: "Short Courses Presentation by PEC",
        url: "/ShortCoursesPPT"
      },
      {
        name: "Forms",
       
        type:"button",
        "child":[
          {
            name: "CPD Resourse Person Allocation Form",
            url: "/CPD_Resource_person",
          },
          {
            name: "EPE Applicatin Forms ",
            url: "/EPEForms",
          },
          {
            name: "PEB Application Form",
            url: "/CPDForms",
          }
        ]
      },
      {
        name: "Documents",
        url: "/Documents"
      }
      ]
      }
  }
  componentDidMount(){
    document.title = "Forms";
}
render() {
  return (
    <React.Fragment>
      <section  className="backco_white" style={{ fontFamily: 'Arial' }}>
        <Header />
        <div className="row col_margin">
          <div className="col-md-3 col_padding" style={{ padding: '2%' }}>
          <CpdActivitiesSIDEbar data={this.state.data} />
          </div>
          <div className="col-md-7 col_padding intr_mr">
            <h2 className="text-center" style={{ margin: '28px 0px 0px 1px' }}>Documents</h2>
            <div className="row">
               <div className="col-md-12"  style={{ padding: '2%', paddingTop: "0px" }}>
              <div style={{ marginBottom:"20px" }}>
              <i className="far fa-file-pdf fa-1x co_black bold"></i>&nbsp;
                <b className="pdf_text">CPD Relax Policy</b>&nbsp;&nbsp;
                <a href="https://www.pec.org.pk/downloads/cpd/CPD_Relaxed_Policy_Updated_Nov_2019.pdf" className="btn btn-success bold font_sans fz_14"
                    target="_blank" download>Download</a>
                </div>
                <div>
              <i className="far fa-file-pdf fa-1x co_black bold"></i>&nbsp;
                <b className="pdf_text">CPD Brief Document</b>&nbsp;&nbsp;
                    <Link to={brochure} className="btn btn-success bold font_sans fz_14" 
                    target="_blank" download>Download</Link>
               </div>
              </div>
            </div>
          </div>
          <div className="col-md-1">
          </div>
        </div>
        {/*<Footer/>*/}
      </section>
    </React.Fragment>
    );
  }
}
export default Documents;