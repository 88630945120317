const initstate = {
  user: null,
  status: null,
  admin: null,
  gallery: null,
  fielddata: null,
  admin_permissions: null,
  epe: null,
  reduxForm: {},
};

export default function userData(state = initstate, action = {}) {
  switch (action.type) {
    case "SUCCESSFUL_LOGIN": {
      return { ...state, token: action.data.token, user: action.data.data };
    }
    case "EPE_LOGIN": {
      return { ...state, epe: action.data };
    }
    case "EPE_POINTS_CHECKED": {
      return { ...state };
    }
    case "GOT_SUB_DISCIPLINE": {
      return { ...state };
    }
    case "EPE_PAYMENT_SUBMITTED":
      return { ...state };
    case "GOT_CURRENT_EXAM":
      return { ...state };
    case "PAYMENT_SUBMITTED":
      return { ...state };
    case "REGISTERED_FREE_WEBINAR":
      return { ...state };
    case "LOGOUT":
      return { ...initstate };
    case "GOT_PAYMENTS":
      return { ...state };
    case "GOT_ATTENDED_WEBINAR_PROFILE":
      return { ...state };
    case "GOT_REGISTERED_WEBINAR_PROFILE":
      return { ...state };
    case "GOTSTATUS": {
      return { ...state, status: action.data };
    }
    case "ADMIN_LOGIN_SUCCESSFUL": {
      return { ...state, token: action.data.token, admin: action.data.data };
    }
    case "GOT_GALLERY": {
      return { ...state, gallery: action.data };
    }
    case "GOT_FIELDS": {
      return { ...state };
    }
    case "GOT_SUBCATEGORIES": {
      return { ...state };
    }
    case "GOT_SUBFIELDS": {
      return { ...state };
    }
    case "GOT_POINTS": {
      return { ...state };
    }
    case "CPD_FORM_SUBMITTED": {
      return { ...state };
    }
    case "GOT_USER_SUBMISSION": {
      return { ...state };
    }
    case "GOT_USER_CLAIM_BASED": {
      return { ...state };
    }
    case "DUPLICATE_RECORDS_CHECK": {
      return { ...state };
    }
    case "GOT_PERMISSIONS_BY_ROLE": {
      return { ...state, admin_permissions: action.data };
    }
    case "GOT_EPE_HISTORY": {
      consol.log("got_epe_history", state);
      return { ...state };
    }
    case "GOT_EPE_CENTER": {
      return { ...state };
    }
    case "SAVED_REDUX_FORM": {
      return { ...state, reduxForm: action.data };
    }
    case "EPE_CENTER_SAVED": {
      console.log("data123>>>>>", action.data);
      console.log("data456>>>>>", state.reduxForm);
      return {
        ...state,
        reduxForm: { ...state.reduxForm, center: action.data },
      };
    }

    case "JOINED_WEBINAR": {
      return { ...state };
    }
    case "LEFT_WEBINAR": {
      return { ...state };
    }
    case "ADDED_POSTAL": {
      return { ...state };
    }
    default:
      return state;
  }
}
