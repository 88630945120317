const initstate = {
  alladmins: null,
};

export default function UserManageData(state = initstate, action = {}) {
  switch (action.type) {
    case "GOT_ADMIN_USERS":
      return { alladmin: action.data };

    case "GOT_ADMIN_ROLES":
      return { ...state, allroles: action.data };

    case "GOT_ADMIN_COUNT":
      return { ...state }

    case "ADDED_ADMIN ":
      return { ...state };

    case "GOT_ATTENDENCE_DATA":
      return { ...state };

      case "GOT_ADMIN_PERMISSION":
        return {...state};
      case "GOT_ALL_COMPONENTS":
        return{...state};
      case "PASSWORD_RESETED":{
        return{...state,};
      }
      case "SET_PASSWORD_RESETTED":{
        return{...state};
      }
      case "GOT_WEBINAR_ATTENDEES":{
        return{...state};
      }
      case "GOT_WEBINAR_REGISTERED":{
        return{...state};
      }
      case "EDITED_ADMIN_PERMISSION":{
        return{...state};
      }
      case "ROLE_DELETED":{
        return{...state};
      }
      case "ROLE_CREATED":{
        return{...state};
      }
    case "EDITED_ADMIN":
      return { ...state };

    case "ADMIN_DELETED":
      return { ...state };

    case "BULK_EDIT":
      return { ...state };

    case "EMAIL_VERIFIED":
      return { ...state };
    case "PASSWORD_SET":
      return { ...state };
    case "ENGINEER_LIST":
    return {...state};
    case "ENGINEER_COUNT":
      return{...state};
    case "GOT_TENURE_POINTS":
      return{...state};
    default:
      return state;
  }
}
