
import React from 'react';
import Chat from "./Chat";
import Header from './Header';
import {connect} from 'react-redux';
import { joinedWebinar,leftwebinar } from "../redux/user/useraction";
// import Zoom from './zoom';
class LiveWebinar extends React.Component {
 
  state = {
    session_id:null,
    course_id:null,

  }
  UNSAFE_componentWillMount(){
    if(this.props.location){
      if(this.props.location.state){
        this.setState({
          session_id: this.props.location.state.session.session_id,
          course_id:this.props.location.state.course.CourseID,
          course_name:this.props.location.state.course.Course_Name
        },()=>{
          localStorage.setItem('attending_session_id',this.state.session_id)
          localStorage.setItem('attending_course_id',this.state.course_id)
          localStorage.setItem('attending_course_name',this.state.course_name)
        })
        
      }
     
    }
  }

  componentDidMount(){
    document.title = "Live Webinar";
  
   

    if(this.state.session_id){
      this.props.joinedWebinar({
        u_id:this.props.User.UserID,
        c_id:this.state.course_id,
        s_id:this.state.session_id
      }).then(
        res=>{
          console.log(res)
        }
      )
    }else{
      this.setState({
        session_id: localStorage.attending_session_id,
        course_id: localStorage.attending_course_id,
        course_name: localStorage.attending_course_name
      },()=>{

        this.props.joinedWebinar({
          u_id:this.props.User.UserID,
          c_id:this.state.course_id,
          s_id:this.state.session_id
        }).then(
          res=>{
            console.log(res)
          }
        )
      })
    }
    
}

componentWillUnmount() {

  this.props.leftwebinar({
    u_id:this.props.User.UserID,
    c_id:this.state.course_id,
    s_id:this.state.session_id
  }).then(
    res=>{
   
      console.log(res)
    }
  )

}
  render() {
    return (
      <React.Fragment>
        <section  className="backco_white" style={{ fontFamily: 'Arial' }}>
          <Header />
          <div className="row col_margin">
            <div className="col-md-1" style={{ padding: '2%' }}>
            
            </div>
            <div className="col-md-11 col_padding intr_mr">
              <div className="row col_margin">
                <div className="col-md-5">
                <h3 className="bold" style={{ margin: '5vh 0px 0px 1px',paddingLeft:"2%" }}>
               {this.state.course_name}</h3>
             </div>
             <div className="col-md-2">
                 <h5 className="live_webinar_text">Live Webinar</h5>
                </div>
             </div>
             
              <div className="row col_margin">
                <div className="col-md-7 col_padding" 
                 style={{ padding: '2%' }}>

                  {/* <Zoom    
                    User={this.props.User}
                  /> */}



                 {/* <div>
                 <iframe
                  allow="microphone; camera; fullscreen"
                  src="https://zoom.us/wc/87372949469/join"
                  width="100%"
                  height="600px"
                  frameborder="0"
                  scrolling="no"
                  sandbox="allow-forms allow-scripts allow-same-origin"
                  allowFullScreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"
                ></iframe>
                 

                </div> */}

               </div>
               <div className="col-md-4 col_padding">
                 <Chat
                  u_id={this.props.User.UserID}
                  user={this.props.User}
                  s_id={this.state.session_id}
                  c_id={this.state.course_id}

                 />
               </div>
             </div>


             </div>
          </div>
         

          
        </section>
      </React.Fragment>
    )
  };
}

function mapStateToProps(state){
  return {
    User : state.userData.user
  }
}
export default connect (mapStateToProps,{joinedWebinar,leftwebinar})(LiveWebinar);



//var countDownDate = new Date("Jan 1, 2020 00:00:00").getTime();
    //   setInterval(() => {
    //     var now = new Date().getTime();
    //     var distance = countDownDate - now;
    //     var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    //     var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    //     var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    //     var seconds = Math.floor((distance % (1000 * 60)) / 1000);

    //     this.setState({
    //         day:days,
    //         hour:hours,
    //         minute:minutes,
    //         secound:seconds
    //       })
    //   }, 1000);





















