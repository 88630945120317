import React, { Component } from "react";
import * as yup from 'yup';
import WebNotif from "../WebNotif";
import Feedback_Webinar from "./Feedback_Webinar";
import { update_assessment } from '../../redux/admin/webinar/webinarActions';
import { connect } from 'react-redux';

var obj = new WebNotif();


const urlSchema = yup.object().shape({
  feedbacklink: yup
    .string()
    .matches(
      /^((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      'Enter correct url!'
    )
  ,
  assessmentlink: yup
    .string()
    .matches(
      /^((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      'Enter correct url!'
    )


});
class Assessments extends Component {
  state = {


    feedbacklink: '',
    assessmentlink: '',



  };

  UNSAFE_componentWillMount() {
    if (this.props.currentWebinarData) {
      this.setState({
        feedbacklink: this.props.currentWebinarData.feedback_url, assessmentlink: this.props.currentWebinarData.assessment_url
      }, () => {

      })
    }
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    })

  }
  saveLinks = () => {
    //condition for empty check

    if ((this.state.feedbacklink == '' && this.state.assessmentlink == '' || this.state.feedbacklink === null || this.state.assessmentlink === null ||this.state.feedbacklink == 'null' && this.state.assessmentlink == 'null')) {

      this.props.nextFromAssessment();

    } else {
      urlSchema.validate(this.statea).then(
        result => {
          let course_id;
          if (this.props.isEdit == false) {

            if (this.props.webinarDetails.insertId) {

              course_id = this.props.webinarDetails.insertId

            } else {
              obj.createNotification("error", "Save Webinar Details First")
            }

          } else {
            course_id = this.props.currentWebinarData.CourseID
          }



          this.props.update_assessment({ data: this.state, isEdit: this.props.isEdit, course_id: course_id }).then(
            res => {
              obj.createNotification("success", "links saved")
              this.props.nextFromAssessment();
            }
          ).catch(err => {

          })

        })
        .catch(
          err => {
            obj.createNotification("error", err.message)
          })
    }




  }

  render() {
    return (
      <React.Fragment>
        <div className="container-fluid ">

          <h4>Assessment/Feedback</h4>

          <div className="row pt-75 mtb-25">

            <div className="form-group col-md-6">

              <label>
                Assessment Form URL
                      </label>

              <input
                type="text"

                name="assessmentlink"

                value={this.state.assessmentlink}



                className="form-control admin-form-control"

                onChange={this.handleChange}


              />
            </div>

            <div className="form-group col-md-6">

              <label>
                Feedback Form URL
                        </label>

              <input
                type="text"

                name="feedbacklink"

                value={this.state.feedbacklink}



                className="form-control admin-form-control"

                onChange={this.handleChange}


              />
            </div>


          </div>
          {this.props.isEdit == true && (this.props.User.RoleID === 8 || this.props.User.RoleID === 7 || this.props.User.RoleID === 3 || this.props.User.RoleID === 4 || this.props.User.RoleID === 5) ? (
            <Feedback_Webinar
              webinarId={this.props.currentWebinarData && this.props.currentWebinarData.CourseID}
              owner={this.props.currentWebinarData && this.props.currentWebinarData.user_id}
            />
          ) : ""}
          {(this.props.User.RoleID !== 7 || this.props.User.RoleID !== 8 || this.props.User.RoleID === 3 || this.props.User.RoleID === 4) &&
            <div className="col-md-12">
              <button
                onClick={this.saveLinks}
                type="submit"
                style={{ marginTop: '23px' }}
                className="btn float-right btn-save   black bold "
              >
                {this.props.isEdit ? "Update and Next" : "Save and Next"}</button>
            </div>



          }

        </div>
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    webinarDetails: state.webinarData.webinarDetails,
    User: state.userData.admin,
  }
}
export default connect(mapStateToProps, { update_assessment })(Assessments);
