import React, { Component } from "react";
import { connect } from "react-redux";
import PermissionOptions from "./PermissionOptions";
import {
  getAdminPermission,
  EditAdminPermission,
  DeleteRole,
  GetAllComponents,
  AddRoleAdmin,
} from "../../redux/admin/users/adminuseractions";
import { filter } from "lodash";
import { confirmAlert } from "react-confirm-alert";
import WebNotif from "../WebNotif";
var obj = new WebNotif();
class AdminPermission extends Component {
  state = {
    allData: null,
    roles: null,
    permiss: [],
    isEdit: false,
    tempPermission: [],
    changes: [],
    components: [],
    // json: {
    //   UC: "User Claims",
    //   CPA: "CPD Point Approval",
    //   AP: "Admin Permission",
    // },
  };

  componentDidMount() {
    this.props.GetAllComponents().then((res1) => {
      // console.log(res1);

      let compdata = [];

      res1.data.map((item) => {
        compdata.push({
          RoleName: "",
          component:
            // this.state.json[item.Name] !== undefined
            //   ? this.state.json[item.Name]
            //   :
            item.Name,
          View: 0,
          Add: 0,
          Edit: 0,
          Delete: 0,
          Approve: 0,
          Publish: 0,
        });
      });
      this.setState(
        {
          components: compdata,
          compdata: compdata,
          newcompdata: compdata,
        },
        () => {
          this.props
            .getAdminPermission()
            .then((res) => {
              // console.log(res);
              this.setState({ allData: res.data }, () => {
                let RoleName = [];
                let uniqueRoles = [];
                this.state.allData.map((item) => {
                  // console.log(item);
                  RoleName.push(item.RoleName);
                });
                uniqueRoles = [...new Set(RoleName)];
                this.setState({ roles: uniqueRoles }, () => {});
              });
            })
            .catch((err) => {
              console.log(err);
            });
        }
      );
    });
  }
  editRole = (key) => {
    let filteredData = this.state.allData.filter(
      (data) => data.RoleName === this.state.roles[key]
    );

    this.setState(
      {
        isEdit: true,
        templateName: this.state.roles[key],
        permiss: filteredData,
        tempPermission: filteredData,
      }
      // ,
      // () => {
      //   console.log(this.state);
      // }
    );
  };
  diff = (obj1, obj2) => {
    let result = {};
    if (Object.is(obj1, obj2)) {
      return undefined;
    }
    if (!obj2 || typeof obj2 !== "object") {
      return obj2;
    }
    Object.keys(obj1 || {})
      .concat(Object.keys(obj2 || {}))
      .forEach((key) => {
        if (obj2[key] !== obj1[key] && !Object.is(obj1[key], obj2[key])) {
          result = obj2;
        }
        if (typeof obj2[key] === "object" && typeof obj1[key] === "object") {
          const value = this.diff(obj1[key], obj2[key]);
          if (value !== undefined) {
            result[key] = value;
          }
        }
      });

    return result;
  };
  handleToggle = (value, index, component, name) => {
    if (this.state.isEdit === false) {
      let val = value === 0 ? 1 : 0;
      const temp2 = JSON.parse(JSON.stringify(this.state.compdata));
      temp2[index][name] = val;
      this.setState({
        compdata: temp2,
      });
    } else {
      let val = value === 0 ? 1 : 0;
      const temp2 = JSON.parse(JSON.stringify(this.state.tempPermission));
      temp2[index][name] = val;
      this.setState({
        tempPermission: temp2,
      });
      let changes = [];
      for (let i = 0; i < temp2.length; i++) {
        let anc = this.diff(this.state.permiss[i], temp2[i]);
        if (Object.keys(anc).length !== 0) {
          changes.push(this.diff(this.state.permiss[i], temp2[i]));
        }
      }
      this.setState({
        changes: changes,
      });
    }
  };
  validateForm = (data) => {
    // console.log(data);
    // var obj = new WebNotif();
    for (let i = 0; i < data.length; i++) {
      if (data[i].value == null || data[i].value == "") {
        // obj.createNotification("error", data[i].slug + " is required");
        return false;
        break;
      }
    }
    return true;
  };
  addRole = () => {
    if (
      this.validateForm([
        { value: this.state.templateName, slug: "Template Name" },
      ])
    ) {
      if (this.state.isEdit === false) {
        let data = [];
        data.push({ data: this.state.compdata });
        data.push({ rollname: this.state.templateName });
        this.props
          .AddRoleAdmin(data)
          .then((res) => {
            // console.log(res);
            obj.createNotification("success", "User Role Added Successfully");
            setTimeout(function () {
              window.location.reload();
            }, 800);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        let data = this.state.changes;
        this.props
          .EditAdminPermission(data)
          .then((res) => {
            obj.createNotification("success", "User Role Updated Successfully");
            setTimeout(function () {
              window.location.reload();
            }, 800);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };
  deleteRole = (key) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            this.props
              .DeleteRole(this.state.roles[key])
              .then((res) => {
                // console.log(res);
                setTimeout(function () {
                  window.location.reload();
                }, 800);
              })
              .catch((err) => {
                console.log(err);
              });
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };
  render() {
    return (
      <React.Fragment>
        <div className="col-md-12">
          <div className="row">
            <br />
            <br />
            {/* <div className="row"> */}
            <div className="col lrp-50 ptb-30 ">
              <div className="admin-card card">
                <div className="card-body">
                  <div className=" col-md-12">
                    <div className="row">
                      <div className="col-md-8">
                        <h5 className="card-title regular_font">Role</h5>
                      </div>
                      <div className="col-md-4 lrp-0" />
                    </div>
                  </div>
                  <hr className="mt-0" />
                  <div className="d-flex justify-content-center">
                    <div className="col-md-8">
                      <label>
                        Role Name<span className="clr-red">*</span>
                      </label>
                      <input
                        type="text"
                        value={this.state.templateName}
                        placeholder="Role name"
                        className="form-control"
                        onChange={(event) =>
                          this.setState({ templateName: event.target.value })
                        }
                      />
                      <br />
                      <PermissionOptions
                        permiss={
                          this.state.isEdit === false
                            ? this.state.compdata
                              ? this.state.compdata
                              : []
                            : this.state.tempPermission
                            ? this.state.tempPermission
                            : []
                        }
                        handleToggle={this.handleToggle}
                      />
                      {this.props.permissions.Edit &&
                      this.props.permissions.Add ? (
                        <button
                          className="btn btn-green"
                          onClick={this.addRole}
                        >
                          Save
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="admin-card card">
                <div className="card-body">
                  {this.state.roles === null
                    ? "Currently there is no role"
                    : Object.keys(this.state.roles).map((key) => (
                        // console.log(key)
                        <div className="accordion-group" key={key}>
                          <div className="accordion-heading admin_faq">
                            <div className="row">
                              <div className="col-md-10">
                                <a
                                  className="accordion-toggle faq no_border"
                                  data-toggle="collapse"
                                  data-parent="toggle"
                                  href={"#" + key}
                                >
                                  <i className="fa fa-align-justify" />
                                  &nbsp; &nbsp; {this.state.roles[key]}
                                </a>
                              </div>
                              <div className="col-md-2 pt-7">
                                {this.props.permissions.Edit ? (
                                  <i
                                    className="fas fa-edit clr-darkpurple pointer"
                                    onClick={() => this.editRole(key)}
                                  />
                                ) : (
                                  ""
                                )}
                                &nbsp; &nbsp; &nbsp; &nbsp;
                                {this.props.permissions.Delete ? (
                                  <i
                                    className="fa fa-trash clr-darkpurple pointer "
                                    onClick={() => this.deleteRole(key)}
                                  />
                                ) : (
                                  ""
                                )}
                                &nbsp; &nbsp;
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    admins: state.UserManageData.alladmins,
  };
}
export default connect(mapStateToProps, {
  getAdminPermission,
  EditAdminPermission,
  DeleteRole,
  GetAllComponents,
  AddRoleAdmin,
})(AdminPermission);
