const initstate={
     coursepayments:null,
     coursepaymentsrest: null,
     registrations:null,
     webinarAttendees: null,
     };

export default function financialData(state=initstate, action ={} ){
    
    switch(action.type) {
        case 'GOT_PAYMENTS':
        return {...state,coursepayments:action.data}
        case 'GOT_PAYMENTS_REST':
        return {...state,coursepaymentsrest: action.data}
        case 'GOT_REGISTRATIONS':
        return {...state, registrations: action.data}
        case 'GOT_WEBINAR_ATTENDEES':
        return {...state, webinarAttendees: action.data}
        case 'GOT_WEBINAR_REG_COUNT':
        return {...state}
        case 'GOT_COURSE_PAYMENT_COUNT_REST':
        return{...state}
        case "GOT_COURSE_PAYMENT_COUNT":
            return{...state};
        case "GOT_ALL_ATTENDEES_COUNT":
            return{...state};
        case "GOT_COURSE_PAYMENT_COUNT_REJECTED":
            return {...state};
        case "GOT_PAYMENTS_REJECTED":
            return{...state};
    default : return state;
    }
}