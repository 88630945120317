import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import CpdActivitiesSIDEbar from "./CpdActivitiesSIDEbar";

class Byelaws extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [
        {
          name: "Home",
          url: "/",
        },
        {
          name: "Introduction to CPD ",
          url: "/Introduction",
        },
        {
          name: "CPD Byelaws",
          url: "/byelaws",
        },
        {
          name: "Engineering Professional Development Committee (EPDC)",
          url: "epdc",
          type: "button",
          child: [
            {
              name: "Introduction",
              url: "/epdc/intro",
            },
            {
              name: "Organogram",
              url: "/Organogram",
            },
          ],
        },
        {
          name: "CPD Guideline Manual",
          url: "/GuideLineMauals",
        },
        {
          name: "CPD Relaxed Policy",
          url: "/CPDRelaxPolicy",
        },
        {
          name: "CPD Brief",
          url: "/CPDBriefs",
        },
        {
          name: " PEC Approved Resource Person",
          url: "psrp",
          type: "button",
          child: [
            {
              name: "List of Resourse Person",
              url: "/CPDListofResourcePerson",
            },
            {
              name: "Criteria for Resourse Person",
              url: "/CPDResourcePersonCriteria",
            },
            // ,
            // // {
            //   name: "Application Form",
            //   url: "/ApplicationForm",
            // }
          ],
        },
        {
          name: "Important News for Engineers",
          url: "/important-news",
        },
      ],
    };
  }
  componentDidMount() {
    document.title = "CPD Bye-Laws";
  }
  render() {
    return (
      <React.Fragment>
        <section className="backco_white" style={{ fontFamily: "Arial" }}>
          <Header />
          <div className="row col_margin">
            <div
              className="col-md-3 col_padding sidebar_container"
              style={{ padding: "2%" }}
            >
              <CpdActivitiesSIDEbar data={this.state.data} />
            </div>
            <div className="col-md-7 col_padding intr_mr">
              <h2
                className="text-center"
                style={{
                  margin: "28px 0px 0px 1px",
                  fontWeight: 700,
                  fontSize: "1.5em",
                }}
              >
                CPD Bye-Laws 2008 (amended upto October 31, 2017)
              </h2>
              <div className="row">
                <div
                  className="col-md-12"
                  style={{ padding: "2%", marginLeft: "30px" }}
                >
                  <div className="into-p">
                    <p>To View CPD Byelaws Click Download Button</p>
                    <i className="far fa-file-pdf fa-1x co_black bold"></i>
                    &nbsp;
                    <b className="pdf_text"> PEC_CPD_ByeLaws-2008</b>
                    &nbsp;&nbsp;
                    <Link
                      to="assets/document/pdf/PEC_CPD_ByeLaws-2008.pdf"
                      className="btn btn-success bold font_sans fz_14"
                      target="_blank"
                      download
                    >
                      Download
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-1"></div>
          </div>
          {/*<Footer/>*/}
        </section>
      </React.Fragment>
    );
  }
}
export default Byelaws;
