import React from 'react';
import {Route,Redirect} from 'react-router-dom';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
const ProtectedAdminRoute = 
({isAuthenticated, component:Component,key, userId, permissions,courseType, ...rest  }) => (

<Route 
     {...rest}  render={props => isAuthenticated ?  
         <Component
          {...props}   
          userId={userId}
          permissions={permissions}
          key={key}
          courseType={courseType}
          />     
          
          
          
          : <Redirect to="/">


          </Redirect>}    />
);
ProtectedAdminRoute.propTypes = {

    component : PropTypes.func.isRequired,
    
}

function mapStateToProps(state){
   return{
    isAuthenticated : state.userData.admin
   };
}

export default connect (mapStateToProps)(ProtectedAdminRoute);