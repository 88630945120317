import React, { Component } from "react";

export default class PermissionOptions extends Component {
  componentDidMount() {}
  render() {
    return (
      <React.Fragment>
        <table className="table table-bordered">
          <tbody>
            <tr>
              <td>
                <b>Role Name</b>
              </td>
              <td>
                <b>Component</b>
              </td>
              <td>
                <b>View</b>
              </td>
              <td>
                <b>Add</b>
              </td>
              <td>
                <b>Edit</b>
              </td>
              <td>
                <b>Delete</b>
              </td>
              <td>
                <b>Approve</b>
              </td>
              <td>
                <b>Publish</b>
              </td>
              <br />
            </tr>
            {Object.keys(this.props.permiss).map((key) => (
              <tr>
                {Object.keys(this.props.permiss[key]).map((item, index) =>
                  this.props.permiss[key][item] === 1 ||
                  this.props.permiss[key][item] === 0 ? (
                    <td>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id={index}
                          onChange={() =>
                            this.props.handleToggle(
                              this.props.permiss[key][item],
                              key,
                              this.props.permiss[key].component,
                              item
                            )
                          }
                          checked={this.props.permiss[key][item]}
                        />
                        <label className="form-check-label" for={index}>
                          {this.props.permiss[key][item]}
                        </label>
                      </div>
                    </td>
                  ) : (
                    <td> {this.props.permiss[key][item]}</td>
                  )
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </React.Fragment>
    );
  }
}
