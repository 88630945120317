import React, {
  useEffect,
  useContext,
  useState,
  useRef,
  Fragment,
} from "react";
import * as yup from "yup";
import Header from "../Header";
import { connect } from "react-redux";
import Loader from "react-loader-spinner";
import { confirmAlert } from "react-confirm-alert";
import axios from "axios";
import { GlobalContext } from "../../context/GlobalContext";
import disciplineData from "../../config/discipline.json";
import {
  saveCenterEpe,
  getEpECenter,
  saveEpeFormRedux,
  submitEpePayment,
  getCurrentExam,
  getPapersOfExam,
} from "../../redux/user/useraction";
import WebNotif from "../../components/WebNotif";
import { urlapiEPE } from "../../config/urlapi";
import Modal from "react-modal";
// import { BoxLoading } from "react-loadingg";
const obj = new WebNotif();

function EngineerEpeForm(props) {
  const [load, setLoad] = useState(false);
  const [center, setCenter] = useState({
    center1: "",
    center2: "",
    center3: "",
  });
  const [centerList, setCenterList] = useState("");

  // Separate states for each dropdown
  const [center1, setCenter1] = useState("");
  const [center2, setCenter2] = useState("");
  const [center3, setCenter3] = useState("");

  // Separate lists for available centers in each dropdown
  const [centerList1, setCenterList1] = useState([]);
  const [centerList2, setCenterList2] = useState([]);
  const [centerList3, setCenterList3] = useState([]);

  const { cpdPointsData } = useContext(GlobalContext);

  const center1Ref = useRef();
  const center2Ref = useRef();
  const center3Ref = useRef();

  useEffect(() => {
    setLoad(true);

    const reduxEpeForm = localStorage.getItem("reduxEpeForm");

    if (reduxEpeForm) {
      const parsedForm = JSON.parse(reduxEpeForm);

      if (!!parsedForm.form) {
        if (parsedForm.form.centerPrority_1) {
          setCenter1(parsedForm.form.centerPrority_1);
        }

        if (parsedForm.form.centerPrority_2) {
          setCenter2(parsedForm.form.centerPrority_2);
        }

        if (parsedForm.form.centerPrority_3) {
          setCenter3(parsedForm.form.centerPrority_3);
        }
      }
    }
    // if (!formFilled) {
    const getLatestExamId = localStorage.getItem("getLatestExamId");

    const examId = JSON.parse(getLatestExamId);
    props
      .getEpECenter(examId)
      .then((res) => {
        console.log("getEpECenter .... ", res);
        const applicationData = localStorage.getItem("application");
        if (!!applicationData) {
          const parsedApplicationData = JSON.parse(applicationData);
          if (parsedApplicationData?.centerPrority_1) {
            setCenter1(parsedApplicationData.centerPrority_1);
          }
          if (parsedApplicationData?.centerPrority_2) {
            setCenter2(parsedApplicationData.centerPrority_2);
          }
          if (parsedApplicationData?.centerPrority_3) {
            setCenter3(parsedApplicationData.centerPrority_3);
          }
        }
        setLoad(false);
        setCenterList(res.data);
      })
      .catch((err) => {
        console.log("Error: ", err);
        setLoad(false);
      });
    // }
  }, []);

  const submitFormConf = (reduxEpeForm, disc) => {
    /**
     * * Taking user to payment screen
     */

    const application = {
      applicationType: reduxEpeForm?.applicationType,
      userProfile: reduxEpeForm?.form?.userProfile,
      currentFileNameofData: reduxEpeForm.form.currentFileNameofData,
      currentFileTypeofData: reduxEpeForm.form.currentFileTypeofData,
      pecNo: reduxEpeForm?.form?.regNo,
      cnic: reduxEpeForm?.form?.cnic,
      examId: reduxEpeForm?.examId,
      selectedPaperPart:
        reduxEpeForm?.selectedPaperPart?.value === 0
          ? ""
          : reduxEpeForm?.selectedPaperPart?.label,
      paymentImage: reduxEpeForm?.paymentImage,
      challanNumber: reduxEpeForm?.challanNumber,
      name: reduxEpeForm?.form?.name,
      regNo: reduxEpeForm?.form?.regNo,
      discipline: disc,
      alliedDiscipline: reduxEpeForm?.form.discipline,
      subDiscipline: reduxEpeForm?.form?.subDiscipline,
      registrationDate: reduxEpeForm?.form?.dor,
      postalAddress: reduxEpeForm?.form?.address,
      alternateContact: reduxEpeForm?.form?.alternateContact,
      mobile: reduxEpeForm?.form?.mobile,
      email: reduxEpeForm?.form?.email,
      nationality: reduxEpeForm?.form?.nationality,
      DOB: reduxEpeForm?.form?.dob,
      transactionType: reduxEpeForm?.transactionType,
      bankName: reduxEpeForm?.bankName,
      challanAmount: reduxEpeForm?.challanAmount,
      centerPrority_1: center1,
      centerPrority_2: center2,
      centerPrority_3: center3,
      paper_count: reduxEpeForm?.form?.paperCount,
      historyExist: reduxEpeForm?.form?.historyExist,
      submissionDate: reduxEpeForm?.submissionDate,
      cpdPointsData: cpdPointsData,
      rejectionReason: "",
    };
    reduxEpeForm = { ...reduxEpeForm, form: application };
    localStorage.setItem("reduxEpeForm", JSON.stringify(reduxEpeForm));
    props.history.push("/engineer/engineer-payment");
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setLoad(true);
    if (center1 === "" || center2 === "" || center3 === "") {
      if (center1 === "") {
        center1Ref.current.focus();
        center1Ref.current.style.border = "3px solid #20A84C";
        obj.createNotification(
          "error",
          "Please select all the priorities in order to proceed"
        );
        setLoad(false);
      } else if (center2 === "") {
        center2Ref.current.focus();
        center2Ref.current.style.border = "3px solid #20A84C";
        obj.createNotification(
          "error",
          "Please select all the priorities in order to proceed"
        );
        setLoad(false);
      } else if (center3 === "") {
        center3Ref.current.focus();
        center3Ref.current.style.border = "3px solid #20A84C";
        obj.createNotification(
          "error",
          "Please select all the priorities in order to proceed"
        );
        setLoad(false);
      } else {
        center1Ref.current.focus();
        center2Ref.current.focus();
        center3Ref.current.focus();
        obj.createNotification(
          "error",
          "Please select all the priorities in order to proceed"
        );
        setLoad(false);
      }
    } else {
      let reduxEpeForm = JSON.parse(localStorage.getItem("reduxEpeForm"));

      // const updatedFormData = {
      //   ...reduxEpeForm,
      //   center: center,
      // };
      // localStorage.setItem("reduxEpeForm", JSON.stringify(updatedFormData));
      // props.saveCenterEpe(center);

      const disciplineRecord = disciplineData.find(
        (dis) => dis.name === reduxEpeForm?.form?.discipline
      );
      if (!!disciplineRecord) {
        submitFormConf(reduxEpeForm, disciplineRecord?.value);
      } else {
        submitFormConf(reduxEpeForm, reduxEpeForm.form.discipline);
      }
      // const electrical_and_allied_disciplines = [
      //   "ELECT",
      //   "ELECTRO",
      //   "TELE",
      //   "AVION",
      //   "MECHATRO",
      //   "BIOMEDICAL",
      //   "COMP",
      //   "ENGG.SCI.",
      // ];
      // if (
      //   electrical_and_allied_disciplines.includes(
      //     reduxEpeForm?.form?.discipline
      //   )
      // ) {
      //   submitFormConf(reduxEpeForm, "ELECT");
      // } else {
      //   submitFormConf(reduxEpeForm, reduxEpeForm.form.discipline);
      // }

      // setLoad(false);
      // props.history.push("/engineer/center-form");
    }
  };

  const selectChange = (e) => {
    setCenter({ ...center, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (centerList && centerList?.length > 0) {
      // Remove selected centers from available centers for other dropdowns
      const availableCenters2 = centerList?.filter(
        (center) => center.center_id !== Number(center1)
      );
      setCenterList2(availableCenters2);
    }
  }, [center1, centerList]);

  useEffect(() => {
    if (centerList && centerList?.length > 0) {
      // Remove selected centers from available centers for other dropdowns
      const availableCenters1 = centerList?.filter(
        (center) => center.center_id !== Number(center2)
      );
      const availableCenters3 = centerList?.filter(
        (center) =>
          center.center_id !== Number(center2) &&
          center.center_id !== Number(center1)
      );
      setCenterList1(availableCenters1);
      setCenterList3(availableCenters3);
    }
  }, [center2, centerList]);

  useEffect(() => {
    if (centerList && centerList?.length > 0) {
      // Remove selected centers from available centers for other dropdowns
      const availableCenters1 = centerList?.filter(
        (center) =>
          center.center_id !== Number(center3) &&
          center.center_id !== Number(center2)
      );
      const availableCenters2 = centerList?.filter(
        (center) =>
          center.center_id !== Number(center3) &&
          center.center_id !== Number(center1)
      );
      const availableCenters3 = centerList.filter(
        (center) =>
          center.center_id !== Number(center1) &&
          center.center_id !== Number(center2)
      );
      setCenterList1(availableCenters1);
      setCenterList2(availableCenters2);
      setCenterList3(availableCenters3);
    }
  }, [center1, center2, center3, centerList]);

  const selectChange1 = (e) => {
    setCenter1(e.target.value);
  };

  const selectChange2 = (e) => {
    setCenter2(e.target.value);
  };

  const selectChange3 = (e) => {
    setCenter3(e.target.value);
  };

  return (
    <Fragment>
      {load ? (
        <div style={{ textAlign: "center" }} className="container-fluid">
          <h1 style={{ marginTop: "50px" }}>Loading...</h1>
        </div>
      ) : (
        <section className="backco_white" style={{ fontFamily: "Arial" }}>
          <Header />
          <div className="App">
            <div className="container-fluid">
              <div className="mt-4 px-5 py-3">
                <div className="row">
                  <div className="col-xl-2 col-lg-2 col-md-2"></div>
                  <div className="col-xl-8 col-lg-8 col-md-8 message-background text-left px-5">
                    <div className="general-height-10 ">
                      <h5 className="mt-4 co_g20A84C font-weight-bold font-16 mb-4">
                        Disclaimer: PEC May Allocate Centers Other Than User's
                        Priority
                      </h5>
                    </div>
                    <form onSubmit={onSubmit}>
                      <h5 className="mt-3 co_g20A84C font-weight-bold font-16 mb-4">
                        Step 3: Center Details
                      </h5>
                      <div className="form-row">
                        <div className="form-group col-md-4">
                          <label className="mb-2 font-14">
                            Priority 1{" "}
                            <span style={{ color: "#06580e" }}>*</span>
                          </label>
                          <select
                            ref={center1Ref}
                            name="center1"
                            className={`form-control form-control-sm text-capitalize`}
                            onChange={selectChange1}
                            value={center1}
                          >
                            <option selected disabled value="">
                              Select 1st Center Priority
                            </option>
                            {centerList1.map((item) => (
                              <option
                                key={item.center_id}
                                value={item.center_id}
                              >
                                {item.center_name.toLowerCase()}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="form-group col-md-4">
                          <label className="mb-2 font-14">
                            Priority 2{" "}
                            <span style={{ color: "#06580e" }}>*</span>
                          </label>
                          <select
                            disabled={center1 === ""}
                            ref={center2Ref}
                            name="center2"
                            className={`form-control form-control-sm text-capitalize`}
                            onChange={selectChange2}
                            value={center2}
                          >
                            <option selected disabled value="">
                              Select 2nd Center Priority
                            </option>
                            {centerList2.map((item) => (
                              <option
                                key={item.center_id}
                                value={item.center_id}
                              >
                                {item.center_name.toLowerCase()}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="form-group col-md-4">
                          <label className="mb-2 font-14">
                            Priority 3{" "}
                            <span style={{ color: "#06580e" }}>*</span>
                          </label>
                          <select
                            disabled={center1 === "" || center2 === ""}
                            ref={center3Ref}
                            name="center3"
                            className={`form-control form-control-sm text-capitalize`}
                            onChange={selectChange3}
                            value={center3}
                          >
                            <option selected disabled value="">
                              Select 3rd Center Priority
                            </option>
                            {centerList3.map((item) => (
                              <option
                                key={item.center_id}
                                value={item.center_id}
                              >
                                {item.center_name.toLowerCase()}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="form-group">
                        {/* <button
                          type="submit"
                          className="btn admin_btn_default btn-sm"
                          id="submit"
                        >
                          Submit
                        </button> */}
                        <button
                          type="submit"
                          className="btn admin_btn_default btn-sm fontSize1Rem float-right"
                          id="submit"
                        >
                          Save
                        </button>
                      </div>{" "}
                    </form>
                    &nbsp;
                  </div>
                  <div className="col-xl-2 col-lg-2 col-md-2"></div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </Fragment>
  );
}

function mapStateToProps(state) {
  return {
    User: state.userData.user,
    userForm: state.userData.reduxForm,
  };
}

export default connect(mapStateToProps, {
  saveCenterEpe,
  getEpECenter,
  saveEpeFormRedux,
  submitEpePayment,
  getCurrentExam,
  getPapersOfExam,
})(EngineerEpeForm);
