import React from "react";
import "../assets/css/main.css";
import Header from "./Header";
import { Link } from "react-router-dom";
import CpdActivitiesSIDEbar from "./CpdActivitiesSIDEbar";
class CPDProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [
        {
          name: "CPD Short Courses and Training",
          url: "/CpdShortCourse",
        },
        {
          name: "Webinars",
          url: "",
          type: "button",
          child: [
            {
              name: "Live Webinars By PEC",
              url: "/WebinarSearch/livewebinar",
            },
            {
              name: "Recorded Webinars BY PEC",
              url: "/WebinarSearch/recordedwebinar",
            },
            {
              name: "Webinars by PEB (Professional Engineering Bodies)",
              url: "/WebinarSearch/pebwebinar",
            },
          ],
        },
        {
          name: "Online Courses",
          url: "/onlineCourse",
        },
        {
          name: "Engineer's CPD Profile",
          url: "Ecp",
          type: "button",
          child: [
            {
              name: " CPD Profile",
              url: "/CpdProfile",
            },
            {
              name: "Calculator of CPD Points",
              url: "/CalculatorofCPD",
            },
          ],
        },
      ],
    };
  }
  componentDidMount() {
    document.title = "Engineer's CPD Profile";
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <React.Fragment>
        <section className="backco_white" style={{ fontFamily: "Arial" }}>
          <Header />
          <div className="row col_margin">
            <div className="col-md-3 col_padding" style={{ padding: "2%" }}>
              <CpdActivitiesSIDEbar data={this.state.data} />
            </div>
            <div className="col-md-7 col_padding intr_mr">
              <h2
                className="text-center"
                style={{ margin: "28px 0px 0px 1px" }}
              >
                Engineer's CPD Profile
              </h2>
              <div className="row">
                <div
                  className="col-md-12 "
                  style={{ padding: "2%", marginLeft: "30px" }}
                >
                  <div className="into-p">
                    <p>
                      The CPD programs include additional qualifications,
                      professional skills, relevant management and communication
                      skills acquired through additional training and
                      experience. These aspects are grouped into the following
                      four different categories of training, namely
                    </p>
                  </div>
                  <ul className="ml_6 points-font">
                    <li>Higher education</li>
                    <li>On-Job/ Work-based learning</li>
                    <li>Developmental activities</li>
                    <li>Individual activities</li>
                  </ul>
                  <div className="into-p">
                    <p style={{ paddingLeft: "30px" }}>
                      For Details of CPD points under these categories and
                      updating CPD profile please{" "}
                      <a href="/cpd/CpdFormProfile">click here</a>
                    </p>
                    <Link
                      to={"/CPDBriefs"}
                      style={{
                        textAlign: "left",
                        marginLeft: "20px",
                        marginTop: "10px",
                      }}
                      className="bold font_sans fz_13"
                      target="_blank"
                      // download
                    >
                      How to Earn CPD Points
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-1"></div>
          </div>
          {/*<Footer/>*/}
        </section>
      </React.Fragment>
    );
  }
}
export default CPDProfile;
