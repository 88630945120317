import React from 'react';
import Header from './Header';
import Footer from './Footer';
import brochure from "../assets/pdf/Doc111111.pdf";
import {Link } from 'react-router-dom';
import CpdActivitiesSIDEbar from './CpdActivitiesSIDEbar'

class CPDBriefs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [
        {
            name: "Home",
            url: "/"
        },
          {
          name: "Introduction to CPD ",
          url: "/Introduction"
        },
        {
          name: "CPD Byelaws",
          url: "/byelaws"
        },
        {
          name: "Engineering Professional Development Committee (EPDC)",
          url: "epdc",
          type:"button",
          "child":[
            {
              name: "Introduction",
              url: "/epdc/intro",
            },
            {
              name: "Organogram",
              url: "/Organogram",
            }
          ]

        },
        {
          name: "CPD Guideline Manual",
          url: "/GuideLineMauals",
       }, 
       {
        name: "CPD Relaxed Policy",
        url: "/CPDRelaxPolicy",
        },
        {
          name: "CPD Brief",
          url: "/CPDBriefs",
       }
       ,
       {
        name: " PEC Approved Resource Person",
        url: "psrp",
        type:"button",
        "child":[
          {
            name: "List of Resourse Person",
            url: "/CPDListofResourcePerson",
          },
          {
            name: "Criteria for Resourse Person",
            url: "/CPDResourcePersonCriteria",
          
          }
          // ,
          // // {
          //   name: "Application Form",
          //   url: "/ApplicationForm",
          // }
        ]
      },
     
    ]
    }
  }
  componentDidMount(){
    document.title = "CPD Relaxed Policy";
}
  render() {
    return (
      <React.Fragment>
        <section  className="backco_white" style={{ fontFamily: 'Arial' }}>
          <Header />
          <div className="row col_margin">
          <div className="col-md-3 col_padding sidebar_container" style={{ padding: '2%' }}>
              <CpdActivitiesSIDEbar data={this.state.data} />
            </div>
            <div className="col-md-8 col_padding intr_mr">
              <h2 className="text-center" style={{ margin: '28px 0px 10px 1px' }}>CPD Brief</h2>
              <div className="row">
                <div className="col-md-12">
                  <p style={{"text-align": "justify"}}>
                  Pakistan Engineering Council (PEC) under PEC Act and CPD-Byelaws, is earnestly working for the professional 
                  growth and skill enhancement of ever growing engineering community. Under CPD framework, the engineers (both PEs and REs) 
                  are required to learn innovative and soft skills to be more effective in playing productive role towards nation building.
                  With these objectives, CPD framework of PEC sets parameters under four dimensions including developmental activities 
                  (short courses, trainings, workshops, research publications, professional memberships, Member of any Technical Committee 
                  etc.), higher education, individual activities and on-job experience. This framework is for the ultimate benefits of engineers
                   in their career development and renewal of registration as well as for elevation from registered engineer (RE) to Professional 
                   Engineer (PE) and also leading to International Professional Engineer (Int PE). 
                   <br/>
                   Under New Vision of CPD, specialized job-oriented  and global certification courses are being introduced to enhance employability
                    and on-job performance. PEC itself and through its its registered Professional Engineering Bodies (List available on PEC website), 
                    regularly conducts short course, trainings and seminars to facilitate the engineers in fulfilling the CPD requirements. CPD activities
                     imparted by well-recognized and Chartered Institutions/Organizations (Local/Foreign) are also acceptable subject to valid documents and verification.

                  </p>
                
                </div>

                <div className="col-md-12">
                  <h3 className="text-center">
                  CPD REQUIREMENTS AND HOW TO EARN CPD POINTS
                  </h3>
                    <h5 className="text-center"> For PEs (Professional Engineers)</h5>
                    <table className="table table-striped">
                    <thead  style={{backgroundColor:'#06580e',color:'white'}}>
                    <tr>
                      <th scope="col">For Life Members</th>
                      <th scope="col">For Yearly Subscribers</th>
                     
                      
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                      9 CPD Points 
                  (3 CPD Points per year)

                      </td>
                      <td>
                   3 CPD Points per year
                      </td>
                    </tr>
                  </tbody>

                    </table>
                  </div> 
                
                <div className="col-md-12 ptb-30"> 
                <h5 className="text-center"> For REs (Registered Engineers)</h5>

                <table className="table table-striped">
                    <thead  style={{backgroundColor:'#06580e',color:'white'}}>
                    <tr>
                      <th scope="col">1st Year after Registration </th>
                      <th scope="col">1<sup>st</sup> cycle of 3 years 
                      (2<sup>nd</sup> to 4<sup>th</sup> Year after registration)</th>
                      <th scope="col">2<sup>nd</sup> Cycle of 3-years 
                      (5<sup>th</sup> to 7<sup>th</sup> Year after registration)
                      </th>
                      <th scope="col">
                      3<sup>rd</sup> Cycle of 3-years 
                      (8<sup>th</sup> to  10<sup>th</sup> 
                      Year after Registration and onwards)

                     </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                      Grace Period
                      </td>
                      <td>
                        9 CPD Points
                   (3 CPD Points per year)
                      </td>

                      <td>
                        12
                   (4 CPD Points per year)
                      </td>
                      <td>
                      15 CPD Points
                (5 CPD Points per year)
                      </td>
                     </tr>
                  </tbody>

                    </table>
                  

                </div>
              </div>
              <div className="row">
                <div className="col-md-12"  >
                  <h4 className="text-center">How to Earn CPD Points</h4>
                  <h5 className="text-center">The REs and PEs can earn CPD points against activities as mentioned below with valid documentary evidence</h5>
                    <table className="table">
                  <thead style={{backgroundColor:'#06580e',color:'white',borderBottom:'0'}}>
                    <tr>
                      <th scope="col">Category</th>
                      <th scope="col">Applicable CPD program</th>
                      <th scope="col">Duration/
                        Credit Hr
                        </th>
                      <th scope="col">CPD Points</th>
                      <th scope="col">Remarks</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr style={{background:'#51a255'}}>
                    <td>
                      <span className="bold" style={{textDecoration:'underline'}}>
                        category-a</span>     
                        <br/>
                      Formal Education (Higher Education recognized by PEC/ HEC)  
                      <br/>
                      Limit = 4 credit points /year
                      </td>
                    <td>a. Post Graduate Diploma (PGD) <br/>
                      b. Award of Post Graduate Degree (Engg,  IT, Finance, Management,
                      Economics, Law, etc). <br/>
                      c. Award of Doctorate  (Ph.D.) in Engg <br/>
                      </td>
                      <td>
                            </td>
                      <td>2<br/>
                            4<br/>
                            4<br/>
                            6<br/>
                      </td>
                      <td>(6 – 9 months) <br/>
                        (9 – 12 months)<br/>
                        (course work only)<br/>
                        (course work plus research)
                        </td>
                    </tr>

                    <tr style={{background:'#8bc34a '}}>
                    <td>
                    <span className="bold" style={{textDecoration:'underline'}}>
                        category-b</span>     
                        <br/>
                      Work Based Activities
                      
                      </td>
                    <td>Work Experience
                      (Engineering related work including relevant aspects of Management)
                      </td>
                      <td>400
                      (2.5 months approx.)
                      </td>
                      <td>1</td>
                      <td>Limit = 2 Credit                     
                      points/ year
                     </td>
                    </tr>

                    <tr style={{background:'#dce775 '}}>
                    <td>
                    <span className="bold" style={{textDecoration:'underline'}}>
                        category-c*</span>     
                        <br/>
                      Development Activities 
                            <br/>
                      Limit  = 2 credit   points per year 
                      </td>
                      <td>1. Membership of a recognized 
                            professional association/ /body
                            <br/>
                        2. Participation in Training &
                            Development programs 
                            <br/>
                        3. Participation in Conference
                        <br/>
                        4. Participation in Seminar
                        <br/>
                        5. Participation in Lectures
                        <br/>
                        6. Participation in Workshop
                        <br/>
                        7. Refresher Course/ short course
                        </td>
                      <td>  <br/>  <br/>  <br/>  <br/>
                              02
                              <br/> 
                              02
                              <br/> 
                              02<br/> 

                              One Day
                              <br/> 
                              One Day
                              </td>
                      <td>

                      <br/>
                        2
                      <br/>
                          1
                          <br/>
                          0.5
                          <br/>
                          0.5
                          <br/>
                          0.5
                          <br/>
                          1
                          <br/>
                          1
                          </td>
                                              <td>National
                        International   
                        Limit = 2 memberships/ year
                        </td>
                    </tr>
                    <tr style={{background:'#fff59d '}}>
                    <td>
                    <span className="bold" style={{textDecoration:'underline'}}>
                        category-d*</span>     
                        <br/>
                      Individual  Activities
                    <br/>
                      Limit = 2 credit   points per year
                      </td>
                      <td>1. Invited lecturer 
                      <br/>
                        2. Symposium panelist
                        <br/>
                        3.  Writing and publishing a research article 
                            in Journals/ Technical report
                            <br/>
                        4. Writing Conference paper

                        <br/>
                        5. Authoring a Book
                        <br/>
                        6. Part of activities such as emergency/
                            recognized social work
                            <br/>
                        7. Participation in statutory. Technical/ 
                            non-technical committees
                            <br/>
                        8. Managing Technical event
                        </td>
                      <td></td>
                      <td>1.5
                      <br/>  
                          0.5
                          <br/>
                          1.5
                          <br/>
                          3.0
                          <br/>
                          0.5
                          <br/>
                          1.5
                          <br/>
                          5.0
                          <br/>
                          1.0
                          <br/>
                          1.0
                          <br/>
                          1.0
                          </td>
                      <td>
                        Lectures exclude routine teaching  
                      <br/>   
                        National Publication
                        <br/>
                       International Publication
                        <br/>
                       National
                        International
                        <br/>
                       Approved by a body
                        <br/>
                       Professional contribution
                        <br/>

                       Active participation
                        <br/>
                       National
                        International
                        </td>
                    </tr>
                   
                  </tbody>
                </table>

              

                </div>
              </div>
            </div>
          
          </div>
          {/* <Footer/> */}
        </section>
      </React.Fragment>
    )
  };
}
export default CPDBriefs;