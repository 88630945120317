import React, { Component } from "react";
import WebNotif from "../WebNotif";
import { webinarFeedback } from "../../redux/admin/webinar/webinarActions";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { toPlainObject } from "lodash";

var obj = new WebNotif();
class Feedback_Webinar extends Component {
  // componentDidMount() {
  //   console.log(this.props);
  // }
  state = {
    feedback: "",
    feebackdetails: {
      userId: "",
      webinarId: "",
      feedback: "",
      owner: "",
      status: "",
    },
  };
  handlefeedback = (event) => {
    this.setState({
      ...this.state,
      [event.target.name]: [event.target.value],
    });
  };
  handleapprove = () => {
    this.setState(
      {
        feebackdetails: {
          userId: this.props.User.UserID,
          webinarId: this.props.webinarId,
          feedback: this.state.feedback,
          owner: this.props.owner,
          status: 1,
          email: this.props.User.PrimaryEmail,
          type: "Approve"
        },
      },
      () => {
        const data = this.state.feebackdetails;
        this.props
          .webinarFeedback(data)
          .then((res) => {
            obj.createNotification("success", "Successfully Saved Feedback");
          })
          .catch((err) => {
            console.log(err);
          });
      }
    );
  };
  handleapproveall = () => {
    this.setState(
      {
        feebackdetails: {
          userId: this.props.User.UserID,
          webinarId: this.props.webinarId,
          feedback: this.state.feedback,
          owner: this.props.owner,
          status: 1,
          email: this.props.User.PrimaryEmail,
          type: "ApproveAll"
        },
      },
      () => {
        const data = this.state.feebackdetails;
        this.props
          .webinarFeedback(data)
          .then((res) => {
            obj.createNotification("success", "Successfully Saved Feedback");
          })
          .catch((err) => {
            console.log(err);
          });
      }
    );
  }
  handlereject = () => {
    this.setState(
      {
        feebackdetails: {
          userId: this.props.User.UserID,
          webinarId: this.props.webinarId,
          feedback: this.state.feedback,
          owner: this.props.owner,
          status: 0,
          email: this.props.User.PrimaryEmail,
          type: "Reject"
        },
      },
      () => {
        const data = this.state.feebackdetails;
        this.props
          .webinarFeedback(data)
          .then((res) => {
            obj.createNotification("success", "Successfully Saved Feedback");
          })
          .catch((err) => {
            console.log(err);
          });
      }
    );
  };
  render() {
    return (
      <div className="form-group">
        <label>
          Feedback <span className="clr-red">*</span>
        </label>
        <textarea
          required
          className="form-control admin-form-control"
          rows="6"
          value={this.state.feedback}
          name="feedback"
          placeholder={`Enter Feedback`}
          onChange={this.handlefeedback}
        />
        <div className="mtb-20">
          {this.props.User.RoleID === 7 || this.props.User.RoleID === 3 || this.props.User.RoleID === 4 || this.props.User.RoleID === 5 ?
            <button
              className="btn btn-success"
              style={{ "margin-right": "15px" }}
              onClick={this.handleapproveall}
            >
              Approve All
          </button>
            : ""}
          <button
            className="btn btn-success"
            style={{ "margin-right": "15px" }}
            onClick={this.handleapprove}
          >
            Approve
          </button>
          <button className="btn  btn-danger" onClick={this.handlereject}>
            Reject
          </button>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    webinarDetails: state.webinarData.webinarDetails,
    User: state.userData.admin,
  };
}
export default withRouter(
  connect(mapStateToProps, {
    webinarFeedback,
  })(Feedback_Webinar)
);
