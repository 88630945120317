import React, { Component } from "react";
import {
  getDashboardWebinars,
  getDashboardStatistics,
} from "../../redux/admin/dashboard/dashboardActions.js";
import { connect } from "react-redux";
import { urlImageUrl } from "../../config/urlapi";
import { Link } from "react-router-dom";
// insert_data
class dashboard extends React.Component {
  state = {
    livewebinars: [],
    recordedwebinars: [],
  };

  componentDidMount() {
    this.props.getDashboardWebinars().then((res) => {
      let temp1 = [];
      let temp2 = [];
      for (let i = 0; i < res.data.length; i++) {
        if (res.data[i].Type_Name == "Live Webinar") {
          temp1.push(res.data[i]);
        } else {
          temp2.push(res.data[i]);
        }
      }
      this.setState({
        livewebinars: temp1,
        recordedwebinars: temp2,
      });
    });

    this.props.getDashboardStatistics();
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.permissions) {
      //   for (let i = 0; i < nextProps.permissions.length; i++) {
      //     if (nextProps.permissions[i].View === 1) {
      //       comp.push(nextProps.permissions[i].component);
      //     }
      //   }
      this.setState({
        allowedComponents: nextProps.permissions,
      });
    }
  }
  hclicked = (value) => {
    this.props.history.push({
      pathname: "/admin/dashboard/webinarregistered",
      state: { id: value },
    });
  };

  h1clicked = (value) => {
    this.props.history.push({
      pathname: "/admin/dashboard/webinarattendees",
      state: { id: value },
    });
  };

  render() {
    return (
      <React.Fragment>
        {/* <h3 style={{ marginTop: "30px" }}>Continuous Professional Dashboard</h3 */}
        <h3 style={{ marginTop: "30px" }}>Admin Dashboard</h3>
        <div className='admin-dash-main'>
          {this.state.allowedComponents && this.state.allowedComponents.View ? (
            <div className="admin-dash-child ">
              <h5 className="co_g20A84C"> Webinars </h5>

              <div className="" style={{ marginBottom: "10px" }}>
                <div>
                  Live Webinars{" "}
                  <Link
                    to="/admin/dashboard/webinar"
                    className=" fsI-10 float-right"
                    style={{ color: "black" }}
                  >
                    see all
                  </Link>
                </div>

                {this.state.livewebinars.length > 0
                  ? this.state.livewebinars.slice(0, 3).map((item, key) => (
                      <div
                        key={key}
                        className=" d-flex justify-content-center align-items-center dashboard-rows"
                      >
                        <div className="col-md-2 col-lg-2 col-sm-2 col-xs-2 lrp-0">
                          <img
                            src={
                              urlImageUrl +
                              item.Image_URL.slice(0, 7) +
                              "\\" +
                              item.Image_URL.slice(7)
                            }
                            className="dash-rows-img"
                          />
                        </div>
                        <div className="col-md-6 col-lg-6 col-sm-8 col-xs-8 ">
                          <div style={{ fontWeight: "bold" }}>
                            {item.Course_Name}
                          </div>
                          <div>
                            <span style={{ fontSize: ".6vw" }}>
                              {"By " + item.FullName}
                            </span>
                          </div>
                          <div>
                            {" "}
                            <span style={{ fontSize: ".6vw" }}>
                              {item[
                                "(SELECT COUNT(*) FROM free_webinars WHERE CourseID = courses.CourseID )"
                              ] + "  Enrollments"}
                            </span>{" "}
                          </div>
                        </div>
                        <div className="col-md-2 col-lg-2 col-sm-2 col-xs-2 lrp-0">
                          <span
                            onClick={() => this.h1clicked(item.CourseID)}
                            style={{
                              fontSize: ".8vw",
                              fontWeight: "bold",
                              color: "#20a74c",
                              cursor: "pointer",
                            }}
                          >
                            Attendees
                          </span>
                        </div>
                        <div className="col-md-2 col-lg-2 col-sm-2 col-xs-2 lrp-0">
                          <span
                            onClick={() => this.hclicked(item.CourseID)}
                            style={{
                              fontSize: ".8vw",
                              fontWeight: "bold",
                              color: "#20a74c",
                              cursor: "pointer",
                            }}
                          >
                            Registered
                          </span>
                        </div>
                        {/* <div className="col-md-2 col-lg-2 col-sm-2 col-xs-2 lrp-0">
  <span style={{fontSize:'.8vw',fontWeight:'bold'}}>{item["(SELECT COUNT(*) FROM free_webinars WHERE CourseID = courses.CourseID )"]+"  Enrollments"}</span> 
                                          </div> */}
                      </div>
                    ))
                  : "No Data"}
              </div>
              <div className="" style={{ height: "45%", marginBottom: "10px" }}>
                <div>
                  Recorded Webinars{" "}
                  <Link
                    to="/admin/dashboard/webinar"
                    className=" fsI-10 float-right"
                    style={{ color: "black" }}
                  >
                    see all
                  </Link>
                </div>

                {this.state.recordedwebinars.length > 0
                  ? this.state.recordedwebinars.slice(0, 3).map((item, key) => (
                      <div
                        key={key}
                        className=" d-flex justify-content-center align-items-center dashboard-rows"
                      >
                        <div className="col-md-2 col-lg-2 col-sm-2 col-xs-2 lrp-0">
                          <img
                            src={
                              urlImageUrl +
                              item.Image_URL.slice(0, 7) +
                              "\\" +
                              item.Image_URL.slice(7)
                            }
                            className="dash-rows-img"
                          />
                        </div>
                        <div className="col-md-6 col-lg-6 col-sm-8 col-xs-8 ">
                          <div style={{ fontWeight: "bold" }}>
                            {item.Course_Name}
                          </div>
                          <div>
                            <span style={{ fontSize: ".6vw" }}>
                              {"By " + item.FullName}
                            </span>
                          </div>
                          <div>
                            {" "}
                            <span style={{ fontSize: ".6vw" }}>
                              {item[
                                "(SELECT COUNT(*) FROM free_webinars WHERE CourseID = courses.CourseID )"
                              ] + "  Enrollments"}
                            </span>{" "}
                          </div>
                        </div>
                        <div className="col-md-2 col-lg-2 col-sm-2 col-xs-2 lrp-0">
                          <span
                            onClick={() => this.h1clicked(item.CourseID)}
                            style={{
                              fontSize: ".8vw",
                              fontWeight: "bold",
                              color: "#20a74c",
                              cursor: "pointer",
                            }}
                          >
                            Attendees
                          </span>
                        </div>
                        <div className="col-md-2 col-lg-2 col-sm-2 col-xs-2 lrp-0">
                          <span
                            onClick={() => this.hclicked(item.CourseID)}
                            style={{
                              fontSize: ".8vw",
                              fontWeight: "bold",
                              color: "#20a74c",
                              cursor: "pointer",
                            }}
                          >
                            Registered
                          </span>
                        </div>
                      </div>
                    ))
                  : "No Data"}
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="admin-dash-child">
            <h5 className="co_g20A84C"> Courses </h5>
          </div>

          <div className="admin-dash-child">
            <h5 className="co_g20A84C"> EPE </h5>
          </div>

          <div className="admin-dash-child">
            <h5 className="co_g20A84C"> PEB </h5>
          </div>

          <div className="admin-dash-child">
            <h5 className="co_g20A84C"> Statistics </h5>
          </div>

          <div className="admin-dash-child">
            <h5 className="co_g20A84C"> Finances </h5>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    webinarsummary: state.dashboardData.webinarsummary,
  };
}
export default connect(mapStateToProps, {
  getDashboardWebinars,
  getDashboardStatistics,
})(dashboard);
