import React from "react";
import "../assets/css/talhacss/Description_webinar.css";
import Header from "./Header";
import axios from "axios";
import { Link } from "react-router-dom";
import Collapsible from "react-collapsible";
import StarRatings from "react-star-ratings";
import { urlapi, urlImageUrl } from "../config/urlapi";
import WebNotif from "../components/WebNotif";
import Moment from "react-moment";
import { connect } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import Loader from "react-loader-spinner";
import avatar from "../assets/image/cpd/avatar.png";
import Countdown from "react-countdown";
import Select from "react-select";
import {
  get_faqs,
  get_sessions,
  get_open_sessions,
  get_ExtraResources,
  getWebinarById,
} from "../redux/admin/webinar/webinarActions";
import {
  registerforfree,
  getStatusFreeWebinar,
  getStatusPricedWebinar,
  addpostalAddress,
  joinedWebinar,
  leftwebinar,
} from "../redux/user/useraction";
import {} from "../redux/user/useraction";

import { TextField, MenuItem } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import momenttz from "moment-timezone";
import ReactPlayer from "react-player";
import Modal from "react-modal";
var jwt = require("jsonwebtoken");

var obj = new WebNotif();

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "green",
    },
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "#20a84c",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#20a84c",
      },
    },
  },
})(TextField);

// let mysessions = [];
class Description_webinar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      icondown: '<i class="fas fa-angle-down co_green"></i>',
      iconup: '<i class="fas fa-angle-up co_green"></i>',
      getEngId: localStorage.getItem("engId"),
      getEngInfo: [],
      rating: 0,
      modalIsOpen: false,
      modal1IsOpen: false,
      postaladdress: "",
      MeetingId: "",
      urlFlag: false,
      MeetingPwd: "",
      loading: true,
      uniqueInst: this.props.sessions,
      statusClass: "d-none",
      disabled: "",
      openSessions: [],
      currentWebinar: {},
      background: "",
      currentsession: {},
      session_payment: { value: "", label: "" },
      disable: "",
      categorylist: "",
      token: {},
      timeFlag: true,
    };
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {});
  };

  openModal = () => {
    if (this.props.User) {
      this.setState(
        { postaladdress: this.props.User.Primary_Address, modalIsOpen: true },
        () => {}
      );
    }
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  mysessions = (i) => {
    // let flag = 0;
    // this.props.sessions.map((item) => {
    //   if (i === item.FullName) {
    //     console.log(item.FullName);
    //     console.log(i);
    //     flag++;
    //   }
    // });
    // console.log(flag);
    // if (flag >= 2) {
    //   return false;
    // } else {
    //   return true;
    // }
  };
  addPostalAddress = () => {
    if (this.state.postaladdress === "") {
      obj.createNotification("error", "Address is empty.");
    } else {
      let data = [];
      data.push({
        address: this.state.postaladdress,
        id: this.props.User.UserID,
      });
      this.props.addpostalAddress(data).then((res) => {
        this.closeModal();

        if (this.state.currentSession.pricing_type == "Free") {
          confirmAlert({
            title: "Confirm to submit",
            message: "Are you sure to register for this free session?",
            buttons: [
              {
                label: "Yes",
                onClick: () => {
                  // console.log(this.props);
                  this.props
                    .registerforfree({
                      currentwebinarID: this.state.currentWebinar.CourseID,
                      currentsession: this.state.session_payment.value,
                      userID: this.props.User.UserID,
                      userEmail: this.props.User.PrimaryEmail,
                    })
                    .then((res) => {
                      if (res.status == 200) {
                        obj.createNotification(
                          "success",
                          "Registered Successfully! Check your email."
                        );
                        setTimeout(function () {
                          window.location.reload();
                        }, 300);
                        this.props
                          .getStatusFreeWebinar({
                            s_id: this.state.currentsession.session_id,
                            cid: this.state.currentWebinar.CourseID,
                            userid: this.props.User.UserID,
                          })
                          .then((res) => {
                            if (res == null) {
                              this.setState(
                                { statusClass: "d-none" },
                                () => {}
                              );
                            } else if (res == "Not Registered") {
                              this.setState(
                                { statusClass: "d-none" },
                                () => {}
                              );
                            } else if (res == "Rejected") {
                              this.setState({ statusClass: "cored" }, () => {});
                            } else if (res == "Approved") {
                              this.setState(
                                { statusClass: "co_g20A84C" },
                                () => {}
                              );
                            } else {
                            }
                          });
                      }
                      if (res.status == 403) {
                        console.log("no token");
                      }
                    });
                },
              },
              {
                label: "No",
                onClick: () => {},
              },
            ],
          });
        } else {
          this.props.history.push({
            pathname: "/UserPayment",
            state: {
              currentCourse: this.state.currentWebinar,
              currentsession: this.state.currentsession,
              u_id: this.state.u_id,
              u_email: this.state.u_email,
            },
          });
        }
      });
    }
  };

  // componentDidUpdate(prevProp, prevState) {
  //   // console.log(prevState);
  //   // console.log(this.state);
  //   if (moment(this.state.startdate).valueOf() > moment().valueOf()) {
  //     console.log("INSIDE");
  //     this.setState({ timeFlag: false });
  //   }
  //   // this.setState({ timeFlag: false }, () => {
  //   //   <Countdown
  //   //     renderer={renderCountdown}
  //   //     date={
  //   //       Date.now() +
  //   //       moment(this.state.startdate).valueOf() -
  //   //       Date.now()
  //   //     }
  //   //   />;
  //   // })
  // }
  componentDidMount() {
    // console.log('Webinar Description');
    // console.log("This Props ID: ", this.props.match.params.id);
    let data = [];
    data.push({
      id: this.props.match.params.id,
      role: this.props.admin ? this.props.admin.RoleID : 0,
    });
    //get webinar details
    this.props.getWebinarById(data).then((res) => {
      //  console.log("Webinar Resss: ", res);
      if (res.category.length === 0) {
        this.setState({ urlFlag: true });
      }
      this.setState(
        {
          u_id: this.props.User ? this.props.User.UserID : "",
          u_email: this.props.User ? this.props.User.PrimaryEmail : "",
          currentWebinar: res,
          MeetingId: res.zoomID,
          MeetingPwd: res.zoomPWD,
          loading: false,
        },

        () => {
          let categorylist = "\xa0 ";
          for (let i = 0; i < res.category.length; i++) {
            categorylist += res.category[i].Name + "\xa0 | ";
          }

          this.setState({ categorylist: categorylist });

          //get open sessions

          this.props
            .get_open_sessions(this.state.currentWebinar.CourseID)
            .then((res) => {
              var temp = [];
              let count = 0;
              let isdisabled = false;
              let temo = [];
              for (var i = 0; i < res.length; i++) {
                if (
                  moment(res[i].Session_EndDate).valueOf() < moment().valueOf()
                ) {
                  isdisabled = true;
                } else {
                  if (count === 0) {
                    // console.log("callled count");
                    this.setState({
                      currentsession: res[i],
                      session_payment: {
                        value: res[i].session_id,
                        disabled: isdisabled,
                        label: res[i].Session_Name,
                      },
                    });
                    count++;
                  }
                }
                var obj = {
                  value: res[i].session_id,
                  label: res[i].Session_Name,
                  disabled: isdisabled,
                };
                temp.push(obj);
              }
              this.setState({ openSessions: temp }, () => {
                this.setStatusandCurrentSession();
              });
            });

          //get faqs
          this.props.get_faqs(this.state.currentWebinar.CourseID);
          //get files
          this.props.get_ExtraResources(this.state.currentWebinar.CourseID);
          //get sessions
          this.props
            .get_sessions(this.state.currentWebinar.CourseID)
            .then((res) => {
              this.setState({ InstSet: this.props.sessions }, () => {
                let newItem = [];
                this.state.InstSet.map((item) => {
                  newItem.push({
                    name: item.FullName,
                    img: item.ProfileImageURL,
                    about: item.about,
                  });
                });
                let unique = [
                  ...new Map(
                    newItem.map((item) => [item["name"], item])
                  ).values(),
                ];

                this.setState({ uniqueInst: unique });
              });
            })
            .catch((err) => {
              console.log(err);
            });
        }
      );
    });
    document.title = "Live Webinar";
    window.scrollTo(0, 0);
  }
  btnshowtext = () => {
    var dots = document.getElementById("dots");
    var moreText = document.getElementById("more");
    var btnText = document.getElementById("myBtn");

    if (dots.style.display === "none") {
      dots.style.display = "inline";
      btnText.innerHTML = `Read more ${this.state.icondown}`;
      moreText.style.display = "none";
    } else {
      dots.style.display = "none";
      btnText.innerHTML = `Read less ${this.state.iconup}`;
      moreText.style.display = "inline";
    }
  };

  handletimer = () => {
    if (moment(this.state.startdate).valueOf() > moment().valueOf()) {
      this.setState({ timeFlag: false });
    }
  };
  RegisteredForWebinar = () => {
    if (this.props.User) {
      if (this.state.session_payment.value == "") {
        obj.createNotification("error", "Select a session");
      } else {
        this.openModal();
      }
    } else {
      this.openModal();
      this.props.history.push({
        pathname: "/Login",
        state: { redirect: `/LiveWebinar/${this.props.match.params.id}` },
      });
    }
  };
  gotoDacast = () => {
    this.props.history.push({
      pathname: "/Userview",
      state: {
        courseId: this.props.openSessions[0].CourseId,
        sessionId: this.props.openSessions[0].session_id,
      },
    });
  };

  gotoInstructorDacast = () => {
    this.props.history.push({
      pathname: "/instructorview",
      state: {
        courseId: this.props.openSessions[0].CourseId,
        sessionId: this.props.openSessions[0].session_id,
      },
    });
  };

  gotoZoom = () => {
    if (this.props.User) {
      //sign jwt with User and Webinar Meeting Id and pwd

      var token = jwt.sign(
        {
          User: this.props.User,
          MeetingId: this.state.MeetingId,
          MeetingPwd: this.state.MeetingPwd,
          CourseID: this.state.currentWebinar.CourseID,
          SessionID: this.state.currentsession.session_id,
        },
        "secretzoomapi"
      );
      this.setState({ token: token }, () => {
        this.props
          .joinedWebinar({
            u_id: this.props.User.UserID,
            c_id: this.state.currentWebinar.CourseID,
            s_id: this.state.currentsession.session_id,
          })
          .then((res) => {
            // if (
            //   this.state.currentWebinar &&
            //   (this.state.currentWebinar.Webinar_URL !== null ||
            //     this.state.currentWebinar.Webinar_URL !== undefined ||
            //     this.state.currentWebinar.Webinar_URL !== "")
            // ) {
            //   window.location.href = this.state.currentWebinar.Webinar_URL;
            // } else {
            //  console.log(this.state);
            window.location.href = `https://cpd.pec.org.pk/zoom?token=${token}`;
            // }
          });
      });
    }
    //ask for meeting id and pwd
    this.openModal1();
  };
  openModal1 = () => {
    if (this.props.User) {
      this.setState(
        { modal1IsOpen: true },

        () => {}
      );
    }
  };

  closeModal1 = () => {
    this.setState({ modal1IsOpen: false });
  };

  yesAttendWebinar = (event) => {
    event.preventDefault();
    //1get eng 2 eng email find 3 create email 4 limit
    axios
      .get(urlapi + "/admin/engLimit")
      .then((res) => {
        if (res.data.length >= 830) {
          // console.log("500>",res)
          axios
            .post(urlapi + "/admin/engContain", {
              engid: localStorage.getItem("engId"),
            })
            .then((res) => {
              if (res.data.length > 0) {
                this.setState({ disabled: "1" });
              } else {
                var obj = new WebNotif();
                obj.createNotification(
                  "error",
                  "We apologize, the number of seats for this webinar already full"
                );
              }
            })
            .catch((erro) => {});
        } else {
          axios
            .post(urlapi + "/admin/engineerInformation", {
              engineerId: this.state.getEngId,
            })
            .then((resi) => {
              this.setState({ disabled: "1" });
              if (resi.data[0] == null || resi.data[0] == undefined) {
                axios
                  .post(urlapi + "/admin/signup", {
                    engid: localStorage.getItem("engId"),
                    cnic: localStorage.getItem("CNIC"),
                  })
                  .then((resi) => {})
                  .catch((erro) => {});
              } else {
                this.setState({ getEngInfo: resi.data[0] });
                var a = resi.data[0];
                axios
                  .post(urlapi + "/admin/engineeridcheck", {
                    email: a.email,
                  })
                  .then((res) => {
                    // console.log("fd",res.data)
                    if (res.data.length > 0) {
                    } else {
                      axios
                        .post(urlapi + "/admin/signup", {
                          engid: a.engineerId,
                          number: a.contactNumber,
                          email: a.email,
                          UserName: a.name,
                          cnic: a.cnic,
                        })
                        .then((resi) => {
                          axios
                            .post(urlapi + "/admin/engineerInformation", {
                              engineerId: localStorage.getItem("engId"),
                            })
                            .then((resi) => {
                              axios
                                .post(urlapi + "/admin/emailSecound", {
                                  toemail: resi.data[0].email,
                                  // toemail:'mohammadtalha163@gmail.com',
                                })
                                .then((resi) => {})
                                .catch((erro) => {
                                  console.log("erro");
                                });
                            })
                            .catch((erro) => {
                              console.log("erro");
                            });
                        })
                        .catch((erro) => {
                          console.log("erro");
                        });
                    }
                  })
                  .catch((erro) => {
                    console.log("erro");
                  });
              }
            })
            .catch((erro) => {
              console.log("erro");
            });
        }
      })
      .catch((erro) => {
        console.log("erro");
      });
  };

  setStatusandCurrentSession = () => {
    for (var i = 0; i < this.props.openSessions.length; i++) {
      if (
        this.props.openSessions[i].session_id ==
        this.state.session_payment.value
      ) {
        let flag = false;
        if (
          this.props.openSessions[i] &&
          this.props.openSessions[i].pricing_type === "Free"
        ) {
          flag = true;
        }
        this.setState(
          {
            currentSession: this.props.openSessions[i],
            currentWebinar: {
              ...this.state.currentWebinar,
              price:
                this.props.openSessions[i].price === ""
                  ? "Free"
                  : this.props.openSessions[i].price,
              pricing_type: flag === true ? "Free" : "OneTimePurchase",
              CPDPoints: this.props.openSessions[i].CPDPoints,
              Session_StartDate: this.props.openSessions[i].Session_StartDate,
            },
            startdate: momenttz(this.props.openSessions[i].Session_StartDate)
              .tz("Asia/Karachi")
              .format(),
            enddate: momenttz(this.props.openSessions[i].Session_EndDate)
              .tz("Asia/Karachi")
              .format(),
          },
          () => {
            //  console.log("**    setStatusandCurrentSession **** ")
            //  console.log(this.state.currentSession);
            // console.log(this.props.openSessions.length)
            // console.log(this.props.openSessions)

            // console.log(this.state.startdate, this.state.enddate);
            // console.log(
            //   momenttz(this.state.startdate).tz("Asia/Karachi").valueOf(),
            //   momenttz(this.state.enddate).tz("Asia/Karachi").valueOf(),
            //   momenttz().tz("Asia/Karachi").valueOf()
            // );
            if (this.props.User) {
              if (this.state.currentWebinar.pricing_type == "Free") {
                this.props
                  .getStatusFreeWebinar({
                    s_id: this.state.session_payment.value,
                    cid: this.state.currentWebinar.CourseID,
                    userid: this.props.User.UserID,
                  })
                  .then((res) => {
                    if (res == null) {
                      this.setState({ statusClass: "d-none" }, () => {});
                    } else if (res == "Not Registered") {
                      this.setState({ statusClass: "d-none" }, () => {});
                    } else if (res == "Rejected") {
                      this.setState({ statusClass: "cored" }, () => {});
                    } else if (res == "Approved") {
                      this.setState({ statusClass: "co_g20A84C" }, () => {});
                    } else {
                    }
                  });
              } else {
                this.props
                  .getStatusPricedWebinar({
                    s_id: this.state.session_payment.value,
                    cid: this.state.currentWebinar.CourseID,
                    userid: this.props.User.UserID,
                  })
                  .then((res) => {
                    if (res == null) {
                      this.setState({ statusClass: "d-none" }, () => {});
                    } else if (res == "Not Registered") {
                      this.setState({ statusClass: "d-none" }, () => {});
                    } else if (res == "Rejected") {
                      this.setState({ statusClass: "cored" }, () => {});
                    } else if (res == "Pending") {
                      this.setState({ statusClass: "co_black" }, () => {});
                    } else if (res == "Approved") {
                      this.setState({ statusClass: "co_g20A84C" }, () => {});
                    } else {
                    }
                  });
              }
            } else {
              console.log("without user");
            }
          }
        );
      }
    }
  };

  handleOpenSessionChange = (selectedOption) => {
    this.setState({ session_payment: selectedOption }, () => {
      this.setStatusandCurrentSession();
    });
  };

  render() {
    // console.log('Description WEBINAR')
    const customStyleModal = {
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: "600px",
        overflowY: "hidden",
        backgroundColor: "#fff",
        border: "none",
        color: "black",
      },
      overlay: {
        zIndex: 1000,
        backgroundColor: "rgba(0, 0, 0, 0.35)",
      },
    };
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? "white" : "black",
        backgroundColor: state.isSelected ? "green" : "white",
        "&:hover": {
          backgroundColor: "#dfffc2",
          color: "black",
        },
      }),
      control: (base, state) => ({
        ...base,
        borderColor: state.isFocused ? "green" : "gray",
        boxShadow: "none",

        "&:hover": {
          borderColor: "green",
        },
      }),
    };

    const renderCountdown = ({ days, hours, minutes, seconds }) => {
      if (days === 0 && hours === 0 && minutes === 0 && seconds === 0) {
        this.setState({ timeFlag: true });
      }
      // Render a countdown
      return (
        <span className="des_countdown">
          {days} days {hours} hours : {minutes} minutes : {seconds} seconds
        </span>
      );
    };
    return this.state.urlFlag === true ? (
      <React.Fragment>
        <section className="backco_white" style={{ fontFamily: "Arial" }}>
          <Header />
          {this.state.loading == true ? (
            <div className="loaderDiv text-center">
              <Loader type="Oval" color="#06580e" height={80} width={80} />
            </div>
          ) : (
            <div>
              <header className="image_hight_area">
                <div className="container h-100">
                  <div className=" top-container">
                    <div>
                      {this.state.currentWebinar.Preview_VideoURL ? (
                        <div
                          id="carouselExampleSlidesOnly"
                          class="carousel slide"
                          data-ride="carousel"
                        >
                          <div className="carousel-inner">
                            <div className="carousel-item active">
                              {!this.state.currentWebinar.BannerImage_URL ? (
                                <h3>Image Not Found</h3>
                              ): (
                                <img
                                className="d-block w-100"
                                style={{ height: "400px" }}
                                src={
                                  urlImageUrl +
                                  this.state.currentWebinar.BannerImage_URL.slice(
                                    0,
                                    7
                                  ) +
                                  "\\" +
                                  this.state.currentWebinar.BannerImage_URL.slice(
                                    7
                                  )
                                }
                                alt="First slide"
                              />
                              )}
                             
                            </div>
                            <div className="carousel-item">
                              <ReactPlayer
                                width="100%"
                                forceVideo={true}
                                controls={true}
                                url={
                                  urlImageUrl +
                                  this.state.currentWebinar.Preview_VideoURL
                                }
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className=" webinar_banner_image text-center">
                           {!this.state.currentWebinar.BannerImage_URL ? (
                                <h3>Image Not Found</h3>
                              ): (
                                <img
                            src={
                              urlImageUrl +
                              this.state.currentWebinar.BannerImage_URL.slice(
                                0,
                                7
                              ) +
                              "\\" +
                              this.state.currentWebinar.BannerImage_URL.slice(7)
                            }
                            alt="Banner Image"
                          />
                              )}
                         
                        </div>
                      )}
                    </div>
                    <br />

                    <div className="col-12">
                      <div className="">
                        <h3 className="bolder mtb-20">
                          {this.state.currentWebinar.Course_Name}
                        </h3>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="fs-14 mb-0_5">
                              <i className="fas  fa-clock i_clock_Des"></i>
                              <span className=" csrd-text mb_unset span_fz">
                                <Moment format="hh:mm a">
                                  {this.state.currentWebinar.Session_StartDate}
                                </Moment>
                                &nbsp; PST
                              </span>
                              &nbsp;&nbsp;&nbsp;
                              <i className="far fs-14 fa-calendar i_clock_Des"></i>
                              <span className=" csrd-text mb_unset span_fz">
                                <Moment format="Do MMM YYYY">
                                  {this.state.currentWebinar.Session_StartDate}
                                </Moment>
                              </span>
                              <br></br>
                              <i className="fas fa-hourglass-half fa-align-justify i_clock "></i>
                              <span className="csrd-text mb_unset span_fz">
                                {" "}
                                <Moment
                                  duration={
                                    this.state.currentWebinar.Session_StartDate
                                  }
                                  date={
                                    this.state.currentWebinar.Session_EndDate
                                  }
                                />
                              </span>
                            </div>

                            <p>
                              <span className="bold fs-16">Categories:</span>
                              {this.state.categorylist}
                            </p>

                            {/* <ul className="categorylist"> 
                      <span className="bold fs-14">Categories</span>
                       {this.state.currentWebinar.category.map(item=>
                      <li className="cat_list fs-14">{item.Name}</li> )} 
                      </ul> */}
                          </div>

                          <div className="col-md-4">
                            <div className="fs-16 mb-0_5">
                              <span className="fw-800 ">Webinar Price :</span>{" "}
                              {this.state.currentWebinar.pricing_type == "Free"
                                ? "Free"
                                : " PKR  " +
                                  this.state.currentWebinar.price +
                                  "/-"}{" "}
                            </div>
                            <br></br>
                            <div className="fs-16">
                              {" "}
                              <span className="fw-800 ">CPD Points :</span>{" "}
                              {"   " + this.state.currentWebinar.CPDPoints}{" "}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="fs-16">
                              <h6 className="">
                                {" "}
                                <span className="fw-800">Status : </span>
                                <span
                                  // className={
                                  //   this.props.status === "Pending"
                                  //     ? "color:yellow"
                                  //     : ""
                                  // }
                                  style={{
                                    color:
                                      this.props.status === "Pending"
                                        ? "#F29339"
                                        : this.props.status === "Not Registered"
                                        ? "Red"
                                        : this.props.status === "Approved" &&
                                          "#06580e",
                                  }}
                                >
                                  {this.props.status === null
                                    ? ""
                                    : this.props.status}
                                </span>
                              </h6>
                            </div>
                            <br></br>
                            <div className="fs-16">
                              {" "}
                              <span className="fw-800 ">
                                Share With Friends :
                              </span>
                              <span
                                style={{
                                  paddingRight: "10px",
                                  paddingLeft: "20px",
                                }}
                              >
                                <Link
                                  to={{
                                    pathname: `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`,
                                  }}
                                  target="_blank"
                                >
                                  <i class="fab fa-2x fa-facebook-square"></i>
                                </Link>
                              </span>
                              <Link
                                to={{
                                  pathname: `https://twitter.com/intent/tweet/?url=${window.location.href}`,
                                }}
                                target="_blank"
                              >
                                <i class="fab fa-2x fa-twitter-square"></i>
                              </Link>
                              <span style={{ paddingLeft: "10px" }}>
                                <Link
                                  to={{
                                    pathname: `mailto:?subject=PEC Webinar&Body=Check out this webinar ${window.location.href}`,
                                  }}
                                  target="_blank"
                                >
                                  <i class="fas fa-2x fa-envelope"></i>
                                </Link>
                              </span>
                            </div>
                          </div>
                        </div>

                        {this.state.openSessions.length > 0 ? (
                          <div className="row">
                            <div className="form-group col-8">
                              <p className="fw-800 fs-16">Select Session:</p>
                              <Select
                                isOptionDisabled={(option) =>
                                  option.disabled === true
                                }
                                //  styles={customStyles}
                                className="admin-form-control"
                                name="session_payment"
                                options={this.state.openSessions}
                                value={this.state.session_payment}
                                onChange={this.handleOpenSessionChange}
                              />

                              {/* <select
                             className="form-control admin-form-control"
                             value={this.state.session_payment} 
                             onChange={this.handleChange1}
                             name="session_payment">
                             {this.props.openSessions && this.props.openSessions.length>0 ?
                             this.props.openSessions.map((item,key) =>
                             
                             <option key={key} className="s_option" value={item.session_id}>
                               {item.Session_Name}
                               </option>
                                )
                                  :
                                   ''}
                             </select> */}
                            </div>
                            <div className="col-4 btn_div ">
                              {this.props.User ? (
                                this.state.currentSession &&
                                this.state.currentSession.total_seats === 0 ? (
                                  <button
                                    type="button"
                                    className="btn btn_success_1 w-100 bold text-center "
                                    onClick={this.RegisteredForWebinar}
                                    data-toggle="modal"
                                    data-target="#exampleModalCenter"
                                    disabled
                                  >
                                    Proceed to Register
                                  </button>
                                ) : this.state.currentSession &&
                                  this.state.currentSession.pricing_type !==
                                    "Free" ? (
                                  this.props.status !== "Not Registered" ? (
                                    this.props.status == "Rejected" ? (
                                      <button
                                        type="button"
                                        className="btn btn_success_1 w-100 bold text-center "
                                        onClick={this.RegisteredForWebinar}
                                        data-toggle="modal"
                                        data-target="#exampleModalCenter"
                                      >
                                        PROCEED TO PAYMENT
                                      </button>
                                    ) : (
                                      <button
                                        type="button"
                                        className="btn btn_success_1 w-100 bold text-center "
                                        onClick={this.RegisteredForWebinar}
                                        data-toggle="modal"
                                        data-target="#exampleModalCenter"
                                        disabled
                                      >
                                        PROCEED TO REGISTER
                                      </button>
                                    )
                                  ) : (
                                    <button
                                      type="button"
                                      className="btn btn_success_1 w-100 bold text-center "
                                      onClick={this.RegisteredForWebinar}
                                      data-toggle="modal"
                                      data-target="#exampleModalCenter"
                                    >
                                      PROCEED TO PAYMENT
                                    </button>
                                  )
                                ) : this.props.status !== "Not Registered" ? (
                                  <button
                                    type="button"
                                    className="btn btn_success_1 w-100 bold text-center "
                                    onClick={this.RegisteredForWebinar}
                                    data-toggle="modal"
                                    data-target="#exampleModalCenter"
                                    disabled
                                  >
                                    PROCEED TO REGISTER
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="btn btn_success_1 w-100 bold text-center "
                                    onClick={this.RegisteredForWebinar}
                                    data-toggle="modal"
                                    data-target="#exampleModalCenter"
                                  >
                                    PROCEED TO REGISTER
                                  </button>
                                )
                              ) : (
                                <button
                                  type="button"
                                  className="btn btn_success_1 w-100 bold text-center "
                                  onClick={this.RegisteredForWebinar}
                                  data-toggle="modal"
                                  data-target="#exampleModalCenter"
                                >
                                  LOGIN TO REGISTER
                                </button>
                              )}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </header>

              <div className="container mtb-20">
                <ul
                  className="nav nav-tabs web_desc_nav_tabs"
                  id="myTab"
                  role="tablist"
                >
                  {this.state.currentWebinar.Type_Name ==
                    "Recorded Webinar" && (
                    <li className="nav-item pill">
                      <a
                        className="nav-link active watch_video"
                        id="contact-tab"
                        data-toggle="tab"
                        href="#video"
                        role="tab"
                        aria-controls="video"
                        aria-selected="false"
                      >
                        <strong>Watch Video</strong>{" "}
                      </a>
                    </li>
                  )}{" "}
                  <li className="nav-item pill">
                    <a
                      className={
                        this.state.currentWebinar.Type_Name ===
                        "Recorded Webinar"
                          ? "nav-link web_desc_navlink"
                          : "nav-link active web_desc_navlink"
                      }
                      id="home-tab"
                      data-toggle="tab"
                      href="#description"
                      role="tab"
                      aria-controls="description"
                      aria-selected="true"
                    >
                      Description
                    </a>
                  </li>
                  <li className="nav-item pill">
                    <a
                      className="nav-link web_desc_navlink"
                      id="profile-tab"
                      data-toggle="tab"
                      href="#sessions"
                      role="tab"
                      aria-controls="sessions"
                      aria-selected="false"
                    >
                      Sessions
                    </a>
                  </li>
                  <li className="nav-item pill">
                    <a
                      className="nav-link web_desc_navlink"
                      id="contact-tab"
                      data-toggle="tab"
                      href="#instructors"
                      role="tab"
                      aria-controls="instructors"
                      aria-selected="false"
                    >
                      Resource Person
                    </a>
                  </li>
                  <li className="nav-item pill">
                    <a
                      className="nav-link web_desc_navlink"
                      id="contact-tab"
                      data-toggle="tab"
                      href="#pricing"
                      role="tab"
                      aria-controls="pricing"
                      aria-selected="false"
                    >
                      Webinar Pricing
                    </a>
                  </li>
                  <li className="nav-item pill">
                    <a
                      className="nav-link web_desc_navlink"
                      id="contact-tab"
                      data-toggle="tab"
                      href="#faqs"
                      role="tab"
                      aria-controls="faqs"
                      aria-selected="false"
                    >
                      FAQs
                    </a>
                  </li>
                  <li className="nav-item pill">
                    <a
                      className="nav-link web_desc_navlink"
                      id="contact-tab"
                      data-toggle="tab"
                      href="#files"
                      role="tab"
                      aria-controls="files"
                      aria-selected="false"
                    >
                      Extra Resources
                    </a>
                  </li>
                  <li className="nav-item pill">
                    <a
                      className="nav-link web_desc_navlink"
                      id="contact-tab"
                      data-toggle="tab"
                      href="#ratings"
                      role="tab"
                      aria-controls="ratings"
                      aria-selected="false"
                    >
                      Ratings
                    </a>
                  </li>
                  <li className="nav-item pill">
                    <a
                      className="nav-link web_desc_navlink"
                      id="contact-tab"
                      data-toggle="tab"
                      href="#assessment"
                      role="tab"
                      aria-controls="assessment"
                      aria-selected="false"
                    >
                      Assessments
                    </a>
                  </li>
                  <li className="nav-item pill">
                    <a
                      className="nav-link web_desc_navlink"
                      id="contact-tab"
                      data-toggle="tab"
                      href="#feedback"
                      role="tab"
                      aria-controls="feedback"
                      aria-selected="false"
                    >
                      Feedback
                    </a>
                  </li>
                  {this.props.admin && this.props.admin.RoleID === 2 ? (
                    <li className="nav-item pill">
                      <a
                        className="nav-link web_desc_navlink"
                        id="contact-tab"
                        data-toggle="tab"
                        href="#instructor"
                        role="tab"
                        aria-controls="instructor"
                        aria-selected="false"
                      >
                        Instructor Dacast
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  {this.state.currentWebinar.Type_Name == "PEB Webinar" && (
                    <li className="nav-item pill">
                      <a
                        className="nav-link  watch_video"
                        id="contact-tab"
                        data-toggle="tab"
                        href="#video"
                        role="tab"
                        aria-controls="video"
                        aria-selected="false"
                      >
                        <strong>Watch Video</strong>{" "}
                      </a>
                    </li>
                  )}
                  {this.state.currentWebinar.Type_Name == "Live Webinar" &&
                    this.props.status &&
                    this.props.status == "Approved" && (
                      <li className="nav-item pill" onClick={this.handletimer}>
                        <a
                          className="nav-link  watch_video"
                          id="contact-tab"
                          data-toggle="tab"
                          href="#livestream"
                          role="tab"
                          aria-controls="livestream"
                          aria-selected="false"
                        >
                          <strong>Attend Webinar</strong>{" "}
                        </a>
                      </li>
                    )}
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div
                    className={
                      this.state.currentWebinar.Type_Name === "Recorded Webinar"
                        ? "tab-pane fs-14 fade"
                        : "tab-pane fs-14 fade show active"
                    }
                    id="description"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                  >
                    <div className="mtb-25">
                      <pre className="Des_text">
                        {this.state.currentWebinar.Course_Description}
                      </pre>
                    </div>
                  </div>

                  <div
                    class="tab-pane fs-14 fade"
                    id="sessions"
                    role="tabpanel"
                    aria-labelledby="profile-tab"
                  >
                    <div className="mtb-25">
                      <div className="row col_margin mtb-20 text_center_mob">
                        <div className="col-md-2 bold">Name</div>
                        <div className="col-md-2 bold">Instructor</div>
                        <div className="col-md-2 bold">Start Date</div>
                        <div className="col-md-2 bold">End Date</div>
                        <div className="col-md-2 bold">CPD Points</div>
                        <div className="col-md-2 bold">Registration</div>
                      </div>
                      <hr />
                      {this.props.sessions && this.props.sessions.length > 0
                        ? this.props.sessions.map((item, index) => (
                            <div
                              key={index}
                              className="row col_margin text_center_mob"
                            >
                              <div className="col-md-2">
                                {item.Session_Name}
                              </div>
                              <div className="col-md-2">{item.FullName}</div>
                              <div className="col-md-2">
                                <Moment format="hh:mm a - DD/MM/YYYY ">
                                  {item.Session_StartDate}
                                </Moment>
                              </div>
                              <div className="col-md-2">
                                <Moment format="hh:mm a - DD/MM/YYYY ">
                                  {item.Session_EndDate}
                                </Moment>
                              </div>
                              <div className="col-md-2">{item.CPDPoints}</div>
                              <div className="col-md-2">
                                {item.Registration === "Close"
                                  ? "Closed"
                                  : item.Registration}
                              </div>
                            </div>
                          ))
                        : ""}
                    </div>
                  </div>

                  <div
                    class="tab-pane fade fs-14"
                    id="instructors"
                    role="tabpanel"
                    aria-labelledby="contact-tab"
                  >
                    <div className="mtb-25">
                      {this.props.sessions &&
                        this.props.sessions.length > 0 &&
                        this.state.uniqueInst.map((item, index) => (
                          <div>
                            <div className="row">
                              <div className="col-md-3">
                                <div
                                  className="card"
                                  style={{ width: "15rem" }}
                                >
                                  <img
                                    style={{ height: "160px" }}
                                    src={
                                      item.img !== null
                                        ? urlImageUrl + item.img
                                        : avatar
                                    }
                                    className="card-img-top"
                                    alt="..."
                                  />
                                  <div className="card-body">
                                    <p className="bold fs-16">{item.name}</p>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-9">
                                <pre className="Des_text">{item.about}</pre>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>

                  <div
                    class="tab-pane fs-14 fade"
                    id="pricing"
                    role="tabpanel"
                    aria-labelledby="contact-tab"
                  >
                    <div className=" mtb-25 row col_margin text_center_mob">
                      <div className="col-md-2 bold">Session Name</div>
                      <div className="col-md-3 bold">Price Type</div>
                      <div className="col-md-2 bold">Price Plan</div>
                      <div className="col-md-2 bold">Price (PKR)</div>
                      <div className="col-md-3 bold">Discount (PKR)</div>
                    </div>
                    <hr />

                    {this.props.sessions &&
                      this.props.sessions.length > 0 &&
                      this.props.sessions.map((item) => (
                        <div className="row col_margin mtb-20 text_center_mob">
                          <div className="col-md-2">{item.Session_Name}</div>
                          <div className="col-md-3">
                            {item.pricing_type === "OneTimePurchase"
                              ? "One Time Purchase"
                              : item.pricing_type}
                          </div>
                          <div className="col-md-2">{item.pricing_plan}</div>
                          <div className="col-md-2">{item.price}</div>
                          <div className="col-md-3">{item.discount}</div>
                        </div>
                      ))}
                  </div>

                  <div
                    class="tab-pane fs-14 fade"
                    id="faqs"
                    role="tabpanel"
                    aria-labelledby="contact-tab"
                  >
                    <div className="row">
                      <div className="col-md-12">
                        <div className="faq-container">
                          {this.props.faqs && this.props.faqs.length > 0 ? (
                            this.props.faqs.map((item, key) => (
                              <Collapsible
                                key={key}
                                transitionTime={100}
                                onOpen={() => this.accordionOpen}
                                onClose={() => this.accordionClose}
                                trigger={
                                  <div className="row pt-7 ">
                                    <div className="col-md-11 text-left ">
                                      <i className="fa fa-align-justify " />

                                      <span className="faq-collapsible-question ">
                                        {item.question}
                                      </span>
                                    </div>
                                    <div className="col-md-1 text-center">
                                      <i className="fa fa-caret-down"> </i>
                                    </div>
                                  </div>
                                }
                              >
                                <p>{item.answer}</p>
                              </Collapsible>
                            ))
                          ) : (
                            <div className="bold">
                              Currently there are no FAQs available
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane fs-14 fade"
                    id="files"
                    role="tabpanel"
                    aria-labelledby="profile-tab"
                  >
                    <div className="col-md-12">
                      <div className=" mtb-25 ">
                        {this.props.files && this.props.files.length > 0 ? (
                          this.props.files.map((item, key) => (
                            <div className="into-p">
                              <i className="far fa-file-pdf fa-1x co_black bold"></i>
                              &nbsp;
                              <b className="pdf_text">
                                {"Download File" + (key + 1)}
                              </b>
                              &nbsp;&nbsp;
                              <a
                                href={
                                  urlImageUrl +
                                  item.file_path.slice(0, 7) +
                                  "\\" +
                                  item.file_path.slice(7)
                                }
                                className="btn btn-success bold font_sans fz_14"
                                target="_blank"
                                download
                              >
                                Download
                              </a>
                            </div>
                          ))
                        ) : (
                          <div className="bold">No Resources Available</div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div
                    class="tab-pane fs-14 fade"
                    id="ratings"
                    role="tabpanel"
                    aria-labelledby="contact-tab"
                  >
                    <div className="mtb-25">
                      <div className="fs-20 bold">Coming Soon.</div>
                      <br></br>
                      <StarRatings
                        rating={this.state.rating}
                        starRatedColor="darkgreen"
                        starDimension="30px"
                        starSpacing="5px"
                        starHoverColor="green"
                        numberOfStars={5}
                        name="rating"
                      />{" "}
                      <br />
                      <span className="lrp-10 fz_13">
                        {this.state.rating} out of (0)
                      </span>
                    </div>
                  </div>

                  <div
                    class="tab-pane fs-14 fade"
                    id="assessment"
                    role="tabpanel"
                    aria-labelledby="contact-tab"
                  >
                    <div className="mtb-25">
                      {new Date(
                        this.state.currentWebinar.Session_EndDate
                      ).getTime() < Date.now() ? (
                        this.state.currentWebinar.assessment_url ? (
                          <a
                            target="_blank"
                            href={this.state.currentWebinar.assessment_url}
                          >
                            Click here to fill the assessment form
                          </a>
                        ) : (
                          "Assesment is not added in this webinar"
                        )
                      ) : (
                        "No Assesment Currently"
                      )}
                    </div>
                  </div>
                  <div
                    class="tab-pane fs-14 fade"
                    id="feedback"
                    role="tabpanel"
                    aria-labelledby="contact-tab"
                  >
                    <div className="mtb-25">
                      {new Date(
                        this.state.currentWebinar.Session_EndDate
                      ).getTime() < Date.now() ? (
                        this.state.currentWebinar.feedback_url ? (
                          <a
                            target="_blank"
                            href={this.state.currentWebinar.feedback_url}
                          >
                            Click here to fill the assessment form
                          </a>
                        ) : (
                          "Feedback is not added in this webinar"
                        )
                      ) : (
                        "No Feedback Currently"
                      )}
                    </div>
                  </div>

                  {this.props.admin && this.props.admin.RoleID === 2 ? (
                    <div
                      class="tab-pane fs-14 fade"
                      id="instructor"
                      role="tabpanel"
                      aria-labelledby="contact-tab"
                    >
                      <div className="mtb-25 text-center">
                        <a
                          className="attend_now"
                          style={{ color: "white" }}
                          onClick={this.gotoInstructorDacast}
                        >
                          Goto Dacast
                        </a>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <div
                    class={
                      this.state.currentWebinar.Type_Name === "Recorded Webinar"
                        ? "tab-pane fs-14 show active fade"
                        : "tab-pane fs-14 fade"
                    }
                    id="video"
                    role="tabpanel"
                    aria-labelledby="contact-tab"
                  >
                    <div className="mtb-25">
                      {this.state.currentWebinar.Recorded_URL ? (
                        <ReactPlayer
                          forceVideo={true}
                          controls={true}
                          url={
                            urlImageUrl + this.state.currentWebinar.Recorded_URL
                          }
                        />
                      ) : (
                        <ReactPlayer
                          controls={true}
                          url={this.state.currentWebinar.Webinar_URL}
                        />
                      )}
                    </div>
                  </div>

                  <div
                    class="tab-pane fs-14 fade"
                    id="livestream"
                    role="tabpanel"
                    aria-labelledby="contact-tab"
                  >
                    <div className="mtb-25">
                      <div className="row pt-75">
                        <div className="col-md-9 offset-md-1 text-center">
                          {moment(this.state.startdate).valueOf() >
                          moment().valueOf() ? (
                            // this.setState({ timeFlag: false }, () => {
                            //   <Countdown
                            //     renderer={renderCountdown}
                            //     date={
                            //       Date.now() +
                            //       moment(this.state.startdate).valueOf() -
                            //       Date.now()
                            //     }
                            //   />;
                            // })
                            <Countdown
                              renderer={renderCountdown}
                              date={
                                Date.now() +
                                moment(this.state.startdate).valueOf() -
                                Date.now()
                              }
                            />
                          ) : this.state.timeFlag === true ? (
                            <div>
                              {moment(this.state.enddate).valueOf() <
                              moment().valueOf() ? (
                                <div className="bold">
                                  {" "}
                                  Webinar has finished. Please enroll in the
                                  next session.
                                </div>
                              ) : (
                                <div>
                                  <a
                                    className="btn btn_success_1 bold text-center"
                                    style={{ color: "white", padding: "20px" }}
                                    onClick={this.gotoZoom}
                                  >
                                    Attend Webinar{" "}
                                  </a>

                                  <div className="row d-flex justify-content-center mtb-35">
                                    <div className="col-md-6 d-flex justify-content-center">
                                      <button
                                        onClick={this.gotoDacast}
                                        type="submit"
                                        className="btn btn_success_1 bold text-center"
                                        style={{
                                          "padding-left": "30px",
                                          "padding-right": "30px",
                                          paddingTop: "20px",
                                          paddingBottom: "20px",
                                        }}
                                      >
                                        Join dacast{" "}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <Modal
            isOpen={this.state.modalIsOpen}
            onRequestClose={this.closeModal}
            style={customStyleModal}
            contentLabel="Provide Your Postal Address."
          >
            <div className="row ">
              <div className=" pb-20 col-md-11">
                Provide Your Postal Address.
              </div>
              <div className="col-md-1 ">
                <a
                  className="float-right modalCloseIcon"
                  onClick={this.closeModal}
                >
                  <i className="fas fa-window-close"></i>
                </a>
              </div>
            </div>
            <div className="form-group ">
              <CssTextField
                type="text"
                fullWidth={true}
                label="Address"
                variant="outlined"
                name="postaladdress"
                value={this.state.postaladdress}
                // placeholder="house no. 007, lane 4, street 10, Saidpur Road, Rawalpindi, Punjab"
                onChange={this.onChange}
              />
            </div>

            <div className="row d-flex justify-content-center ">
              <div className="col-md-6 d-flex justify-content-center">
                <button
                  onClick={this.addPostalAddress}
                  type="submit"
                  className="btn text-center btn-submit black bold"
                >
                  Save{" "}
                </button>
              </div>
            </div>
          </Modal>
          {/* <Modal
            isOpen={this.state.modal1IsOpen}
            onRequestClose={this.closeModal1}
            style={customStyleModal}
            contentLabel="Enter Meeting Id and Password"
          >
            {/* <div className="row ">
              <div className=" pb-20 col-md-11">
                Enter Meeting Id and Password
              </div>
              <div className="col-md-1 ">
                <a
                  className="float-right modalCloseIcon"
                  onClick={this.closeModal1}
                >
                  <i className="fas fa-window-close"></i>
                </a>
              </div>
            </div> */}
          {/* <div className="form-group ">
              <CssTextField
                type="text"
                fullWidth={true}
                label="Meeting Id"
                variant="outlined"
                name="MeetingId"
                value={this.state.MeetingId}
                // placeholder="house no. 007, lane 4, street 10, Saidpur Road, Rawalpindi, Punjab"
                //onChange={this.onChange}
              />
            </div>
            <div className="form-group ">
              <CssTextField
                type="password"
                fullWidth={true}
                label="Meeting Password"
                variant="outlined"
                name="MeetingPwd"
                value={this.state.MeetingPwd}
                // placeholder="house no. 007, lane 4, street 10, Saidpur Road, Rawalpindi, Punjab"
                //onChange={this.onChange}
              />
            </div> */}
          {/* <div className="row d-flex justify-content-center ">
              <div className="col-md-6 d-flex justify-content-center">
                <button
                  onClick={this.gotoZoom}
                  type="submit"
                  className="btn text-center btn-submit black bold"
                >
                  Join Zoom Meeting{" "}
                </button>
              </div>
            </div>
          </Modal> */}{" "}
        </section>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <section className="backco_white" style={{ fontFamily: "Arial" }}>
          <Header />
          {this.state.loading == true ? (
            <div className="loaderDiv text-center">
              <Loader type="Oval" color="#06580e" height={80} width={80} />
            </div>
          ) : (
            <div>
              <header className="image_hight_area">
                <div className="container h-100">
                  <div className=" top-container">
                    <div>
                      {this.state.currentWebinar.Preview_VideoURL ? (
                        <div
                          id="carouselExampleSlidesOnly"
                          class="carousel slide"
                          data-ride="carousel"
                        >
                          <div className="carousel-inner">
                            <div className="carousel-item active">
                              <img
                                className="d-block w-100"
                                style={{ height: "400px" }}
                                src={
                                  urlImageUrl +
                                  this.state.currentWebinar.BannerImage_URL.slice(
                                    0,
                                    7
                                  ) +
                                  "\\" +
                                  this.state.currentWebinar.BannerImage_URL.slice(
                                    7
                                  )
                                }
                                alt="First slide"
                              />
                            </div>
                            <div className="carousel-item">
                              <ReactPlayer
                                width="100%"
                                forceVideo={true}
                                controls={true}
                                url={
                                  urlImageUrl +
                                  this.state.currentWebinar.Preview_VideoURL
                                }
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className=" webinar_banner_image text-center">
                          <img
                            src={
                              urlImageUrl +
                              this.state.currentWebinar.BannerImage_URL.slice(
                                0,
                                7
                              ) +
                              "\\" +
                              this.state.currentWebinar.BannerImage_URL.slice(7)
                            }
                            alt="Banner Image"
                          />
                        </div>
                      )}
                    </div>
                    <br />

                    <div className="col-12">
                      <div className="">
                        <h3 className="bolder mtb-20">
                          {this.state.currentWebinar.Course_Name}
                        </h3>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="fs-14 mb-0_5">
                              <i className="fas  fa-clock i_clock_Des"></i>
                              <span className=" csrd-text mb_unset span_fz">
                                <Moment format="hh:mm a">
                                  {this.state.currentWebinar.Session_StartDate}
                                </Moment>
                                &nbsp; PST
                              </span>
                              &nbsp;&nbsp;&nbsp;
                              <i className="far fs-14 fa-calendar i_clock_Des"></i>
                              <span className=" csrd-text mb_unset span_fz">
                                <Moment format="Do MMM YYYY">
                                  {this.state.currentWebinar.Session_StartDate}
                                </Moment>
                              </span>
                              <br></br>
                              <i className="fas fa-hourglass-half fa-align-justify i_clock "></i>
                              <span className="csrd-text mb_unset span_fz">
                                {" "}
                                <Moment
                                  duration={
                                    this.state.currentWebinar.Session_StartDate
                                  }
                                  date={
                                    this.state.currentWebinar.Session_EndDate
                                  }
                                />
                              </span>
                            </div>

                            <p>
                              <span className="bold fs-16">Categories:</span>
                              {this.state.categorylist}
                            </p>

                            {/* <ul className="categorylist"> 
                      <span className="bold fs-14">Categories</span>
                       {this.state.currentWebinar.category.map(item=>
                      <li className="cat_list fs-14">{item.Name}</li> )} 
                      </ul> */}
                          </div>

                          <div className="col-md-4">
                            <div className="fs-16 mb-0_5">
                              <span className="fw-800 ">Webinar Price :</span>{" "}
                              {this.state.currentWebinar.pricing_type == "Free"
                                ? "Free"
                                : " PKR  " +
                                  this.state.currentWebinar.price +
                                  "/-"}{" "}
                            </div>
                            <br></br>
                            <div className="fs-16">
                              {" "}
                              <span className="fw-800 ">CPD Points :</span>{" "}
                              {"   " + this.state.currentWebinar.CPDPoints}{" "}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="fs-16">
                              <h6 className="">
                                {" "}
                                <span className="fw-800">Status : </span>
                                <span
                                  // className={
                                  //   this.props.status === "Pending"
                                  //     ? "color:yellow"
                                  //     : ""
                                  // }
                                  style={{
                                    color:
                                      this.props.status === "Pending"
                                        ? "#F29339"
                                        : this.props.status === "Not Registered"
                                        ? "Red"
                                        : this.props.status === "Approved" &&
                                          "#06580e",
                                  }}
                                >
                                  {this.props.status === null
                                    ? ""
                                    : this.props.status}
                                </span>
                              </h6>
                            </div>
                            <br></br>
                            <div className="fs-16">
                              {" "}
                              <span className="fw-800 ">
                                Share With Friends :
                              </span>
                              <span
                                style={{
                                  paddingRight: "10px",
                                  paddingLeft: "20px",
                                }}
                              >
                                <Link
                                  to={{
                                    pathname: `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`,
                                  }}
                                  target="_blank"
                                >
                                  <i class="fab fa-2x fa-facebook-square"></i>
                                </Link>
                              </span>
                              <Link
                                to={{
                                  pathname: `https://twitter.com/intent/tweet/?url=${window.location.href}`,
                                }}
                                target="_blank"
                              >
                                <i class="fab fa-2x fa-twitter-square"></i>
                              </Link>
                              <span style={{ paddingLeft: "10px" }}>
                                <Link
                                  to={{
                                    pathname: `mailto:?subject=PEC Webinar&Body=Check out this webinar ${window.location.href}`,
                                  }}
                                  target="_blank"
                                >
                                  <i class="fas fa-2x fa-envelope"></i>
                                </Link>
                              </span>
                            </div>
                          </div>
                        </div>

                        {this.state.openSessions.length > 0 ? (
                          <div className="row">
                            <div className="form-group col-8">
                              <p className="fw-800 fs-16">Select Session:</p>
                              <Select
                                isOptionDisabled={(option) =>
                                  option.disabled === true
                                }
                                //  styles={customStyles}
                                className="admin-form-control"
                                name="session_payment"
                                options={this.state.openSessions}
                                value={this.state.session_payment}
                                onChange={this.handleOpenSessionChange}
                              />

                              {/* <select
                             className="form-control admin-form-control"
                             value={this.state.session_payment} 
                             onChange={this.handleChange1}
                             name="session_payment">
                             {this.props.openSessions && this.props.openSessions.length>0 ?
                             this.props.openSessions.map((item,key) =>
                             
                             <option key={key} className="s_option" value={item.session_id}>
                               {item.Session_Name}
                               </option>
                                )
                                  :
                                   ''}
                             </select> */}
                            </div>
                            <div className="col-4 btn_div ">
                              {this.props.User ? (
                                this.state.currentSession &&
                                this.state.currentSession.total_seats === 0 ? (
                                  <button
                                    type="button"
                                    className="btn btn_success_1 w-100 bold text-center "
                                    onClick={this.RegisteredForWebinar}
                                    data-toggle="modal"
                                    data-target="#exampleModalCenter"
                                    disabled
                                  >
                                    Proceed to Register
                                  </button>
                                ) : this.state.currentSession &&
                                  this.state.currentSession.pricing_type !==
                                    "Free" ? (
                                  this.props.status !== "Not Registered" ? (
                                    this.props.status == "Rejected" ? (
                                      <button
                                        type="button"
                                        className="btn btn_success_1 w-100 bold text-center "
                                        onClick={this.RegisteredForWebinar}
                                        data-toggle="modal"
                                        data-target="#exampleModalCenter"
                                      >
                                        PROCEED TO PAYMENT
                                      </button>
                                    ) : (
                                      <button
                                        type="button"
                                        className="btn btn_success_1 w-100 bold text-center "
                                        onClick={this.RegisteredForWebinar}
                                        data-toggle="modal"
                                        data-target="#exampleModalCenter"
                                        disabled
                                      >
                                        PROCEED TO REGISTER
                                      </button>
                                    )
                                  ) : (
                                    <button
                                      type="button"
                                      className="btn btn_success_1 w-100 bold text-center "
                                      onClick={this.RegisteredForWebinar}
                                      data-toggle="modal"
                                      data-target="#exampleModalCenter"
                                    >
                                      PROCEED TO PAYMENT
                                    </button>
                                  )
                                ) : this.props.status !== "Not Registered" ? (
                                  <button
                                    type="button"
                                    className="btn btn_success_1 w-100 bold text-center "
                                    onClick={this.RegisteredForWebinar}
                                    data-toggle="modal"
                                    data-target="#exampleModalCenter"
                                    disabled
                                  >
                                    PROCEED TO REGISTER
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="btn btn_success_1 w-100 bold text-center "
                                    onClick={this.RegisteredForWebinar}
                                    data-toggle="modal"
                                    data-target="#exampleModalCenter"
                                  >
                                    PROCEED TO REGISTER
                                  </button>
                                )
                              ) : (
                                <button
                                  type="button"
                                  className="btn btn_success_1 w-100 bold text-center "
                                  onClick={this.RegisteredForWebinar}
                                  data-toggle="modal"
                                  data-target="#exampleModalCenter"
                                >
                                  LOGIN TO REGISTER
                                </button>
                              )}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </header>

              <div className="container mtb-20">
                <ul
                  className="nav nav-tabs web_desc_nav_tabs"
                  id="myTab"
                  role="tablist"
                >
                  {this.state.currentWebinar.Type_Name ==
                    "Recorded Webinar" && (
                    <li className="nav-item pill">
                      <a
                        className="nav-link active watch_video"
                        id="contact-tab"
                        data-toggle="tab"
                        href="#video"
                        role="tab"
                        aria-controls="video"
                        aria-selected="false"
                      >
                        <strong>Watch Video</strong>{" "}
                      </a>
                    </li>
                  )}{" "}
                  <li className="nav-item pill">
                    <a
                      className={
                        this.state.currentWebinar.Type_Name ===
                        "Recorded Webinar"
                          ? "nav-link web_desc_navlink"
                          : "nav-link active web_desc_navlink"
                      }
                      id="home-tab"
                      data-toggle="tab"
                      href="#description"
                      role="tab"
                      aria-controls="description"
                      aria-selected="true"
                    >
                      Description
                    </a>
                  </li>
                  <li className="nav-item pill">
                    <a
                      className="nav-link web_desc_navlink"
                      id="profile-tab"
                      data-toggle="tab"
                      href="#sessions"
                      role="tab"
                      aria-controls="sessions"
                      aria-selected="false"
                    >
                      Sessions
                    </a>
                  </li>
                  <li className="nav-item pill">
                    <a
                      className="nav-link web_desc_navlink"
                      id="contact-tab"
                      data-toggle="tab"
                      href="#instructors"
                      role="tab"
                      aria-controls="instructors"
                      aria-selected="false"
                    >
                      Resource Person
                    </a>
                  </li>
                  <li className="nav-item pill">
                    <a
                      className="nav-link web_desc_navlink"
                      id="contact-tab"
                      data-toggle="tab"
                      href="#pricing"
                      role="tab"
                      aria-controls="pricing"
                      aria-selected="false"
                    >
                      Webinar Pricing
                    </a>
                  </li>
                  <li className="nav-item pill">
                    <a
                      className="nav-link web_desc_navlink"
                      id="contact-tab"
                      data-toggle="tab"
                      href="#faqs"
                      role="tab"
                      aria-controls="faqs"
                      aria-selected="false"
                    >
                      FAQs
                    </a>
                  </li>
                  <li className="nav-item pill">
                    <a
                      className="nav-link web_desc_navlink"
                      id="contact-tab"
                      data-toggle="tab"
                      href="#files"
                      role="tab"
                      aria-controls="files"
                      aria-selected="false"
                    >
                      Extra Resources
                    </a>
                  </li>
                  <li className="nav-item pill">
                    <a
                      className="nav-link web_desc_navlink"
                      id="contact-tab"
                      data-toggle="tab"
                      href="#ratings"
                      role="tab"
                      aria-controls="ratings"
                      aria-selected="false"
                    >
                      Ratings
                    </a>
                  </li>
                  <li className="nav-item pill">
                    <a
                      className="nav-link web_desc_navlink"
                      id="contact-tab"
                      data-toggle="tab"
                      href="#assessment"
                      role="tab"
                      aria-controls="assessment"
                      aria-selected="false"
                    >
                      Assessments
                    </a>
                  </li>
                  <li className="nav-item pill">
                    <a
                      className="nav-link web_desc_navlink"
                      id="contact-tab"
                      data-toggle="tab"
                      href="#feedback"
                      role="tab"
                      aria-controls="feedback"
                      aria-selected="false"
                    >
                      Feedback
                    </a>
                  </li>
                  {this.props.admin && this.props.admin.RoleID === 2 ? (
                    <li className="nav-item pill">
                      <a
                        className="nav-link web_desc_navlink"
                        id="contact-tab"
                        data-toggle="tab"
                        href="#instructor"
                        role="tab"
                        aria-controls="instructor"
                        aria-selected="false"
                      >
                        Instructor Dacast
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  {this.state.currentWebinar.Type_Name == "PEB Webinar" && (
                    <li className="nav-item pill">
                      <a
                        className="nav-link  watch_video"
                        id="contact-tab"
                        data-toggle="tab"
                        href="#video"
                        role="tab"
                        aria-controls="video"
                        aria-selected="false"
                      >
                        <strong>Watch Video</strong>{" "}
                      </a>
                    </li>
                  )}
                  {this.state.currentWebinar.Type_Name == "Live Webinar" &&
                    this.props.status &&
                    this.props.status == "Approved" && (
                      <li className="nav-item pill" onClick={this.handletimer}>
                        <a
                          className="nav-link  watch_video"
                          id="contact-tab"
                          data-toggle="tab"
                          href="#livestream"
                          role="tab"
                          aria-controls="livestream"
                          aria-selected="false"
                        >
                          <strong>Attend Webinar</strong>{" "}
                        </a>
                      </li>
                    )}
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div
                    className={
                      this.state.currentWebinar.Type_Name === "Recorded Webinar"
                        ? "tab-pane fs-14 fade"
                        : "tab-pane fs-14 fade show active"
                    }
                    id="description"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                  >
                    <div className="mtb-25">
                      <pre className="Des_text">
                        {this.state.currentWebinar.Course_Description}
                      </pre>
                    </div>
                  </div>

                  <div
                    class="tab-pane fs-14 fade"
                    id="sessions"
                    role="tabpanel"
                    aria-labelledby="profile-tab"
                  >
                    <div className="mtb-25">
                      <div className="row col_margin mtb-20 text_center_mob">
                        <div className="col-md-2 bold">Name</div>
                        <div className="col-md-2 bold">Instructor</div>
                        <div className="col-md-2 bold">Start Date</div>
                        <div className="col-md-2 bold">End Date</div>
                        <div className="col-md-2 bold">CPD Points</div>
                        <div className="col-md-2 bold">Registration</div>
                      </div>
                      <hr />
                      {this.props.sessions && this.props.sessions.length > 0
                        ? this.props.sessions.map((item, index) => (
                            <div
                              key={index}
                              className="row col_margin text_center_mob"
                            >
                              <div className="col-md-2">
                                {item.Session_Name}
                              </div>
                              <div className="col-md-2">{item.FullName}</div>
                              <div className="col-md-2">
                                <Moment format="hh:mm a - DD/MM/YYYY ">
                                  {item.Session_StartDate}
                                </Moment>
                              </div>
                              <div className="col-md-2">
                                <Moment format="hh:mm a - DD/MM/YYYY ">
                                  {item.Session_EndDate}
                                </Moment>
                              </div>
                              <div className="col-md-2">{item.CPDPoints}</div>
                              <div className="col-md-2">
                                {item.Registration === "Close"
                                  ? "Closed"
                                  : item.Registration}
                              </div>
                            </div>
                          ))
                        : ""}
                    </div>
                  </div>

                  <div
                    class="tab-pane fade fs-14"
                    id="instructors"
                    role="tabpanel"
                    aria-labelledby="contact-tab"
                  >
                    <div className="mtb-25">
                      {this.props.sessions &&
                        this.props.sessions.length > 0 &&
                        this.state.uniqueInst.map((item, index) => (
                          <div>
                            <div className="row">
                              <div className="col-md-3">
                                <div
                                  className="card"
                                  style={{ width: "15rem" }}
                                >
                                  <img
                                    style={{ height: "160px" }}
                                    src={
                                      item.img !== null
                                        ? urlImageUrl + item.img
                                        : avatar
                                    }
                                    className="card-img-top"
                                    alt="..."
                                  />
                                  <div className="card-body">
                                    <p className="bold fs-16">{item.name}</p>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-9">
                                <pre className="Des_text">{item.about}</pre>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>

                  <div
                    class="tab-pane fs-14 fade"
                    id="pricing"
                    role="tabpanel"
                    aria-labelledby="contact-tab"
                  >
                    <div className=" mtb-25 row col_margin text_center_mob">
                      <div className="col-md-2 bold">Session Name</div>
                      <div className="col-md-3 bold">Price Type</div>
                      <div className="col-md-2 bold">Price Plan</div>
                      <div className="col-md-2 bold">Price (PKR)</div>
                      <div className="col-md-3 bold">Discount (PKR)</div>
                    </div>
                    <hr />

                    {this.props.sessions &&
                      this.props.sessions.length > 0 &&
                      this.props.sessions.map((item) => (
                        <div className="row col_margin mtb-20 text_center_mob">
                          <div className="col-md-2">{item.Session_Name}</div>
                          <div className="col-md-3">
                            {item.pricing_type === "OneTimePurchase"
                              ? "One Time Purchase"
                              : item.pricing_type}
                          </div>
                          <div className="col-md-2">{item.pricing_plan}</div>
                          <div className="col-md-2">{item.price}</div>
                          <div className="col-md-3">{item.discount}</div>
                        </div>
                      ))}
                  </div>

                  <div
                    class="tab-pane fs-14 fade"
                    id="faqs"
                    role="tabpanel"
                    aria-labelledby="contact-tab"
                  >
                    <div className="row">
                      <div className="col-md-12">
                        <div className="faq-container">
                          {this.props.faqs && this.props.faqs.length > 0 ? (
                            this.props.faqs.map((item, key) => (
                              <Collapsible
                                key={key}
                                transitionTime={100}
                                onOpen={() => this.accordionOpen}
                                onClose={() => this.accordionClose}
                                trigger={
                                  <div className="row pt-7 ">
                                    <div className="col-md-11 text-left ">
                                      <i className="fa fa-align-justify " />

                                      <span className="faq-collapsible-question ">
                                        {item.question}
                                      </span>
                                    </div>
                                    <div className="col-md-1 text-center">
                                      <i className="fa fa-caret-down"> </i>
                                    </div>
                                  </div>
                                }
                              >
                                <p>{item.answer}</p>
                              </Collapsible>
                            ))
                          ) : (
                            <div className="bold">
                              Currently there are no FAQs available
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane fs-14 fade"
                    id="files"
                    role="tabpanel"
                    aria-labelledby="profile-tab"
                  >
                    <div className="col-md-12">
                      <div className=" mtb-25 ">
                        {this.props.files && this.props.files.length > 0 ? (
                          this.props.files.map((item, key) => (
                            <div className="into-p">
                              <i className="far fa-file-pdf fa-1x co_black bold"></i>
                              &nbsp;
                              <b className="pdf_text">
                                {"Download File" + (key + 1)}
                              </b>
                              &nbsp;&nbsp;
                              <a
                                href={
                                  urlImageUrl +
                                  item.file_path.slice(0, 7) +
                                  "\\" +
                                  item.file_path.slice(7)
                                }
                                className="btn btn-success bold font_sans fz_14"
                                target="_blank"
                                download
                              >
                                Download
                              </a>
                            </div>
                          ))
                        ) : (
                          <div className="bold">No Resources Available</div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div
                    class="tab-pane fs-14 fade"
                    id="ratings"
                    role="tabpanel"
                    aria-labelledby="contact-tab"
                  >
                    <div className="mtb-25">
                      <div className="fs-20 bold">Coming Soon.</div>
                      <br></br>
                      <StarRatings
                        rating={this.state.rating}
                        starRatedColor="darkgreen"
                        starDimension="30px"
                        starSpacing="5px"
                        starHoverColor="green"
                        numberOfStars={5}
                        name="rating"
                      />{" "}
                      <br />
                      <span className="lrp-10 fz_13">
                        {this.state.rating} out of (0)
                      </span>
                    </div>
                  </div>

                  <div
                    class="tab-pane fs-14 fade"
                    id="assessment"
                    role="tabpanel"
                    aria-labelledby="contact-tab"
                  >
                    <div className="mtb-25">
                      {new Date(
                        this.state.currentWebinar.Session_EndDate
                      ).getTime() < Date.now() ? (
                        this.state.currentWebinar.assessment_url ? (
                          <a
                            target="_blank"
                            href={this.state.currentWebinar.assessment_url}
                          >
                            Click here to fill the assessment form
                          </a>
                        ) : (
                          "Assesment is not added in this webinar"
                        )
                      ) : (
                        "No Assesment Currently"
                      )}
                    </div>
                  </div>
                  <div
                    class="tab-pane fs-14 fade"
                    id="feedback"
                    role="tabpanel"
                    aria-labelledby="contact-tab"
                  >
                    <div className="mtb-25">
                      {new Date(
                        this.state.currentWebinar.Session_EndDate
                      ).getTime() < Date.now() ? (
                        this.state.currentWebinar.feedback_url ? (
                          <a
                            target="_blank"
                            href={this.state.currentWebinar.feedback_url}
                          >
                            Click here to fill the assessment form
                          </a>
                        ) : (
                          "Feedback is not added in this webinar"
                        )
                      ) : (
                        "No Feedback Currently"
                      )}
                    </div>
                  </div>

                  {this.props.admin && this.props.admin.RoleID === 2 ? (
                    <div
                      class="tab-pane fs-14 fade"
                      id="instructor"
                      role="tabpanel"
                      aria-labelledby="contact-tab"
                    >
                      <div className="mtb-25 text-center">
                        <a
                          className="attend_now"
                          style={{ color: "white" }}
                          onClick={this.gotoInstructorDacast}
                        >
                          Goto Dacast
                        </a>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <div
                    class={
                      this.state.currentWebinar.Type_Name === "Recorded Webinar"
                        ? "tab-pane fs-14 show active fade"
                        : "tab-pane fs-14 fade"
                    }
                    id="video"
                    role="tabpanel"
                    aria-labelledby="contact-tab"
                  >
                    <div className="mtb-25">
                      {this.state.currentWebinar.Recorded_URL ? (
                        <ReactPlayer
                          forceVideo={true}
                          controls={true}
                          url={
                            urlImageUrl + this.state.currentWebinar.Recorded_URL
                          }
                        />
                      ) : (
                        <ReactPlayer
                          controls={true}
                          url={this.state.currentWebinar.Webinar_URL}
                        />
                      )}
                    </div>
                  </div>

                  <div
                    class="tab-pane fs-14 fade"
                    id="livestream"
                    role="tabpanel"
                    aria-labelledby="contact-tab"
                  >
                    <div className="mtb-25">
                      <div className="row pt-75">
                        <div className="col-md-9 offset-md-1 text-center">
                          {moment(this.state.startdate).valueOf() >
                          moment().valueOf() ? (
                            // this.setState({ timeFlag: false }, () => {
                            //   <Countdown
                            //     renderer={renderCountdown}
                            //     date={
                            //       Date.now() +
                            //       moment(this.state.startdate).valueOf() -
                            //       Date.now()
                            //     }
                            //   />;
                            // })
                            <Countdown
                              renderer={renderCountdown}
                              date={
                                Date.now() +
                                moment(this.state.startdate).valueOf() -
                                Date.now()
                              }
                            />
                          ) : this.state.timeFlag === true ? (
                            <div>
                              {moment(this.state.enddate).valueOf() <
                              moment().valueOf() ? (
                                <div className="bold">
                                  {" "}
                                  Webinar has finished. Please enroll in the
                                  next session.
                                </div>
                              ) : (
                                <div>
                                  <a
                                    className="btn btn_success_1 bold text-center"
                                    style={{ color: "white", padding: "20px" }}
                                    onClick={this.gotoZoom}
                                  >
                                    Attend Webinar{" "}
                                  </a>

                                  <div className="row d-flex justify-content-center mtb-35">
                                    <div className="col-md-6 d-flex justify-content-center">
                                      <button
                                        onClick={this.gotoDacast}
                                        type="submit"
                                        className="btn btn_success_1 bold text-center"
                                        style={{
                                          "padding-left": "30px",
                                          "padding-right": "30px",
                                          paddingTop: "20px",
                                          paddingBottom: "20px",
                                        }}
                                      >
                                        Join dacast{" "}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <Modal
            isOpen={this.state.modalIsOpen}
            onRequestClose={this.closeModal}
            style={customStyleModal}
            contentLabel="Provide Your Postal Address."
          >
            <div className="row ">
              <div className=" pb-20 col-md-11">
                Provide Your Postal Address.
              </div>
              <div className="col-md-1 ">
                <a
                  className="float-right modalCloseIcon"
                  onClick={this.closeModal}
                >
                  <i className="fas fa-window-close"></i>
                </a>
              </div>
            </div>
            <div className="form-group ">
              <CssTextField
                type="text"
                fullWidth={true}
                label="Address"
                variant="outlined"
                name="postaladdress"
                value={this.state.postaladdress}
                // placeholder="house no. 007, lane 4, street 10, Saidpur Road, Rawalpindi, Punjab"
                onChange={this.onChange}
              />
            </div>

            <div className="row d-flex justify-content-center ">
              <div className="col-md-6 d-flex justify-content-center">
                <button
                  onClick={this.addPostalAddress}
                  type="submit"
                  className="btn text-center btn-submit black bold"
                >
                  Save{" "}
                </button>
              </div>
            </div>
          </Modal>
          {/* <Modal
            isOpen={this.state.modal1IsOpen}
            onRequestClose={this.closeModal1}
            style={customStyleModal}
            contentLabel="Enter Meeting Id and Password"
          >
            {/* <div className="row ">
              <div className=" pb-20 col-md-11">
                Enter Meeting Id and Password
              </div>
              <div className="col-md-1 ">
                <a
                  className="float-right modalCloseIcon"
                  onClick={this.closeModal1}
                >
                  <i className="fas fa-window-close"></i>
                </a>
              </div>
            </div> */}
          {/* <div className="form-group ">
              <CssTextField
                type="text"
                fullWidth={true}
                label="Meeting Id"
                variant="outlined"
                name="MeetingId"
                value={this.state.MeetingId}
                // placeholder="house no. 007, lane 4, street 10, Saidpur Road, Rawalpindi, Punjab"
                //onChange={this.onChange}
              />
            </div>
            <div className="form-group ">
              <CssTextField
                type="password"
                fullWidth={true}
                label="Meeting Password"
                variant="outlined"
                name="MeetingPwd"
                value={this.state.MeetingPwd}
                // placeholder="house no. 007, lane 4, street 10, Saidpur Road, Rawalpindi, Punjab"
                //onChange={this.onChange}
              />
            </div> */}
          {/* <div className="row d-flex justify-content-center ">
              <div className="col-md-6 d-flex justify-content-center">
                <button
                  onClick={this.gotoZoom}
                  type="submit"
                  className="btn text-center btn-submit black bold"
                >
                  Join Zoom Meeting{" "}
                </button>
              </div>
            </div>
          </Modal> */}{" "}
        </section>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    User: state.userData.user,
    admin: state.userData.admin,
    status: state.userData.status,
    faqs: state.webinarData.webinarFaqs,
    openSessions: state.webinarData.openSessions,
    sessions: state.webinarData.webinarSessions,
    special: state.webinarData.specialSessions,
    files: state.webinarData.webinarFiles,
  };
}
export default connect(mapStateToProps, {
  getWebinarById,
  addpostalAddress,
  get_ExtraResources,
  get_sessions,
  get_faqs,
  get_open_sessions,
  registerforfree,
  getStatusPricedWebinar,
  getStatusFreeWebinar,
  joinedWebinar,
  leftwebinar,
})(Description_webinar);
