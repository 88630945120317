import {
  POPULATE_SESSIONS,
  CREATE_WEBINAR_DETAILS,
  CREATE_WEBINAR_SESSIONS,
  CREATE_WEBINAR_PRICING,
  CREATE_WEBINAR_FAQS,
  GOT_ALL_WEBINARS,
  GOT_WEBINAR_DATA,
  SAVE_WEBINAR_DRAFT,
  EDITED_WEBINAR_DETAILS,
  EDITED_WEBINAR_SESSIONS,
  POPULATE_PRICE_PLAN,
  EDITED_WEBINAR_PRICING,
} from "../../types";
import api from "../../../api";

export const createdwebinardetails = (data) => ({
  type: CREATE_WEBINAR_DETAILS,
  data,
});

export const createwebinarfaqs = (data) => ({
  type: CREATE_WEBINAR_FAQS,
  data,
});
export const saveWebinarD = (data) => ({
  type: SAVE_WEBINAR_DRAFT,
  data,
})

export const createwebinarpricing = (data) => ({
  type: CREATE_WEBINAR_PRICING,
  data,
});

export const createdwebinarsessions = (data) => ({
  type: CREATE_WEBINAR_SESSIONS,
  data,
});

export const gotAllWebinars = (data) => ({
  type: GOT_ALL_WEBINARS,
  data,
});

export const gotWebinar = (data) => ({
  type: GOT_WEBINAR_DATA,
  data,
});
export const gotAllPublishedWebinars = (data) => ({
  type: "GOT_PUBLISHED_WEBINARS",
  data,
});

export const gotAllWebinarByType = (data) =>({
  type: "GOT_ALL_WEBINAR_BY_TYPE",
  data,
})

export const gotAllWebinarAdmin = (data) => ({
  type: "GOT_ADMIN_ALL_WEBINAR",
  data,
});

export const PublishedWebinar = (data) => ({
  type: "WEBINAR_PUBLISHED",
  data,
});

export const ArchivedWebinar = (data) => ({
  type: "ARCHIVED_WEBINAR",
  data,
});

export const publishWebinarFeedback = (data) => ({
  type: "PUBLISH_WEBINAR_FEEDBACK",
  data,
});

export const create_webinar_details = (data) => (dispatch) =>
  api.admin.webinar.create_webinar(data).then((res) => {
    dispatch(createdwebinardetails(res.data));
    return res.data;
  });

export const create_webinar_sessions = (data) => (dispatch) =>
  api.admin.webinar.create_webinar_sessions(data).then((res) => {
    dispatch(createdwebinarsessions(res.data));
    return res.data;
  });
//   export const create_webinar_pricing = (data) => (dispatch) =>

//   api.admin.webinar.create_webinar_pricing(data).then (res =>
//     {

//         dispatch(createwebinarpricing(res.data));
//         console.log(res.data)
//         return res.data

// });
export const create_webinar_faqs = (data) => (dispatch) =>
  api.admin.webinar.create_webinar_faqs(data).then((res) => {
    dispatch(createwebinarfaqs(res.data));
    return res.data;
  });

export const saveWebinarDraft = (data) => (dispatch) =>
  api.admin.webinar.archive_webinar(data).then((res) => {
    dispatch(saveWebinarD(res.data));
    return res.data;
  });

export const getAllWebinars = () => (dispatch) =>
  api.admin.webinar.getallwebinars().then((res) => {
    dispatch(gotAllWebinars(res.data));
    return res.data;
  });

export const getAllWebinarAdmin = (data) => (dispatch) =>
api.admin.webinar.GetAllWebinarsAdmin(data).then((res) => {
  dispatch(gotAllWebinarAdmin(res.data));
  return res.data;
});

export const getAllPublishedWebinars = (data) => (dispatch) =>
  api.admin.webinar.getAllPublishedWebinars(data).then((res) => {
    dispatch(gotAllPublishedWebinars(res.data));
    return res.data;
  });

  
  export const getWebinarByType = (data) => (dispatch) =>
  api.admin.webinar.getWebinarByType(data).then((res) => {
    dispatch(gotAllWebinarByType(res.data));
    return res.data;
  });
export const getWebinar = (id) => (dispatch) =>
  api.admin.webinar.getWebinar(id).then((res) => {
    dispatch(gotWebinar(res.data));
    return res.data;
  });

export const publishwebinar = (id) => (dispatch) =>
  api.admin.webinar.publishwebinar(id).then((res) => {
    dispatch(PublishedWebinar(res.data));
    return res.data;
  });

export const archive_webinar = (id) => (dispatch) =>
  api.admin.webinar.archive_webinar(id).then((res) => {
    dispatch(ArchivedWebinar(res.data));
    return res.data;
  });

export const webinarFeedback = (data) => (dispatch) =>
  api.admin.webinar.publishwebinarfeedback(data).then((res) => {
    dispatch(publishWebinarFeedback(res.data));
    return res.data;
  });
//EDIT ACTIONS
export const editedwebinarDetails = (data) => ({
  type: EDITED_WEBINAR_DETAILS,
  data,
});

export const editedwebinarPricing = (data) => ({
  type: EDITED_WEBINAR_PRICING,
  data,
});
export const populateSession = (data) => ({
  type: POPULATE_SESSIONS,
  data,
});

export const populatePricePlan = (data) => ({
  type: POPULATE_PRICE_PLAN,
  data,
})
export const populateOpenSession = (data) => ({
  type: "POPULATE_OPEN_SESSIONS",
  data,
});

export const populateFiles = (data) => ({
  type: "POPULATE_FILES",
  data,
});

export const populateFaqs = (data) => ({
  type: "POPULATE_FAQS",
  data,
});

export const GetInstructors = () => ({
  type: "GOTINSTRUCTORS",
});
export const GotPaymentPlan = () => ({
  type: "GOTPAYMENTPLAN"
});

export const UpdatedPaymentPlan = () => ({
  type: "UPDATEDPAYMENTPLAN"
});
export const GotWebinarCount = () => ({
  type: "GOTWEBINARCOUNT",
});


export const GotCategories = () => ({
  type: "GOTCATEGORIES",
});

export const GotAdminWebinarCount = () => ({
  type: "GOTADMINWEBINARCOUNT"
})
export const GotWebinarById = (data) => ({
  type: "GOTWEBINARBYID",
  data,
});
export const SetFileFlag = () => ({
  type: "SETFILEFLAG",
});

export const savedWebinarData = (data) =>({
  type: "UPDATE_FORM_DATA",
  data
})


export const save_webinar_form = (data) => (dispatch) => dispatch(savedWebinarData(data));

export const edit_webinar_details = (data) => (dispatch) =>
  api.admin.webinar.edit_webinar_details(data).then((res) => {
    dispatch(editedwebinarDetails(res.data));
    return res.data;
  });

export const AssessmentUpdated = (data) => ({
  type: "ASSESSMENT_UPDATED",
  data,
});
export const update_assessment = (data) => (dispatch) =>
  api.admin.webinar.update_assessment(data).then((res) => {
    dispatch(AssessmentUpdated(res.data));
    return res;
  });





export const editSession = (data) => (dispatch) => {
  api.admin.webinar.edit_session(data).then((res) => {
    dispatch(populateSession(res));
    return res;
  });
};

export const delete_session = (data) => (dispatch) => {
  api.admin.webinar.delete_sess(data).then((res) => {
    dispatch(populateSession(res));
    return res;
  });
};

export const add_session = (data) => (dispatch) => {
  api.admin.webinar.add_sess(data).then((res) => {
    dispatch(populateSession(res));
    return res;
  });
};

export const addPricePlan = (data) => (dispatch) => 
  api.admin.webinar.add_price_plans(data).then((res)=>{
    dispatch(populatePricePlan(res));
    return res;
  })


export const get_ExtraResources = (data) => (dispatch) => 
  api.admin.webinar.get_extraresources(data).then((res) => {
    dispatch(populateFiles(res));
    return res;
  });

export const deleteFile = (data) => (dispatch) => {
  api.admin.webinar.delete_file(data).then((res) => {
    dispatch(populateFiles(res));
    return res;
  });
};

export const add_faq = (data) => (dispatch) =>
  api.admin.webinar.add_faq(data).then((res) => {
    dispatch(populateFaqs(res));
    return res;
  });

export const get_faqs = (data) => (dispatch) => {
  api.admin.webinar.get_faqs(data).then((res) => {
    dispatch(populateFaqs(res));
    return res;
  });
};

export const delete_faq = (data) => (dispatch) => {
  api.admin.webinar.delete_faq(data).then((res) => {
    dispatch(populateFaqs(res));
    return res;
  });
};

export const edit_faq = (data) => (dispatch) => {
  api.admin.webinar.edit_faq(data).then((res) => {
    dispatch(populateFaqs(res));
    return res;
  });
};

export const get_open_sessions = (data) => (dispatch) =>
  api.admin.webinar.get_open_sessions(data).then((res) => {
    dispatch(populateOpenSession(res));
    return res;
  });

  export const get_sessions = (data) => (dispatch) =>
  api.admin.webinar.get_sessions(data).then((res) => {
    dispatch(populateSession(res));
    return res;
  });

export const getInstructors = () => (dispatch) =>
  api.admin.webinar.get_Instructors().then((res) => {
    dispatch(GetInstructors());
    return res;
  });
export const getPaymentPlans = (data) => (dispatch) =>
api.admin.webinar.get_PaymentPlan(data).then((res) => {
  dispatch(GotPaymentPlan());
  return res;
});
export const updatePaymentPlan = (data) => (dispatch) =>
api.admin.webinar.update_PaymentPlan(data).then((res) => {
  dispatch(UpdatedPaymentPlan());
  return res;
});
export const getWebinarCount = (data) => (dispatch) =>
  api.admin.webinar.get_webinar_count(data).then((res) => {
    dispatch(GotWebinarCount());
    return res;
  });


export const getCategories = () => (dispatch) =>
  api.admin.webinar.get_categories().then((res) => {
    dispatch(GotCategories());
    return res;
  });

export const getAdminWebinarCount = () => (dispatch) =>
  api.admin.webinar.get_admin_webinar_count().then((res)=>{
    dispatch(GotAdminWebinarCount());
    return res;
  })

export const getWebinarById = (c_id) => (dispatch) =>
  api.admin.webinar.get_webinar_id(c_id).then((res) => {
    dispatch(GotWebinarById(res));
    return res;
  });
export const setFilesFirstSaveFlag = () => (dispatch) =>
  dispatch(SetFileFlag());
