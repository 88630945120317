const initstate={
   webinarsummary:[]
    };

export default function dashboardData(state=initstate, action ={} ){
   
   switch(action.type) {
    case  'WEBINAR_SUMMARY':
        return {...state, webinarsummary:action.data}
        
    default : return state;
   }
}