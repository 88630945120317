import {
  POPULATE_SESSIONS,
  CREATE_WEBINAR_DETAILS,
  GOT_ALL_WEBINARS,
  CREATE_WEBINAR_SESSIONS,
  EDITED_WEBINAR_PRICING,
  CREATE_WEBINAR_PRICING,
  CREATE_WEBINAR_FAQS,
  POPULATE_PRICE_PLAN,
  EDITED_WEBINAR_DETAILS,
  SAVE_WEBINAR_DRAFT,
} from "../../types";

const initstate = {
  allWebinars: [],
  webinarDetails: [],
  webinarSessions: [],
  formData: [],
  webinarPricing: [],
  webinarFiles: [],
  webinarFaqs: [],
  publishedWebinars: [],
  currentWebinar: [],
  openSessions: [],
  pricePlan: [],
  FirstTimeSave: { details: false, files: false, faqs: false },
};

export default function webinarData(state = initstate, action = {}) {
  switch (action.type) {
    case CREATE_WEBINAR_DETAILS:
      return {
        ...state,
        webinarDetails: action.data,
        FirstTimeSave: { ...state.FirstTimeSave, details: true },
      };
    case CREATE_WEBINAR_SESSIONS:
      return { ...state, webinarSessions: action.data };
    case CREATE_WEBINAR_PRICING:
      return { ...state, webinarPricing: action.data };
    case CREATE_WEBINAR_FAQS:
      return { ...state };
    case GOT_ALL_WEBINARS:
      return { ...initstate, allWebinars: action.data };
    case SAVE_WEBINAR_DRAFT:
      return {...state}
    case "WEBINAR_PUBLISHED":
      return {
        ...state,
      };
    case "UPDATE_FORM_DATA": 
    return {...state,formData:action.data}
    case "GOT_PUBLISHED_WEBINARS":
      return { ...initstate, publishedWebinars: action.data };
    case "GOT_ALL_WEBINAR_BY_TYPE": 
    return {...state}
    case "ARCHIVED_WEBINAR":
      return { ...state };
    case "PUBLISH_WEBINAR_FEEDBACK":
      return { ...state };
    case EDITED_WEBINAR_DETAILS:
      return { ...state,webinarDetails: action.data };

    case EDITED_WEBINAR_PRICING: {
      return { ...state, webinarPricing: action.data };
    }
    case POPULATE_SESSIONS: {
      return { ...state, webinarSessions: action.data };
    }
    case "POPULATE_OPEN_SESSIONS": {
      return { ...state, openSessions: action.data };
    }
    case POPULATE_PRICE_PLAN: {
      return {...state, pricePlan: action.data}
    }
    case "POPULATE_FILES": {
      return { ...state, webinarFiles: action.data };
    }
    case "UPDATEDPAYMENTPLAN" : {
      return {...state}
    }
    case "POPULATE_FAQS": {
      return { ...state, webinarFaqs: action.data };
    }
    case "GOTINSTRUCTORS": {
      return { ...state };
    }
    case "GOTPAYMENTPLAN": {
      return { ...state };
    }
    case "GOT_ADMIN_ALL_WEBINAR": {
      return {...state};
    }
    case "GOTWEBINARCOUNT": {
      return { ...state };
    }
    case "GOTCATEGORIES": {
      return { ...state };
    }
    case "GOTADMINWEBINARCOUNT": {
      return {...state};
    }
    case "GOTWEBINARBYID": {
      return { ...state, currentWebinar: action.data };
    }
    case "SETFILEFLAG": {
      return {
        ...state,
        FirstTimeSave: { ...state.FirstTimeSave, files: true },
      };
    }

    case "ASSESSMENT_UPDATED":
      return { ...state };
    default:
      return state;
  }
}
