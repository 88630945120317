import {createStore, applyMiddleware, compose} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import rootReducer from './redux';


const initialState = {};



const store = createStore(rootReducer, initialState, composeWithDevTools(
		applyMiddleware(thunk), 
		//window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__() 
	));

export default store;