import React, { Component } from "react";
import WebNotif from "../WebNotif";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MUIDataTable, { ExpandButton } from "mui-datatables";
import { TextField } from "@material-ui/core";
import Loader from "react-loader-spinner";
import moment from "moment";
import DatePicker from "react-datepicker";
import Select from "react-select";
import QualityCell from "./QualityCell";
import CpdTable from "./cpd_table";
import { connect } from "react-redux";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import {
  get_cpd_formdata,
  get_cpd_form_rows,
  get_cpd_form_rows_pr,
  get_cpd_form_rows_rejected,
  get_total_cpd_count,
  get_total_cpd_count_pr,
  get_total_cpd_count_rejected,
  get_claims_filter_data,
  updateCpdStatus,
  rejectCpdStatus,
  searchClaims,
  searchClaimsSecond,
} from "../../redux/admin/cpdActions/cpdactions";

var obj = new WebNotif();

class CPDClaims extends Component {
  state = {
    searchOptionsState: false,
    filterOptionsState: false,
    simpleOptionsState: true,
    modalIsOpen: false,
    originalData: [],
    data: [],
    count: 1,
    page: 0,
    pageState: 0,
    pendingPage: 0,
    approvedPage: 0,
    rejectedPage: 0,
    rowsExpanded: [],
    perPage: 25,
    limit: 25,
    setActiveTab: "Pending",
    allowPagination: true,
    searchType: [
      { label: "Email", value: "userinfo.PrimaryEmail" },
      { label: "Full Name", value: "userinfo.FullName" },
    ],
    selectedType: "",
    search: "",
    tableSearch: "",
    from_date: "",
    to_date: "",
    modalIsOpen: false,
    modalIsOpen1: false,
    loader: false,
    loader2: false,
    topNavPending: "",
    topNavApproved: "",
    topNavRejected: "",
    showActivePending: "",
    showActiveApproved: "",
    showActiveRejected: "",
    offset: 0,
    expand: true,
    filePath: "",
    filterList: [],
    formdata: {
      UserClaim: "",
      PecCalculator: "",
      ApprovedPoints: "",
      submissionID: "",
    },
  };
  //lifecycle
  constructor(props) {
    super(props);

    this.searchEmailInput = React.createRef();
    this.searchNameInput = React.createRef();
    this.searchTypeInput = React.createRef();
    this.searchTextInput = React.createRef();
    this.searchPecInput = React.createRef();
    this.searchDisciplineInput = React.createRef();
    this.fromDateInput = React.createRef();
    this.toDateInput = React.createRef();
    this.searchButton = React.createRef();

    this.searchFilter = this.searchFilter.bind(this);
    this.handleChange1 = this.handleChange1.bind(this);
    this.handleFromDate = this.handleFromDate.bind(this);
    this.handleToDate = this.handleToDate.bind(this);
    this.searchData = this.searchData.bind(this);
  }

  getCpdFormRows = () => {
    this.setState({ loader: true }, () => {
      this.props
        .get_cpd_form_rows({
          limit: this.state.limit,
          offset: this.state.offset,
        })
        .then((res) => {
          console.log("GET CPD FORM ROWS ....... ", res);
          let temp = [];
          for (let i = 0; i < res.data.length; i++) {
            res.data[i].date = moment(res.data[i].date).format("DD-MM-YYYY");
            res.data[i].FullName = res.data[i].FullName.split(" ")
              .map((s) => s.charAt(0).toUpperCase() + s.slice(1).toLowerCase())
              .join(" ");
            temp.push(Object.values(res.data[i]));
          }
          this.setState({
            loader: false,
            newdata: temp,
            newdata2: temp,
            newdata3: res.data,
          });
        })
        .catch((err) => {
          this.setState({
            loader: false,
          });
          console.log(err);
        });
    });
  };

  getCpdFormCount = () => {
    this.props
      .get_total_cpd_count()
      .then((res) => {
        let count = res.data;

        this.setState({ count: count }, () => {
          this.getCpdFormRows();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  componentDidMount() {
    if (this.props.location.state === undefined) {
      this.getCpdFormCount();
      this.setState({
        topNavPending: "active",
        topNavApproved: "",
        topNavRejected: "",
        showActivePending: "show active",
        showActiveApproved: "",
        showActiveRejected: "",
      });
    } else {
      if (this.props.location.state.pending) {
        this.loadPendingData();
        this.setState({
          topNavPending: "active",
          topNavApproved: "",
          topNavRejected: "",
          showActivePending: "show active",
          showActiveApproved: "",
          showActiveRejected: "",
        });
      }
      if (this.props.location.state.approved) {
        this.loadData();
        this.setState({
          topNavApproved: "active",
          topNavRejected: "",
          topNavPending: "",
          showActivePending: "",
          showActiveApproved: "show active",
          showActiveRejected: "",
        });
      }
      if (this.props.location.state.rejected) {
        this.loadDataRejected();
        this.setState({
          topNavRejected: "active",
          topNavPending: "",
          topNavApproved: "",
          showActivePending: "",
          showActiveApproved: "",
          showActiveRejected: "show active",
        });
      }
    }
  }

  componentDidUpdate(prevProp) {
    if (prevProp.location.state !== this.props.location.state) {
      if (this.props.location.state === undefined) {
        this.loadPendingData();
        this.setState({
          topNavPending: "active",
          topNavApproved: "",
          topNavRejected: "",
          showActivePending: "show active",
          showActiveApproved: "",
          showActiveRejected: "",
        });
      } else {
        if (this.props.location.state.pending) {
          this.loadPendingData();
          this.setState({
            topNavPending: "active",
            topNavApproved: "",
            topNavRejected: "",
            showActivePending: "show active",
            showActiveApproved: "",
            showActiveRejected: "",
          });
        }
        if (this.props.location.state.approved) {
          this.loadData();
          this.setState({
            topNavApproved: "active",
            topNavRejected: "",
            topNavPending: "",
            showActivePending: "",
            showActiveApproved: "show active",
            showActiveRejected: "",
          });
        }
        if (this.props.location.state.rejected) {
          this.loadDataRejected();
          this.setState({
            topNavRejected: "active",
            topNavPending: "",
            topNavApproved: "",
            showActivePending: "",
            showActiveApproved: "",
            showActiveRejected: "show active",
          });
        }
      }
    }
  }

  openModal1 = (userpoint, calc, submissionID) => {
    this.setState({
      formdata: {
        ...this.state.formdata,
        UserClaim: userpoint,
        PecCalculator: calc,
        submissionID: submissionID,
      },
    });

    this.setState({ modalIsOpen1: true }, () => {});
  };
  closeModal1 = () => {
    this.setState({
      modalIsOpen1: false,
      isEditable: false,
      formdata: {
        ...this.state.formdata,
        ApprovedPoints: "",
      },
    });
  };

  setpoints = (e, f) => {
    this.setState({
      formdata: {
        ...this.state.formdata,
        UserClaim: e,
        PecCalculator: f,
      },
    });
  };
  onChange = (e) => {
    this.setState({
      formdata: { ...this.state.formdata, [e.target.name]: e.target.value },
    });
  };

  changePageApproved = (page, sortOrder) => {
    this.setState(
      {
        offset: page * this.state.perPage,
        approvedPage: page,
      },

      () => {
        this.cpdrowRA();
      }
    );
  };

  resetFiltersApproved = () => {
    this.searchEmailInput.current.value = "";
    this.searchNameInput.current.value = "";
    this.searchPecInput.current.value = "";
    this.searchDisciplineInput.current.value = "";

    this.setState(
      {
        allowPagination: true,
      },
      () => {
        this.loadData();
      }
    );
  };

  resetFiltersPending = () => {
    this.searchEmailInput.current.value = "";
    this.searchNameInput.current.value = "";
    this.searchPecInput.current.value = "";
    this.searchDisciplineInput.current.value = "";

    this.setState(
      {
        allowPagination: true,
      },
      () => {
        this.loadPendingData();
      }
    );
  };

  resetFiltersRejected = () => {
    this.searchEmailInput.current.value = "";
    this.searchNameInput.current.value = "";
    this.searchPecInput.current.value = "";
    this.searchDisciplineInput.current.value = "";

    this.setState(
      {
        allowPagination: true,
      },
      () => {
        this.loadDataRejected();
      }
    );
  };

  setTableApprovedState = (page) => {
    this.setState({
      approvedPage: page,
    });
  };

  setTablePendingState = (page) => {
    this.setState({
      pendingPage: page,
    });
  };

  setTableRejectedState = (page) => {
    this.setState({
      rejectedPage: page,
    });
  };

  changePagePending = (page, sortOrder) => {
    this.setState(
      {
        offset: page * this.state.perPage,
        pendingPage: page,
      },

      () => {
        this.getCpdFormRows();
      }
    );
  };

  changePageRejected = (page, sortOrder) => {
    this.setState(
      {
        offset: page * this.state.perPage,
        rejectedPage: page,
      },

      () => {
        this.cpdrowRejected();
      }
    );
  };

  changeRowsApproved = (num) => {
    this.setState(
      {
        limit: num,
      },

      () => {
        this.cpdrowRA();
      }
    );
  };

  changeFilteredRowsApproved = (currentPage) => {
    this.setState(
      {
        offset: currentPage * this.state.perPage,
        approvedPage: currentPage,
      },
      () => {
        this.changeFilter();
      }
    );
  };

  changeRowsPending = (num) => {
    this.setState(
      {
        limit: num,
      },

      () => {
        this.getCpdFormRows();
      }
    );
  };

  changeRowsRejected = (num) => {
    this.setState(
      {
        limit: num,
      },

      () => {
        this.cpdrowRejected();
      }
    );
  };

  changePage2 = (page, sortOrder) => {
    this.setState(
      {
        offset: page * this.state.perPage,
      },
      () => {}
    );
  };

  handleChange1 = (event) => {
    this.setState({ ...this.state, [event.target.name]: event.target.value });
  };
  handleFromDate = (e) => {
    this.setState({ ...this.state, from_date: e });
  };
  handleToDate = (e) => {
    this.setState({ ...this.state, to_date: e });
  };
  changeFilter = (data) => {
    console.log("INSIDE CHANGE FILTER");
    let newdata = [];
    if (data) {
      console.log("INSIDE IF");
      data.map((item, index) => {
        if (!(item.length === 0)) {
          switch (index) {
            case 0: {
              newdata.push({ "cpd_form_submission.submission_id": item[0] });
              break;
            }
            case 1: {
              newdata.push({ "userinfo.FullName": item[0] });
              break;
            }
            case 2: {
              newdata.push({ "userinfo.PEC_REG_NO": item[0] });
              break;
            }
            case 3: {
              newdata.push({ "userinfo.discipline": item[0] });
              break;
            }
            case 4: {
              newdata.push({ "cpd_form_submission.category": item[0] });
              break;
            }
            case 5: {
              newdata.push({ "user_claims.field_value": item[0] });
              break;
            }
            case 6: {
              newdata.push({ "cpd_form_submission.cpd_points_pec": item[0] });
              break;
            }
            case 7: {
              newdata.push({ "cpd_form_submission.status": item[0] });
              break;
            }
          }
        }
      });
      if (newdata.length === 0) {
        this.setState({ newdata: this.state.newdata2, allowPagination: true });
      } else {
        let filterActiveTab = this.state.setActiveTab;
        this.setState({
          filterList: newdata,
        });

        newdata.push({ "cpd_form_submission.status": filterActiveTab });
        newdata.push({ limit: this.state.limit, offset: this.state.offset });

        this.props
          .get_claims_filter_data(newdata)
          .then((res) => {
            let filterCount = res.data.length;

            let temp = [];
            for (let i = 0; i < res.data.length; i++) {
              res.data[i].date = moment(res.data[i].date).format("DD-MM-YYYY");
              res.data[i].FullName = res.data[i].FullName.split(" ")
                .map(
                  (s) => s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()
                )
                .join(" ");
              temp.push(Object.values(res.data[i]));
            }

            this.setState(
              {
                filterOptionsState: true,
                simpleOptionsState: false,
                searchOptionsState: false,
                newdata: temp,
                allowPagination: true,
                count: filterCount,
                perPage: 25,
              },
              () => {}
            );
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else if (!data && this.state.filterList.length !== 0) {
      console.log("INSIDE ELSE");
      console.log("FILTER LIST: ", this.state.filterList);

      if (newdata.length === 0) {
        this.setState({ newdata: this.state.newdata2, allowPagination: true });
      } else {
        let filterActiveTab = this.state.setActiveTab;
        // this.setState({
        //   filterList: newdata
        // });

        // newdata.push({ "cpd_form_submission.status": filterActiveTab });
        // newdata.push({ limit: this.state.limit, offset: this.state.offset });

        this.props
          .get_claims_filter_data(this.state.filterList)
          .then((res) => {
            let filterCount = res.data.length;

            let temp = [];
            for (let i = 0; i < res.data.length; i++) {
              res.data[i].date = moment(res.data[i].date).format("DD-MM-YYYY");
              res.data[i].FullName = res.data[i].FullName.split(" ")
                .map(
                  (s) => s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()
                )
                .join(" ");
              temp.push(Object.values(res.data[i]));
            }

            this.setState(
              {
                filterOptionsState: true,
                simpleOptionsState: false,
                searchOptionsState: false,
                newdata: temp,
                allowPagination: true,
                count: filterCount,
                perPage: 25,
              },
              () => {}
            );
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };
  changeFilterPageApproved = (page, sortOrder, data) => {
    this.setState(
      {
        offset: page * this.state.perPage,
        approvedPage: page,
      },
      () => {
        this.changeFilter(data);
      }
    );
  };

  submitcpd = (e) => {
    e.preventDefault();
    this.props
      .updateCpdStatus({
        apporvedPoints: this.state.formdata.ApprovedPoints,
        SubmissionId: this.state.formdata.submissionID,
      })
      .then((res) => {
        obj.createNotification("success", "Status Updated Successfully");
        setTimeout(function () {
          window.location.reload();
        }, 1200);
      })
      .catch();
  };
  changeStatusFromChild = (val) => {
    let data = [...this.state.newdata];
    data.map((item, index) => {
      if (item[0] === val) {
        if (data[index][7] === "Pending") {
          data[index][7] = "Approved";
        } else if (data[index][7] === "Rejected") {
          data[index][7] = "Approved";
        }
      }
    });
    this.setState({ newdata: data });
  };
  rejectStatusFromChild = (val) => {
    let data = [...this.state.newdata];
    data.map((item, index) => {
      if (item[0] === val) {
        if (data[index][7] === "Pending") {
          data[index][7] = "Rejected";
        } else if (data[index][7] === "Approved") {
          data[index][7] = "Rejected";
        }
      }
    });
    this.setState({ newdata: data });
  };

  refreshPendingList = () => {
    if (this.state.searchOptionsState === true) {
      this.searchData();
      this.setState({
        rowsExpanded: [],
      });
    } else if (this.state.filterOptionsState === true) {
      this.changeFilter();
      this.setState({
        rowsExpanded: [],
      });
      console.log("NEW DATA: ", this.state.filterList);
    } else {
      this.getCpdFormRows();
      this.getCpdFormCount();
      this.setState({
        rowsExpanded: [],
      });
    }
  };

  refreshApprovedList = () => {
    if (this.state.searchOptionsState === true) {
      this.searchData();
      this.setState({
        rowsExpanded: [],
      });
    } else if (this.state.filterOptionsState === true) {
      this.changeFilter();
      this.setState({
        rowsExpanded: [],
      });
    } else {
      this.props
        .get_total_cpd_count_pr()
        .then((res) => {
          let count = res.data;

          this.setState({ count: count }, () => {
            this.cpdrowRA();
          });
        })
        .catch((err) => {
          console.log(err);
        });
      this.setState({
        rowsExpanded: [],
      });
    }
  };

  refreshRejectedList = () => {
    if (this.state.searchOptionsState === true) {
      this.searchData();
      this.setState({
        rowsExpanded: [],
      });
    } else if (this.state.filterOptionsState === true) {
      this.changeFilter();
      this.setState({
        rowsExpanded: [],
      });
    } else {
      this.props
        .get_total_cpd_count_rejected()
        .then((res) => {
          let count = res.data;

          this.setState({ count: count }, () => {});
        })
        .catch((err) => {
          console.log(err);
        });

      this.cpdrowRejected();
      this.setState({
        rowsExpanded: [],
      });
    }
  };

  loadPendingData = () => {
    this.searchEmailInput.current.value = "";
    this.searchNameInput.current.value = "";
    this.searchPecInput.current.value = "";
    this.searchDisciplineInput.current.value = "";

    (() => {
      this.setState({
        selectedType: "",
        search: "",
        from_date: "",
        to_date: "",
        page: 0,
        pendingPage: 0,
        approvedPage: 0,
        rejectedPage: 0,
        offset: 0,
        setActiveTab: "Pending",
        allowPagination: true,
        simpleOptionsState: true,
        searchOptionsState: false,
        filterOptionsState: false,
      });
    })();
    this.getCpdFormRows();
    this.getCpdFormCount();
    if (this.searchTypeInput?.current?.state?.value?.value !== undefined) {
      this.searchTextInput.current.value = "";
    }
  };
  loadData = (e) => {
    this.searchEmailInput.current.value = "";
    this.searchNameInput.current.value = "";
    this.searchPecInput.current.value = "";
    this.searchDisciplineInput.current.value = "";

    (() => {
      this.setState({
        selectedType: "",
        search: "",
        from_date: "",
        to_date: "",
        page: 0,
        pendingPage: 0,
        approvedPage: 0,
        rejectedPage: 0,
        offset: 0,
        setActiveTab: "Approved",
        allowPagination: true,
        simpleOptionsState: true,
        searchOptionsState: false,
        filterOptionsState: false,
      });
    })();
    this.props
      .get_total_cpd_count_pr()
      .then((res) => {
        let count = res.data;

        this.setState({ count: count }, () => {
          this.cpdrowRA();
        });
      })
      .catch((err) => {
        console.log(err);
      });

    if (this.searchTypeInput?.current?.state?.value?.value !== undefined) {
      this.searchTextInput.current.value = "";
    }
  };

  loadDataRejected = (e) => {
    this.searchEmailInput.current.value = "";
    this.searchNameInput.current.value = "";
    this.searchPecInput.current.value = "";
    this.searchDisciplineInput.current.value = "";

    (() => {
      this.setState({
        selectedType: "",
        search: "",
        from_date: "",
        to_date: "",
        page: 0,
        pendingPage: 0,
        approvedPage: 0,
        rejectedPage: 0,
        offset: 0,
        setActiveTab: "Rejected",
        allowPagination: true,
        simpleOptionsState: true,
        searchOptionsState: false,
        filterOptionsState: false,
      });
    })();
    this.props
      .get_total_cpd_count_rejected()
      .then((res) => {
        let count = res.data;

        this.setState({ count: count }, () => {});
      })
      .catch((err) => {
        console.log(err);
      });

    this.cpdrowRejected();

    if (this.searchTypeInput?.current?.state?.value?.value !== undefined) {
      this.searchTextInput.current.value = "";
    }
  };

  cpdrowRejected = () => {
    this.setState(
      {
        loader2: true,
      },
      () => {
        this.props
          .get_cpd_form_rows_rejected({
            limit: this.state.limit,
            offset: this.state.offset,
          })
          .then((res) => {
            let temp = [];
            for (let i = 0; i < res.data.length; i++) {
              res.data[i].date = moment(res.data[i].date).format("DD-MM-YYYY");
              res.data[i].FullName = res.data[i].FullName.split(" ")
                .map(
                  (s) => s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()
                )
                .join(" ");
              temp.push(Object.values(res.data[i]));
            }
            this.setState({
              loader2: false,
              newdata: temp,
              newdata2: temp,
            });
          })
          .catch((err) => {
            this.setState({ loader2: false });
            console.log(err);
          });
      }
    );
  };

  searchFilter = (selectedOption) => {
    this.setState({
      selectedType: selectedOption,
      // searchOptionsState: true,
      // filterOptionsState: false,
      // simpleOptionsState: false,
    });
  };

  searchData = () => {
    let searchEmail = this.searchEmailInput?.current?.value;

    let searchName = this.searchNameInput?.current?.value;

    let searchTextValue = this.searchTextInput?.current?.value;

    let searchPecValue = this.searchPecInput?.current?.value;

    let searchDisciplineValue = this.searchDisciplineInput?.current?.value;

    let tabActive = this.state.setActiveTab;

    if (
      searchEmail ||
      searchName ||
      (searchTextValue && this.state.selectedType !== "") ||
      searchPecValue ||
      searchDisciplineValue
    ) {
      this.setState(
        {
          loader2: true,
        },
        () => {
          let data = [];

          let searchTypeValue = this.state.selectedType;

          data.push({ type: searchTypeValue });
          data.push({ search: searchTextValue });
          data.push({ email: searchEmail });
          data.push({ name: searchName });
          data.push({ pecNo: searchPecValue });
          data.push({ discipline: searchDisciplineValue });

          let newdata = [];
          let itemType = "";
          data.map((item, index) => {
            itemType = item["type"]?.label;

            if (!(item.length === 0)) {
              switch (index) {
                case 0: {
                  if (itemType && itemType === "Email") {
                    newdata.push({ "userinfo.PrimaryEmail": searchTextValue });
                  } else if (itemType && itemType === "Full Name") {
                    newdata.push({ "userinfo.FullName": searchTextValue });
                  }
                  break;
                }
                // case 1: {
                //   if (item['discipline'] !== "") {
                //     newdata.push({ "userinfo.discipline": item['discipline'] });
                //   }
                //   break;
                // }
                case 2: {
                  if (item["email"]) {
                    newdata.push({ "userinfo.PrimaryEmail": item["email"] });
                  }
                  break;
                }
                case 3: {
                  if (item["name"]) {
                    newdata.push({ "userinfo.FullName": item["name"] });
                  }
                  break;
                }
                case 4: {
                  if (item["pecNo"]) {
                    newdata.push({ "userinfo.PEC_REG_NO": item["pecNo"] });
                  }
                  break;
                }
                case 5: {
                  if (item["discipline"]) {
                    newdata.push({ "userinfo.discipline": item["discipline"] });
                  }
                  break;
                }
              }
            }
          });

          let filterActiveTab = this.state.setActiveTab;

          newdata.push({ "cpd_form_submission.status": filterActiveTab });

          if (
            (this.state.from_date === "" && this.state.to_date === "") ||
            (this.state.from_date === null && this.state.to_date === null)
          ) {
            newdata.push({
              from: moment(this.state.from_date).format("YYYY-MM-DD"),
            });
            newdata.push({
              to: moment(this.state.to_date).format("YYYY-MM-DD"),
            });
          } else if (
            this.state.from_date === "" ||
            this.state.to_date === "" ||
            this.state.from_date === null ||
            this.state.to_date === null
          ) {
            newdata.push({
              from: moment(this.state.from_date).format("YYYY-MM-DD"),
            });
            newdata.push({
              to: moment(this.state.to_date).format("YYYY-MM-DD"),
            });
          } else {
            let day = 60 * 60 * 24 * 1000;
            let fromDate = new Date(this.state.from_date.getTime());
            let toDate = new Date(this.state.to_date.getTime() + day);

            newdata.push({
              from: moment(fromDate).format("YYYY-MM-DD"),
            });
            newdata.push({ to: moment(toDate).format("YYYY-MM-DD") });
          }

          this.props
            .searchClaims(newdata)
            .then((res) => {
              let searchLength = res.data.length;
              let temp = [];
              for (let i = 0; i < res.data.length; i++) {
                res.data[i].date = moment(res.data[i].date).format(
                  "DD-MM-YYYY"
                );
                temp.push(Object.values(res.data[i]));
              }
              this.setState({
                loader2: false,
                newdata: temp,
                newdata2: temp,
                count: searchLength,
                allowPagination: true,
                searchOptionsState: true,
                filterOptionsState: false,
                simpleOptionsState: false,
              });
            })
            .catch((err) => {
              this.setState({
                loader: false,
              });
              console.log(err);
            });
        }
      );
    }
  };

  searchSecond = () => {
    let searchPecValue = this.searchPecInput?.current?.value;

    let searchDisciplineValue = this.searchDisciplineInput?.current?.value;

    let tabActive = this.state.setActiveTab;

    if (searchPecValue || searchDisciplineValue) {
      this.setState(
        {
          loader2: true,
        },
        () => {
          let data = [];

          data.push({ pecNo: searchPecValue });
          data.push({ discipline: searchDisciplineValue });

          let newdata = [];
          data.map((item, index) => {
            if (!(item.length === 0)) {
              switch (index) {
                case 0: {
                  if (item["pecNo"] !== "") {
                    newdata.push({ "userinfo.PEC_REG_NO": item["pecNo"] });
                  }
                  break;
                }
                case 1: {
                  if (item["discipline"] !== "") {
                    newdata.push({ "userinfo.discipline": item["discipline"] });
                  }
                  break;
                }
              }
            }
          });

          let filterActiveTab = this.state.setActiveTab;

          newdata.push({ "cpd_form_submission.status": filterActiveTab });

          this.props
            .searchClaimsSecond(newdata)
            .then((res) => {
              let searchLength = res.data.length;
              let temp = [];
              for (let i = 0; i < res.data.length; i++) {
                res.data[i].date = moment(res.data[i].date).format(
                  "DD-MM-YYYY"
                );
                temp.push(Object.values(res.data[i]));
              }
              this.setState({
                loader2: false,
                newdata: temp,
                newdata2: temp,
                count: searchLength,
                allowPagination: true,
                searchOptionsState: true,
                filterOptionsState: false,
                simpleOptionsState: false,
              });
            })
            .catch((err) => {
              this.setState({
                loader: false,
              });
              console.log(err);
            });
        }
      );
    }
  };

  cpdrowRA = () => {
    this.setState(
      {
        loader2: true,
      },
      () => {
        this.props
          .get_cpd_form_rows_pr({
            limit: this.state.limit,
            offset: this.state.offset,
          })
          .then((res) => {
            let temp = [];
            for (let i = 0; i < res.data.length; i++) {
              res.data[i].date = moment(res.data[i].date).format("DD-MM-YYYY");
              res.data[i].FullName = res.data[i].FullName.split(" ")
                .map(
                  (s) => s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()
                )
                .join(" ");
              temp.push(Object.values(res.data[i]));
            }
            this.setState(
              {
                loader2: false,
                newdata: temp,
                newdata2: temp,
              },
              () => {}
            );
          })
          .catch((err) => {
            this.setState({ loader2: false });
            console.log(err);
          });
      }
    );
  };

  changePageSearch = (tableState) => {
    this.setState(
      {
        filterOptionsState:
          tableState.searchText === null ||
          tableState.searchText === "" ||
          !tableState.searchText
            ? false
            : true,
        searchOptionsState:
          tableState.searchText === null ||
          tableState.searchText === "" ||
          !tableState.searchText
            ? false
            : true,
        allowPagination:
          tableState.searchText === null ||
          tableState.searchText === "" ||
          !tableState.searchText
            ? true
            : false,
      },
      () => {}
    );
  };

  render() {
    const columns = [
      {
        name: "Submission ID",
        options: {
          filter: false,
          display: false,
        },
      },
      {
        name: "Name",
        options: {
          filter: false,
        },
      },
      {
        name: "PEC No",
        options: {
          filter: true,
        },
      },
      {
        name: "Discipline",
        options: {
          filter: false,
        },
      },
      {
        name: "Category",
        options: {
          filter: true,
        },
      },
      // {
      //   name: "Institution/PEB",
      //   options: {
      //     filter: true,
      //     customBodyRender: (value) => (value === null ? "Not Found" : value),
      //   },
      // },
      {
        name: "CPD Points",
        options: {
          filter: true,
        },
      },
      {
        name: "Date of Submission",
        options: {
          filter: false,
        },
      },

      {
        name: "Status",
        options: {
          filter: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <QualityCell
                value={value}
                index={tableMeta.columnIndex}
                change={(event) => updateValue(event)}
              />
            );
          },
        },
        customBodyRender: (value, tableMeta, updateValue) => (
          <FormControlLabel
            value={value}
            Style={{ color: "red" }}
            control={<TextField Style={{ color: "red" }} value={value} />}
            onChange={(event) => updateValue(event.target.value)}
          />
        ),
      },

      {
        name: "UserID",
        options: {
          filter: false,
          display: false,
        },
      },
      {
        name: "claim_date",
        options: {
          filter: false,
          display: false,
        },
      },
      {
        name: "certificate_number",
        options: {
          filter: false,
          display: false,
        },
      },
    ];

    const theme = createMuiTheme({
      overrides: {
        MUIDataTableSelectCell: {
          expandDisabled: {
            visibility: "hidden",
          },
        },
        MUIDataTableHeadCell: {
          // 473
          fixedHeader: {
            background: "#e7e7e7 !important",
          },
        },
        MUIDataTableSelectCell: {
          headerCell: {
            background: "#e7e7e7 !important",
          },
        },
        MUIDataTableToolbar: {
          filterPaper: {
            width: "450px !important",
            height: "auto !important",
          },
        },
      },
    });
    const components = {
      ExpandButton: function (props) {
        if (props.dataIndex === 3 || props.dataIndex === 4)
          return <div style={{ width: "24px" }} />;
        return <ExpandButton {...props} />;
      },
    };
    return (
      <React.Fragment>
        <h4 className="regular_font fs-26 mtb-35">CPD Claims</h4>
        <ul
          className="nav nav-tabs adm_desc_nav_tabs"
          id="myTab"
          role="tablist"
        >
          <li className="nav-item pill">
            <a
              className={
                "nav-link  admin_desc_navlink " + this.state.topNavPending
              }
              id="profile-tab"
              data-toggle="tab"
              href="#pending"
              role="tab"
              aria-controls="pending"
              aria-selected="false"
              onClick={this.loadPendingData}
            >
              Pending Claims
            </a>
          </li>
          <li className="nav-item pill">
            <a
              className={
                "nav-link  admin_desc_navlink " + this.state.topNavApproved
              }
              id="home-tab"
              data-toggle="tab"
              href="#approvedrej"
              role="tab"
              aria-controls="approvedrej"
              aria-selected="true"
              onClick={this.loadData}
            >
              Approved Claims
            </a>
          </li>
          <li className="nav-item pill">
            <a
              className={
                "nav-link  admin_desc_navlink " + this.state.topNavRejected
              }
              id="home-tab"
              data-toggle="tab"
              href="#rejected"
              role="tab"
              aria-controls="rejected"
              aria-selected="true"
              onClick={this.loadDataRejected}
            >
              Rejected Claims
            </a>
          </li>
        </ul>

        <hr></hr>
        <div className="col-md-12">
          <div className="row">
            {/* <div className="col-md-2 ">
              <label className="bm-0">Type</label>
              <Select
                className=" mtb-10"
                options={this.state.searchType}
                value={this.state.selectedType}
                onChange={this.searchFilter}
                ref={this.searchTypeInput}
              />
            </div> */}

            <div className="col-md-2">
              <label className="bm-0">Email</label>
              <div className="input-group md-form form-sm form-2 mtb-10">
                <input
                  type="text"
                  className="form-control"
                  name="search"
                  // value={this.state.search}
                  // onChange={this.handleTextInput}
                  ref={this.searchEmailInput}
                  placeholder={"Search By Email"}
                  aria-label="Text input with dropdown button"
                />
              </div>
            </div>

            <div className="col-md-2">
              <label className="bm-0">Full Name</label>
              <div className="input-group md-form form-sm form-2 mtb-10">
                <input
                  type="text"
                  className="form-control"
                  name="search"
                  // value={this.state.search}
                  // onChange={this.handleTextInput}
                  ref={this.searchNameInput}
                  placeholder={"Search By Name"}
                  aria-label="Text input with dropdown button"
                />
              </div>
            </div>

            {/* <div className="col-md-2 ">
              <label className="bm-0">Email Or Name</label>
              <div className="input-group md-form form-sm form-2 mtb-10">
                <input
                  type="text"
                  className="form-control"
                  name="search"
                  ref={this.searchTextInput}
                  // value={this.state.search}
                  placeholder={"Search By Value"}
                  aria-label="Text input with dropdown button"
                  // onChange={this.handleChange1}
                />
              </div>
            </div> */}
            <div className="col-md-2">
              <label className="bm-0">From</label>
              <div className="mtb-10">
                <DatePicker
                  className="form-control cat-size "
                  name="from_date"
                  ref={this.fromDateInput}
                  selected={this.state.from_date}
                  onChange={this.handleFromDate}
                  minDate={new Date("2010/07/01")}
                  maxDate={new Date()}
                />
              </div>
            </div>
            <div className="col-md-2">
              <label className="bm-0">To</label>
              <div className="mtb-10">
                <DatePicker
                  className="form-control cat-size "
                  name="to_date"
                  ref={this.toDateInput}
                  selected={this.state.to_date}
                  maxDate={new Date()}
                  minDate={new Date("2010/07/01")}
                  onChange={this.handleToDate}
                />
              </div>
            </div>
            <div className="col-md-2">
              <label className="bm-0">PEC No</label>
              <div className="input-group md-form form-sm form-2 mtb-10">
                <input
                  type="text"
                  className="form-control"
                  name="search"
                  // value={this.state.search}
                  // onChange={this.handleTextInput}
                  ref={this.searchPecInput}
                  placeholder={"Search By PEC No"}
                  aria-label="Text input with dropdown button"
                />
              </div>
            </div>

            <div className="col-md-2">
              <label className="bm-0">Discipline</label>
              <div className="input-group md-form form-sm form-2 mtb-10">
                <input
                  type="text"
                  className="form-control"
                  name="search"
                  // value={this.state.search}
                  // onChange={this.handleTextInput}
                  ref={this.searchDisciplineInput}
                  placeholder={"Search By Discipline"}
                  aria-label="Text input with dropdown button"
                />
              </div>
            </div>
          </div>
          {/* <hr /> */}
          <div className="row">
            <div className="col-md-2">
              <label className="bm-0"></label>
              <button
                className="btn admin_btn white w-150p float-right bold mtb-10"
                // ref={this.searchButton}
                onClick={() => {
                  this.searchData();
                }}
              >
                Search
              </button>
            </div>
            <div className="col-md-2">
              <label className="bm-0"></label>
              <button
                className="btn admin_btn_new text-center white w-150p float-right bold mtb-10"
                onClick={() => {
                  this.state.setActiveTab === "Pending"
                    ? this.resetFiltersPending()
                    : this.state.setActiveTab === "Approved"
                    ? this.resetFiltersApproved()
                    : this.resetFiltersRejected();
                }}
              >
                Reset
              </button>
            </div>
            {/* <div className='col-md-2'>
                  <label className='bm-0'></label>
                  <button
                    className='btn admin_btn white w-150p float-right bold mtb-10'
                    // ref={this.searchButton}
                    onClick={() => {
                      this.searchSecond();
                    }}
                  >
                    Search
                  </button>
            </div> */}
          </div>
        </div>
        <hr></hr>
        <div className="tab-content" id="myTabContent">
          <div
            className={"tab-pane fs-14 fade " + this.state.showActiveApproved}
            id="approvedrej"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <div className="mtb-25">
              {this.state.loader2 === true ? (
                <div className="loaderDiv text-center">
                  <Loader type="Oval" color="#06580e" height={80} width={80} />
                </div>
              ) : (
                <>
                  {/* {console.log("THIS NEW DATAAA ... ", this.state.newdata)} */}
                  <MuiThemeProvider theme={theme}>
                    <MUIDataTable
                      data={this.state.newdata}
                      columns={columns}
                      options={{
                        responsive: "vertical",
                        tableBodyHeight: "600px",
                        tableBodyMaxHeight: "",
                        pagination: this.state.allowPagination,
                        serverSide:
                          this.state.filterOptionsState ||
                          this.state.searchOptionsState
                            ? false
                            : true,
                        filter: true,
                        rowsPerPage: this.state.perPage,
                        rowsPerPageOptions: [25, 50, 75, 100],
                        count: this.state.count,
                        page: this.state.approvedPage,
                        jumpToPage:
                          this.state.filterOptionsState ||
                          this.state.searchOptionsState
                            ? false
                            : true,
                        filterType: "dropdown",
                        expandableRows: true,
                        rowsExpanded: this.state.rowsExpanded,
                        expandableRowsHeader: false,
                        expandableRowsOnClick: this.state.expand,
                        customToolbarSelect: () => {},
                        selectableRows: false,
                        renderExpandableRow: (data, rowMeta) => {
                          console.log("RENDER FOR EXPAND ... ", data);
                          return (
                            <CpdTable
                              id={data[0]}
                              category={data[4]}
                              sendData={this.changeStatusFromChild}
                              rejectStatus={this.rejectStatusFromChild}
                              permissions={this.props.permissions}
                              refreshList={this.refreshApprovedList}
                              pecNo={data[2]}
                              UserID={data[8]}
                              claimDate={data[9]}
                              certificateNumber={data[10]}
                              dateOfSubmission={data[6]}
                            />
                          );
                        },
                        onChangeRowsPerPage: (num) => {
                          if (
                            this.state.filterOptionsState === true ||
                            this.state.searchOptionsState === true
                          ) {
                          } else {
                            this.setState({ perPage: num, approvedPage: 0 });
                            this.changeRowsApproved(num);
                          }
                        },
                        onFilterChange: (changedColumn, filterList, type) => {
                          if (type === "reset") {
                            this.resetFiltersApproved();
                          }
                        },
                        onFilterChipClose: (
                          index,
                          removedFilter,
                          filterList
                        ) => {
                          let temp = [];
                          filterList.map((value, index) => {
                            for (let i = 0; i <= value.length; i++) {
                              temp.push(i);
                            }
                          });

                          if (temp.length === 9) {
                            this.resetFiltersApproved();
                          }
                        },
                        onTableChange: (action, tableState) => {
                          (this.state.filterOptionsState === true ||
                            this.state.searchOptionsState === true) &&
                          action === "changePage"
                            ? this.setTableApprovedState(tableState.page)
                            : action === "changePage"
                            ? this.changePageApproved(
                                tableState.page,
                                tableState.sortOrder
                              )
                            : "";

                          if (action === "filterChange") {
                            this.changeFilter(tableState.filterList);
                          }
                          if (action === "rowExpansionChange") {
                            if (
                              this.state.filterOptionsState === true ||
                              this.state.searchOptionsState === true
                            ) {
                              var page = tableState.page * this.state.limit;
                              var rowsExpanded =
                                tableState.expandedRows.data.map(
                                  (item) => item.index + page
                                );
                              if (rowsExpanded.length > 1) {
                                rowsExpanded = rowsExpanded.slice(-1);
                              }

                              this.setState((prevState) => ({
                                rowsExpanded: rowsExpanded,
                              }));
                            } else {
                              var rowsExpanded =
                                tableState.expandedRows.data.map(
                                  (item) => item.index
                                );
                              if (rowsExpanded.length > 1) {
                                rowsExpanded = rowsExpanded.slice(-1);
                              }

                              this.setState((prevState) => ({
                                rowsExpanded: rowsExpanded,
                              }));
                            }
                          }
                          if (action === "search") {
                            this.changePageSearch(tableState);
                          }
                        },
                      }}
                    />
                  </MuiThemeProvider>
                </>
              )}
            </div>
          </div>
          <div
            className={"tab-pane fs-14 fade " + this.state.showActivePending}
            id="pending"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <div className="mtb-25">
              {this.state.loader === true ? (
                <div className="loaderDiv text-center">
                  <Loader type="Oval" color="#06580e" height={80} width={80} />
                </div>
              ) : (
                <>
                  <MuiThemeProvider theme={theme}>
                    <MUIDataTable
                      data={this.state.newdata}
                      columns={columns}
                      options={{
                        responsive: "vertical",
                        tableBodyHeight: "600px",
                        tableBodyMaxHeight: "",
                        pagination: this.state.allowPagination,
                        serverSide:
                          this.state.filterOptionsState ||
                          this.state.searchOptionsState
                            ? false
                            : true,
                        filter: true,
                        rowsPerPage: this.state.perPage,
                        rowsPerPageOptions: [25, 50, 75, 100],
                        count: this.state.count,
                        page: this.state.pendingPage,
                        jumpToPage:
                          this.state.filterOptionsState ||
                          this.state.searchOptionsState
                            ? false
                            : true,
                        filterType: "dropdown",
                        expandableRows: true,
                        rowsExpanded: this.state.rowsExpanded,
                        expandableRowsHeader: false,
                        expandableRowsOnClick: this.state.expand,
                        customToolbarSelect: () => {},
                        selectableRows: false,
                        renderExpandableRow: (data, rowMeta) => {
                          return (
                            <CpdTable
                              id={data[0]}
                              category={data[4]}
                              sendData={this.changeStatusFromChild}
                              rejectStatus={this.rejectStatusFromChild}
                              permissions={this.props.permissions}
                              refreshList={this.refreshPendingList}
                              pecNo={data[2]}
                              UserID={data[8]}
                              claimDate={data[9]}
                              dateOfSubmission={data[6]}
                            />
                          );
                        },
                        onChangeRowsPerPage: (num) => {
                          if (
                            this.state.filterOptionsState === true ||
                            this.state.searchOptionsState === true
                          ) {
                          } else {
                            this.setState({ perPage: num, pendingPage: 0 });
                            this.changeRowsPending(num);
                          }
                        },
                        onFilterChange: (changedColumn, filterList, type) => {
                          if (type === "reset") {
                            this.resetFiltersPending();
                          }
                        },
                        onFilterChipClose: (
                          index,
                          removedFilter,
                          filterList
                        ) => {
                          let temp = [];
                          filterList.map((value, index) => {
                            for (let i = 0; i <= value.length; i++) {
                              temp.push(i);
                            }
                          });

                          if (temp.length === 9) {
                            this.resetFiltersPending();
                          }
                        },
                        onTableChange: (action, tableState) => {
                          (this.state.filterOptionsState === true ||
                            this.state.searchOptionsState === true) &&
                          action === "changePage"
                            ? this.setTablePendingState(tableState.page)
                            : action === "changePage"
                            ? this.changePagePending(
                                tableState.page,
                                tableState.sortOrder
                              )
                            : "";

                          if (action === "filterChange") {
                            this.changeFilter(tableState.filterList);
                          }
                          if (action === "search") {
                            this.changePageSearch(tableState);
                          }
                          if (action === "rowExpansionChange") {
                            if (
                              this.state.filterOptionsState === true ||
                              this.state.searchOptionsState === true
                            ) {
                              var page = tableState.page * this.state.limit;
                              var rowsExpanded =
                                tableState.expandedRows.data.map(
                                  (item) => item.index + page
                                );
                              if (rowsExpanded.length > 1) {
                                rowsExpanded = rowsExpanded.slice(-1);
                              }

                              this.setState((prevState) => ({
                                rowsExpanded: rowsExpanded,
                              }));
                            } else {
                              var rowsExpanded =
                                tableState.expandedRows.data.map(
                                  (item) => item.index
                                );
                              if (rowsExpanded.length > 1) {
                                rowsExpanded = rowsExpanded.slice(-1);
                              }

                              this.setState((prevState) => ({
                                rowsExpanded: rowsExpanded,
                              }));
                            }
                          }
                        },
                      }}
                    />
                  </MuiThemeProvider>
                </>
              )}
            </div>
          </div>
          <div
            className={"tab-pane fs-14 fade " + this.state.showActiveRejected}
            id="rejected"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <div className="mtb-25">
              {this.state.loader === true ? (
                <div className="loaderDiv text-center">
                  <Loader type="Oval" color="#06580e" height={80} width={80} />
                </div>
              ) : (
                <>
                  <MuiThemeProvider theme={theme}>
                    <MUIDataTable
                      data={this.state.newdata}
                      columns={columns}
                      options={{
                        responsive: "vertical",
                        tableBodyHeight: "600px",
                        tableBodyMaxHeight: "",
                        pagination: this.state.allowPagination,
                        serverSide:
                          this.state.filterOptionsState ||
                          this.state.searchOptionsState
                            ? false
                            : true,
                        filter: true,
                        rowsPerPage: this.state.perPage,
                        rowsPerPageOptions: [25, 50, 75, 100],
                        count: this.state.count,
                        page: this.state.rejectedPage,
                        jumpToPage:
                          this.state.filterOptionsState ||
                          this.state.searchOptionsState
                            ? false
                            : true,
                        filterType: "dropdown",
                        expandableRows: true,
                        rowsExpanded: this.state.rowsExpanded,
                        expandableRowsHeader: false,
                        expandableRowsOnClick: this.state.expand,
                        customToolbarSelect: () => {},
                        selectableRows: false,
                        renderExpandableRow: (data, rowMeta) => {
                          return (
                            <CpdTable
                              id={data[0]}
                              category={data[4]}
                              sendData={this.changeStatusFromChild}
                              rejectStatus={this.rejectStatusFromChild}
                              permissions={this.props.permissions}
                              refreshList={this.refreshRejectedList}
                              pecNo={data[2]}
                              UserID={data[8]}
                              claimDate={data[9]}
                              dateOfSubmission={data[6]}
                            />
                          );
                        },
                        onChangeRowsPerPage: (num) => {
                          if (
                            this.state.filterOptionsState === true ||
                            this.state.searchOptionsState === true
                          ) {
                          } else {
                            this.setState({ perPage: num, rejectedPage: 0 });
                            this.changeRowsRejected(num);
                          }
                        },
                        onFilterChange: (changedColumn, filterList, type) => {
                          if (type === "reset") {
                            this.resetFiltersRejected();
                          }
                        },
                        onFilterChipClose: (
                          index,
                          removedFilter,
                          filterList
                        ) => {
                          let temp = [];
                          filterList.map((value, index) => {
                            for (let i = 0; i <= value.length; i++) {
                              temp.push(i);
                            }
                          });

                          if (temp.length === 9) {
                            this.resetFiltersRejected();
                          }
                        },
                        onTableChange: (action, tableState) => {
                          (this.state.filterOptionsState === true ||
                            this.state.searchOptionsState === true) &&
                          action === "changePage"
                            ? this.setTableRejectedState(tableState.page)
                            : action === "changePage"
                            ? this.changePageRejected(
                                tableState.page,
                                tableState.sortOrder
                              )
                            : "";

                          if (action === "filterChange") {
                            this.changeFilter(tableState.filterList);
                          }
                          if (action === "search") {
                            this.changePageSearch(tableState);
                          }
                          if (action === "rowExpansionChange") {
                            if (
                              this.state.filterOptionsState === true ||
                              this.state.searchOptionsState === true
                            ) {
                              var page = tableState.page * this.state.limit;
                              var rowsExpanded =
                                tableState.expandedRows.data.map(
                                  (item) => item.index + page
                                );
                              if (rowsExpanded.length > 1) {
                                rowsExpanded = rowsExpanded.slice(-1);
                              }

                              this.setState((prevState) => ({
                                rowsExpanded: rowsExpanded,
                              }));
                            } else {
                              var rowsExpanded =
                                tableState.expandedRows.data.map(
                                  (item) => item.index
                                );
                              if (rowsExpanded.length > 1) {
                                rowsExpanded = rowsExpanded.slice(-1);
                              }

                              this.setState((prevState) => ({
                                rowsExpanded: rowsExpanded,
                              }));
                            }
                          }
                        },
                      }}
                    />
                  </MuiThemeProvider>
                </>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    cpdData: state.CPDData.cpddata,
    // permissions: state.userData.admin_permissions,
  };
}

export default connect(mapStateToProps, {
  get_cpd_formdata,
  updateCpdStatus,
  get_cpd_form_rows,
  get_cpd_form_rows_pr,
  get_cpd_form_rows_rejected,
  get_claims_filter_data,
  get_total_cpd_count,
  get_total_cpd_count_pr,
  get_total_cpd_count_rejected,
  rejectCpdStatus,
  searchClaims,
  searchClaimsSecond,
})(CPDClaims);
