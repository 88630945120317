import React from "react";
import { Link } from "react-router-dom";
import WebNotif from "../components/WebNotif";
import axios from "axios";
import { urlapi } from "../config/urlapi";
import logo from "../assets/image/PEC.png";
import Select from "react-select";
import img1 from "../assets/image/PEC.png";
import img2 from "../assets/image/white.png";
var obj = new WebNotif();

class Footer extends React.Component {
  state = {
    email: "",
    profession: "",
    select: { value: "", label: "Select Your Profession" },
    cat1Set: [
      { value: "Electrical Engineer", label: "Electrical Engineer" },
      { value: "Civil Engineer", label: "Civil Engineer" },
      { value: "Mechanical Engineer", label: "Mechanical Engineer" },
      { value: "Sofware Engineer", label: "Software Engineer" },
      { value: "Electronics Engineer", label: "Electronics Engineer" },
      { value: "Computer Engineer", label: "Computer Engineer" },
      { value: "Telecom Engineer", label: "Telecom Engineer" },
    ],
    selectedOption: "",
  };

  statechange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleDropdownChange = (selectedOption) => {
    this.setState({
      selectedOption: selectedOption.label,
      select: {
        label: selectedOption.label,
        value: selectedOption.value,
      },
    });
  };
  onsub = (event) => {
    event.preventDefault();
    if (this.state.email == "" || this.state.selectedOption === "") {
      obj.createNotification("warning", "Fill the required fields.");
    } else {
      var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (this.state.email.match(mailformat)) {
        axios
          .post(urlapi + "/admin/subscription", {
            email: this.state.email,
            profession: this.state.profession,
          })
          .then((res) => {
            obj.createNotification(
              "success",
              "You have successfully subscribed to CPD PEC."
            );
            this.setState({ email: "" });
          })
          .catch((err) => this.setState({ loading: false }));
      } else {
        obj.createNotification("warning", "Please enter a correct email.");
      }
    }
  };
  render() {
    return (
      <React.Fragment>
        <footer className="footer">
          <div className="container bottom_border none_other">
            <div className="row">
              <div className=" col-md-3 col-sm-12 col-xs-12">
                <div className="row">
                  <div className="col-3">
                    <img
                      src={img1}
                      alt="LOGO"
                      className="pecxlogo"
                      style={{
                        paddingTop: "70px",
                        width: "50px",
                        height: "auto",
                      }}
                    />
                  </div>
                  <div className="col-9">
                    <h1 className="headin5_amrc col_white_amrc pt2">CPD</h1>
                    <hr className="hr_row" />
                    <p className="co_white">
                      Pakistan Engineering Council (PEC) under PEC Act and CPD
                      Byelaws-2008, is earnestly working for the professional
                      growth and skill enhancement of ever growing engineering
                      community.
                    </p>
                  </div>
                </div>
              </div>
              <div className=" col-md-3 col-sm-12 col-xs-12">
                <h1 className="headin5_amrc col_white_amrc pt2">Navigation</h1>
                <hr className="hr_row" />
                <ul className="footer_ul_amrc">
                  <li>
                    <Link to="/ContactUS">Contact Us</Link>
                  </li>
                  <li>
                    <a href="/#upcomingCourses">Upcomming Courses</a>
                  </li>
                  <li>
                    <Link to="/WebinarSearch/livewebinar">Live Webinars</Link>
                  </li>
                  {/* <li><a href="#">News and Updates</a></li> */}
                  <li>
                    <Link to="/album">Gallery</Link>
                  </li>
                  <li>
                    <Link to="/CpdProfile">CPD Profile</Link>
                  </li>
                </ul>
              </div>
              <div className=" col-md-3 col-sm-12 col-xs-12">
                <h1 className="headin5_amrc col_white_amrc pt2">Contact</h1>
                <hr className="hr_row" />
                <div className="row Pt_10">
                  <div className="col-1">
                    <i className="fas fa-map-marker-alt co_white mt_10"></i>
                  </div>
                  <div className="col-10 pt_3">
                    <a
                      target="_blank"
                      href="https://www.google.com/maps/place/Pakistan+Engineering+Council/@33.7239847,73.0909047,17z/data=!3m1!4b1!4m5!3m4!1s0x38dfc07e98fce659:0xbf9fcfb8daab700b!8m2!3d33.7239803!4d73.0930934"
                      className="fz_12 co_white a_footer"
                    >
                      Pakistan Engineering Council Ataturk Avenue (East), G-5/2
                      Islamabad
                    </a>
                  </div>
                </div>
                <div className="row ">
                  <div className="col-1">
                    <i
                      className="fa fa-phone  co_white fz_13 fa-rotate-90 mt-2"
                      aria-hidden="true"
                    ></i>
                  </div>
                  <div className="col-10 pt_3 ">
                    <a
                      target="_blank"
                      href="tel:(+92-51) 111-111-732"
                      className="fz_12 co_white a_footer"
                    >
                      (+92-51) 111-111-732
                    </a>
                  </div>
                </div>
                <div className="row ">
                  <div className="col-1">
                    <i className="fas fa-envelope co_white mt-0_5"></i>
                  </div>
                  <div className="col-10 pt_3">
                    <a
                      target="_blank"
                      href="mailto:cpd@pec.org.pk"
                      className="fz_12 co_white a_footer"
                    >
                      cpd@pec.org.pk
                    </a>
                  </div>
                </div>
              </div>
              <div className=" col-md-3 col-sm-12 col-xs-12">
                <h1 className="headin5_amrc col_white_amrc pt2">Subscribe</h1>
                <hr className="hr_row" />
                <div className="form-group">
                  <div className="new-class-test">
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control place_white"
                        value={this.state.email}
                        onChange={this.statechange}
                        name="email"
                        style={{
                          backgroundColor: "transparent",
                          width: "90%",
                          height: "45px",
                          color: "white",
                          border: "1px solid",
                        }}
                        placeholder="Your Email *"
                      />

                      <Select
                        name="cat1Set"
                        options={this.state.cat1Set}
                        className="ny-custom"
                        value={this.state.select}
                        menuPlacement="auto"
                        onChange={this.handleDropdownChange}
                      />
                    </div>
                  </div>
                  <div className="">
                    <div className="form-group">
                      <button
                        type="button"
                        className="btn btn-success bold font_sans fz_14"
                        onClick={this.onsub}
                        style={{
                          width: "90%",
                          height: "45px",
                          border: "1px solid #06580E",
                        }}
                      >
                        SUBSCRIBE
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-100 footer_end text-center  ">
            {" "}
            <a
              href="http://www.codistan.org/"
              target="_black"
              className="footer_link"
            >
              {" "}
              Copyright © {new Date().getFullYear()}, Powered By Codistan. All
              Rights Reserved{" "}
            </a>
          </div>

          <div className="container bottom_border display_ipad">
            <div className="row">
              <div className=" col-md-6 col-sm-12 col-xs-12">
                <div className="row">
                  <div className="col-2" style={{ marginTop: "85px" }}>
                    <img
                      src={img2}
                      alt=""
                      className
                      style={{ width: "40px", height: "70px" }}
                    />
                  </div>
                  <div className="col-10" style={{ marginTop: "10px" }}>
                    <h1
                      className="headin5_amrc col_white_amrc pt2"
                      style={{ marginBottom: "0px" }}
                    >
                      CPD
                    </h1>
                    <p className="co_white">
                      Pakistan Engineering Council (PEC) under PEC Act and CPD
                      Byelaws-2008, is earnestly working for the professional
                      growth and skill enhancement of ever growing engineering
                      community.
                    </p>
                  </div>
                </div>
              </div>
              <div className=" col-md-7 col-sm-12 col-xs-12">
                <h1 className="headin5_amrc col_white_amrc pt2">Navigation</h1>
                <hr className="hr_row" />
                <ul className="footer_ul_amrc">
                  <li>
                    <Link to="/ContactUS">Contact Us</Link>
                  </li>
                  <li>
                    <a href="/#upcomingCourses">Upcomming Courses</a>
                  </li>
                  <li>
                    <Link to="/LiveWebinar">Live Webinars</Link>
                  </li>
                  {/* <li><a href="#">News and Updates</a></li> */}
                  <li>
                    <Link to="/gallery">Gallery</Link>
                  </li>
                  <li>
                    <Link to="/CpdProfile">CPD Profile</Link>
                  </li>
                </ul>
              </div>
              <div className=" col-md-7 col-sm-12 col-xs-12">
                <h1 className="headin5_amrc col_white_amrc pt2">Contact</h1>
                <hr className="hr_row" />
                <div className="row Pt_10">
                  <div className="col-1">
                    <i className="fas fa-map-marker-alt co_white mt_10"></i>
                  </div>
                  <div className="col-10 pt_3">
                    <a
                      target="_blank"
                      href="https://www.google.com/maps/place/Pakistan+Engineering+Council/@33.7239847,73.0909047,17z/data=!3m1!4b1!4m5!3m4!1s0x38dfc07e98fce659:0xbf9fcfb8daab700b!8m2!3d33.7239803!4d73.0930934"
                      className="fz_12 co_white a_footer"
                    >
                      Pakistan Engineering Council Ataturk Avenue (East), G-5/2
                      Islamabad
                    </a>
                  </div>
                </div>
                <div className="row ">
                  <div className="col-1">
                    <i
                      className="fa fa-phone  co_white fz_13 fa-rotate-90 mt-2"
                      aria-hidden="true"
                    ></i>
                  </div>
                  <div className="col-10 pt_3">
                    <a
                      target="_blank"
                      href="tel:(+92-51) 111-111-732"
                      className="fz_12 co_white a_footer"
                    >
                      (+92-51) 111-111-732
                    </a>
                  </div>
                </div>
                <div className="row ">
                  <div className="col-1">
                    <i className="fas fa-envelope co_white mt-0_5"></i>
                  </div>
                  <div className="col-10 pt_3">
                    <a
                      target="_blank"
                      href="mailto:cpd@pec.org.pk"
                      className="fz_12 co_white a_footer"
                    >
                      cpd@pec.org.pk
                    </a>
                  </div>
                </div>
              </div>
              <div className=" col-md-6 col-sm-12 col-xs-12">
                <h1 className="col_white_amrc pt2">Subscribe</h1>
                <hr className="hr_row" />
                <div className="form-group">
                  <div className="">
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control place_white"
                        style={{
                          backgroundColor: "transparent",
                          width: "90%",
                          height: "45px",
                          color: "white",
                          border: "1px solid",
                        }}
                        placeholder="Your Email *"
                      />
                    </div>
                  </div>
                  <div className="">
                    <div className="form-group">
                      <button
                        type="button"
                        className="btn btn-success bold font_sans fz_14"
                        style={{
                          width: "90%",
                          height: "45px",
                          border: "1px solid #06580E",
                        }}
                      >
                        SUBSCRIBE
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}
export default Footer;
