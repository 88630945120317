import React from "react";
import Header from "./Header";
import axios from "axios";
import { urlapi, urlImageUrl } from "../config/urlapi";
import WebNotif from "../components/WebNotif";
import Loader from "react-loader-spinner";
import Select from "react-select";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
var obj = new WebNotif();

class ContactUS extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    loading: false,
    name: "",
    email: "",
    subject: "",
    message: "",
    number: "",
    toemail: "cpd@pec.org.pk",
    to: { value: "cpd@pec.org.pk", label: "CPD" },
    options: [
      { value: "cpd@pec.org.pk", label: "CPD" },

      { value: "peb@pec.org.pk", label: "PEB" },

      { value: "epe@pec.org.pk", label: "EPE" },
    ],
    disable: false,
  };
  componentDidMount() {
    document.title = "Contact Us";
    window.scrollTo(0, 0);
  }
  statechange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  statePhonechange = (event) => {
    this.setState({ number: event.phone });
  };

  stateNamechange = (event) => {
    if (/^\s/.test(event.target.value)) {
      event.target.value = "";
    } else if (/^[a-zA-Z ]*$/.test(event.target.value)) {
      this.setState({ [event.target.name]: event.target.value });
    } else {
    }
  };
  handleSelect = (selectedOption) => {
    this.setState({ to: selectedOption, toemail: selectedOption.value });
  };
  contactus = (event) => {
    event.preventDefault();
    if (
      this.state.name == "" ||
      this.state.email == "" ||
      this.state.message == ""
    ) {
      obj.createNotification("warning", "Fill the required fields.");
    } else {
      var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (this.state.email.match(mailformat)) {
        this.setState(
          {
            loading: true,
            disable: true,
          },
          () => {
            console.log;
            axios
              .post(urlapi + "/admin/contact_us", {
                name: this.state.name,
                email: this.state.email,
                subject: this.state.subject,
                phone: this.state.number,
                message: this.state.message,
                toemail: this.state.toemail,
              })
              .then((res) => {
                obj.createNotification(
                  "success",
                  "Thankyou for contacting us, your message has been delivered."
                );
                this.setState({
                  email: "",
                  name: "",
                  subject: "",
                  message: "",
                  loading: false,
                  disable: false,
                });
              })
              .catch((err) => {
                console.log(err);
                this.setState({
                  loading: false,
                  disable: false,
                });
              });
          }
        );
      } else {
        obj.createNotification("warning", "Please enter a correct email.");
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        <section className="backco_white" style={{ fontFamily: "Arial" }}>
          <Header />
          <div className="container">
            <h4 className="ptb-20">Contact Us</h4>
            <hr />
            <div className="row mtb_7v">
              <div className="col-md-3"></div>
              <div className="col-md-6">
                <form>
                  <div className="mb-3">
                    <label>
                      To<span className="co_red">*</span>
                    </label>
                    <Select
                      options={this.state.options}
                      value={this.state.to}
                      onChange={this.handleSelect}
                    />
                  </div>
                  <div className="mb-3">
                    <label>
                      Name<span className="co_red">*</span>
                    </label>
                    <input
                      name="name"
                      type="text"
                      value={this.state.name}
                      onChange={this.stateNamechange}
                      className="form-control fz_16"
                      placeholder="Your Name"
                      disabled={this.state.disable}
                    />
                  </div>
                  <div className="mb-3">
                    <label>
                      Email<span className="co_red">*</span>
                    </label>
                    <input
                      name="email"
                      type="email"
                      value={this.state.email}
                      onChange={this.statechange}
                      className="form-control fz_16"
                      placeholder="Your Email"
                      disabled={this.state.disable}
                    />
                  </div>
                  <div className="mb-3">
                    <label>Phone Number</label>
                    <PhoneInput
                      className="form-control fz_16"
                      name="Phone"
                      country={"pk"}
                      disabled={this.state.disable}
                      //  value={this.state.number}
                      onChange={(phone) => this.statePhonechange({ phone })}
                    />
                    {/* <input 
                                    name="number" 
                                    type="number" 
                                    value={this.state.number} 
                                    onChange={this.statechange} 
                                    className="form-control fz_16" 
                                    placeholder="Your Number" 
                                    /> */}
                  </div>
                  <div className="mb-3">
                    <label>
                      Subject<span className="co_red">*</span>
                    </label>
                    <input
                      name="subject"
                      className="form-control fz_16"
                      value={this.state.subject}
                      onChange={this.statechange}
                      placeholder="Your Message"
                      disabled={this.state.disable}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="validationDefault04">
                      Message<span className="co_red">*</span>
                    </label>
                    <textarea
                      name="message"
                      className="form-control fz_16"
                      value={this.state.message}
                      onChange={this.statechange}
                      placeholder="Your Message"
                      rows={5}
                      disabled={this.state.disable}
                    />
                  </div>
                  {this.state.loading ? (
                    <div className="mt-15 mb-40 center ">
                      <Loader
                        type="Oval"
                        color="#06580e"
                        height={80}
                        width={80}
                      />
                    </div>
                  ) : (
                    <button
                      onClick={this.contactus}
                      className="btn btn-success bold fz_14"
                      type="submit"
                    >
                      Submit
                    </button>
                  )}
                </form>
              </div>
              <div className="col-md-3"></div>
            </div>
            <h4 className="">Head Office Address</h4>
            <hr />
            <a
              target="_blank"
              href="https://www.google.com/maps/place/Pakistan+Engineering+Council/@33.7239847,73.0909047,17z/data=!3m1!4b1!4m5!3m4!1s0x38dfc07e98fce659:0xbf9fcfb8daab700b!8m2!3d33.7239803!4d73.0930934"
              className="fz_14 co_black bold"
            >
              Pakistan Engineering Council Ataturk Avenue (East), G-5/2
              Islamabad
            </a>
            <br />
            <span className="bold">UAN:</span>
            <span className="fz_14 ml-2">
              <a
                target="_blank"
                href="tel:(+92-51) 111-111-732"
                className="fz_14 co_black"
              >
                (+92-51) 111-111-732
              </a>
            </span>
            <br />
            <span className="bold">Mail:</span>
            <span className="fz_14 ml-2">
              &nbsp;
              <a
                target="_blank"
                href="mailto:cpd@pec.org.pk"
                className="fz_14 co_black"
              >
                cpd@pec.org.pk
              </a>
            </span>
            <br />
            <div className="text-center mtb-15">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3318.4188124486104!2d73.09090471454577!3d33.72398474240572!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38dfc07e98fce659%3A0xbf9fcfb8daab700b!2sPakistan%20Engineering%20Council!5e0!3m2!1sen!2s!4v1578400445814!5m2!1sen!2s"
                width={"100%"}
                height={400}
                frameBorder={0}
                style={{ border: 0 }}
                allowFullScreen
              />{" "}
            </div>
          </div>
          {/* <form>
                        <div className="form-row">
                        <div className="col-md-4 mb-3">
                            <label htmlFor="validationDefault01">Name</label>
                            <input type="text" className="form-control" id="validationDefault01" placeholder="First name" defaultValue="Mark" required />
                        </div>
                        </div>
                        <div className="form-row">
                      
                        <div className="col-md-3 mb-3">
                            <label htmlFor="validationDefault04">State</label>
                            <input type="text" className="form-control" id="validationDefault04" placeholder="State" required />
                        </div>
                        <div className="col-md-3 mb-3">
                            <label htmlFor="validationDefault05">Zip</label>
                            <input type="text" className="form-control" id="validationDefault05" placeholder="Zip" required />
                        </div>
                        </div>
                        <div className="form-group">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" defaultValue id="invalidCheck2" required />
                            <label className="form-check-label" htmlFor="invalidCheck2">
                            Agree to terms and conditions
                            </label>
                        </div>
                        </div>
                        <button className="btn btn-primary" type="submit">Submit form</button>
                    </form> */}
          {/*<Footer/>*/}
        </section>
      </React.Fragment>
    );
  }
}
export default ContactUS;
