import api from "../../../api";

//get cpd form data

export const GotCPDForm = (data) => ({
  type: "GOT_CPD_FORM",
  data,
});

export const get_cpd_formdata = () => (dispatch) =>
  api.admin.cpd.get_cpd_form_data().then((res) => {
    dispatch(GotCPDForm(res.data));
    return res;
  });

export const GotCPDFormRow = (data) => ({
  type: "GOT_CPD_DATA_ROW",
  data,
});

export const get_cpd_form_rows = (data) => (dispatch) =>
  api.admin.cpd.get_cpd_form_row_data(data).then((res) => {
    dispatch(GotCPDFormRow(res.data));
    return res;
  });

export const GotCPDFormRowPR = (data) => ({
  type: "GOT_CPD_DATA_ROW_PR",
  data,
});

export const get_cpd_form_rows_pr = (data) => (dispatch) =>
  api.admin.cpd.get_cpd_form_row_data_pr(data).then((res) => {
    dispatch(GotCPDFormRowPR(res.data));
    return res;
  });

export const GotCPDFormRowRejected = (data) => ({
  type: "GOT_CPD_DATA_ROW_REJECTED",
  data,
});

export const get_cpd_form_rows_rejected = (data) => (dispatch) =>
  api.admin.cpd.get_cpd_form_rows_rejected(data).then((res) => {
    dispatch(GotCPDFormRowRejected(res.data));
    return res;
  });

export const GotFilterClaimData = (data) => ({
  type: "GOT_FILTER_CLAIM_DATA",
  data,
});
export const get_claims_filter_data = (data) => (dispatch) =>
  api.admin.cpd.get_claims_filter(data).then((res) => {
    dispatch(GotFilterClaimData(res.data));
    return res;
  });

export const GotFilterClaimCount = (data) => ({
  type: "GOT_FILTER_CLAIM_COUNT",
  data,
});

export const get_claims_filter_count = () => (dispatch) =>
  api.admin.cpd.get_claims_filter_count().then((res) => {
    dispatch(GotFilterClaimCount(res.data));
    return res;
  });

export const GotApprovedPointsYear = (data) => ({
  type: "GOT_YEAR_APPROVED_POINTS",
  data,
});
export const get_approved_points_year = (data) => (dispatch) =>
  api.admin.cpd.get_year_approved_points(data).then((res) => {
    dispatch(GotApprovedPointsYear(res.data));
    return res;
  });

export const GotCPDFormCount = (data) => ({
  type: "GOT_CPD_FORM_COUNT",
  data,
});
export const get_total_cpd_count = () => (dispatch) =>
  api.admin.cpd.get_total_cpd_row_count().then((res) => {
    dispatch(GotCPDFormCount(res.data));
    return res;
  });

export const GotCPDFormCountPR = (data) => ({
  type: "GOT_CPD_FORM_COUNT_PR",
  data,
});
export const get_total_cpd_count_pr = () => (dispatch) =>
  api.admin.cpd.get_total_cpd_row_count_pr().then((res) => {
    dispatch(GotCPDFormCountPR(res.data));
    return res;
  });
export const GotCPDFormCountRejected = (data) => ({
  type: "GOT_CPD_FORM_COUNT_REJECTED",
  data,
});
export const get_total_cpd_count_rejected = () => (dispatch) =>
  api.admin.cpd.get_total_cpd_count_rejected().then((res) => {
    dispatch(GotCPDFormCountRejected(res.data));
    return res;
  });

export const GotCPDFormExpendable = (data) => ({
  type: "GOT_CPD_FORM_EXPENDABLE",
  data,
});
export const get_cpd_form_expendable = (data) => (dispatch) =>
  api.admin.cpd.get_cpd_form_row_expendable(data).then((res) => {
    dispatch(GotCPDFormExpendable(res.data));
    return res;
  });
// update cpd points
export const CpdUpdated = (data) => ({
  type: "CPD_STATUS_UPDATED",
  data,
});
export const updateCpdStatus = (data) => (dispatch) =>
  api.admin.cpd.update_cpd_status(data).then((res) => {
    dispatch(CpdUpdated(res.data));
    return res;
  });

export const CpdRejected = (data) => ({
  type: "CPD_STATUS_REJECTED",
  data,
});

export const rejectCpdStatus = (data) => (dispatch) =>
  api.admin.cpd.reject_cpd_status(data).then((res) => {
    dispatch(CpdRejected(res.data));
    return res;
  });

export const searchedClaims = (data) => ({
  type: "SEARCH_CLAIMS",
  data,
});
export const searchClaims = (data) => (dispatch) =>
  api.admin.cpd.search_claims(data).then((res) => {
    dispatch(searchedClaims(res.data));
    return res;
  });

export const searchedClaimsSecond = (data) => ({
  type: "SEARCH_CLAIMS_SECOND",
  data,
});
export const searchClaimsSecond = (data) => (dispatch) =>
  api.admin.cpd.search_claims_second(data).then((res) => {
    dispatch(searchedClaimsSecond(res.data));
    return res;
  });

// profile page
export const gotClaimUser = (data) => ({
  type: "GOT_CLAIM_USER",
  data,
});
export const cpd_claim_user = (data) => (dispatch) =>
  api.admin.cpd.user_cpd_claim(data).then((res) => {
    dispatch(gotClaimUser(res.data));
    return res;
  });

export const gotProfileDataYear = (data) => ({
  type: "GOT_YEAR_PROFILE_DATA",
  data,
});

export const gotUserClaimsSubmission = (data) => ({
  type: "GOT_USER_CLAIMS_SUBMISSION",
  data,
});

export const cpd_profile_data_byYear = (data) => (dispatch) =>
  api.admin.cpd.profile_data_year(data).then((res) => {
    // console.log('cpd_profile_data_byYear action data: ', data);
    console.log("cpd_profile_data_byYear action data: ", data);
    dispatch(gotProfileDataYear(res.data));
    return res;
  });

export const getUserClaimsBySubmissionId = (data) => (dispatch) =>
  api.admin.cpd.get_user_claims_by_submission_id(data).then((res) => {
    dispatch(gotUserClaimsSubmission(res.data));
    return res;
  });

export const GotAttendence = (data) => ({
  type: "GOT_ATTENDENCE_DATA",
  data,
});

export const getAttendence = () => (dispatch) =>
  api.admin.cpd.get_attendence().then((res) => {
    dispatch(GotAttendence(res.data));
    return res;
  });

export const UpdatedPoints = (data) => ({
  type: "UPDATED_POINTS",
  data,
});

export const getUpdatedCpdPoints = (data) => (dispatch) =>
  api.admin.cpd.update_cpd_points(data).then((res) => {
    dispatch(UpdatedPoints(res.data));
    return res;
  });

export const GOTUSERSUBMISSION = (data) => ({
  type: "GOT_USER_SUBMISSION",
  data,
});

export const GOTUSERCLAIMBASED = (data) => ({
  type: "GOT_USER_CLAIM_BASED",
  data,
});

export const getUserSubmission = (data) => (dispatch) =>
  api.admin.cpd.get_user_submission(data).then((res) => {
    dispatch(GOTUSERSUBMISSION());
    return res;
  });

export const approveAttendence = (data) => (dispatch) =>
  api.admin.cpd.approve_attendence(data).then((res) => {
    return res;
  });

export const rejectAttendence = (data) => (dispatch) =>
  api.admin.cpd.reject_attendence(data).then((res) => {
    return res;
  });

export const getClaimBasedSubmission = (data) => (dispatch) =>
  api.admin.cpd.get_claim_based_points(data).then((res) => {
    dispatch(GOTUSERCLAIMBASED(data));
    return res;
  });

// export const getAdminSummary = (data) => (dispatch) =>
//   api.admin.cpd.get_admin_summary(data).then((res) => {
//     return res;
//   });

export const getAdminSummary = (data) => {
  console.log("getAdminSummary ..... ", data);
};
