import api from "../../../api";

export const GotCoursePayments = (data) => ({
  type: "GOT_PAYMENTS",
  data,
});

export const getcoursepayments = (data) => (dispatch) =>
  api.admin.financial.getcoursepayments(data).then((res) => {
    dispatch(GotCoursePayments(res));
    return res;
  });

export const GotCoursePaymentsRest = (data) => ({
  type: "GOT_PAYMENTS_REST",
  data,
});

export const getcoursepaymentsrest = (data) => (dispatch) =>
  api.admin.financial.getcoursepaymentsrest(data).then((res) => {
    dispatch(GotCoursePaymentsRest(res));
    return res;
  });
export const GotCoursePaymentsRejected = (data) => ({
  type: "GOT_PAYMENTS_REJECTED",
  data,
});

export const getcoursepaymentsRejected = (data) => (dispatch) =>
  api.admin.financial.getcoursepaymentsRejected(data).then((res) => {
    dispatch(GotCoursePaymentsRejected(res));
    return res;
  });

export const GotFilterPaymentData = (data) => ({
  type: "GOT_FILTER_PAYMENT_DATA",
  data,
});

export const getFilterPaymentsData = (data) => (dispatch) =>
  api.admin.financial.getFilterPayments(data).then((res) => {
    dispatch(GotFilterPaymentData(res.data));
    return res;
  });

export const GotPaymentSearch = (data) => ({
  type: "PAYMENT_SEARCH",
  data,
});

export const getPaymentSearch = (data) => (dispatch) =>
  api.admin.financial.getPaymentSearch(data).then((res) => {
    dispatch(GotPaymentSearch(res.data));
    return res;
  });

export const GotCoursePaymentCount = (data) => ({
  type: "GOT_COURSE_PAYMENT_COUNT",
  data,
});
export const getCoursePaymentCount = () => (dispatch) =>
  api.admin.financial.getCoursePaymentCount().then((res) => {
    dispatch(GotCoursePaymentCount(res));
    return res;
  });

export const GotCoursePaymentCountREST = (data) => ({
  type: "GOT_COURSE_PAYMENT_COUNT_REST",
  data,
});
export const getCoursePaymentCountRest = () => (dispatch) =>
  api.admin.financial.getCoursePaymentCountRest().then((res) => {
    dispatch(GotCoursePaymentCountREST(res));
    return res;
  });
export const GotCoursePaymentCountRejected = (data) => ({
  type: "GOT_COURSE_PAYMENT_COUNT_REJECTED",
  data,
});
export const getCoursePaymentCountRejected = () => (dispatch) =>
  api.admin.financial.getCoursePaymentCountRejected().then((res) => {
    dispatch(GotCoursePaymentCountRejected(res));
    return res;
  });

export const approvePayment = (data) => (dispatch) =>
  api.admin.financial.approvePayment(data).then((res) => {
    dispatch(GotCoursePayments(res));
    return res;
  });

export const rejectPayment = (data) => (dispatch) =>
  api.admin.financial.rejectPayment(data).then((res) => {
    dispatch(GotCoursePayments(res));
    return res;
  });

export const GotFreeRegistrations = (data) => ({
  type: "GOT_REGISTRATIONS",
  data,
});

export const getFreeWebinarsRegistrations = (data) => (dispatch) =>
  api.admin.financial.get_free_registrations(data).then((res) => {
    dispatch(GotFreeRegistrations(res.data));
    return res;
  });

export const GotWebinarRegistrationCount = (data) => ({
  type: "GOT_WEBINAR_REG_COUNT",
  data,
});
export const get_webinar_registration_count = () => (dispatch) =>
  api.admin.financial.get_webinar_reg_count().then((res) => {
    dispatch(GotWebinarRegistrationCount(res.data));
    return res;
  });

export const GotwebinarAttendees = (data) => ({
  type: "GOT_WEBINAR_ATTENDEES",
  data,
});

export const getwebinarAttendees = (data) => (dispatch) =>
  api.admin.financial.get_webinar_attendees(data).then((res) => {
    dispatch(GotwebinarAttendees(res.data));
    return res;
  });

export const GotAllwebinarAttendees = (data) => ({
  type: "GOT_ALL_ATTENDEES_COUNT",
  data,
});

export const allAttendeesCount = (data) => (dispatch) =>
  api.admin.financial.all_attendees_count(data).then((res) => {
    dispatch(GotAllwebinarAttendees(res.data));
    return res;
  });

export const GotWebinarFilters = (data) => ({
  type: "GOT_FILTER_WEBINARS",
  data,
});

export const getWebinarFiltersData = (data) => (dispatch) =>
  api.admin.financial.getWebinarFilters(data).then((res) => {
    dispatch(GotWebinarFilters(res.data));
    return res;
  });

export const searchedWebinarData = (data) => ({
  type: "SEARCH_WEBINAR_DATA",
  data,
});

export const getSearchPaymentData = (data) => (dispatch) =>
  api.admin.financial.getWebinarSearch(data).then((res) => {
    dispatch(searchedWebinarData(res.data));
    return res;
  });

export const searchedWebinarAttendees = (data) => ({
  type: "SEARCH_WEBINAR_ATTENDEES",
  data,
});

export const getSearchWebinarAttendees = (data) => (dispatch) =>
  api.admin.financial.getSearchAttendees(data).then((res) => {
    dispatch(searchedWebinarAttendees(res.data));
    return res;
  });
