import React, { Component } from "react";
import Loader from "react-loader-spinner";
import Header from "../Header";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import moment from "moment";
import { Link } from "react-router-dom";
import { urlImageUrl } from "../../config/urlapi";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import {
  cpd_claim_user,
  cpd_profile_data_byYear,
  getUserClaimsBySubmissionId,
} from "../../redux/admin/cpdActions/cpdactions";
import {
  attended_webinars_profile,
  registered_webinar_profile,
} from "../../redux/user/useraction";
// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";
import brochure from "../../assets/pdf/Calculator of CPD Points.xls";
import { initial } from "lodash";
import Modal from "react-modal";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Tooltip from "@material-ui/core/Tooltip";

let flag = false;
let a;
let monthDiff;
let yearDiff;
let allPointsSum;
// let yearCheck;
Modal.setAppElement("#root");
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    overflowY: "hidden",
    backgroundColor: "#fff",
    border: "none",
    color: "black",
  },
  overlay: {
    zIndex: 1000,
    backgroundColor: "rgba(0, 0, 0, 0.35)",
  },
};

const dateFields = [
  "Admission Date",
  "From Date",
  "To Date",
  "Publish Date",
  "Date Of Activity",
  "Publishing Date",
  "Date Of Event",
  "Participation Date",
];

const ignoreFields = ["Upload", "File Type"];

const imageTypes = ["jpg", "png", "jpeg"];

class CpdFormProfile extends Component {
  state = {
    loading: false,
    data: null,
    yearData: null,
    redirect: "",
    dataCopy: null,
    seeAll1: false,
    seeAll: false,
    approvedData: null,
    attendedWebinar: null,
    topthreewebinar: null,
    remaningwebinar: null,
    topthreeregistered: null,
    remaningregistered: null,
    flag: false,
    attendflag: false,
    fileUploadUrl: null,
    modalIsOpen: false,
    showSubmissionData: "",
    submissionData: [],
    uploadedClaimType: "",
  };

  openModal = (id) => {
    this.setState({ loading: true });
    this.props
      .getUserClaimsBySubmissionId({ submissionID: id })
      .then((res) => {
        console.log("GET USER CLAIMS SUBMISSION ID .... ", res);
        if (res.status === 200) {
          this.setState({ modalIsOpen: true, showSubmissionData: id });
          const subData = res?.data?.submissionData;
          const fileTypeData = res?.data?.fileTypeInfo;

          this.setState({
            submissionData: subData,
            uploadedClaimType: fileTypeData?.field_value,
          });
        }
      })
      .catch((err) => {
        console.log("Error: ", err);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  componentDidMount() {
    console.log("THIS PROPS USER ... ", this.props.User);
    document.title = "Profile";
    this.props
      .attended_webinars_profile({ ID: this.props.User.UserID })
      .then((res) => {
        this.setState({ attendedWebinar: res.data.res }, () => {
          let topthreewebinar = this.state.attendedWebinar.slice(0, 3);
          let remaningwebinar = this.state.attendedWebinar.slice(4);
          this.setState({
            topthreewebinar: topthreewebinar,
            remaningwebinar: remaningwebinar,
          });
        });
      })
      .catch((err) => {
        console.log(err);
      });

    this.props
      .registered_webinar_profile({ ID: this.props.User.UserID })
      .then((res) => {
        this.setState({ attendedWebinar: res.data.res }, () => {
          let topthreeregistered = this.state.attendedWebinar.slice(0, 3);
          let remaningregistered = this.state.attendedWebinar.slice(4);
          this.setState({
            topthreeregistered: topthreeregistered,
            remaningregistered: remaningregistered,
          });
        });
      })
      .catch((err) => {
        console.log(err);
      });

    let RegDate =
      this.props.User.PEC_REG_DATE &&
      moment(this.props.User.PEC_REG_DATE).toDate();
    let nowDate = moment();
    let milisecond = nowDate.diff(RegDate);
    let str =
      this.props.User.PEC_REG_DATE &&
      this.props.User.PEC_REG_DATE.split("/").pop();
    let str2 = str && str.split(" ")[0];
    monthDiff = milisecond / 2629746000;
    let now = moment().format("YYYY");
    yearDiff = now - str2;
    if (this.props.User.UserID) {
      this.props
        .cpd_claim_user({ ID: this.props.User.UserID })
        .then((res) => {
          this.setState({ data: res.data.res }, () => {
            let year = [];
            let five_year = [];
            let fiverYearSum;
            let allPoints = [];
            allPointsSum;
            let total_points = [];
            let sum;
            this.state.data.map((item, index) => {
              year.push(moment(item.date).format("YYYY"));
              if (
                item.status === "Approved" &&
                now - moment(item.date).format("YYYY") <= 3
              ) {
                item.final_approved_points === null
                  ? total_points.push(item.cpd_points_pec)
                  : total_points.push(item.final_approved_points);
              }
              if (item.status === "Approved") {
                item.final_approved_points === null
                  ? allPoints.push(item.cpd_points_pec)
                  : allPoints.push(item.final_approved_points);
              }
              if (
                item.status === "Approved" &&
                now - moment(item.date).format("YYYY") <= 5
              ) {
                item.final_approved_points === null
                  ? five_year.push(item.cpd_points_pec)
                  : five_year.push(item.final_approved_points);
              }
            });
            fiverYearSum = five_year.reduce((a, b) => {
              return a + b;
            }, 0);
            allPointsSum = allPoints.reduce((a, b) => {
              return a + b;
            }, 0);
            sum = total_points.reduce((a, b) => {
              return a + b;
            }, 0);
            let uniqueyear = [...new Set(year)];
            let approvedData = this.state.data.filter((e) => {
              return e.status === "Approved";
            });

            this.setState({
              year: uniqueyear,
              approvedData: approvedData,
              totalPoints: sum,
              fiverYearSum: fiverYearSum,
              allPointsSum: allPointsSum,
            });
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
  subaccordionclick = (data) => {
    let subData = this.state.yearData.filter((e) => {
      return e.category === data;
    });
    this.setState({ selectedCategory: subData });
  };
  registeredWebinar = () => {
    this.setState({ flag: !this.state.flag, seeAll1: !this.state.seeAll1 });
  };
  attendwebinar = () => {
    this.setState({
      attendflag: !this.state.attendflag,
      seeAll: !this.state.seeAll,
    });
  };
  preview = (cid) => {
    let url = `/LiveWebinar/${cid}`;
    // this.props.history.push(`/LiveWebinar/${cid}`);
    window.open(url, "_blank", "noopener,noreferrer");
  };
  accordionclick = (data) => {
    // let testData = "2021";
    // let testId = 262211;
    this.props
      .cpd_profile_data_byYear({ year: data, ID: this.props.User.UserID })
      .then((res) => {
        this.setState(
          { yearData: res.data.res, dataCopy: res.data.res },
          () => {
            let categoriesSet = [];
            for (let i = 0; i < res.data.res.length; i++) {
              categoriesSet.push(res.data.res[i].category);
            }
            let uniquecat = [...new Set(categoriesSet)];
            this.setState({
              uniquecat: uniquecat,
            });
          }
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };
  btnclick = (data, data1) => {
    this.setState({ redirect: data, subcatredirect: data1 }, () => {
      this.props.history.push({
        pathname: "/cpd/claimcpd",
        state: { cat: this.state.redirect, sub: this.state.subcatredirect },
      });
    });
    flag = !flag;
  };

  yearDifferenceFunction = (diff) => {
    if (diff > 1 && diff < 3) {
      return "1st";
    } else if (diff > 3 && diff < 6) {
      return "2nd";
    } else if (diff > 6 && diff < 9) {
      return "3rd";
    } else {
      return "";
    }
  };

  render() {
    return (
      <React.Fragment>
        <Header />
        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Claim Details"
        >
          {!!this.state.loading ? (
            <>
              <div className="loaderDiv text-center d-flex align-items-center justify-content-center">
                <Loader type="Oval" color="#06580e" height={80} width={80} />
              </div>
            </>
          ) : (
            <>
              <div className="row ptb-15">
                <div className="col-md-11"></div>
                <div className="col-md-1 ">
                  <a
                    className="float-right modalCloseIcon"
                    onClick={this.closeModal}
                  >
                    <i className="fas fa-window-close"></i>
                  </a>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 offset-md-3">
                  <h4
                    className="co_g20A84C text-center"
                    style={{ fontWeight: "700" }}
                  >
                    Claim Details
                  </h4>
                </div>
              </div>
              {!!this.state.submissionData &&
              !!this.state.uploadedClaimType &&
              this.state.submissionData.length > 0 ? (
                <>
                  <div className="row mt-15">
                    {this.state.submissionData.map((field, index) => {
                      let filePath;

                      if (ignoreFields?.includes(field?.field_name)) {
                        return null;
                      }

                      if (!filePath && field?.field_name === "File Path") {
                        let splitString = field?.field_value;
                        filePath = splitString.replace("claims", "claims/");
                      }

                      if (!!filePath && field?.field_name === "File Path") {
                        return (
                          <div className="col-md-6" key={index}>
                            <div className="row" style={{ marginTop: "10px" }}>
                              <div className="col-md-2 mtb-5"></div>
                              <div className="col-md-4 mtb-5">
                                <b>Uploaded File:</b>
                              </div>
                              <div className="col-md-4 mtb-5">
                                {imageTypes.includes(
                                  this.state.uploadedClaimType
                                ) ? (
                                  <img
                                    style={{
                                      width: "100px",
                                      height: "100px",
                                    }}
                                    src={urlImageUrl + filePath}
                                  />
                                ) : (
                                  <i
                                    className={
                                      this.state.uploadedClaimType === "pdf"
                                        ? "far fa-file-pdf fa-3x pb-10"
                                        : this.state.currentFileTypeofData ===
                                            "docx" ||
                                          this.state.currentFileTypeofData ===
                                            "doc"
                                        ? "far fa-file-word fa-3x pb-10"
                                        : ""
                                    }
                                  />
                                )}
                              </div>
                              <div className="col-md-2 mtb-5"></div>
                            </div>
                          </div>
                        );
                      }

                      if (dateFields.includes(field.field_name)) {
                        const dateValue = field?.field_value?.split("T");

                        return (
                          <div className="col-md-6" key={index}>
                            <div className="row" style={{ marginTop: "10px" }}>
                              <div className="col-md-2 mtb-5"></div>
                              <div className="col-md-4 mtb-5">
                                <b>{field.field_name}:</b>
                              </div>
                              <div className="col-md-4 mtb-5">
                                {dateValue[0]}
                              </div>
                              <div className="col-md-2 mtb-5"></div>
                            </div>
                          </div>
                        );
                      }

                      return (
                        <div className="col-md-6" key={index}>
                          <div className="row" style={{ marginTop: "10px" }}>
                            <div className="col-md-2 mtb-5"></div>
                            <div className="col-md-4 mtb-5">
                              <b>{field.field_name}:</b>
                            </div>
                            <div className="col-md-4 mtb-5">
                              {field.field_value}
                            </div>
                            <div className="col-md-2 mtb-5"></div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              ) : (
                <>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <h4
                      className="co_g20A84C text-center"
                      style={{ fontWeight: "700" }}
                    >
                      Claim Details
                    </h4>
                  </div>
                </>
              )}
              <div className="row ptb-15" style={{ marginTop: "10px" }}>
                <div className="col-md-12" style={{ textAlign: "center" }}>
                  <div></div>
                </div>
              </div>
            </>
          )}
        </Modal>

        <div className="container">
          <div className="d-flex justify-content-center">
            <img
              src={`data:image/jpeg;base64,
              ${
                this.props.User
                  ? this.props.User.ProfileImageURL
                    ? this.props.User.ProfileImageURL
                    : this.props.User.profilePicture
                  : "/img.jpg"
              }`}
              height="150"
              width="150"
              style={{ "border-radius": "100px", "margin-top": "20px" }}
              alt="..."
              className="img-thumbnail"
            />
          </div>
          <div className="d-flex justify-content-center">
            <span className="cpd-name">{this.props.User.FullName}</span>
          </div>
          <div className="d-flex justify-content-center">
            <span>
              {this.props.User && this.props.User.engineerType === 2
                ? "Professional Engineer"
                : "Registered Engineer"}
            </span>
          </div>
          <div className=" top-card d-flex justify-content-center">
            <div className="profile-card-3">
              {this.props.User.engineerType &&
                this.props.User.engineerType === 2 && (
                  <span className="profile-card-top-2">3</span>
                )}
              {this.props.User.engineerType &&
                this.props.User.engineerType === 1 && (
                  <span className="profile-card-top-2">
                    {this.props.User.engineerType &&
                    this.props.User.engineerType == 1
                      ? yearDiff > 1 && yearDiff <= 3 && allPointsSum < 9
                        ? 9
                        : yearDiff > 1 && yearDiff <= 3 && allPointsSum >= 9
                        ? "0"
                        : yearDiff >= 3 && yearDiff <= 6 && allPointsSum < 12
                        ? 12
                        : yearDiff >= 3 && yearDiff <= 6 && allPointsSum >= 12
                        ? "0"
                        : yearDiff >= 6 && yearDiff <= 9 && allPointsSum < 15
                        ? 15
                        : yearDiff >= 6 && yearDiff <= 9 && allPointsSum >= 15
                        ? "0"
                        : yearDiff >= 9 && allPointsSum < 15
                        ? 15
                        : "0"
                      : yearDiff > 1 && yearDiff <= 3 && allPointsSum < 9
                      ? 3
                      : yearDiff > 1 && yearDiff <= 3 && allPointsSum >= 9
                      ? "0"
                      : yearDiff >= 3 && yearDiff <= 6 && allPointsSum < 12
                      ? 3
                      : yearDiff >= 3 && yearDiff <= 6 && allPointsSum >= 12
                      ? "0"
                      : yearDiff >= 6 && yearDiff <= 9 && allPointsSum < 15
                      ? 3
                      : yearDiff >= 6 && yearDiff <= 9 && allPointsSum >= 15
                      ? "0"
                      : yearDiff >= 9
                      ? "0"
                      : "0"}
                  </span>
                )}

              <span className="profile-card-bottom-2 justify-content-center">
                {this.props.User.engineerType == 1
                  ? "Required CPD Points in " +
                    (yearDiff > 1 && yearDiff <= 3
                      ? "1st"
                      : yearDiff >= 3 && yearDiff <= 6
                      ? "2nd"
                      : yearDiff >= 6 && yearDiff <= 9
                      ? "3rd"
                      : "") +
                    " Cycle"
                  : "Required CPD Points in " + moment().format("YYYY")}
              </span>
            </div>
            <div className="profile-card">
              <span className="profile-card-top">{this.state.totalPoints}</span>
              <span className="profile-card-bottom justify-content-center">
                {this.props.User.engineerType == 1
                  ? "Earned CPD Points in " +
                    (yearDiff > 1 && yearDiff <= 3
                      ? "1st"
                      : yearDiff >= 3 && yearDiff <= 6
                      ? "2nd"
                      : yearDiff >= 6 && yearDiff <= 9
                      ? "3rd"
                      : "") +
                    " Cycle"
                  : "Earned CPD Points in " + moment().format("YYYY")}
              </span>
            </div>
            <div className="profile-card-3">
              {this.props.User.engineerType &&
                this.props.User.engineerType === 1 && (
                  <span className="profile-card-top-3">
                    {this.props.User.engineerType &&
                    this.props.User.engineerType == 1
                      ? yearDiff > 1 && yearDiff <= 3 && allPointsSum < 9
                        ? 9 - this.state.totalPoints
                        : yearDiff > 1 && yearDiff <= 3 && allPointsSum >= 9
                        ? "0"
                        : yearDiff >= 3 && yearDiff <= 6 && allPointsSum < 12
                        ? 12 - this.state.totalPoints
                        : yearDiff >= 3 && yearDiff <= 6 && allPointsSum >= 12
                        ? "0"
                        : yearDiff >= 6 && yearDiff <= 9 && allPointsSum < 15
                        ? 15 - this.state.totalPoints
                        : yearDiff >= 6 && yearDiff <= 9 && allPointsSum >= 15
                        ? "0"
                        : yearDiff >= 9
                        ? "0"
                        : "0"
                      : yearDiff > 1 && yearDiff <= 3 && allPointsSum < 9
                      ? 3 - this.state.totalPoints
                      : yearDiff > 1 && yearDiff <= 3 && allPointsSum >= 9
                      ? "0"
                      : yearDiff >= 3 && yearDiff <= 6 && allPointsSum < 12
                      ? 3 - this.state.totalPoints
                      : yearDiff >= 3 && yearDiff <= 6 && allPointsSum >= 12
                      ? "0"
                      : yearDiff >= 6 && yearDiff <= 9 && allPointsSum < 15
                      ? 3 - this.state.totalPoints
                      : yearDiff >= 6 && yearDiff <= 9 && allPointsSum >= 15
                      ? "0"
                      : yearDiff >= 9
                      ? "0"
                      : "0"}
                  </span>
                )}
              {this.props.User.engineerType &&
                this.props.User.engineerType === 2 && (
                  <span className="profile-card-top-3">
                    {this.state.totalPoints >= 3
                      ? "0"
                      : 3 - this.state.totalPoints}
                  </span>
                )}
              <span className="profile-card-bottom-3 justify-content-center">
                {this.props.User.engineerType == 1
                  ? "Remaining CPD Points in " +
                    (yearDiff > 1 && yearDiff <= 3
                      ? "1st"
                      : yearDiff >= 3 && yearDiff <= 6
                      ? "2nd"
                      : yearDiff >= 6 && yearDiff <= 9
                      ? "3rd"
                      : "") +
                    " Cycle"
                  : "Remaining CPD Points in " + moment().format("YYYY")}
              </span>
            </div>
          </div>
          <div className="cpd-profile-detail">
            <div className=" cpd-row-new">
              <div className="cpd-column flex-item-new">
                <div className="row">
                  <span className="col cpd-span">
                    CPD Points Since Registration
                  </span>
                  <span className="col cpd-newspan">
                    {this.state.allPointsSum}
                  </span>
                </div>
                <div className="row">
                  <span className="col cpd-span">PEC NO</span>
                  <span className="col cpd-newspan">
                    {this.props.User && this.props.User.PEC_REG_NO
                      ? this.props.User.PEC_REG_NO
                      : "-"}
                  </span>
                </div>
                <div className="row">
                  <span className="col cpd-span">Discipline</span>
                  <span className="col cpd-newspan">
                    {this.props.User && this.props.User.discipline
                      ? this.props.User.discipline
                      : "-"}
                  </span>
                </div>
                <div className="row">
                  <span className="col cpd-span">Engineer Title</span>
                  <span className="col cpd-newspan">
                    {this.props.User && this.props.User.engineerType === 2
                      ? "Professional Engineer"
                      : "Registered Engineer"}
                  </span>
                </div>
                <div className="row">
                  <span className="col cpd-span">Engineer Validity</span>
                  <span className="col cpd-newspan">
                    {this.props.User && this.props.User.engineerValidity
                      ? this.props.User.engineerValidity
                      : "-"}
                  </span>
                </div>
                <div className="row">
                  <span className="col cpd-span">Email Address</span>
                  <span className="col cpd-newspan">
                    {this.props.User && this.props.User.PrimaryEmail
                      ? this.props.User.PrimaryEmail
                      : "-"}
                  </span>
                </div>
                <div className="row">
                  <span className="col cpd-span">CNIC</span>
                  <span className="col cpd-newspan">
                    {this.props.User && this.props.User.CNIC
                      ? this.props.User.CNIC
                      : "-"}
                  </span>
                </div>
                <div className="row">
                  <span className="col cpd-span">Institute</span>
                  <span className="col cpd-newspan">
                    {this.props.User
                      ? this.props.User.institute
                        ? this.props.User.institute
                        : this.props.User.Institute
                      : "-"}
                  </span>
                </div>
              </div>
              <div className="cpd-column flex-item-new">
                <div className="row">
                  <span className="col cpd-span">Year of Graduation</span>
                  <span className="col cpd-newspan">
                    {this.props.User && this.props.User.yearOfGraduation
                      ? this.props.User.yearOfGraduation
                      : "-"}
                  </span>
                </div>

                <div className="row">
                  <span className="col cpd-span">City</span>
                  <span className="col cpd-newspan">
                    {this.props.User
                      ? this.props.User.city
                        ? this.props.User.city
                        : this.props.User.City
                      : "-"}
                  </span>
                </div>
                <div className="row">
                  <span className="col cpd-span">Province</span>
                  <span className="col cpd-newspan">
                    {this.props.User && this.props.User.province
                      ? this.props.User.province
                      : "-"}
                  </span>
                </div>
                <div className="row">
                  <span className="col cpd-span">Registration Date</span>
                  <span className="col cpd-newspan">
                    {this.props.User.PEC_REG_DATE === null
                      ? "-"
                      : this.props.User &&
                        moment(this.props.User.PEC_REG_DATE).format(
                          "MMMM Do YYYY"
                        )
                      ? moment(
                          this.props.User.PEC_REG_DATE.split(" ")[0]
                        ).format("Do MMMM, YYYY")
                      : "-"}
                  </span>
                </div>
                <div className="row">
                  <span className="col cpd-span">Address</span>
                  <span className="col cpd-newspan">
                    {this.props.User && this.props.User.Primary_Address
                      ? this.props.User.Primary_Address
                      : "-"}
                  </span>
                </div>
              </div>
            </div>
            <div></div>
          </div>
          <div
            className="cpd-row-new"
            style={{ paddingLeft: "30px", paddingBottom: "10px" }}
          >
            <span style={{ color: "#20a84c", "font-weight": "700" }}>
              <a
                style={{ color: "#20a84c", textDecoration: "underline" }}
                href="https://portal.pec.org.pk/Login/Login"
              >
                To update your profile information, please click here
              </a>
            </span>
          </div>
          <div className="cpd-profile-detail cpd-row">
            <div className="cpd-column flex-item">
              <span className="heading-cpd">CPD Profile History</span>
              <div className="cpd-block-points">
                <div>
                  <span className="points-detail-cpd">
                    Total CPD Points since registration
                  </span>
                  <span className="fw-600-fs-15 cpd-profile-points">
                    {this.state.allPointsSum}
                  </span>
                </div>
                <div>
                  <span className="points-detail-cpd">
                    CPD Points for last three years
                  </span>
                  <span className="fw-600-fs-15 cpd-profile-points">
                    {this.state.totalPoints}
                  </span>
                </div>
                <div>
                  <span className="points-detail-cpd">
                    CPD Points for last five years
                  </span>
                  <span className="fw-600-fs-15 cpd-profile-points">
                    {this.state.fiverYearSum}
                  </span>
                </div>
              </div>
              <Accordion allowZeroExpanded>
                {this.state.year &&
                  this.state.year.map((item, index) => (
                    <AccordionItem onClick={() => this.accordionclick(item)}>
                      <AccordionItemHeading>
                        <AccordionItemButton>{item}</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <Accordion allowZeroExpanded>
                          {this.state.uniquecat &&
                            this.state.uniquecat.map((item1, index1) => (
                              <AccordionItem
                                onClick={() => this.subaccordionclick(item1)}
                              >
                                <AccordionItemHeading>
                                  <AccordionItemButton>
                                    {item1}
                                  </AccordionItemButton>
                                </AccordionItemHeading>
                                {this.state.selectedCategory &&
                                  this.state.selectedCategory.map((item2) => (
                                    <AccordionItemPanel>
                                      <span className="acc-profile-span">
                                        {item2.final_approved_points === null
                                          ? item2.cpd_points_pec
                                          : item2.final_approved_points}
                                      </span>
                                      <div className="acc-profile-div-heading">
                                        {item2.subcategory}
                                      </div>
                                      <div className="acc-profile-div">
                                        Status |{" "}
                                        {item2.status === "Pending" && (
                                          <span style={{ color: "Orange" }}>
                                            {item2.status}
                                          </span>
                                        )}
                                        {item2.status === "Rejected" && (
                                          <span style={{ color: "red" }}>
                                            Rejected
                                          </span>
                                        )}
                                        {item2.status === "Not Approved" && (
                                          <span style={{ color: "red" }}>
                                            Rejected
                                          </span>
                                        )}
                                        {item2.status === "Approved" && (
                                          <span style={{ color: "Green" }}>
                                            {item2.status}
                                          </span>
                                        )}
                                      </div>
                                      <div className="acc-profile-div">
                                        {moment(item2.date).format(
                                          "DD-MM-YYYY"
                                        )}
                                      </div>
                                      <div className="acc-profile-div">
                                        {item2.status === "Rejected" && (
                                          <>
                                            <span
                                              style={{ fontWeight: "bold" }}
                                            >
                                              Rejection Reason:{" "}
                                            </span>
                                            <span>{item2?.remarks}</span>
                                          </>
                                        )}
                                      </div>
                                      <div className="acc-profile-btn">
                                        <Tooltip
                                          title="View More"
                                          placement="bottom"
                                        >
                                          <button
                                            className="btn btn-outline-success btn-sm"
                                            onClick={() => {
                                              this.openModal(
                                                item2?.submission_id
                                              );
                                            }}
                                          >
                                            <VisibilityIcon />
                                          </button>
                                        </Tooltip>
                                      </div>
                                    </AccordionItemPanel>
                                  ))}
                              </AccordionItem>
                            ))}
                        </Accordion>
                      </AccordionItemPanel>
                    </AccordionItem>
                  ))}
              </Accordion>
            </div>
            <div className="cpd-column flex-item">
              <span className="heading-cpd">Claim CPD Points</span>
              <Link
                to={"/CPDBriefs"}
                style={{
                  textAlign: "left",
                  marginLeft: "20px",
                  marginTop: "10px",
                }}
                className="bold font_sans fz_13"
                target="_blank"
                // download
              >
                How to Earn CPD Points
              </Link>
              <div className="pd-profile-claim-box-group pd-profile-claim-box-group-margin">
                <div
                  onClick={() =>
                    this.btnclick(
                      "Formal Education",
                      "Post Graduate Diploma (PGD)"
                    )
                  }
                  className="cpd-profile-claim-box cpd-claim-box-marg"
                >
                  <span>
                    Formal Education
                    <br />
                    <span style={{ fontSize: "9px" }}>
                      {" "}
                      Max 4 Credit Points/Year
                    </span>
                  </span>
                </div>
                <div
                  onClick={() =>
                    this.btnclick(
                      "Work Based Activities",
                      "Work Experience (Engineering related work including relevant aspects of Management)"
                    )
                  }
                  className="cpd-profile-claim-box"
                >
                  <span>
                    Work Based Activities
                    <br />
                    <span style={{ fontSize: "9px" }}>
                      {" "}
                      Max 2 Credit Points/Year
                    </span>
                  </span>
                </div>
              </div>
              <div className="pd-profile-claim-box-group">
                <div
                  onClick={() =>
                    this.btnclick(
                      "Developmental Activities",
                      "Membership of a recognized professional  association/ body"
                    )
                  }
                  className="cpd-profile-claim-box cpd-claim-box-marg"
                >
                  <span>
                    Developmental Activities
                    <br />
                    <span style={{ fontSize: "9px" }}>
                      {" "}
                      Max 3 Credit Points/Year
                    </span>
                  </span>
                </div>
                <div
                  onClick={() =>
                    this.btnclick("Individual  Activities", "Invited lecture ")
                  }
                  className="cpd-profile-claim-box"
                >
                  <span>
                    Individual Activities
                    <br />
                    <span style={{ fontSize: "9px" }}>
                      {" "}
                      Max 3 Credit Points/Year
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="cpd-row">
            <div className="cpd-column flex-item">
              <span className="heading-cpd">Webinars</span>
              <div>
                <span className="card-heading">Attended Webinars</span>
                {this.state.topthreewebinar &&
                  this.state.topthreewebinar.map((item, index) => (
                    <a
                      onClick={() => this.preview(item.CourseID)}
                      className="cards-ahref"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ cursor: "pointer" }}
                    >
                      <div
                        className="card cpd-card"
                        style={{ "max-height": "100px!important" }}
                      >
                        <div className="card-horizontal">
                          <div className="img-square-wrapper">
                            <img
                              className=""
                              style={{
                                "max-width": "160px",
                                "max-height": "100px",
                              }}
                              src={
                                urlImageUrl +
                                item.Image_URL.slice(0, 7) +
                                "\\" +
                                item.Image_URL.slice(7)
                              }
                              alt="Card image cap"
                            />
                          </div>
                          <div className="card-body card-body-cpd">
                            <p className="card-text">{item.Course_Name}</p>
                            <p className="card-text">By: {item.FullName}</p>
                          </div>
                        </div>
                      </div>
                    </a>
                  ))}

                {this.state.attendflag === true &&
                  this.state.remaningwebinar &&
                  this.state.remaningwebinar.map((item, index) => (
                    <a className="cards-ahref">
                      <div
                        className="card cpd-card"
                        style={{ "max-height": "100px!important" }}
                      >
                        <div className="card-horizontal">
                          <div className="img-square-wrapper">
                            <img
                              className=""
                              style={{
                                "max-width": "160px",
                                "max-height": "100px",
                              }}
                              src={
                                urlImageUrl +
                                item.Image_URL.slice(0, 7) +
                                "\\" +
                                item.Image_URL.slice(7)
                              }
                              alt="Card image cap"
                            />
                          </div>
                          <div className="card-body card-body-cpd">
                            <p className="card-text">{item.Course_Name}</p>
                            <p className="card-text">By: {item.FullName}</p>
                          </div>
                        </div>
                      </div>
                    </a>
                  ))}

                <span className="card-see-all">
                  <a
                    onClick={this.attendwebinar}
                    style={{ cursor: "pointer" }}
                    className="cards-ahref"
                  >
                    {this.state.seeAll ? "See Less" : "See All"}
                  </a>
                </span>
              </div>
              <div>
                <span className="card-heading">Registered Webinars</span>
                {this.state.topthreeregistered &&
                  this.state.topthreeregistered.map((item, index) => (
                    <a className="cards-ahref">
                      <div
                        className="card cpd-card"
                        style={{ "max-height": "100px!important" }}
                      >
                        <div className="card-horizontal">
                          <div className="img-square-wrapper">
                            <img
                              className=""
                              style={{
                                "max-width": "160px",
                                "max-height": "100px",
                              }}
                              src={
                                urlImageUrl +
                                item.Image_URL.slice(0, 7) +
                                "\\" +
                                item.Image_URL.slice(7)
                              }
                              alt="Card image cap"
                            />
                          </div>
                          <div className="card-body card-body-cpd">
                            <p
                              className="card-text"
                              style={{ lineHeight: "initial" }}
                            >
                              {item.Course_Name}
                            </p>
                            <p className="card-text">By: {item.FullName}</p>
                          </div>
                        </div>
                      </div>
                    </a>
                  ))}
                {this.state.flag === true &&
                  this.state.remaningregistered &&
                  this.state.remaningregistered.map((item, index) => (
                    <a className="cards-ahref">
                      <div
                        className="card cpd-card"
                        style={{ "max-height": "100px!important" }}
                      >
                        <div className="card-horizontal">
                          <div className="img-square-wrapper">
                            <img
                              className=""
                              style={{
                                "max-width": "160px",
                                "max-height": "100px",
                              }}
                              src={
                                urlImageUrl +
                                item.Image_URL.slice(0, 7) +
                                "\\" +
                                item.Image_URL.slice(7)
                              }
                              alt="Card image cap"
                            />
                          </div>
                          <div className="card-body card-body-cpd">
                            <p
                              className="card-text"
                              style={{ lineHeight: initial }}
                            >
                              {item.Course_Name}
                            </p>
                            <p className="card-text">By: {item.FullName}</p>
                          </div>
                        </div>
                      </div>
                    </a>
                  ))}
                <span className="card-see-all">
                  <a
                    onClick={this.registeredWebinar}
                    style={{ cursor: "pointer" }}
                    className="cards-ahref"
                  >
                    {this.state.seeAll1 ? "See Less" : "See All"}
                  </a>
                </span>
              </div>
            </div>
            <div className="cpd-column flex-item">
              <span className="heading-cpd">Online Courses</span>
              <div>
                <span className="card-heading">Enrolled Courses</span>
                {/* <a className="cards-ahref" href="#">
                  <div className="card cpd-card" style={{"max-height": "100px!important"}}>
                    <div className="card-horizontal">
                      <div className="img-square-wrapper">
                        <img
                          className=""
                          src="http://via.placeholder.com/80x80"
                          alt="Card image cap"
                        />
                      </div>
                      <div className="card-body card-body-cpd">
                        <h6 class="card-title">Coming Soon</h6>
                      </div>
                    </div>
                  </div>
                </a> */}
                <h2 className="mtb-50">Coming Soon</h2>
                {/* <span className="card-see-all">
                  <a className="cards-ahref" href="#">
                    See All
                  </a>
                </span> */}
              </div>
              <div>
                <span className="card-heading">Completed Courses</span>
                {/* <a className="cards-ahref" href="#">
                  <div className="card cpd-card" style={{"max-height": "100px!important"}}>
                    <div className="card-horizontal">
                      <div className="img-square-wrapper">
                        <img
                          className=""
                          src="http://via.placeholder.com/80x80"
                          alt="Card image cap"
                        />
                      </div>
                      <div className="card-body card-body-cpd">
                        <h6 class="card-title">Coming Soon</h6>
                      </div>
                    </div>
                  </div>
                </a> */}
                <h2 className="mtb-50">Coming Soon</h2>
                {/* <span className="card-see-all">
                  <a className="cards-ahref" href="#">
                    See All
                  </a>
                </span> */}
              </div>
            </div>
          </div>
          <div className="cpd-row epe-row" style={{ display: "none" }}>
            <div className="cpd-column flex-item">
              <span className="heading-cpd">EPE</span>
              <div>
                <span className="card-heading">Exams Completed</span>
                {/* <a className="cards-ahref" href="#">
                  <div className="card cpd-card" style={{"max-height": "100px!important"}}>
                    <div className="card-horizontal">
                      <div className="card-body exams-body-cpd">
                        <h6 class="card-title">Coming Soon</h6>
                      </div>
                      <div className="card-text status-exams-cpd">
                        <span style={{ color: "#20a84c" }}>Passed</span>
                      </div>
                    </div>
                  </div>
                </a> */}
                <h2 className="mtb-50">Coming Soon</h2>

                {/* <span className="card-see-all">
                  <a className="cards-ahref" href="#">
                    See All
                  </a>
                </span> */}
              </div>
              <div>
                <span className="card-heading">Upcoming Exams</span>
                {/* <a className="cards-ahref" href="#">
                  <div className="card cpd-card" style={{"max-height": "100px!important"}}>
                    <div className="card-horizontal">
                      <div className="card-body exams-body-cpd">
                        <h6 class="card-title">Coming Soon</h6>
                      </div>
                      <div className="card-text status-exams-cpd">
                        21/9/2020
                      </div>
                    </div>
                  </div>
                </a> */}
                <h2 className="mtb-50">Coming Soon</h2>
                {/* <span className="card-see-all">
                  <a className="cards-ahref" href="#">
                    See All
                  </a>
                </span> */}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    User: state.userData.user,
    userdatacomplete: state.userData.userdatacomplete,
  };
}

export default connect(mapStateToProps, {
  cpd_claim_user,
  cpd_profile_data_byYear,
  getUserClaimsBySubmissionId,
  attended_webinars_profile,
  registered_webinar_profile,
})(CpdFormProfile);
