import React from 'react';
import Header from './Header';
import Footer from './Footer';
import CpdActivitiesSIDEbar from './CpdActivitiesSIDEbar'
import Pdf from '../assets/pdf/Revised Application Form & Criteria  for Resource Persons.docx';

class ApplicationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [
        {
            name: "Home",
            url: "/"
        },
          {
          name: "Introduction to CPD ",
          url: "/Introduction"
        },
        {
          name: "CPD Byelaws",
          url: "/byelaws"
        },
        {
          name: "Engineering Professional Development Committee (EPDC)",
          url: "epdc",
          type:"button",
          "child":[
            {
              name: "Introduction",
              url: "/epdc/intro",
            },
            {
              name: "Organogram",
              url: "/Organogram",
            }
          ]

        },
        {
          name: "CPD Guideline Manual",
          url: "/GuideLineMauals",
       }, 
       {
        name: "CPD Relaxed Policy",
        url: "/CPDRelaxPolicy",
        },
        {
          name: "CPD Brief",
          url: "/CPDBriefs",
       }
       ,
       {
        name: " PEC Approved Resource Person",
        url: "psrp",
        type:"button",
        "child":[
          // {
          //   name: "List of Resourse Person",
          //   url: "/CPDListofResourcePerson",
          // },
          {
            name: "Criteria for Resourse Person",
            url: "/CPDResourcePersonCriteria",
          },
          {
            name: "Application Form",
            url: "/ApplicationForm",
          }
        ]
      },
     
    ]
    }
  }
  componentDidMount(){
    document.title = "Introduction";
}
render() {
    return (
      <React.Fragment>
        <section  className="backco_white" style={{ fontFamily: 'Arial' }}>
          <Header />
          <div className="row col_margin">
          <div className="col-md-3 col_padding sidebar_container" style={{ padding: '2%' }}>
              <CpdActivitiesSIDEbar data={this.state.data} />
            </div>
            <div className="col-md-7 col_padding intr_mr">
              <h2 className="text-center" style={{ margin: '28px 0px 0px 1px' }}>CPD Resource Person Application Form</h2>
              <div className="row">
                <div className="col-md-12 " style={{ padding: '2%',marginLeft:"30px" }}>
                  <div className="into-p">
                    <p>
                    To View Application Form for Resouce Person Click Below!
                    </p>
                  </div>
                  <i className="far fa-file-pdf fa-1x co_black bold"></i>&nbsp;
                <b className="pdf_text">Resource Person Application Form</b>&nbsp;&nbsp;
                <a href={Pdf} className="btn btn-success bold font_sans fz_14" 
                    target="_blank" download>Download</a>
                </div>
              </div>
            </div>
            <div className="col-md-1">
            </div>
          </div>
          {/*<Footer/>*/}
        </section>
      </React.Fragment>
    )
  };
}
export default ApplicationForm;