import React, { Component } from "react";
import { connect } from "react-redux";
import CreatableSelect from "react-select/lib/Creatable";
import VideoThumbnail from "react-video-thumbnail"; // use npm published version
import { webinarFeedback } from "../../redux/admin/webinar/webinarActions";
import Select from "react-select";
import Feedback_Webinar from "./Feedback_Webinar";
import "video-react/dist/video-react.css"; // import css
import { Player } from "video-react";
import { urlImageUrl } from "../../config/urlapi";
import WebNotif from "../WebNotif";
import Loader from "react-loader-spinner";
import Dropzone from "react-dropzone";
import _ from "lodash";
import { withRouter } from "react-router";
import img from "../../assets/image/designer_icon/Upload-files-here.png";
var obj = new WebNotif();
const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});
const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};
const groupBadgeStyles = {
  backgroundColor: "#EBECF0",
  borderRadius: "2em",
  color: "#172B4D",
  display: "inline-block",
  fontSize: 12,
  fontWeight: "normal",
  lineHeight: "1",
  minWidth: 1,
  padding: "0.16666666666667em 0.5em",
  textAlign: "center",
};
var pushKey = null;

const acceptedImageFileTypes =
  "image/x-png, image/png, image/jpg, image/jpeg, image/gif";
const acceptedImageFileTypesArray = acceptedImageFileTypes
  .split(",")
  .map((item) => {
    return item.trim();
  });

const acceptedVideoFileTypes =
  "video/mp4, video/m4a, video/mov,video/3gp, video/ogg, video/ogx, video/wmv, video/flv, video/avi";
const acceptedVideoFileTypesArray = acceptedVideoFileTypes
  .split(",")
  .map((item) => {
    return item.trim();
  });
let categoriess = [
  { value: "25", label: "All Categories", disabled: false },
  { value: "1", label: "Civil", disabled: false },
  { value: "2", label: "Electrical", disabled: false },
  { value: "3", label: "Mechanical", disabled: false },
  { value: "4", label: "Chemical", disabled: false },
  { value: "5", label: "Electronics", disabled: false },
  { value: "6", label: "Metallurgy", disabled: false },
  { value: "7", label: "Agricultural", disabled: false },
  { value: "8", label: "Aeronautical", disabled: false },
  { value: "9", label: "Mining", disabled: false },
  { value: "10", label: "Petrogas", disabled: false },
  { value: "11", label: "Tele Communication", disabled: false },
  { value: "12", label: "Mechatronics", disabled: false },
  { value: "13", label: "Textile", disabled: false },
  { value: "14", label: "Biomedical", disabled: false },
  { value: "15", label: "Engineering Sciences", disabled: false },
  { value: "16", label: "Arch Engg", disabled: false },
  { value: "17", label: "Computer", disabled: false },
  { value: "18", label: "Geology", disabled: false },
  { value: "19", label: "Transport", disabled: false },
  { value: "20", label: "Poly", disabled: false },
  { value: "21", label: "Environment", disabled: false },
  { value: "22", label: "Urban", disabled: false },
  { value: "23", label: "Automotive", disabled: false },
  { value: "24", label: "Energy", disabled: false },
  { value: "26", label: "Management", disabled: false },
];
class AddCourse extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    username: "",
    image_url: null,
    video_url: null,
    file: null,
    showLoader: false,
    contentType: 0,
    loading: false,
    courseViewType: 0,
    isEdit: false,
    v_file: null,
    v_file_url: null,
    isUploading_image: false,
    isUploading_video: false,
    progress_video: 0,
    progress_image: 0,
    avatarURL: "",
    title: null,
    code: null,
    courseType: 0,
    price: null,
    description: null,
    validity: null,
    access_features: null,
    imageURL: null,
    videoURL: null,
    selectedOption: null,
    ioptions: [],
    instructor: null,
    selectedOptionCategory: null,
    category_options: null,
    seeInstructor: false,
    options: [],
    selectedCat: null,
    options1: [
      {
        label: "Group 1",
        options: [
          { label: "Group 1, option 1", value: "value_1" },
          { label: "Group 1, option 2", value: "value_2" },
        ],
      },
      { label: "A root option", value: "value_3" },
      { label: "Another root option", value: "value_4" },
    ],
    totalSession: null,
    sessionDuration: null,

    endDate: null,
    weeksTotal: null,
    courseTypeset: "",
    image64: null,
    imagebanner64: null,
    saveEnabled: true,
    feedback: "",
    //states I added
    feebackdetails: {
      userId: "",
      webinarId: "",
      feedback: "",
      owner: "",
      status: "",
    },
    webinardetails: {
      title: "",
      categoryplaceholder: "",
      category: [],
      description: "",
      publishDate: new Date(),
      webinarType: "",
      webinarUrl: "",
      userId: "",
      MeetingID: "",
      MeetingPWD: "",
      owner: "",
      files: {
        thumbnailImage: [],
        bannerImage: [],
        thumbnailVideo: [],
        recordedWebinarVideo: [],
      },
    },
    errors: "",
    coursecategorysample: null,
  };

  changeSaveStatus = () => {
    this.setState({ saveEnabled: !this.state.saveEnabled });
  };

  componentWillMount() {
    // debugger;
    if (this.props.courseType == "0") {
      this.setState({ courseTypeset: "Pec Courses" });
    } else if (this.props.courseType == "1") {
      this.setState({ courseTypeset: "Pec Training" });
    } else {
      this.setState({ courseTypeset: "Webinar" });
    }

    if (this.props.currentWebinarData) {
      const a = this.props.currentWebinarData;
      console.log(a);
      var commence_date = new Date(a.Commence_Date);
      var categoryplaceholder = "";
      a.category.map((item) => {
        var nitem = item.Name;
        categoryplaceholder += nitem + ",";
      });
      this.setState(
        {
          ...this.state,
          webinardetails: {
            ...this.state.webinardetails,
            title: a.Course_Name,
            description: a.Course_Description,
            publishDate: commence_date,
            webinarType: a.Type_Name,
            webinarUrl: a.Webinar_URL,
            MeetingID: a.zoomID,
            MeetingPWD: a.zoomPWD,
            categoryplaceholder: categoryplaceholder,
            userID: a.userID,
            current: this.props.User.UserID,
            emailcurrent: this.props.User.PrimaryEmail,
          },
          image64:
            urlImageUrl + a.Image_URL.slice(0, 7) + "\\" + a.Image_URL.slice(7),

          imagebanner64:
            urlImageUrl +
            a.BannerImage_URL.slice(0, 7) +
            "\\" +
            a.BannerImage_URL.slice(7),
        },
        () => {
          if (a.Preview_VideoURL) {
            this.setState({
              video_url:
                urlImageUrl +
                a.Preview_VideoURL.slice(0, 7) +
                "\\" +
                a.Preview_VideoURL.slice(7),
            });
          }
          if (a.Recorded_URL === "" || a.Recorded_URL === null) {
          } else {
            // if (a.Type_Name == "Recorded Webinar" || ) {
            this.setState({
              v_file:
                urlImageUrl +
                a.Recorded_URL.slice(0, 7) +
                "\\" +
                a.Recorded_URL.slice(7),
            });
            // }
          }
        }
      );
    }
  }

  componentDidMount() {
    if (this.props.formData.length !== 0) {
      let thumbnailImage;
      let thumbnailVideo;
      let recordedWebinarVideo;
      let bannerImage;
      Object.keys(this.props.formData[1].file).map((index) => {
        if (this.props.formData[1].file[index].name === "thumbnailImage") {
          thumbnailImage =
            urlImageUrl + "uploads/" + this.props.formData[1].file[index].file;
        } else if (
          this.props.formData[1].file[index].name === "thumbnailVideo"
        ) {
          thumbnailVideo =
            urlImageUrl + "uploads/" + this.props.formData[1].file[index].file;
        } else if (
          this.props.formData[1].file[index].name === "recordedWebinarVideo"
        ) {
          recordedWebinarVideo =
            urlImageUrl + "uploads/" + this.props.formData[1].file[index].file;
        } else if (this.props.formData[1].file[index].name === "bannerImage") {
          bannerImage =
            urlImageUrl + "uploads/" + this.props.formData[1].file[index].file;
        }
      });
      this.setState({
        webinardetails: this.props.formData[0].data,
        image64: thumbnailImage,
        video_url: thumbnailVideo,
        v_file: recordedWebinarVideo,
        imagebanner64: bannerImage,
      });
    } else {
      this.setState(
        {
          webinardetails: {
            ...this.state.webinardetails,
            userId: this.props.User.UserID,
          },
        },
        () => {
          if (this.props.isEdit === true) {
            this.setState({
              webinardetails: {
                ...this.state.webinardetails,
                owner: this.props.currentWebinarData.user_id,
              },
            });
          }
        }
      );
    }
    this.props.onRef(this);
  }
  handleChange1 = (event) => {
    this.setState({
      webinardetails: {
        ...this.state.webinardetails,
        [event.target.name]: event.target.value,
      },
    });
  };

  handlefeedback = (event) => {
    this.setState({
      ...this.state,
      [event.target.name]: [event.target.value],
    });
  };

  handleChangeStart = (date) => {
    this.setState({
      webinardetails: { ...this.state.webinardetails, publishDate: date },
    });
  };

  verifyImageFile = (files) => {
    if (files && files.length > 0) {
      const currentFile = files[0];
      const currentFileType = currentFile.type;
      const currentFileSize = currentFile.size;
      if (currentFileSize > 2e6) {
        obj.createNotification(
          "error",
          "Image should be less than 2mbs, Try Again"
        );
        return false;
      }
      if (!acceptedImageFileTypesArray.includes(currentFileType)) {
        obj.createNotification(
          "error",
          "This file type is not allowed, Only Images are allowed"
        );

        return false;
      }
      return true;
    }
  };
  verifyVideoFile = (files) => {
    if (files && files.length > 0) {
      const currentFile = files[0];
      const currentFileType = currentFile.type;
      const currentFileSize = currentFile.size;
      if (currentFileSize > 1e9) {
        obj.createNotification("error", "Video Cant be Larger than 1gb");
        return false;
      }
      if (!acceptedVideoFileTypesArray.includes(currentFileType)) {
        obj.createNotification(
          "error",
          "This file type is not allowed, Only Video are allowed"
        );
        return false;
      }
      return true;
    }
  };

  handleOnDropBannerImage = (files, rejectedFiles) => {
    if (rejectedFiles && rejectedFiles.length > 0) {
      this.verifyImageFile(rejectedFiles);
    }
    if (files && files.length > 0) {
      if (this.verifyImageFile(files)) {
        const currentFile = files[0];
        const reader = new FileReader();
        reader.addEventListener(
          "load",
          () => {
            this.setState({ imagebanner64: reader.result });
          },
          false
        );
        reader.readAsDataURL(currentFile);

        obj.createNotification("success", "Image Dropped");
        this.setState({
          webinardetails: {
            ...this.state.webinardetails,
            files: {
              ...this.state.webinardetails.files,
              bannerImage: files[0],
            },
          },
        });
      }
    }
  };
  handleOnDropImage = (files, rejectedFiles) => {
    if (rejectedFiles && rejectedFiles.length > 0) {
      this.verifyImageFile(rejectedFiles);
    }
    if (files && files.length > 0) {
      if (this.verifyImageFile(files)) {
        const currentFile = files[0];
        const reader = new FileReader();
        reader.addEventListener(
          "load",
          () => {
            this.setState({ image64: reader.result });
          },
          false
        );
        reader.readAsDataURL(currentFile);

        obj.createNotification("success", "Image Dropped");
        this.setState({
          webinardetails: {
            ...this.state.webinardetails,
            files: {
              ...this.state.webinardetails.files,
              thumbnailImage: files[0],
            },
          },
        });
      }
    }
  };
  handleOnDropVideo = (files, rejectedFiles) => {
    if (rejectedFiles && rejectedFiles.length > 0) {
      this.verifyVideoFile(rejectedFiles);
    }
    if (files && files.length > 0) {
      if (this.verifyVideoFile(files)) {
        const currentFile = files[0];
        const reader = new FileReader();
        reader.addEventListener(
          "load",
          () => {
            this.setState({ video_url: reader.result });
          },
          false
        );
        reader.readAsDataURL(currentFile);
        obj.createNotification("success", "Preview Video Dropped");

        this.setState({
          webinardetails: {
            ...this.state.webinardetails,
            files: {
              ...this.state.webinardetails.files,
              thumbnailVideo: files[0],
            },
          },
        });
      }
    }
  };
  handleOnDropRecordedVideo = (files, rejectedFiles) => {
    if (rejectedFiles && rejectedFiles.length > 0) {
      this.verifyVideoFile(rejectedFiles);
    }
    if (files && files.length > 0) {
      if (this.verifyVideoFile(files)) {
        obj.createNotification("success", "Recorded Webinar Video Dropped");
        const currentFile = files[0];
        const reader = new FileReader();
        reader.addEventListener(
          "load",
          () => {
            this.setState({ v_file: reader.result });
          },
          false
        );
        reader.readAsDataURL(currentFile);
        this.setState({
          webinardetails: {
            ...this.state.webinardetails,
            files: {
              ...this.state.webinardetails.files,
              recordedWebinarVideo: files[0],
            },
          },
        });
      }
    }
  };

  handlerExample = (options) => {
    var output = [];
    var categories = [];
    if (options) {
    }

    _.map(options, function (option) {
      output = output.concat([
        "Multiselect Chosen Option = {\n",
        "\tid: ",
        option.id,
        "\n",
        "\tname: ",
        option.name,
        "\n",
        "\tsize: ",
        option.size,
        "\n\t};",
      ]);
      categories.push({ name: option.name, categoryid: option.id });
    });
    this.setState({
      webinardetails: { ...this.state.webinardetails, category: categories },
    });
  };
  handleCategoryChange = (selectedOptions) => {
    let selected = null;
    if (selectedOptions.length === 0) {
      for (let j = 0; j < categoriess.length; j++) {
        categoriess[j] = { ...categoriess[j], disabled: false };
      }
    }
    for (let i = 0; i < selectedOptions.length; i++) {
      if (selectedOptions[i].label === "All Categories") {
        selected = selectedOptions.filter(
          (item) => item.label === "All Categories"
        );

        for (let j = 0; j < categoriess.length; j++) {
          if (categoriess[j].label !== "All Categories") {
            categoriess[j] = { ...categoriess[j], disabled: true };
          }
        }
      }
    }
    if (selected) {
      this.setState({
        ...this.state,
        webinardetails: {
          ...this.state.webinardetails,
          category: selected,
        },
      });
    } else {
      this.setState({
        ...this.state,
        webinardetails: {
          ...this.state.webinardetails,
          category: selectedOptions,
        },
      });
    }
  };

  validateEditData(data) {
    if (
      data.title == "" ||
      data.description == "" ||
      data.webinarType == "" ||
      this.state.image64 == null ||
      this.state.imagebanner64 == null
    ) {
      return { errors: "Fill out Required Fields", Isvalidated: false };
    }
    //else {
    // if (
    //   data.webinarType == "Recorded Webinar" ||
    //   data.webinarType == "PEB Webinar"
    // ) {
    //   if (data.files.recordedWebinarVideo == "") {
    //     return {
    //       errors:
    //         "Please upload a recorded webinar video in case of type recorded",
    //       Isvalidated: false,
    //     };
    //   }
    // }
    // if (data.webinarType == "Live Webinar") {
    //   if (data.webinarUrl == "") {
    //     return {
    //       errors: "Please webinar url in case of type live",
    //       Isvalidated: false,
    //     };
    //   }
    // }
    // return { errors: "", Isvalidated: true };
    //  }
    return { errors: "", Isvalidated: true };
  }
  is_url = (str) => {
    let regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
    if (regexp.test(str)) {
      return true;
    } else {
      return false;
    }
  };
  validateData(data) {
    if (
      data.title == "" ||
      data.webinarType == "" ||
      data.description == "" ||
      this.state.image64 == null ||
      data.category.length == 0 ||
      this.state.imagebanner64 == null
    ) {
      return { errors: "Fill out all required fields", Isvalidated: false };
    } else {
      // if (data.webinarType == "Live Webinar") {
      //   // if (data.webinarUrl == "") {
      //   //   return {
      //   //     errors: "In Case of Type Live Webinar, Please Provide a Url",
      //   //     Isvalidated: false,
      //   //   };
      //   // }
      //    //if {
      //     console.log(this.is_url(data.webinarUrl));
      //     if (!this.is_url(data.webinarUrl)) {
      //       return { errors: "Url Not Valid.", Isvalidated: false };
      //    // }
      //   }
      // }
      // if (
      //   data.webinarType == "Recorded Webinar" ||
      //   data.webinarType == "PEB Webinar"
      // ) {
      //   if (data.files.recordedWebinarVideo == "") {
      //     return {
      //       errors: "Please upload a recorded webinar video",
      //       Isvalidated: false,
      //     };
      //   }
      // }

      return { errors: "", Isvalidated: true };
    }
  }
  onSubmit = (e) => {
    e.preventDefault();
    const datatosend = this.state.webinardetails;
    if (this.props.isEdit == true) {
      const resultValidation = this.validateEditData(datatosend);
      if (resultValidation.Isvalidated) {
        this.props.editwebinarDetails({
          webinardetails: this.state.webinardetails,
        });
      } else {
        obj.createNotification("error", resultValidation.errors);
      }
    } else {
      const resultValidation = this.validateData(datatosend);
      if (resultValidation.Isvalidated) {
        this.changeSaveStatus();
        const fd = new FormData();
        fd.append(
          "thumbnailImage",
          this.state.webinardetails.files.thumbnailImage
        );
        fd.append(
          "thumbnailVideo",
          this.state.webinardetails.files.thumbnailVideo
        );
        fd.append(
          "recordedWebinarVideo",
          this.state.webinardetails.files.recordedWebinarVideo
        );
        fd.append("bannerImage", this.state.webinardetails.files.bannerImage);

        if (this.props.flag == true) {
          this.props.editwebinarDetails({
            webinardetails: this.state.webinardetails,
          });
        } else {
          this.props.setwebinarDetails({
            webinardetails: this.state.webinardetails,
            fd: fd,
          });
        }
      } else {
        obj.createNotification("error", resultValidation.errors);
      }
    }
  };

  //END OF THE FUNCTIONS I MADE (TAYYAB)

  handleChangeEnd = (endDate) => {
    this.setState({ endDate: endDate });
  };
  handleChange = (selectedOption) => {
    this.setState({ selectedCat: selectedOption });
  };
  instructorHandleChange = (selectedOption) => {
    this.setState({ selectedOption });
  };
  handleUploadStart = (event) => {
    this.setState({
      file: URL.createObjectURL(event),
    });
    this.setState({
      isUploading_image: true,
      progress_image: 0,
      isEdit: false,
    });
  };
  handleProgress = (progress_image) => {
    this.setState({ progress_image });
  };
  handleUploadError = (error) => {
    this.setState({ isUploading_image: false });
  };
  handleUploadSuccess = (filename) => {};
  handleToggle = () => {
    this.setState({ seeInstructor: !this.state.seeInstructor }, () => {});
  };
  handleapprove = () => {
    this.setState(
      {
        feebackdetails: {
          userId: this.props.User.UserID,
          webinarId: this.props.currentWebinarData.CourseID,
          feedback: this.state.feedback,
          owner: this.props.currentWebinarData.user_id,
          status: 1,
          email: this.props.User.PrimaryEmail,
        },
      },
      () => {
        const data = this.state.feebackdetails;
        this.props
          .webinarFeedback(data)
          .then((res) => {
            obj.createNotification("success", "Successfully Saved Feedback");
          })
          .catch((err) => {
            console.log(err);
          });
      }
    );
  };
  handlereject = () => {
    this.setState(
      {
        feebackdetails: {
          userId: this.props.User.UserID,
          webinarId: this.props.currentWebinarData.CourseID,
          feedback: this.state.feedback,
          owner: this.props.currentWebinarData.user_id,
          status: 0,
          email: this.props.User.PrimaryEmail,
        },
      },
      () => {
        const data = this.state.feebackdetails;
        this.props
          .webinarFeedback(data)
          .then((res) => {
            obj.createNotification("success", "Successfully Saved Feedback");
          })
          .catch((err) => {
            console.log(err);
          });
      }
    );
  };
  validateForm = (data) => {
    for (let i = 0; i < data.length; i++) {
      if (data[i].value == null || data[i].value == "") {
        obj.createNotification("error", data[i].slug + " is required");
        return false;
        break;
      }
    }
    return true;
  };
  removeImage = () => {
    this.setState({
      image64: null,
      webinardetails: {
        ...this.state.webinardetails,
        files: { ...this.state.webinardetails.files, thumbnailImage: true },
      },
    });
  };
  removeBannerImage = () => {
    this.setState({
      imagebanner64: null,
      webinardetails: {
        ...this.state.webinardetails,
        files: { ...this.state.webinardetails.files, bannerImage: true },
      },
    });
  };

  removeVideo = () => {
    this.setState({
      video_url: null,
      webinardetails: {
        ...this.state.webinardetails,
        files: { ...this.state.webinardetails.files, thumbnailVideo: true },
      },
    });
  };
  removeVideoRecorded = () => {
    this.setState({
      v_file: null,
      webinardetails: {
        ...this.state.webinardetails,
        files: {
          ...this.state.webinardetails.files,
          recordedWebinarVideo: true,
        },
      },
    });
  };
  handleUploadStart1 = (event) => {
    this.setState({
      v_file_url: null,
      v_file: null,
    });

    this.setState({
      v_file_url: URL.createObjectURL(event),
    });
    this.setState({ isUploading_video: true, progress_video: 0 });
  };
  handleProgress1 = (progress_video) => {
    if (progress_video >= this.state.progress_video) {
      this.setState({ progress_video });
    }
  };
  handleUploadError1 = (error) => {
    this.setState({ isUploading_video: false });
    console.error(error);
  };
  handleUploadSuccess1 = (filename) => {};
  render() {
    console.log("INSIDE ADD WEBINAR / COURSE");
    return (
      <React.Fragment>
        <form onSubmit={this.onSubmit}>
          <WebNotif />
          <div className=" col-md-12">
            <div className="row">
              <div className="col-md-7">
                <h5 className="card-title regular_font">
                  {this.props.courseType == 0
                    ? "PEC Courses "
                    : this.props.courseType == 1
                    ? "PEC Training "
                    : "Webinars "}
                  Details
                </h5>
              </div>
            </div>
          </div>
          <hr />
          <div className="col-md-12">
            <div className="row">
              <div className="form-group col-md-6">
                <label>
                  Title <span className="clr-red">*</span>
                </label>
                <input
                  type="text"
                  name="title"
                  value={this.state.webinardetails.title}
                  placeholder={`Enter title.`}
                  className="form-control admin-form-control"
                  onChange={this.handleChange1}
                  required
                />
              </div>
              <div className="form-group  col-md-6 fs-14">
                <label>
                  Category <span className="clr-red">*</span>
                </label>

                <Select
                  isMulti
                  options={categoriess}
                  value={this.state.webinardetails.category}
                  onChange={this.handleCategoryChange}
                  placeholder={this.state.webinardetails.categoryplaceholder}
                  // isOptionDisabled={(option) => console.log(option)}
                />
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>
                    Description <span className="clr-red">*</span>
                  </label>
                  <textarea
                    required
                    className="form-control admin-form-control"
                    rows="6"
                    value={this.state.webinardetails.description}
                    name="description"
                    placeholder={`Enter ${this.state.courseTypeset} description`}
                    onChange={this.handleChange1}
                  />
                </div>
                <div className="col-md-3">
                  <div className="form-group" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <div className="row">
                    <div className="col-md-6">
                      <label className="fs-14">
                        Add Thumbnail (150x150)
                        <span className="clr-red">*</span>
                      </label>
                      <br />

                      {this.state.image64 !== null ? (
                        <div
                          onClick={this.removeImage}
                          className="pointer"
                          style={{
                            position: "absolute",
                            color: "#20A84C",
                            height: "150px",
                            width: "185px",
                            paddingLeft: "5px",
                            paddingTop: "2px",
                          }}
                        >
                          <img
                            className="preview-image"
                            src={this.state.image64}
                          />
                          <i
                            className="fa fa-times-circle preview-close-icon fa-lg"
                            style={{ color: "#20A84C" }}
                          />
                        </div>
                      ) : (
                        ""
                      )}

                      {this.state.image64 == null && (
                        <div
                          className="text-center"
                          style={{
                            color: "black",
                            border: "1px solid #719e54",
                            padding: 10,
                            borderRadius: 4,
                            cursor: "pointer",
                            height: "150px",
                            width: "auto",
                            padding: "15px",
                          }}
                        >
                          {" "}
                          <Dropzone
                            name="dropzone1"
                            accept="image/*"
                            onDrop={this.handleOnDropImage}
                            maxSize={2e6}
                            multiple={false}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <section>
                                <div {...getRootProps()}>
                                  <input {...getInputProps()} />
                                  <i
                                    className="fa fa-image fa-3x pb-10"
                                    style={{ color: "#20A84C" }}
                                  />
                                  <br />
                                  <span className="fs-10">
                                    Click or Drag Image to Upload Here.
                                    <br />
                                    (x-png, png, jpg, jpeg, gif)
                                  </span>
                                </div>
                              </section>
                            )}
                          </Dropzone>
                        </div>
                      )}
                    </div>

                    {this.state.video_url == null ||
                    this.state.video_url == undefined ? (
                      <div className="col-md-6">
                        <label className="fs-14">Add Video</label>
                        <br />
                        <div
                          className="text-center"
                          style={{
                            color: "black",
                            border: "1px solid #719e54",
                            padding: 10,
                            borderRadius: 4,
                            cursor: "pointer",
                            height: "150px",
                            width: "auto",
                            padding: "15px",
                          }}
                        >
                          <Dropzone
                            name="dropzone2"
                            accept="video/*"
                            onDrop={this.handleOnDropVideo}
                            maxSize={5e8}
                            multiple={false}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <section>
                                <div {...getRootProps()}>
                                  <input {...getInputProps()} />
                                  <i
                                    className="fa fa-film fa-3x pb-10"
                                    style={{ color: "#20A84C" }}
                                  >
                                    {" "}
                                  </i>

                                  <br />
                                  <span className="fs-10">
                                    Click or Drag Video to Upload here (mp4,
                                    m4a, mov, 3gp, ogg, ogx, wmv, flv, avi)
                                  </span>
                                </div>
                              </section>
                            )}
                          </Dropzone>
                        </div>
                      </div>
                    ) : (
                      <div className="col-md-6">
                        <label className="fs-14">
                          Preview Video <span className="clr-red">*</span>
                        </label>

                        <div
                          className="pointer"
                          style={{
                            position: "absolute",
                            color: "#fc5757",
                            paddingLeft: "5px",
                            paddingTop: "2px",
                            right: "20px",
                            zIndex: "1000",
                          }}
                          onClick={this.removeVideo}
                        >
                          <i className="fa fa-times-circle fa-lg" />{" "}
                        </div>
                        <Player playsInline src={this.state.video_url} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.props.courseType == 2 ? (
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6 align-self-center">
                  <div className="form-group">
                    <label>
                      Webinar Type <span className="clr-red">*</span>
                    </label>

                    <select
                      className="form-control admin-form-control"
                      value={this.state.webinardetails.webinarType}
                      onChange={this.handleChange1}
                      name="webinarType"
                    >
                      <option value="">Select a webinar</option>
                      <option value="Live Webinar">Live Webinar By PEC</option>
                      <option value="Recorded Webinar">
                        Recorded Webinar By PEC{" "}
                      </option>
                      <option value="PEB Webinar">
                        Webinar by PEB (Professional Engineering Bodies){" "}
                      </option>
                    </select>
                  </div>
                </div>

                <div className="col-md-6">
                  <label className="fs-14">
                    Add Banner Image (800x400){" "}
                    <span className="clr-red">*</span>
                  </label>
                  <br />

                  {this.state.imagebanner64 !== null ? (
                    <div
                      onClick={this.removeBannerImage}
                      className="pointer"
                      style={{
                        position: "absolute",
                        color: "#20A84C",
                        height: "150px",
                        width: "auto",
                        paddingLeft: "5px",
                        paddingTop: "2px",
                      }}
                    >
                      <img
                        className="preview-image"
                        src={this.state.imagebanner64}
                      />
                      <i
                        className="fa fa-times-circle preview-close-icon fa-lg"
                        style={{ color: "#20A84C" }}
                      />
                    </div>
                  ) : (
                    ""
                  )}

                  {this.state.imagebanner64 == null && (
                    <div
                      className="text-center"
                      style={{
                        color: "black",
                        border: "1px solid #719e54",
                        padding: 10,
                        borderRadius: 4,
                        cursor: "pointer",
                        height: "150px",
                        width: "auto",
                        padding: "15px",
                      }}
                    >
                      {" "}
                      <Dropzone
                        name="dropzone1"
                        accept="image/*"
                        onDrop={this.handleOnDropBannerImage}
                        maxSize={2e6}
                        multiple={false}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section>
                            <div {...getRootProps()}>
                              <input {...getInputProps()} />
                              <i
                                className="fa fa-image fa-3x pb-10"
                                style={{ color: "#20A84C" }}
                              />
                              <br />
                              <span className="fs-10">
                                Click or Drag Image to Upload here (x-png, png,
                                jpg, jpeg, gif)
                              </span>
                            </div>
                          </section>
                        )}
                      </Dropzone>
                    </div>
                  )}
                </div>
              </div>

              {this.state.webinardetails.webinarType == "Live Webinar" ? (
                <div>
                  <div className="row col_margin mt_2v">
                    <div className="URL-BOX col-md-6  ptb_4v ">
                      {" "}
                      <span> URL </span>
                      <input
                        type="text"
                        name="webinarUrl"
                        value={this.state.webinardetails.webinarUrl}
                        onChange={this.handleChange1}
                      />
                    </div>
                  </div>

                  <div className="row" style={{ marginTop: "25px" }}>
                    <div className="col-md-3">
                      <label>Meeting ID</label>
                      <input
                        type="number"
                        name="MeetingID"
                        value={this.state.webinardetails.MeetingID}
                        placeholder={`Enter Meeting ID`}
                        className="form-control admin-form-control"
                        onChange={this.handleChange1}
                      />
                    </div>
                    <div className="col-md-3">
                      <label>Meeting Password</label>
                      <input
                        type="text"
                        name="MeetingPWD"
                        value={this.state.webinardetails.MeetingPWD}
                        placeholder={`Enter Meeting Password`}
                        className="form-control admin-form-control"
                        onChange={this.handleChange1}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="row col_margin mt_2v">
                  <div className="col-md-6">
                    <div className="URL-BOX col-md-12 mtb-10 ptb_4v">
                      {" "}
                      <span> URL </span>
                      <input
                        type="url"
                        name="webinarUrl"
                        size="100"
                        value={this.state.webinardetails.webinarUrl}
                        onChange={this.handleChange1}
                      />
                    </div>

                    <div className="col-md-12 file_upload_admin ptb_4v flex_center flex_colum">
                      {this.state.v_file !== null ? (
                        <div className="col-md-12">
                          <div
                            className="pointer"
                            style={{
                              position: "absolute",
                              color: "#fc5757",
                              paddingLeft: "5px",
                              paddingTop: "2px",
                              right: "20px",
                              zIndex: "1000",
                            }}
                            onClick={this.removeVideoRecorded}
                          >
                            <i className="fa fa-times-circle fa-lg" />{" "}
                          </div>
                          <Player playsInline src={this.state.v_file} />
                        </div>
                      ) : (
                        <Dropzone
                          name="dropzone2"
                          accept="video/*"
                          onDrop={this.handleOnDropRecordedVideo}
                          maxSize={1e9}
                          multiple={false}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <section>
                              <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <img
                                  src={img}
                                  className="height_7v mtb-10"
                                  alt="Download"
                                />
                                <h6 className="mb_unset">
                                  Upload your file here
                                </h6>
                                <br />
                                Click or Drag Video to Upload here the recorded
                                webinar
                                <br />
                                (mp4, m4a, mov, 3gp, ogg, ogx, wmv, flv, avi)
                              </div>
                            </section>
                          )}
                        </Dropzone>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            ""
          )}
          {this.state.instructor != null ? (
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group bm-0">
                    <label
                      className=" form-check-label bm-0 p-5 opt-label"
                      for="ins"
                    >
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="ins"
                        onChange={this.handleToggle}
                        checked={this.state.seeInstructor}
                      />
                      <span className="checkbox-fix-admin"> Instructor</span>
                      <br></br>
                    </label>{" "}
                  </div>

                  <CreatableSelect
                    value={this.state.selectedOption}
                    onChange={this.instructorHandleChange}
                    options={this.state.ioptions}
                    className={this.state.seeInstructor ? "" : "hide"}
                    isMulti
                  />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}{" "}
          <br />
          {/* <Loader showLoader={this.state.showLoader} /> */}
        </form>
        {/* {this.props.isEdit == true && (
          <div className="form-group">
            <label>
              Feedback <span className="clr-red">*</span>
            </label>
            <textarea
              required
              className="form-control admin-form-control"
              rows="6"
              value={this.state.feedback}
              name="feedback"
              placeholder={`Enter Feedback`}
              onChange={this.handlefeedback}
            />
            <div className="mtb-20">
              <button
                className="btn btn-success"
                style={{ "margin-right": "15px" }}
                onClick={this.handleapprove}
              >
                Approve
              </button>
              <button className="btn  btn-danger" onClick={this.handlereject}>
                Reject
              </button>
            </div>
          </div>
        )} */}
        {this.props.isEdit == true
          ? (this.props.User.RoleID === 8 ||
              this.props.User.RoleID === 7 ||
              this.props.User.RoleID === 3 ||
              this.props.User.RoleID === 4 ||
              this.props.User.RoleID === 5) && (
              <Feedback_Webinar
                webinarId={
                  this.props.currentWebinarData &&
                  this.props.currentWebinarData.CourseID
                }
                owner={
                  this.props.currentWebinarData &&
                  this.props.currentWebinarData.user_id
                }
              />
            )
          : ""}
        {this.props.isEdit == true ? (
          <div className="col-md-12">
            <button
              className="btn float-right btn-save   black bold "
              onClick={this.onSubmit}
            >
              Save and Next
            </button>{" "}
            &nbsp; &nbsp;
          </div>
        ) : (
          <div className="col-md-12">
            <button
              className="btn float-right btn-save   black bold "
              onClick={this.onSubmit}
              {...(this.state.saveEnabled
                ? { disabled: false }
                : { disabled: true })}
            >
              {this.props.flag == true ? "Save and Next" : "Save and Next"}
            </button>{" "}
            &nbsp; &nbsp;
          </div>
        )}
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    webinarDetails: state.webinarData.webinarDetails,
    flag: state.webinarData.FirstTimeSave.details,
    User: state.userData.admin,
    formData: state.webinarData.formData,
  };
}
export default withRouter(
  connect(mapStateToProps, {
    webinarFeedback,
  })(AddCourse)
);
